// -----------------------------------------
// The loadingReducer is a place to store all the loading that is required
// The format under the reducer is
// {
//   loading: (true/false) Is the loading currently occurring
//   loaded: {true/false} Has it been loaded?
//   error: '' Is there an error during the load
//   startedAt: null/int - If this is null then it hasn't started loading
//   endedAt: null/int - If this is null it hasn't finished loading
// }
//
//  It's important to note that the selectors will always return loading: true
//  if you haven't initialized the action.loading. You can't rely on loading: true
//  to know if the loading has started. You can only rely on loaded: true means
//  the loading was completed, either success or failure depending on the error value
// -----------------------------------------

import initialState from 'state/initialState';

const loadingReducer = (state = initialState.loading, action) => {
  if (action.loadingKey) {
    if (/loading.LOAD_START_/g.exec(action.type)) {
      return {
        ...state,
        [action.loadingKey]: {
          ...state[action.loadingKey],
          loading: true,
          loaded: false,
          startedAt: action.startedAt,
          endedAt: null,
          error: null,
        },
      };
    }
    if (/loading.LOAD_SUCCESS_/g.exec(action.type)) {
      return {
        ...state,
        [action.loadingKey]: {
          ...state[action.loadingKey],
          loading: false,
          loaded: true,
          error: null,
          endedAt: action.endedAt,
        },
      };
    }
    if (/loading.LOAD_ERROR_/g.exec(action.type)) {
      return {
        ...state,
        [action.loadingKey]: {
          ...state[action.loadingKey],
          loading: false,
          loaded: true,
          error: action.error,
          endedAt: action.endedAt,
        },
      };
    }
    return state;
  }
  return state;
};

export default loadingReducer;
