import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputRadio extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    defaultValue: PropTypes.string,
    elementClass: PropTypes.string,
    groupValue: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    updateHandler: PropTypes.func,
    wrapperClass: PropTypes.string,
  };

  componentDidMount() {
    if (this.props.checked) {
      this.refs.input.setAttribute('checked', 'checked');
    }
  }

  updateValue = () => {
    const { value } = this.refs.input;
    const fieldName = this.props.name;
    if (typeof this.props.updateHandler === 'function') {
      this.props.updateHandler(value, fieldName);
    }
  };

  componentDidUpdate() {
    const { defaultValue } = this.props;
    const { groupValue } = this.props;
    if (defaultValue === groupValue) {
      this.refs.input.checked = 'checked';
    }
  }

  render() {
    const { defaultValue, elementClass, label, labelClass, name, wrapperClass } = this.props;

    return (
      <label className={ ['ts__radio', wrapperClass].join(' ') }>
        <input
          className={ elementClass }
          defaultValue={ defaultValue }
          name={ name }
          onChange={ this.updateValue }
          type="radio"
          ref="input"
        />
        <label className={ ['ts__radio-label', labelClass].join(' ') } htmlFor={ name }>
          { label }
        </label>
      </label>
    );
  }
}

export default InputRadio;
