import { selectProgramMembers } from 'state/programMembers/selectors';
import { createSelector } from 'reselect';

export const selectCurrentPage = state => state.leagueMembers.listView.currentPage;
export const selectPerPage = state => state.leagueMembers.listView.perPage;
export const selectTotalPages = state => state.leagueMembers.listView.totalPages;
export const selectSearchFilter = state => state.leagueMembers.listView.searchFilterParams;
export const selectTotalProgramMemberCount = state => state.leagueMembers.listView.totalProgramMemberCount;

// selects the org whole org member for every member id in state on the current page or returns null
export const selectProgramMembersByPage = (state, currentPage) => {
  if (state.leagueMembers.listView.programMemberIdsPerPage[currentPage]) {
    return (state.leagueMembers.listView.programMemberIdsPerPage[currentPage] || []).map(
      id => selectProgramMembers(state)[id],
    );
  }
  return null;
};

export const selectProgramMemberCountRange = createSelector(
  selectCurrentPage,
  selectPerPage,
  selectTotalProgramMemberCount,
  (currentPage, perPage, totalProgramMembers) => {
    // eslint-disable-next-line no-mixed-operators
    const firstProgramMemberIndex = (currentPage - 1) * perPage + 1;
    let lastProgramMemberIndex = currentPage * perPage;
    if (lastProgramMemberIndex > totalProgramMembers) {
      lastProgramMemberIndex = totalProgramMembers;
    }
    return `${firstProgramMemberIndex} - ${lastProgramMemberIndex}`;
  },
);

// selects the org whole org member for every member id in state on the current page or returns empty array
export const selectProgramMembersByCurrentPage = state =>
  selectProgramMembersByPage(state, selectCurrentPage(state)) || [];
