import getErrorMessage from 'utils/errorMessage';
import teamsnapSdk from 'utils/teamsnapSdk';
import forOwn from 'lodash/forOwn';

import { selectById } from './selectors';

import { setRegistrationVersion } from 'state/leagueRostering/actions';
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { convertReg2Items2Reg1Items } from '../leagueRostering/helpers';

import {
  loadLeagueCustomFieldsByDivisionId,
  loadAdditionalQuestionsFiltersByDivisionId,
} from 'state/teamsnap/leagueCustomFields/actions';
const pluralizeType = (type) => teamsnapSdk().getPluralType(type);

function convertToActionType(type) {
  // Bypass the need to change teamsnapSdk to get plural type for particiapntGroup
  const pluralType = pluralizeType(type) || 'participantGroups';
  return pluralType.replace(/[A-Z]/g, (char) => `_${char}`)
    .toUpperCase();
}

// -----------------------------------------------------
// Load actions
// -----------------------------------------------------
const loadItemsStartAction = ({ itemType, loadParams }) => ({
  type: `teamsnap.LOAD_ITEMS_START_${convertToActionType(itemType)}`,
  itemType,
  loadParams,
});

const loadItemsSuccessAction = ({ itemType, loadParams, items }) => ({
  type: `teamsnap.LOAD_ITEMS_SUCCESS_${convertToActionType(itemType)}`,
  itemType,
  loadParams,
  items,
});

const loadItemsErrorAction = ({ itemType, loadParams, error }) => ({
  type: `teamsnap.LOAD_ITEMS_ERROR_${convertToActionType(itemType)}`,
  itemType,
  loadParams,
  error,
});

const addItemsAction = ({ itemType, items, loadParams }) => ({
  type: `teamsnap.ADD_ITEMS_${convertToActionType(itemType)}`,
  itemType,
  items,
  loadParams,
});

const addItems =
  ({ items, loadParams }) =>
    (dispatch) =>
      new Promise((resolve) => {
        const itemsByType = {};
        items.forEach((item) => {
          if (!itemsByType[item.type]) {
            itemsByType[item.type] = [];
          }
          itemsByType[item.type].push(item);
        });
        forOwn(itemsByType, (items, itemType) => {
          dispatch(addItemsAction({ itemType, items, loadParams }));
        });
        resolve();
      });

const loadAdditionalQuestionsFilters = (loadParams) => (dispatch) => {
  const itemType = 'leagueCustomField';
  dispatch(loadItemsStartAction({ itemType, loadParams }));
  const reqUrl = `${teamsnapSdk().apiUrl}/signup_custom_fields?division_id=${loadParams.divisionId}`;

  teamsnapSdk()
    .request.get(reqUrl)
    .then(
      (response) => {
        const items = response.data.data;
        dispatch(addItemsAction({ itemType, items, loadParams }));
        return dispatch(loadItemsSuccessAction({ itemType, loadParams, items }));
      },
      (error) => {
        const errorMessage = getErrorMessage(error);
        return dispatch(loadItemsErrorAction({ itemType, loadParams, error: errorMessage }));
      },
    );
};

const loadItems =
  (pluralItemType, loadParams, sdkFunction = 'loadItems') =>
    (dispatch, getState) => {
      const activeDivision = selectActiveDivision(getState());
      const itemType = teamsnapSdk().getSingularType(pluralItemType);
      dispatch(loadItemsStartAction({ itemType, loadParams }));

      // Grab reg2 registrartion forms first and see if we have any
      const v1ItemsPromise = teamsnapSdk()[sdkFunction](itemType, loadParams);
      const v2ItemsPromise = teamsnapSdk().request.get(
        `${teamsnapSdk().apiUrl}/signup_forms?division_id=${loadParams.divisionId}`,
      );

      return Promise.all([v1ItemsPromise, v2ItemsPromise])
        .then((results) => {
          let items = [];
          const v1Items = results[0] || {};
          const v2Items = results[1].data.data || {};

          if (itemType === 'registrationForm') {
            if (v1Items.length > 0) {
              dispatch(setRegistrationVersion(1));
              dispatch(loadLeagueCustomFieldsByDivisionId(activeDivision.rootId));
              items = v1Items;
            }

            if (v2Items.length > 0) {
              dispatch(setRegistrationVersion(2));
              dispatch(loadAdditionalQuestionsFiltersByDivisionId(activeDivision.rootId));

              // Add particpant groups to the store
              const participantGroups = v2Items.map(f => f.participant_groups).flat();
              dispatch(addItemsAction({ itemType: 'participantGroup', loadParams, items: participantGroups }));
              
              // Add addition questions to store
              dispatch(loadAdditionalQuestionsFiltersByDivisionId(activeDivision.rootId));
              items = convertReg2Items2Reg1Items(v2Items);
            }

            if (v1Items.length > 0 && v2Items.length > 0) {
              dispatch(setRegistrationVersion(1));

              items = v1Items;
            }
          } else {
            // If we are not looking at registrations, pass on the value we used to here
            // which is the results from the promise to reg1
            items = v1Items;
          }

          dispatch(addItemsAction({ itemType, items, loadParams }));

          return dispatch(loadItemsSuccessAction({ itemType, loadParams, items }));
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);

          return dispatch(loadItemsErrorAction({ itemType, loadParams, error: errorMessage }));
        });
    };

// -----------------------------------------------------
// Save actions
// -----------------------------------------------------

const saveItemStartAction = (item) => ({
  type: `teamsnap.SAVE_ITEM_START_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const saveItemSuccessAction = (item) => ({
  type: `teamsnap.SAVE_ITEM_SUCCESS_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const saveItemErrorAction = ({ item, error }) => ({
  type: `teamsnap.SAVE_ITEM_ERROR_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
  error,
});

const updateItem = (item) => ({
  type: `teamsnap.UPDATE_ITEM_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const saveItem = (item) => (dispatch, getState) => {
  dispatch(saveItemStartAction(item));
  return teamsnapSdk()
    .saveItem(item)
    .then(
      (item) => {
        if (selectById(getState(), item.type, item.id)) {
          dispatch(updateItem(item));
        } else {
          dispatch(addItems({ items: [item] }));
        }
        return dispatch(saveItemSuccessAction(item));
      },
      // eslint-disable-next-line
      (error) => {
        const errorMessage = getErrorMessage(error);
        return dispatch(saveItemErrorAction({ item, error: errorMessage, fullError: error }));
      },
    );
};

// -----------------------------------------------------
// Delete actions
// -----------------------------------------------------

const deleteItemStartAction = (item) => ({
  type: `teamsnap.DELETE_ITEM_START_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const deleteItemSuccessAction = (item) => ({
  type: `teamsnap.DELETE_ITEM_SUCCESS_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const deleteItemErrorAction = ({ item, error }) => ({
  type: `teamsnap.DELETE_ITEM_ERROR_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
  error,
});

const removeItem = (item) => ({
  type: `teamsnap.REMOVE_ITEM_${convertToActionType(item.type)}`,
  itemType: item.type,
  item,
});

const deleteItem = (item) => (dispatch) => {
  dispatch(deleteItemStartAction(item));
  return teamsnapSdk()
    .deleteItem(item)
    .then(
      () => {
        dispatch(removeItem(item));
        dispatch(deleteItemSuccessAction(item));
      },
      // eslint-disable-next-line
      (error) => {
        const errorMessage = getErrorMessage(error);
        return dispatch(deleteItemErrorAction({ item, error: errorMessage }));
      },
    );
};

export {
  loadItems,
  addItems,
  loadItemsStartAction,
  loadItemsSuccessAction,
  loadItemsErrorAction,
  addItemsAction,
  saveItemStartAction,
  saveItemSuccessAction,
  saveItemErrorAction,
  updateItem,
  saveItem,
  deleteItemStartAction,
  deleteItemSuccessAction,
  deleteItemErrorAction,
  removeItem,
  deleteItem,
  loadAdditionalQuestionsFilters,
};
