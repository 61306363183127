import * as constants from 'state/teamsnap/broadcastEmails/constants';
import { loadItems, removeItem } from 'state/teamsnap/actions';
import teamsnapSdk from 'utils/teamsnapSdk';
import getErrorMessage from 'utils/errorMessage';

const loadBroadcastEmailsByMemberId = memberId => dispatch => dispatch(loadItems('broadcastEmails', { memberId }));

const loadBroadcastEmailById = id => dispatch => dispatch(loadItems('broadcastEmails', { id }));

const deleteBroadcastEmailsStart = broadcastEmailIds => ({
  type: constants.DELETE_BROADCAST_EMAILS_START,
  broadcastEmailIds,
});

const deleteBroadcastEmailsSuccess = broadcastEmailIds => ({
  type: constants.DELETE_BROADCAST_EMAILS_SUCCESS,
  broadcastEmailIds,
});

const deleteBroadcastEmailsError = (error, broadcastEmailIds) => ({
  type: constants.DELETE_BROADCAST_EMAILS_ERROR,
  error,
  broadcastEmailIds,
});

function bulkDeleteBroadcastEmails(broadcastEmails, onSuccess, onError) {
  return dispatch => {
    const broadcastEmailIds = broadcastEmails.map(broadcastEmail => broadcastEmail.id);
    dispatch(deleteBroadcastEmailsStart(broadcastEmailIds));

    return teamsnapSdk()
      .bulkDeleteBroadcastEmails(broadcastEmailIds)
      .then(
        result => {
          broadcastEmails.forEach(broadcastEmail => {
            dispatch(removeItem(broadcastEmail));
          });
          if (onSuccess) {
            onSuccess();
          }
          return dispatch(deleteBroadcastEmailsSuccess(broadcastEmailIds));
        },
        error => {
          if (onError) {
            onError(error);
          }
          return dispatch(deleteBroadcastEmailsError(getErrorMessage(error), broadcastEmailIds));
        },
      );
  };
}

export { loadBroadcastEmailsByMemberId, loadBroadcastEmailById, bulkDeleteBroadcastEmails };
