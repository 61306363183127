import { connect } from 'react-redux';

import { selectErrorMessage, selectNoticeMessage } from 'state/app/selectors';

import AppMessage from './AppMessage';

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  errorMessage: selectErrorMessage(state),
  noticeMessage: selectNoticeMessage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMessage);
