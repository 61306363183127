import * as React from 'react';
import { Table, Tooltip, Icon } from '@teamsnap/teamsnap-ui';
import { linkDetailedMemberView } from 'utils/links';
import PropTypes from 'prop-types';
import { Popover, PopoverItem } from 'shared/toolkit';
import { Link } from 'react-router-dom';
import BackgroundCheckButton from './BackgroundCheckButton';
import TeamsAndDivisionsModal from '../../containers/TeamsAndDivisionsModalContainer';

import classes from './MembersTable.module.scss';

export class LeagueMembersTable extends React.Component {
  static propTypes = {
    programMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFetching: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    activeDivisionId: PropTypes.number.isRequired,
    analyticEvent: PropTypes.func.isRequired,
  };

  rows = rows =>
    rows.map(member => ({
      id: member.id,
      name: member.displayName,
      gender: member.gender,
      age: member.age,
      roles: member.role,
      programMemberships: member.programMemberships,
      actions: this.memberSnapActions(member),
      member,
    }));

  columns = () => [
    {
      name: 'name',
      label: 'Member Name',
      mods: 'u-size4of24',
      render: (column, row) => (
        <div key={ row.id }>
          <Link className={ classes.TruncateText } to={ linkDetailedMemberView(this.props.activeDivisionId, row.id) }>
            { row.name }
          </Link>
          { this.memberRoles(row.roles) }
          { this.manageInvites(row) }
          <BackgroundCheckButton member={ row.member } />
        </div>
      ),
    },
    { name: 'gender', label: 'Gender', mods: classes.DataColumn },
    { name: 'age', label: 'Age', mods: classes.DataColumn },
    {
      name: 'team1',
      label: 'Team/Division',
      mods: ['u-size9of24', classes.DataColumn].join(' '),
      render: (column, row) => this.showTeamsAndDivisions(row),
    },
    { name: 'actions', label: 'Actions', align: 'center' },
  ];

  manageInvites = ({ id, member }) => {
    const { activeDivisionId, analyticEvent } = this.props;
    if (member.invitationStatus === 'is_invitable') {
      const event = {
        eventCategory: 'members_tab',
        eventAction: 'manage_invites_button',
      };
      return (
        <Link
          className="Button Button--primary Button--small u-spaceTopSm"
          to={ linkDetailedMemberView(activeDivisionId, id) }
          onClick={ () => analyticEvent(event) }
        >
          Manage Invites
        </Link>
      );
    }
  };

  tooltip = () => (
    <div>
      Reg Status{ ' ' }
      <Tooltip
        type="icon"
        text='"Waiting for Payment" displays if one or more registrations are not paid in full.'
        className={ ['Tooltip', classes.TooltipLeft].join(' ') }
      >
        <Icon name="info" />
      </Tooltip>
    </div>
  );

  // Member Role function for displaying a single role
  memberRole(role) {
    return (
      <li className={ classes.CommaListItem } key={ role }>
        { role }
      </li>
    );
  }

  // Member Roles function for displaying a comma seperate list of roles
  memberRoles(roles) {
    const rolesList = roles.map(role => this.memberRole(role));
    return <ul className={ [classes.CommaList, classes.TruncateText].join(' ') }>{ rolesList }</ul>;
  }

  showTeamsAndDivisions(row) {
    if (row.programMemberships && row.programMemberships.length > 0) {
      return (
        <div>
          { row.programMemberships.map(programMembership => (
            <div className={ classes.TruncateText } key={ `${programMembership.id} + ${programMembership.teamName}` }>
              <span>{ programMembership.teamName }</span>
              <small> ({ programMembership.divisionName })</small>
            </div>
          )) }
        </div>
      );
    }

    return null;
  }

  showTeamsAndDivisionsModal = ({ id, firstName }) => {
    const { showModal } = this.props;
    const modalProps = {
      title: `Copy ${firstName} to Team / Division`,
      confirmationButtonText: 'Copy to Team/Division',
      successMessage: 'Member Added/Invited!',
      failureMessage: 'Member was not successfully added.',
      type: 'Add',
      origin: 'League Members Table',
      id,
    };
    this.sendAnalytics();
    return showModal(TeamsAndDivisionsModal, modalProps);
  };

  sendAnalytics = () => {
    const event = {
      eventCategory: 'members_tab',
      eventAction: 'snap_actions',
      eventLabel: 'add_to_team',
    };
    this.props.analyticEvent(event);
  };

  memberSnapActions(member) {
    const { id } = member;
    const actionItems = [
      <PopoverItem
        className="GI-snapManage"
        link={ linkDetailedMemberView(this.props.activeDivisionId, id) }
        id={ `${id} manage` }
        key={ `${id} manage` }
        icon="edit"
        text="Manage"
      />,
      <PopoverItem
        className="GI-snapAddToTeam"
        callBack={ () => this.showTeamsAndDivisionsModal(member) }
        id={ `${id} add` }
        key={ `${id} add` }
        icon="plus"
        text="Copy Profile"
        isModalTrigger
      />,
    ];
    return <Popover items={ actionItems } />;
  }

  render() {
    const { programMembers, isFetching } = this.props;

    return (
      <div>
        <Table
          defaultSort="name"
          columns={ this.columns() }
          rows={ this.rows(programMembers) }
          placeHolder="No members to show."
          isLoading={ isFetching }
        />
      </div>
    );
  }
}
