import React, { useEffect } from 'react';



const Safety = props => {
  const { rootDivisionId, initDefaultHealthCheckQuestionnaire } = props;
  useEffect(() => {
    if (initDefaultHealthCheckQuestionnaire){
      initDefaultHealthCheckQuestionnaire(rootDivisionId);
    }
  }, );


  return <> { props.children } </>;
};



export default Safety;
