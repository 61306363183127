import * as Sentry from '@sentry/react';
import createSentryMiddleware from 'redux-sentry-middleware';

// https://forum.sentry.io/t/switching-to-sentry-javascript/4732

import { notifyConfig } from 'utils/notifierConfig';

const isProduction = process.env.NODE_ENV === 'production';

// Setup & Initialize Exception Handler
export const setupNotifier = (config = {}) => {
  Sentry.init({ ...notifyConfig, ...config });
};

// Manually throw exception error
// Sentry will also 'catch' any thrown errors -> `throw new Error('testy')`
export const notify = (section, err, extra) => {
  const error = new Error(`${section} - ${err}`);

  if (isProduction) {
    Sentry.captureException(error, {
      extra: { data: extra },
    });
  }

  console.error('Exception Notifier: ', err, 'Extras: ', extra);
};

export default createSentryMiddleware(Sentry);
