import { connect } from 'react-redux';

// Selectors
import { selectRootDivisionPreferences } from 'state/teamsnap/divisionsPreferences/selectors';
// Components
import DateSelect from './DateSelect';

const mapDispatchToProps = (dispatch, ownProps) => ({});

const mapStateToProps = (state, ownProps) => ({
  dateDisplayMonthFirst: !selectRootDivisionPreferences(state).hasInternationalDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(DateSelect);
