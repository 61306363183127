import { ANALYTIC_EVENT } from 'state/analyticEvent/actions';
import { LOCATION_CHANGE } from 'state/locationChange/actions';

const gtmMiddleware = () => next => action => {
  window.dataLayer = window.dataLayer || [];

  if (action?.type === ANALYTIC_EVENT) {
    const { url, eventCategory, eventAction, eventLabel } = action.payload;
    window.dataLayer.push({
      event: 'League JS App Event',
      url,
      eventCategory,
      eventAction,
      eventLabel,
    });
  } else if (action?.type === LOCATION_CHANGE && action?.payload && action?.payload.pathname) {
    window.dataLayer.push({
      event: 'virtual-page-view',
      path: action.payload.pathname,
    });
  }
  return next(action);
};

export default gtmMiddleware;
