/**
 * Panel Component
 *
 * A panel component that requires children.  You can also send a full React
 * element to render unique headers and footers.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getClassNameStyles } from 'utils/utils';
import './Panel.module.scss';

const baseClassNames = {
  Panel: 'Panel',
  PanelHeader: 'Panel-header',
  PanelTitle: 'Panel-title',
  PanelBody: 'Panel-body',
  PanelRow: 'Panel-row',
  PanelFooter: 'Panel-footer',
};

class Panel extends PureComponent {
  getClassName = key => {
    const { componentClassNames, componentStyles } = this.props;
    return getClassNameStyles(key, baseClassNames, componentClassNames, componentStyles);
  };

  renderChildren = (children, isGrid) => (
    <div key={ children.key } className={ `${this.getClassName('PanelRow')} ${isGrid ? 'Grid' : ''}` }>
      { children }
    </div>
  );

  renderHeader = title => (
    <div className={ this.getClassName('PanelHeader') }>
      <h3 className={ this.getClassName('PanelTitle') }>{ title }</h3>
    </div>
  );

  render() {
    const { title, children, isRows, headerComponent, footerComponent, isGrid } = this.props;

    return (
      <section className={ this.getClassName('Panel') }>
        { title ? this.renderHeader(title) : headerComponent }

        <div className={ this.getClassName('PanelBody') }>
          { isRows && React.Children.toArray(children).map(child => this.renderChildren(child, isGrid)) }
          { !isRows && isGrid ? <div className="Grid">{ children }</div> : children }
        </div>

        { footerComponent && <div className={ this.getClassName('PanelFooter') }>{ footerComponent }</div> }
      </section>
    );
  }
}

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  headerComponent: PropTypes.node,
  footerComponent: PropTypes.node,
  isRows: PropTypes.bool,
  isGrid: PropTypes.bool,
  componentClassNames: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
};

Panel.defaultProps = {
  title: null,
  headerComponent: null,
  footerComponent: null,
  isRows: false,
  isGrid: false,
  componentClassNames: {},
  componentStyles: {},
};

export default Panel;
