import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { featureRollout } from 'utils/window';

// Form Elements
import InputCheckbox from 'shared/components/Form/InputCheckbox';
import InputRadio from 'shared/components/Form/InputRadio';
import InputSelect from 'shared/components/Form/InputSelect';
import InputText from 'shared/components/Form/InputText';

import DateSelectContainer from 'shared/components/DateSelect/DateSelectContainer';
import AdvancedFilterContainer from '../containers/AdvancedFilterContainer';

import classes from './EditFilter.module.scss';

const batchInvoiceStatuses = [
  { value: 'paid', name: 'Paid' },
  { value: 'open', name: 'Open' },
  { value: 'open,paid,submitted,pending', name: 'Paid and Open' },
  { value: 'canceled', name: 'Canceled' },
];

class EditFilter extends Component {
  state = {
    registration_form_id: null,
    registration_form_line_item_id: null,
    registration_form_line_item_option_id: null,
  };

  getDefaultValue = (fieldName, defaultValue = '') => {
    const { currentFilter } = this.props;
    const value = currentFilter[fieldName] || defaultValue;
    return value;
  };

  resetAllRegistrationFilters = () => {
    this.setState(
      {
        registration_form_id: null,
        registration_form_line_item_id: null,
        registration_form_line_item_option_id: null,
      },
      () => this.props.resetAllRegistrationFilters(),
    );
  };

  updateRegistrationState = (value, field) => {
    this.setState({ [value]: [field] });
    return this.props.updateField(value, field);
  };

  updateRegistrationFilters = (value, field) => {
    if (field === 'registration_form_id' && !value) {
      return this.resetAllRegistrationFilters();
    }
    if (field === 'registration_form_line_item_id' && !value) {
      return this.resetAllRegistrationFilters();
    }
    return this.updateRegistrationState(value, field);
  };

  saveFilter = () => {
    const completeSave = () => {
      this.props.formSaveFilter();
    };

    const { currentFilter } = this.props;
    const filterName = (currentFilter.search_title || '').trim();
    const lowerCaseFilterNames = this.props.filterNames.map(filterName => filterName.toLowerCase());

    if (filterName === '') {
      alert('You must specify a Search Title before saving');
    } else if (lowerCaseFilterNames.indexOf(filterName.toLowerCase()) > -1) {
      const confirmMessage = 'The search filter already exists,' + 'would you like to replace the current filter?';
      // eslint-disable-next-line no-restricted-globals
      if (confirm(confirmMessage)) {
        completeSave();
      }
    } else {
      completeSave();
    }
  };

  updateBornAfter = date => {
    this.props.updateField(date, 'born_after_date');
  };

  updateBornBefore = date => {
    this.props.updateField(date, 'born_before_date');
  };

  render() {
    const {
      addAdvancedFilter,
      currentFilter,
      formName,
      updateField,
      registrationForms,
      registrationFormLineItems,
      registrationFormLineItemOptions,
      batchInvoices,
    } = this.props;

    return (
      <section className="u-spaceMd">
        <div id="roster_filters" className={ classes['sub-panel'] }>
          <div className={ classes.QuickFilters }>
            <form id="roster_search" style={ { padding: '0' } }>
              <fieldset>
                <div className={ classes.FilterGroup }>
                  <div className={ classes.Filter }>
                    <h5 className={ classes['filter-title'] }>Gender</h5>

                    <div className={ classes.FilterGroupItem }>
                      <InputRadio
                        defaultValue="male"
                        groupValue={ this.getDefaultValue('gender') }
                        label="Male"
                        labelClass="Roster-Search__radio-label"
                        name="gender"
                        updateHandler={ updateField }
                        wrapperClass="Roster-Search__radio--inline"
                      />

                      <InputRadio
                        defaultValue="female"
                        groupValue={ this.getDefaultValue('gender') }
                        label="Female"
                        labelClass="Roster-Search__radio-label"
                        name="gender"
                        updateHandler={ updateField }
                        wrapperClass="Roster-Search__radio--inline"
                      />

                      <InputRadio
                        checked
                        defaultValue=""
                        groupValue={ this.getDefaultValue('gender') }
                        label="Any"
                        labelClass="Roster-Search__radio-label"
                        name="gender"
                        updateHandler={ updateField }
                        wrapperClass="Roster-Search__radio--inline"
                      />
                    </div>
                  </div>

                  <div className={ classes.FilterSpacer } />

                  <div className={ classes.Filter }>
                    <h5 className={ classes['filter-title'] }>Born on or After</h5>

                    <div className={ classes.FilterGroupItem }>
                      <InputCheckbox
                        checked={ this.getDefaultValue('born_after', false) }
                        labelClass="Roster-Search__checkbox-label"
                        name="born_after"
                        updateHandler={ (value, field) => {
                          if (value !== true) {
                            this.updateBornAfter(null);
                          }
                          updateField(value, field);
                        } }
                        wrapperClass={ classes['checkbox--inline'] }
                      />

                      <DateSelectContainer
                        dateFormat="YYYY-MM-DD"
                        defaultDate={ this.getDefaultValue('born_after_date') }
                        disabled={ !this.getDefaultValue('born_after') }
                        key={ `born_after${this.getDefaultValue('born_after_date')}` }
                        updateHandler={ this.updateBornAfter }
                      />
                    </div>
                  </div>

                  <div className={ classes.FilterSpacer } />

                  <div className={ classes.Filter }>
                    <h5 className={ classes['filter-title'] }>Born on or Before</h5>

                    <div className={ classes.FilterGroupItem }>
                      <InputCheckbox
                        checked={ this.getDefaultValue('born_before', false) }
                        name="born_before"
                        updateHandler={ (value, field) => {
                          if (value !== true) {
                            this.updateBornBefore(null);
                          }
                          updateField(value, field);
                        } }
                        wrapperClass={ classes['checkbox--inline'] }
                      />

                      <DateSelectContainer
                        dateFormat="YYYY-MM-DD"
                        defaultDate={ this.getDefaultValue('born_before_date') }
                        disabled={ !this.getDefaultValue('born_before') }
                        key={ `born_before${this.getDefaultValue('born_before_date')}` }
                        updateHandler={ this.updateBornBefore }
                      />
                    </div>
                  </div>

                  <div className={ classes.FilterSpacer } />

                  <div className={ `${classes.Filter} u-padBottomNone` }>
                    <InputCheckbox
                      checked={ this.getDefaultValue('include_rostered', false) }
                      label="Include Rostered Members"
                      name="include_rostered"
                      updateHandler={ updateField }
                    />

                    <InputSelect
                      defaultValue={ this.getDefaultValue('pending_assignments', 'include') }
                      name="pending_assignments"
                      options={ [
                        {
                          label: 'Include Pending Members',
                          value: 'include',
                        },
                        {
                          label: 'Exclude Pending Members',
                          value: 'exclude',
                        },
                        { label: 'Only Pending Members', value: 'only' },
                      ] }
                      updateHandler={ updateField }
                      wrapperClass="u-sizeFull"
                    />
                  </div>
                </div>

                <hr />

                <div className={ classes.FilterGroup }>
                  <div className={ classes.Filter }>
                    <h5 className={ classes['filter-title'] }>Registration Form</h5>

                    <InputSelect
                      disabled={ registrationForms.length === 0 }
                      defaultValue={ this.getDefaultValue('registration_form_id') }
                      name="registration_form_id"
                      options={ registrationForms }
                      optionsDataFields={ { label: 'name', value: 'id' } }
                      placeholder="Any Form"
                      updateHandler={ updateField }
                      wrapperClass={ classes.RegistrationSelect }
                    />

                    <InputSelect
                      disabled={ !this.getDefaultValue('registration_form_id') }
                      defaultValue={ this.getDefaultValue('registration_form_line_item_id') }
                      name="registration_form_line_item_id"
                      options={ registrationFormLineItems }
                      optionsDataFields={ { label: 'name', value: 'id' } }
                      placeholder="Any Fee"
                      wrapperClass={ classes.RegistrationSelect }
                      updateHandler={ updateField }
                    />

                    <InputSelect
                      disabled={
                        !this.getDefaultValue('registration_form_id') ||
                        !this.getDefaultValue('registration_form_line_item_id')
                      }
                      defaultValue={ this.getDefaultValue('registration_form_line_item_option_id') }
                      name="registration_form_line_item_option_id"
                      options={ registrationFormLineItemOptions }
                      optionsDataFields={ { label: 'name', value: 'id' } }
                      placeholder="Any Fee Choice"
                      wrapperClass={ classes.RegistrationSelect }
                      updateHandler={ updateField }
                    />
                  </div>

                  <div className={ classes.Filter }>
                    <h5 className={ classes['filter-title'] }>Batch Invoice</h5>
                    <InputSelect
                      disabled={ batchInvoices.length === 0 }
                      defaultValue={ this.getDefaultValue('batch_invoice_id') }
                      name="batch_invoice_id"
                      options={ batchInvoices }
                      optionsDataFields={ { label: 'title', value: 'id' } }
                      placeholder="Any Batch Invoice"
                      wrapperClass={ classes.BatchInvoiceSelect }
                      updateHandler={ updateField }
                    />
                    <InputSelect
                      disabled={ !this.getDefaultValue('batch_invoice_id') }
                      defaultValue={ this.getDefaultValue('invoice_status') }
                      name="invoice_status"
                      options={ batchInvoiceStatuses }
                      optionsDataFields={ { label: 'name', value: 'value' } }
                      placeholder="Any Status"
                      wrapperClass={ classes.BatchInvoiceSelect }
                      updateHandler={ updateField }
                    />
                  </div>
                </div>

                <hr />
              </fieldset>
            </form>
          </div>

          <div className={ classes.AdvancedFilters }>
            <div className={ classes.Filter }>
              <h5 className={ classes['filter-title'] }>
                Advanced Filters <small>( name, team, custom fields, etc. )</small>
              </h5>

              <div className={ classes.FilterGroupRows }>
                { currentFilter.advancedFilters &&
                  Object.keys(currentFilter.advancedFilters).map(key => {
                    const advancedFilter = currentFilter.advancedFilters[key];
                    return (
                      <AdvancedFilterContainer
                        key={ key }
                        advancedFilterIndex={ key }
                        currentFilter={ currentFilter }
                        field={ advancedFilter.field }
                        formName={ formName }
                        deleteFilter={ this.deleteAdvancedFilter }
                        value={ advancedFilter.value }
                        operator={ advancedFilter.operator }
                      />
                    );
                  }) }
              </div>

              <button className={ classes['small-button--success'] } onClick={ addAdvancedFilter }>
                Add Filter
              </button>
            </div>

            <hr />
            <div className={ classes['column-footer'] }>
              <h5 className={ classes['footer-title'] }>
                Save Your Filters for Next Time <small>( U5 Recreational Boys, U19 Competitive Girls, etc. )</small>
              </h5>
              <div className={ classes['save-filter'] }>
                <div className={ classes.input }>
                  <InputText
                    defaultValue={ this.getDefaultValue('search_title') }
                    name="search_title"
                    placeholder="Enter a title for your saved filters"
                    updateHandler={ updateField }
                    maxLength={ 25 }
                    elementClass="u-sizeFull"
                    wrapperClass="u-sizeFull"
                  />
                </div>
                <button className={ classes['button--ss-check--default'] } onClick={ this.saveFilter }>
                  Save Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

EditFilter.propTypes = {
  addAdvancedFilter: PropTypes.func,
  currentFilter: PropTypes.object,
  filterNames: PropTypes.array,
  formFieldHandler: PropTypes.func,
  formName: PropTypes.string,
  formSaveFilter: PropTypes.func,
  registrationForms: PropTypes.arrayOf(PropTypes.shape({})),
  batchInvoices: PropTypes.arrayOf(PropTypes.shape({})),
  updateField: PropTypes.func,
  registrationFormLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  registrationFormLineItemOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resetAllRegistrationFilters: PropTypes.func.isRequired,
};

export default EditFilter;
