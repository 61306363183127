import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldGroup } from '@teamsnap/teamsnap-ui';
import _parseInt from 'lodash/parseInt';

export class DivisionsAndTeamsDropdown extends Component {
  static propTypes = {
    selectTeam: PropTypes.func.isRequired,
    selectDivision: PropTypes.func.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.object).isRequired,
    teams: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTeamId: PropTypes.number,
    selectedDivisionId: PropTypes.number.isRequired,
  };

  selectDivision = e => {
    const { selectDivision, selectTeam } = this.props;
    const { value } = e.target;
    const selectedDivisionId = _parseInt(value);
    selectDivision(selectedDivisionId);
    selectTeam(null);
  };

  selectTeam = e => {
    const { value } = e.target;
    this.props.selectTeam(value);
  };

  render() {
    const { divisions, teams, selectedDivisionId, selectedTeamId } = this.props;

    const divisionList = divisions.map((item, index) => (
      <option key={ index } value={ item.value }>
        { item.label }
      </option>
    ));
    const teamList = teams.map((item, index) => (
      <option key={ index } value={ item.id }>
        { item.name }
      </option>
    ));

    return (
      <div>
        <FieldGroup name="divisions" label="divisions">
          <form onChange={ this.selectDivision }>
            <div className="FieldGroup">
              <label className="FieldGroup-label" htmlFor="select-1">
                Select Division
              </label>
              <div className="SelectBox">
                <select className="SelectBox-options" name="divisions" id="divisions" defaultValue={ selectedDivisionId }>
                  <option disabled="disabled" value={ 0 }>
                    - Select an option -
                  </option>
                  { divisionList }
                </select>
              </div>
            </div>
          </form>
          <form onChange={ this.selectTeam }>
            <div className="FieldGroup">
              <label className="FieldGroup-label" htmlFor="select-1">
                Select Team
              </label>
              <div className="SelectBox">
                <select className="SelectBox-options" name="teams" id="teams" defaultValue={ selectedTeamId }>
                  <option disabled="disabled" value={ 0 }>
                    - Select an option -
                  </option>
                  <option value={ -1 }>Unrostered / No Team</option>
                  { teamList.length ? (
                    <optgroup label="Teams in Division">{ teamList }</optgroup>
                  ) : (
                    <optgroup label="No teams in Division" />
                  ) }
                </select>
              </div>
            </div>
          </form>
        </FieldGroup>
      </div>
    );
  }
}
