import _forOwn from 'lodash/forOwn';
import _values from 'lodash/values';
import _sortBy from 'lodash/sortBy';
import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { isActive, isChildDivision } from 'utils/divisions';

import { selectMemberById } from 'state/teamsnap/members/selectors';
import { selectActiveDivisionId } from 'state/app/selectors';
import { select, selectAndSortBy, selectAsArray } from 'state/teamsnap/selectors';

const selectDivisions = state => select(state, 'divisions');
const selectDivisionById = (state, id) => selectDivisions(state)[_parseInt(id)];
const selectDivisionsAsArray = state => selectAsArray(state, 'divisions');
const selectActiveDivision = state => selectDivisionById(state, selectActiveDivisionId(state));

const selectActiveDivisionPersistentUuid = state => {
  const activeDivision = selectActiveDivision(state, selectActiveDivisionId(state));
  if (activeDivision) {
    return activeDivision.persistentUuid;
  }
  return null;
};

const selectActiveDivisionRootId = state => {
  const rootDivision = selectActiveDivision(state, selectActiveDivisionId(state));
  if (rootDivision) {
    return rootDivision.rootId;
  }
  return null;
};


const selectDivisionForMemberId = (state, memberId) => {
  const member = selectMemberById(state, memberId);
  let division = null;
  if (member && member.divisionId) {
    division = selectDivisionById(state, member.divisionId);
  }
  return division;
};

const selectActiveTeamsCountForDivisionId = state => divisionId => {
  let count = 0;
  const division = selectDivisionById(state, divisionId);
  if (division) {
    count = division.activeTeamsCount;
  }
  return count;
};

const selectAndSortDivisionsByParentId = createSelector([selectDivisions], allDivisions => {
  const divisionsByParentId = selectAndSortBy(allDivisions, 'parentId');
  _forOwn(divisionsByParentId, (divisions, parentId) => {
    if (divisions && divisions.length > 0) {
      divisionsByParentId[parentId] = _sortBy(divisions, ['name']);
    }
  });
  return divisionsByParentId;
});

const selectActiveDivisions = createSelector([selectDivisions], divisions => {
  const activeDivisions = {};
  _values(divisions).forEach(division => {
    if (isActive(division)) {
      activeDivisions[division.id] = division;
    }
  });
  return activeDivisions;
});

const selectDivisionsByParentId = state => divisionId =>
  selectAndSortDivisionsByParentId(state)[_parseInt(divisionId)] || [];

const selectActiveDivisionsByParentId = state => divisionId => {
  const divisions = selectDivisionsByParentId(state)(divisionId);
  return _values(divisions).filter(division => !division.isArchived);
};

// -----------------------------------------
// Return all the divisionIds for the division and all it's children
// -----------------------------------------

const selectDivisionAndChildDivisionIds = (state, divisionId) => {
  // go through all the divisions with the same root divsion
  const division = selectDivisionById(state, divisionId);
  const divisionsById = selectDivisions(state);

  const divisionIds = [];
  Object.keys(divisionsById).forEach(id => {
    const innerDivision = divisionsById[id];
    if (innerDivision && division) {
      if (
        innerDivision.rootId === division.rootId &&
        innerDivision.leftBoundary >= division.leftBoundary &&
        innerDivision.rightBoundary <= division.rightBoundary
      ) {
        divisionIds.push(divisionsById[id].id);
      }
    }
  });
  return divisionIds;
};

const selectAncestorDivisions = (state, divisionId) => {
  const divisionsById = selectDivisions(state);
  let currentDivision = selectDivisionById(state, divisionId);
  const ancestorDivisions = [];

  while (currentDivision && currentDivision.parentId > 0 && divisionsById[currentDivision.parentId]) {
    currentDivision = divisionsById[currentDivision.parentId];
    ancestorDivisions.push(currentDivision);
  }

  return ancestorDivisions;
};

const selectDescendantDivisions = (state, activeDivisionId) => {
  const rootDivision = selectDivisionById(state, activeDivisionId);
  const descendantDivisions = [];
  const divisions = selectDivisionsAsArray(state);
  divisions.forEach(division => {
    if (isChildDivision(rootDivision, division)) {
      descendantDivisions.push(division);
    }
  });
  return descendantDivisions;
};

const selectDivisionLeaves = (state, activeDivisionId) => {
  const divisionLeaves = selectDivisionsAsArray(state).filter(
    division => division.activeChildrenCount === 0 && division.id > activeDivisionId,
  );
  return divisionLeaves;
};

const selectDivisionTreeForDivisionId = state => divisionId => {
  // Note: This only returns active (non-archived) divisions
  const divisionTree = [selectDivisionById(state, divisionId)];
  const recurse = divisions => {
    divisions.forEach(division => {
      const divisionChildren = [...selectActiveDivisionsByParentId(state)(division.id)];
      division.childDivisions = divisionChildren;
      if (divisionChildren.length) {
        return recurse(divisionChildren);
      }
      return null;
    });
    return divisions;
  };
  return recurse(divisionTree);
};

const selectHigherDivision = (state, divisionId) => {
  const divisionsById = selectDivisions(state);
  let currentDivision = selectDivisionById(state, divisionId);
  const higherDivisions = [];
  while (
    currentDivision &&
    currentDivision.parentId > 0 &&
    divisionsById[currentDivision.parentId] &&
    higherDivisions.indexOf(currentDivision) < 0
  ) {
    // Had to add this ^ `higherDivisions.indexOf` becuase it was getting
    // stuck in an infinite loop. Thoughts here, Dustin?
    currentDivision = divisionsById[currentDivision.parentId];
    higherDivisions.push(currentDivision);
  }
  return higherDivisions;
};

const selectPotentialDivisions = (state, divisionId) => {
  const divisionLeaves = selectDivisionLeaves(state);
  const topDivision = selectDivisionById(state, divisionId);
  const divisionsInfo = {};
  _forOwn(divisionLeaves, (division, divisionId) => {
    // check if inside top division
    if (division.leftBoundary >= topDivision.leftBoundary && division.rightBoundary <= topDivision.rightBoundary) {
      if (division.parentId) {
        if (!divisionsInfo[division.parentId]) {
          const parentDivision = selectDivisionById(state, division.parentId);
          if (parentDivision) {
            const higherDivisions = selectHigherDivision(state, division.parentId);
            let { name } = parentDivision;
            if (higherDivisions && higherDivisions.length > 0) {
              name = `${higherDivisions
                .reverse()
                .map(division => division.name)
                .join(' - ')} - ${name}`;
            }
            divisionsInfo[division.parentId] = {
              id: parentDivision.id,
              name,
              children: [],
            };
          }
        }
        divisionsInfo[division.parentId].children.push({
          id: division.id,
          name: division.name,
        });
      } else {
        divisionsInfo[division.id] = { id: division.id, name: division.name };
      }
    }
  });
  return _values(divisionsInfo);
};

const selectParentDivisionForChild = state => divisionId => {
  let parent = null;
  const divisions = selectDivisions(state);
  const child = divisions[divisionId];
  if (child && child.parentId) {
    parent = divisions[child.parentId];
  }
  return parent;
};

const selectDivisionHierarchy = state => (divisionId, rootDivisionId = 0) => {
  const divisions = selectDivisions(state);
  const currentDivision = divisions[divisionId] || null;
  const hierarchy = [];
  if (currentDivision) {
    hierarchy.push(currentDivision);
  }
  let parent = selectParentDivisionForChild(state)(divisionId);
  while (parent !== null && parent.id >= rootDivisionId) {
    hierarchy.push(parent);
    parent = selectParentDivisionForChild(state)(parent.id);
  }
  return hierarchy.reverse();
};

const selectDivisionHierarchyUpToActiveDivision = state => divisionId =>
  selectDivisionHierarchy(state)(divisionId, selectActiveDivisionId(state));

const selectAncestorSelfAndDescendantDivisionIds = (state, divisionId) => [
  ...selectDivisionAndChildDivisionIds(state, divisionId),
  ...selectAncestorDivisions(state, divisionId).map(division => division.id),
];

const selectDivisionLeavesOrActiveDivision = state => {
  const divisionLeaves = selectDivisionLeaves(state, selectActiveDivisionId(state));
  if (divisionLeaves.length > 0) {
    return divisionLeaves;
  }
  return [selectActiveDivision(state)];
};

export {
  selectActiveDivision,
  selectActiveDivisionPersistentUuid,
  selectActiveDivisionRootId,
  selectActiveDivisions,
  selectActiveDivisionsByParentId,
  selectActiveTeamsCountForDivisionId,
  selectAndSortDivisionsByParentId,
  selectAncestorDivisions,
  selectAncestorSelfAndDescendantDivisionIds,
  selectDescendantDivisions,
  selectDivisions,
  selectDivisionById,
  selectDivisionsAsArray,
  selectDivisionsByParentId,
  selectDivisionHierarchy,
  selectDivisionTreeForDivisionId,
  selectDivisionAndChildDivisionIds,
  selectPotentialDivisions,
  selectDivisionForMemberId,
  selectDivisionHierarchyUpToActiveDivision,
  selectDivisionLeaves,
  selectDivisionLeavesOrActiveDivision,
};
