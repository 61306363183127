import React from 'react';

// Components
import { Button, Cell, Grid, Icon } from '@teamsnap/teamsnap-ui';

import styles from './SearchPill.module.scss';

const SearchPill = ({
  buttonText,
  children,
  direction,
  openedPopupId,
  popupFooter,
  popupId,
  popupStyle,
  popupTitle,
  popupTrigger,
  togglePopup,
}) => {
  const isPopupOpened = openedPopupId === popupId;

  const dirString = direction.reduce((acc, cur) => `${acc} Popup-container--${cur}`, '');

  /** handles click events outside of the pill's popup to close the popup */
  React.useEffect(() => {
    const closePopup = e => {
      const isTargetingPopup = e.target.closest('.Popup-content') != null;
      if (!isTargetingPopup) {
        togglePopup();
      }
    };

    if (isPopupOpened) {
      document.body.addEventListener('click', closePopup, { capture: true });
    }

    return () => {
      document.body.removeEventListener('click', closePopup);
    };
  }, [isPopupOpened]);

  return (
    <div className={ styles.searchPillContainer }>
      <div onClick={ () => togglePopup(popupId) }>{ popupTrigger }</div>
      <div
        className={ `Popup-container ${dirString}
          ${openedPopupId === popupId ? ' is-open' : ''}` }
        style={ popupStyle }
      >
        <div className="Popup-content">
          <Grid mods={ styles['Popup-title'] }>
            <Cell className="u-sizeFill">{ popupTitle }</Cell>
            <Cell className="u-sizeFit">
              <a onClick={ () => togglePopup(null) } className="u-colorGrey">
                <Icon className="Icon" name="dismiss" mods="u-colorGrey" />
              </a>
            </Cell>
          </Grid>
          <div className={ styles['Popup-body'] }>{ children }</div>
          { popupFooter }
        </div>
      </div>
    </div>
  );
};

export default SearchPill;
