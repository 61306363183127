import _parseInt from 'lodash/parseInt';

export const displayFileSize = bytes => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = _parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / (1024 ** i), 2)} ${sizes[i]}`;
};
