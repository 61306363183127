import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputCheckbox from 'shared/components/Form/InputCheckbox';
import FontIcon from 'shared/toolkit/FontIcon';
import RosterListRowContainer from '../containers/RosterListRowContainer';
import classes from './RosterList.module.scss';




class RosterList extends Component {
  static propTypes = {
    isAllSelected: PropTypes.bool,
    isSearching: PropTypes.bool,
    members: PropTypes.array,
    division: PropTypes.object,
    toggleRosterListSort: PropTypes.func,
    toggleSelectAllMembers: PropTypes.func,
  };

  makeClickSort = sortOn => () => {
    this.props.toggleRosterListSort(sortOn);
  };

  render() {
    const { isAllSelected, isSearching, members, toggleSelectAllMembers, division } = this.props;
    const sortIcon = <FontIcon name="icon-sort" />;

    return (
      <div className="RosterList__panel">
        <div className={ `RosterList__${classes['panel-header']}` }>
          <div
            className={ `${classes['col-player-name']} ${classes.sortable}` }
            onClick={ this.makeClickSort('firstName') }
          >
            <span className={ classes['table-header'] }>First Name</span>
            { sortIcon }
          </div>
          <div className={ `${classes['col-player-name']} ${classes.sortable}` } onClick={ this.makeClickSort('lastName') }>
            <span className={ classes['table-header'] }>Last Name</span>
            { sortIcon }
          </div>
          <div className={ `${classes['col-player-gender']} ${classes.sortable}` } onClick={ this.makeClickSort('gender') }>
            <span className={ classes['table-header'] }>Gender</span>
            { sortIcon }
          </div>
          <div className={ `${classes['col-player-age']} ${classes.sortable}` } onClick={ this.makeClickSort('age') }>
            <span className={ classes['table-header'] }>Age</span>
            { sortIcon }
          </div>
          <div className={ `${classes['col-player-team']} ${classes.sortable}` } onClick={ this.makeClickSort('team') }>
            <span className={ classes['table-header'] }>Team</span>
            { sortIcon }
          </div>
          <div className={ classes['col-player-select'] }>
            <InputCheckbox
              wrapperClass={ classes.centerCheckBox }
              checked={ isAllSelected }
              updateHandler={ toggleSelectAllMembers }
              name="mass_actions_selector"
              readOnly={ true }
            />
          </div>
        </div>
        <div className={ classes['panel-body'] }>
          <table
            id="players_table"
            className={ classes['table--full-width'] }
            cellPadding={ 0 }
            data-league-roster-table
            role="grid"
          >
            { !isSearching &&
              members &&
              members.map(member => <RosterListRowContainer key={ member.id } member={ member } />) }

            { members.length === 0 && (
              <tbody>
                <tr>
                  <td className={ classes.noMembers }>
                    <i className={ classes['noMembersIcon--ss-user'] } />
                    <p className={ classes.noMembersText }>Sorry, no members found. Please update filters above.</p>
                    <small className={ classes.noMembersSubText }>
                      Note, if no players are assigned to this division you may need to navigate to a higher division
                      within the divisions tab.
                    </small>
                  </td>
                </tr>
              </tbody>
            ) }
          </table>
        </div>
      </div>
    );
  }
}

export default RosterList;
