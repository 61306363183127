const selectForms = state => (state.forms && state.form) || {}; // eslint-disable-line

const selectFormByName = (state, formName) => {
  const forms = selectForms(state);
  return forms[formName];
};

const selectFormValues = (state, formName) => {
  const forms = selectForms(state);
  return forms[formName].values;
};

export { selectForms, selectFormByName, selectFormValues };
