import ENVIRONMENT from 'environment';
import { EnvironmentUtilities } from 'frontend-toolkit';

const envUtils = new EnvironmentUtilities(window.location.host);

export const ORG_API_URL = {
  local: 'http://localhost:5302',
  test: 'http://localhost:5302',
  staging: `https://${envUtils.getStagingHost('organization-api', 'staging-organization-api.teamsnap.com')}`,
  prod: 'https://organization-api.teamsnap.com',
}[ENVIRONMENT];

export const LOADING_APP_START = 'app.LOADING_APP_START';
export const LOADING_APP_SUCCESS = 'app.LOADING_APP_SUCCESS';
export const LOADING_APP_ERROR = 'app.LOADING_APP_ERROR';

export const LOADING_DIVISON_START = 'app.LOADING_DIVISON_START';
export const LOADING_DIVISON_SUCCESS = 'app.LOADING_DIVISON_SUCCESS';
export const LOADING_DIVISON_ERROR = 'app.LOADING_DIVISON_ERROR';

export const SET_APP_ERROR = 'app.SET_APP_ERROR';
export const SET_APP_NOTICE = 'app.SET_APP_NOTICE';
export const SET_STARTUP_MESSAGE = 'app.SET_STARTUP_MESSAGE';

export const SET_LOGGED_IN_MEMBER_ID = 'app.SET_LOGGED_IN_MEMBER_ID';

export const ADD_ROLLOUTS = 'app.ADD_ROLLOUTS';

export const SET_ACTIVE_DIVISON_ID = 'app.SET_ACTIVE_DIVISON_ID';

export const SET_LOADED_DIVISIONS = 'app.SET_LOADED_DIVISIONS';
export const SET_LOADED_DIVISION_TEAMS = 'app.SET_LOADED_DIVISION_TEAMS';
export const SET_LOADED_DIVISION_TEAM_NAMES = 'app.SET_LOADED_DIVISION_TEAM_NAMES';

export const SHOW_MODAL = 'app.SHOW_MODAL';
export const HIDE_MODAL = 'app.HIDE_MODAL';

export const SHOW_FEEDBACK = 'app.SHOW_FEEDBACK';
export const HIDE_FEEDBACK = 'app.HIDE_FEEDBACK';

export const SET_WEPAY_STATUS = 'app.SET_WEPAY_STATUS';

export const SET_SNAPI_URL = 'teamsnapSdk.SET_SNAPI_URL';
export const SET_AUTH_TOKEN = 'teamsnapSdk.SET_AUTH_TOKEN';
export const LOAD_COLLECTIONS_START = 'teamsnapSdk.LOAD_COLLECTIONS_START';
export const LOAD_COLLECTIONS_SUCCESS = 'teamsnapSdk.LOAD_COLLECTIONS_SUCCESS';
export const LOAD_COLLECTIONS_ERROR = 'teamsnapSdk.LOAD_COLLECTIONS_ERROR';
