import { connect } from 'react-redux';
// Actions
import { assignMembersToActiveDivision } from 'state/leagueRostering/actions';
import { selectRosteringPendingMode } from 'state/leagueRostering/selectors';
import { showModal } from 'state/modal/actions';
// Selectors
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { selectMembers } from 'state/teamsnap/members/selectors';
// Components
import RosterUnassignmentDropTarget from '../components/RosterUnassignmentDropTarget';


const mapDispatchToProps = (dispatch, ownProps) => ({
  assignMembers: (memberId, isManager) => dispatch(assignMembersToActiveDivision([memberId], isManager)),
  showModal: (modal, modalProps) => {
    dispatch(showModal(modal, modalProps));
  },
});

const mapStateToProps = (state, ownProps) => ({
  divisionName: selectActiveDivision(state).name,
  isPendingMode: selectRosteringPendingMode(state),
  members: selectMembers(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterUnassignmentDropTarget);
