import { selectLoaded, selectLoading } from 'state/loading/selectors';

const selectLoadingApp = state => selectLoading(state, 'app');
const selectAppLoaded = state => selectLoaded(state, 'app');
const selectLoadingDivision = (state, divisionId) => selectLoading(state, 'rootDivision');

const selectStartupMessage = state => state.app.startupMessage;

const selectActiveDivisionId = state => state.app.activeDivisionId;
const selectIsWepayReady = state => state.app.isWePayReady;

const selectNoticeMessage = state => state.app.noticeMessage;
const selectErrorMessage = state => state.app.errorMessage;

const selectLoadedDivisions = (state, divisionId) =>
  state.app.loadedDivisions.some(_divisionId => _divisionId === divisionId);

const selectLoadedDivisionTeams = (state, divisionId) =>
  state.app.loadedDivisionTeams.some(_divisionId => _divisionId === divisionId);

const selectLoadedDivisionTeamNames = (state, divisionId) =>
  state.app.loadedDivisionTeamNames.some(_divisionId => _divisionId === divisionId);

const selectAuthenticatedUserId = () => window.TeamSnap.userId;

const selectSnapiUrl = state => state.app.snapiUrl;
const selectAuthToken = state => state.app.authToken;

export {
  selectSnapiUrl,
  selectAuthToken,
  selectLoadingApp,
  selectAppLoaded,
  selectLoadingDivision,
  selectLoadedDivisions,
  selectStartupMessage,
  selectActiveDivisionId,
  selectIsWepayReady,
  selectNoticeMessage,
  selectErrorMessage,
  selectLoadedDivisionTeams,
  selectLoadedDivisionTeamNames,
  selectAuthenticatedUserId,
};
