import { connect } from 'react-redux';
import { assignSelectedMembersToDivision } from 'state/leagueRostering/actions';
import { selectRosteringPendingMode, selectSelectedMembersCount } from 'state/leagueRostering/selectors';
import {
  selectDivisionAggregateByDivisionIdOrEmpty,
  selectDivisionAggregateSelfAndDescendantAllMemberCount,
  selectSelfAndDescendantsCommissionerCount
} from 'state/teamsnap/divisionAggregates/selectors';
import { selectDivisionById } from 'state/teamsnap/divisions/selectors';
import DivisionAssignmentOverview from '../components/DivisionAssignmentOverview';



const mapDispatchToProps = (dispatch, ownProps) => ({
  assignMembers: () => dispatch(assignSelectedMembersToDivision(ownProps.division.id)),
});

const mapStateToProps = (state, ownProps) => {
  const aggregateDivision = selectDivisionAggregateByDivisionIdOrEmpty(state, ownProps.division.id);
  const pendingAssignmentsCount = aggregateDivision.selfAndDescendantsPendingMemberCount;
  const pendingRemovalsCount = aggregateDivision.selfAndDescendantsPendingMemberRemovalCount;
  return {
    allMemberCount: selectDivisionAggregateSelfAndDescendantAllMemberCount(state, ownProps.division.id),
    allRosteredCount: aggregateDivision.selfAndDescendantsAssignedMemberCount,
    allUnRosteredCount: aggregateDivision.selfAndDescendantsUnassignedMemberCount,
    assignedToDivisionCount: aggregateDivision.unassignedMemberCount,
    countOfSelectedMembers: selectSelectedMembersCount(state),
    division: selectDivisionById(state, ownProps.division.id),
    isPendingMode: selectRosteringPendingMode(state),
    isPlayerTab: ownProps.isPlayerTab,
    setIsPlayerTab: ownProps.setIsPlayerTab,
    pendingChangesCount: pendingAssignmentsCount + pendingRemovalsCount,
    selfAndDescendantsCommissionerCount: selectSelfAndDescendantsCommissionerCount(state, ownProps.division.id),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DivisionAssignmentOverview);
