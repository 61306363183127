import _values from 'lodash/values';
import { createSelector } from 'reselect';
import { COLLECTIONS } from 'state/snapi/slice';
import { selectRegistrationForms } from 'state/registrationForms/selectors';

const stateSelector = state => state.snapi[COLLECTIONS.MEMBER_REGISTRATION_SIGNUPS];
const isFetchingSelector = state => stateSelector(state).isFetching;
const isErrorSelector = state => stateSelector(state).isError;
const itemSelector = state => stateSelector(state).items;

// Select members Fetching or Error states
export const selectMemberRegistrationSignupsIsFetching = state => isFetchingSelector(state);
export const selectMemberRegistrationSignupsIsError = state => isErrorSelector(state);

export const selectMemberRegistrationSignupsIsLoading = state => stateSelector(state).status === 'loading';

export const selectRawMemberRegistrationSignups = state => itemSelector(state) || {};
export const selectMemberRegistrationSignupsValues = state => _values(itemSelector(state));

export const selectMemberRegistrationSignupsByMemberId = createSelector(
  selectMemberRegistrationSignupsValues,
  selectRegistrationForms,
  (memberRegistrationSignups, regForms) => {
    const registrationSignups = {};
    _values(memberRegistrationSignups).forEach(regSignup => {
      if (regSignup.memberIds) {
        regSignup.memberIds.forEach(memberId => {
          registrationSignups[memberId] = {
            ...regSignup,
            registrationForm: regForms[regSignup.registrationFormId] || [],
          };
        });
      }
    });
    return registrationSignups;
  },
);

export const selectRegistrationFormIdsFromMemberRegistrationSignups = createSelector(
  selectMemberRegistrationSignupsValues,
  memberRegistrationSignups => {
    const registrationFormIds = [];
    _values(memberRegistrationSignups).forEach(regSignup => {
      if (!registrationFormIds.includes(regSignup.registrationFormId)) {
        registrationFormIds.push(regSignup.registrationFormId);
      }
    });
    return registrationFormIds;
  },
);
