import { connect } from 'react-redux';

// Actions
import { saveFilterFromForm, addAdvancedFilter, updateCurrentFilterField } from 'state/leagueRostering/actions';
import { updateFieldByFormName } from 'state/forms/actions';
// Selectors
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { selectCurrentFilter, selectFilterNames } from 'state/leagueRostering/selectors';
import { selectRegistrationFormsAsArraySortedByActive } from 'state/teamsnap/registrationForms/selectors';
import { selectRegistrationLineItemsByFormId } from 'state/teamsnap/registrationFormLineItems/selectors';
import { selectRegistrationLineItemOptionsByLineItemId } from 'state/teamsnap/registrationFormLineItemOptions/selectors';
import { selectRootDivisionPreferences } from 'state/teamsnap/divisionsPreferences/selectors';
import { selectBatchInvoicesByDivisionId } from 'state/teamsnap/batchInvoices/selectors';
// Components
import EditFilter from '../components/EditFilter';

const mapDispatchToProps = (dispatch, ownProps) => ({
  formFieldHandler: (value, fieldName) => {
    dispatch(updateFieldByFormName(ownProps.formName, fieldName, value));
  },
  formSaveFilter: () => {
    dispatch(saveFilterFromForm(ownProps.formName));
  },
  updateField: (value, fieldName) => {
    if (value === null || fieldName === 'registration_form_id') {
      dispatch(updateCurrentFilterField('registration_form_line_item_id', ''));
      dispatch(updateCurrentFilterField('registration_form_line_item_option_id', ''));
    }
    dispatch(updateCurrentFilterField(fieldName, value));
  },
  resetAllRegistrationFilters: () => {
    dispatch(updateCurrentFilterField('registration_form_id', ''));
    dispatch(updateCurrentFilterField('registration_form_line_item_id', ''));
    dispatch(updateCurrentFilterField('registration_form_line_item_option_id', ''));
  },
  addAdvancedFilter: () => {
    dispatch(addAdvancedFilter());
  },
});

const mapStateToProps = (state, ownProps) => {
  const activeDivision = selectActiveDivision(state);
  const formId = state.leagueRostering.data.currentFilter.registration_form_id;
  const lineItemId = state.leagueRostering.data.currentFilter.registration_form_line_item_id;

  const lineItems = selectRegistrationLineItemsByFormId(state)(formId);
  const lineItemOptions = formId ? selectRegistrationLineItemOptionsByLineItemId(state)(lineItemId) : [];

  return {
    currentFilter: selectCurrentFilter(state) || {},
    filterNames: selectFilterNames(state) || [],
    formName: ownProps.formName,
    registrationForms: selectRegistrationFormsAsArraySortedByActive(state) || [],
    registrationFormLineItems: lineItems || [],
    registrationFormLineItemOptions: lineItemOptions || [],
    batchInvoices: selectBatchInvoicesByDivisionId(state)(activeDivision.rootId),
    dateDisplayMonthFirst: !selectRootDivisionPreferences(state).hasInternationalDate,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditFilter);
