import { createSelector } from 'reselect';
import { select, selectAndSortBy } from 'state/teamsnap/selectors';
import _parseInt from 'lodash/parseInt';

const selectRegistrationFormLineItems = state => select(state, 'registrationFormLineItems');

const selectAndSortRegistraionLineItemsByFormId = createSelector([selectRegistrationFormLineItems], lineItems =>
  selectAndSortBy(lineItems, 'registrationFormId'),
);

const selectRegistrationLineItemsByFormId = state => registrationFormId =>
  selectAndSortRegistraionLineItemsByFormId(state)[_parseInt(registrationFormId)] || [];

export { selectRegistrationFormLineItems, selectRegistrationLineItemsByFormId };
