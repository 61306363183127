import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputCheckbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    elementClass: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    updateHandler: PropTypes.func,
    wrapperClass: PropTypes.string,
  };

  updateValue = event => {
    const value = event.target.checked;
    const fieldName = this.props.name;
    if (typeof this.props.updateHandler === 'function') {
      this.props.updateHandler(value, fieldName);
    }
  };

  onClickHandler = event => {
    const value = event.target.checked;
    const fieldName = this.props.name;
    this.props.updateHandler(value, fieldName);
  };

  render() {
    const { defaultValue, disabled, title, elementClass, label, labelClass, name, wrapperClass, readOnly } = this.props;

    return (
      <div className={ ['ts__checkbox', wrapperClass].join(' ') }>
        { readOnly ? (
          <input
            className={ elementClass }
            defaultValue={ defaultValue }
            disabled={ disabled }
            title={ title }
            name={ name }
            onClick={ this.onClickHandler }
            type="checkbox"
            ref="input"
            checked={ this.props.checked || false }
            readOnly={ readOnly }
          />
        ) : (
          <input
            className={ elementClass }
            defaultValue={ defaultValue }
            disabled={ disabled }
            title={ title }
            name={ name }
            onChange={ this.updateValue }
            type="checkbox"
            ref="input"
            checked={ this.props.checked || false }
          />
        ) }
        <label className={ ['ts__checkbox-label', labelClass].join(' ') } htmlFor={ name }>
          { label }
        </label>
      </div>
    );
  }
}

export default InputCheckbox;
