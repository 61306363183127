import localStorage from 'store';
import { getDate } from 'utils/date-service';
import * as constants from '../constants';

import { disableRosteringPendingMode, enableRosteringPendingMode } from './actions';

import { selectCurrentFilter, selectFilteredMemberIds, selectFiltersById } from '../selectors';

const savePreviousFilteredSession = () => (dispatch, getState) => {
  const currentFilter = selectCurrentFilter(getState());
  const currentFilteredMemberIds = selectFilteredMemberIds(getState());
  if (Object.keys(currentFilter).length) {
    const session = {
      memberIds: currentFilteredMemberIds,
      filter: currentFilter,
      date: getDate(),
    };
    localStorage.set('roster_search_previous_session', JSON.stringify(session));
  }
};

const saveFiltersToLocalStorage = () => (dispatch, getState) => {
  const filtersInState = selectFiltersById(getState());
  localStorage.set('roster_search_filters', JSON.stringify(filtersInState));
};

const addLoadedFiltersToState = filters => ({
  type: constants.ADD_STORED_FILTERS,
  filters,
});

const getAnalyticsFromFilterParams = filterParams => {
  const newfilterParams = { ...filterParams };
  const { advancedFilters } = filterParams;
  delete newfilterParams.filterIndex;
  delete newfilterParams.advancedFilters;
  let params = Object.keys(filterParams).join(':');
  if (advancedFilters) {
    Object.keys(advancedFilters).forEach(advancedFilterIndex => {
      const advancedFilter = advancedFilters[advancedFilterIndex];
      params += `:${advancedFilter.field}`;
    });
  }
  return params;
};

const applyRosterFilter = filterParams => ({
  type: constants.APPLY_ROSTER_FILTER,
  meta: {
    analytics: getAnalyticsFromFilterParams(filterParams),
  },
});

const clearCurrentFilter = () => ({
  type: constants.CLEAR_FILTER,
});

const clearMemberSearchFilters = name => ({
  type: constants.CLEAR_MEMBER_SEARCH_FILTERS,
  name,
});

const updateCurrentFilter = filterParams => ({
  type: constants.UPDATE_CURRENT_FILTER,
  filterParams,
});

const addCurrentAdvancedFilter = ({ filterIndex, filterParams }) => ({
  type: constants.ADD_CURRENT_ADVANCED_FILTER,
  filterIndex,
  filterParams,
});

const saveFilter = ({ filterIndex, filterParams }) => ({
  type: constants.SAVE_FILTER,
  filterIndex,
  filterParams,
  meta: {
    analytics: getAnalyticsFromFilterParams(filterParams),
  },
});

const deleteCurrentAdvancedFilter = filterIndex => ({
  type: constants.DELETE_CURRENT_ADVANCED_FILTER,
  filterIndex,
});

const updateCurrentAdvancedFilter = ({ filterIndex, filterParams }) => ({
  type: constants.UPDATE_CURRENT_ADVANCED_FILTER,
  filterIndex,
  filterParams,
});

const removeSavedFilter = filterIndex => ({
  type: constants.REMOVE_SAVED_FILTER,
  filterIndex,
});

const replaceCurrentFilter = (filterParams, filterIndex) => ({
  type: constants.REPLACE_CURRENT_FILTER,
  filterParams,
  filterIndex,
});

const updateCurrentFilterField = (fieldName, value) => dispatch => {
  const filterParams = {
    fieldName,
    value,
  };
  dispatch(updateCurrentFilter(filterParams));
};

const addAdvancedFilter = () => dispatch => {
  const filterIndex = new Date().getTime();
  const filterParams = {
    field: null,
    operator: null,
    value: null,
  };
  dispatch(addCurrentAdvancedFilter({ filterIndex, filterParams }));
};

const updateAdvancedFilter = (filterIndex, fieldName, value) => dispatch => {
  const filterParams = {
    fieldName,
    value,
  };
  dispatch(updateCurrentAdvancedFilter({ filterIndex, filterParams }));
};

const removeSavedFilterWithLocalStorage = filterIndex => dispatch => {
  dispatch(removeSavedFilter(filterIndex));
  dispatch(saveFiltersToLocalStorage());
};

const saveFilterFromForm = formName => (dispatch, getState) => {
  const filterParams = selectCurrentFilter(getState());
  const searchTitle = filterParams.search_title;
  const filterIndex = searchTitle
    .trim()
    .replace(/[^A-Za-z0-9\s]/g, '')
    .replace(/\s/g, '_')
    .toLowerCase();
  filterParams.filterIndex = filterIndex;
  dispatch(saveFilter({ filterIndex, filterParams }));
  dispatch(saveFiltersToLocalStorage());
  dispatch(clearCurrentFilter());
};

const deleteAdvancedFilter = filterIndex => dispatch => {
  dispatch(deleteCurrentAdvancedFilter(filterIndex));
};

const loadSavedFilters = () => dispatch => {
  const filtersFromLocalStorage = localStorage.get('roster_search_filters');
  if (filtersFromLocalStorage) {
    const filters = JSON.parse(localStorage.get('roster_search_filters'));
    dispatch(addLoadedFiltersToState(filters));
  }
};

const loadRosteringPendingMode = () => dispatch => {
  const pendingModeEnabled = localStorage.get('rostering_pending_mode_enabled');
  if (pendingModeEnabled) {
    dispatch(enableRosteringPendingMode());
  } else {
    dispatch(disableRosteringPendingMode());
  }
};

const resetAdvancedFilter = filterIndex => dispatch => {
  dispatch({
    type: constants.RESET_ADVANCED_FILTER,
    filterIndex,
  });
};

const searchFilterAdd = () => ({
  type: constants.SEARCH_FILTER_ADD,
  searchFilter: {
    id: Date.now(),
    field: 'lastName',
    value: '',
    comparison: 'contains',
  },
});

const searchFilterUpdate = searchFilter => ({
  type: constants.SEARCH_FILTER_UPDATE,
  searchFilter,
});

const searchFilterDelete = searchFilter => ({
  type: constants.SEARCH_FILTER_DELETE,
  searchFilter,
});

const searchFilterClear = () => ({
  type: constants.SEARCH_FILTER_CLEAR,
});

const updateHasUnappliedFilters = hasUnappliedFilters => ({
  type: constants.UPDATE_HAS_UNAPPLIED_FILTERS,
  value: hasUnappliedFilters,
});

export {
  clearMemberSearchFilters,
  searchFilterAdd,
  searchFilterUpdate,
  searchFilterDelete,
  searchFilterClear,
  updateAdvancedFilter,
  clearCurrentFilter,
  updateCurrentFilter,
  addCurrentAdvancedFilter,
  savePreviousFilteredSession,
  addLoadedFiltersToState,
  applyRosterFilter,
  saveFilter,
  deleteCurrentAdvancedFilter,
  updateCurrentAdvancedFilter,
  removeSavedFilter,
  replaceCurrentFilter,
  updateCurrentFilterField,
  addAdvancedFilter,
  removeSavedFilterWithLocalStorage,
  saveFilterFromForm,
  deleteAdvancedFilter,
  loadSavedFilters,
  loadRosteringPendingMode,
  resetAdvancedFilter,
  saveFiltersToLocalStorage,
  updateHasUnappliedFilters,
};
