// ---------------------------------------------
// By default selectloading() will return true
// This is to ensure that if you are checking if something is loading
// it will always return true even if the loading hasn't started
// If loaded: true then you are guaranteed that the loading has finished
// either success or fail depending on the error value
// ---------------------------------------------

const DefaultLoading = {
  loading: true,
  loaded: false,
  error: null,
  startedAt: null,
  endedAt: null,
};

const selectLoadingInfo = (state, loadingKey) => state.loading[loadingKey] || DefaultLoading;

const selectLoading = (state, loadingKey) => selectLoadingInfo(state, loadingKey).loading;

const selectLoaded = (state, loadingKey) => selectLoadingInfo(state, loadingKey).loaded;

const selectLoadingError = (state, loadingKey) => selectLoadingInfo(state, loadingKey).error;

const selectLoadingStartedAt = (state, loadingKey) => selectLoadingInfo(state, loadingKey).startedAt;

const selectLoadingEndedAt = (state, loadingKey) => selectLoadingInfo(state, loadingKey).endedAt;

export { selectLoaded, selectLoading, selectLoadingError, selectLoadingStartedAt, selectLoadingEndedAt };
