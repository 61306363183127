import { combineReducers } from 'redux';
import initialState from 'state/initialState';
import _without from 'lodash/without';
import _parseInt from 'lodash/parseInt';
import * as constants from './constants';

const data = (state = initialState.leagueRostering.data, action) => {
  const { filterIndex, filterParams } = action;

  switch (action.type) {
    case constants.ADD_FILTERED_MEMBER_IDS:
      return {
        ...state,
        filteredMemberIds: action.memberIds,
        selectedMemberIds: [],
        selectedAllMembers: false,
      };
    case constants.ADD_MEMBER_SELECTION: {
      const isAllSelected =
        action.isAllSelected !== undefined
          ? action.isAllSelected
          : state.filteredMemberIds.length && state.filteredMemberIds.length === state.selectedMemberIds.length + 1;
      return {
        ...state,
        selectedMemberIds: [...state.selectedMemberIds, _parseInt(action.memberId)],
        selectedAllMembers: isAllSelected,
      };
    }
    case constants.ADD_STORED_FILTERS:
      return {
        ...state,
        filters: { ...action.filters },
      };
    case constants.CLEAR_FILTER:
      return {
        ...state,
        activeSavedFilterId: null,
        currentFilter: {},
      };
    case constants.CLEAR_MEMBER_SEARCH_FILTERS: {
      let currentFilterCopy = { ...state.currentFilter };
      switch (action.name) {
        case 'divisionOrTeam': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
          const { divisionId, teamId, ...filtersToKeep } = localStorageFilters;

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.divisionId;
          delete currentFilterCopy.teamId;
          break;
        }
        case 'memberStatus': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
          const { hideRostered, pending_assignments, ...filtersToKeep } = localStorageFilters;

          // Remove the item from localStorage
          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.hideRostered;
          delete currentFilterCopy.pending_assignments;
          break;
        }
        case 'memberInfo': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));

          const { born_after_date, born_before_date, first_name, gender, last_name, ...filtersToKeep } =
            localStorageFilters;

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.born_after_date;
          delete currentFilterCopy.born_before_date;
          delete currentFilterCopy.first_name;
          delete currentFilterCopy.gender;
          delete currentFilterCopy.last_name;
          break;
        }
        case 'registrationStatus': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));

          const {
            registration_form_id,
            registration_form_line_item_id,
            registration_form_line_item_option_id,
            ...filtersToKeep
          } = localStorageFilters;

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.registration_form_id;
          delete currentFilterCopy.registration_form_line_item_id;
          delete currentFilterCopy.registration_form_line_item_option_id;
          delete currentFilterCopy.participantGroups;
          currentFilterCopy.participantGroups = [];
          currentFilterCopy.advancedFilters = {};
          break;
        }
        case 'customFields': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
          const { advancedFilters, ...filtersToKeep } = localStorageFilters;
          filtersToKeep['advancedFilters'] = {};

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.advancedFilters;
          currentFilterCopy.advancedFilters = {};
          break;
        }
        case 'additionalQuestions': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
          const { advancedFilters, ...filtersToKeep } = localStorageFilters;
          filtersToKeep['advancedFilters'] = {};

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.advancedFilters;
          currentFilterCopy.advancedFilters = {};
          break;
        }
        case 'participantGroups': {
          const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
          const { advancedFilters, ...filtersToKeep } = localStorageFilters;
          filtersToKeep['participantGroups'] = [];

          localStorage.setItem('rosterFiltering', JSON.stringify(filtersToKeep));
          delete currentFilterCopy.participantGroups;
          currentFilterCopy.participantGroups = [];
          break;
        }
        default:
          localStorage.removeItem('rosterFiltering');
          currentFilterCopy = {};
      }
      return {
        ...state,
        activeSavedFilterId: null,
        currentFilter: { ...currentFilterCopy },
      };
    }
    case constants.SET_ASSIGNED_MEMBER_COUNT:
      if (action.teamId) {
        return {
          ...state,
          countAssignedToTeam: {
            ...state.countAssignedToTeam,
            [action.teamId]: action.count,
          },
        };
      }
      return {
        ...state,
        countAssignedToDivision: {
          ...state.countAssignedToDivision,
          [action.divisionId]: action.count,
        },
      };

    case constants.LOAD_MEMBERS_FOR_TEAM_START:
      return {
        ...state,
        loadingMembersForTeamId: {
          ...state.loadingMembersForTeamId,
          [action.teamId]: true,
        },
      };
    case constants.LOAD_MEMBERS_FOR_TEAM_SUCCESS:
      return {
        ...state,
        loadedMembersForTeamId: [...state.loadedMembersForTeamId, action.teamId],
        loadingMembersForTeamId: {
          ...state.loadingMembersForTeamId,
          [action.teamId]: false,
        },
      };
    case constants.LOAD_MEMBERS_FOR_TEAM_END:
      return {
        ...state,
        loadingMembersForTeamId: {
          ...state.loadingMembersForTeamId,
          [action.teamId]: false,
        },
      };
    case constants.REMOVE_ALL_MEMBER_SELECTIONS:
      return {
        ...state,
        selectedMemberIds: [],
        selectedAllMembers: false,
      };
    case constants.REMOVE_MEMBER_SELECTION: {
      const index = state.selectedMemberIds.indexOf(action.memberId);
      const newArray = [...state.selectedMemberIds];
      newArray.splice(index, 1);
      return {
        ...state,
        selectedMemberIds: [...newArray],
        selectedAllMembers: false,
      };
    }
    case constants.REMOVE_SAVED_FILTER: {
      const newFilters = { ...state.filters };
      delete newFilters[action.filterIndex];
      return {
        ...state,
        filters: { ...newFilters },
      };
    }
    case constants.REPLACE_CURRENT_FILTER:
      return {
        ...state,
        currentFilter: { ...filterParams, search_title: '' },
        activeSavedFilterId: action.filterIndex,
      };
    case constants.ROSTER_SEARCH_START:
      return {
        ...state,
        isSearching: true,
      };
    case constants.ROSTER_SEARCH_END:
      return {
        ...state,
        isSearching: false,
      };
    case constants.RESET_ADVANCED_FILTER:
      return {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          advancedFilters: {
            ...state.currentFilter.advancedFilters,
            [filterIndex]: {
              field: null,
              operator: null,
              value: null,
            },
          },
        },
      };
    case constants.SELECT_ALL_FILTERED_MEMBERS:
      return {
        ...state,
        selectedMemberIds: [...state.filteredMemberIds],
        selectedAllMembers: true,
      };
    case constants.SAVE_FILTER: {
      const filters = { ...(state.filters || {}), [filterIndex]: filterParams };
      return {
        ...state,
        filters,
        currentFilter: { ...state.currentFilter, search_title: '' },
      };
    }
    case constants.UPDATE_CURRENT_FILTER: {
      const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));

      // For reg 2 only remove default advanced filter
      if (
        state?.registrationVersion === 2 &&
        localStorageFilters?.advancedFilters &&
        state?.currentFilter?.advancedFilters &&
        (Object.keys(localStorageFilters?.advancedFilters)?.length > 0 ||
          Object.keys(state?.currentFilter?.advancedFilters)?.length > 0)
      ) {
        delete localStorageFilters?.advancedFilters['0'];
        delete state?.currentFilter?.advancedFilters['0'];
      }

      const newState = {
        ...state,
        activeSavedFilterId: null,
        currentFilter: {
          ...state.currentFilter,
          ...localStorageFilters,
          [filterParams.fieldName]: filterParams.value,
        },
      };
      if (filterParams.fieldName === 'registration_form_id') {
        newState.currentFilter.participantGroups = [];
        newState.currentFilter.advancedFilters = {};
      }

      localStorage.setItem('rosterFiltering', JSON.stringify(newState.currentFilter));
      return {
        ...newState,
      };
    }
    case constants.UPDATE_MEMBER_SELECTION:
      return {
        ...state,
        selectedMemberIds: [...action.selectedMemberIds],
        selectedAllMembers: action.selectedAllMembers,
      };
    case constants.ADD_CURRENT_ADVANCED_FILTER: {
      const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));
      const field = filterParams.field || null;
      const operator = filterParams.operator || null;
      const value = filterParams.value || null;
      const comparison = filterParams.comparison || null;

      const advancedFilters = {
        ...state.currentFilter.advancedFilters,
        [filterIndex]: {
          field,
          operator,
          value,
          comparison,
        },
      };
      const newState = {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          ...localStorageFilters,
          advancedFilters,
        },
        activeSavedFilterId: null,
      };

      localStorage.setItem('rosterFiltering', JSON.stringify(newState.currentFilter));

      return {
        ...newState,
      };
    }
    case constants.DELETE_CURRENT_ADVANCED_FILTER: {
      const advancedFilters = {
        ...state.currentFilter.advancedFilters,
      };

      delete advancedFilters[filterIndex];

      const remap = (filters) => {
        const values = Object.values(filters);
        const remapped = {};

        values.forEach((filter, index) => {
          remapped[`custom_field_${index}`] = filter;
        });

        return remapped;
      };

      const newState = {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          advancedFilters: state.registrationVersion === 2 ? remap(advancedFilters) : advancedFilters,
        },
        activeSavedFilterId: null,
      };

      localStorage.setItem('rosterFiltering', JSON.stringify(newState.currentFilter));

      return {
        ...newState,
      };
    }
    case constants.UPDATE_CURRENT_ADVANCED_FILTER: {
      const newState = {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          advancedFilters: {
            ...state.currentFilter.advancedFilters,
            [filterIndex]: {
              ...state.currentFilter.advancedFilters[filterIndex],
              [filterParams.fieldName]: filterParams.value,
            },
          },
        },
        activeSavedFilterId: null,
      };

      localStorage.setItem('rosterFiltering', JSON.stringify(newState.currentFilter));

      return {
        ...newState,
      };
    }
    case constants.LIST_CLICK_ALL_MEMBERS:
      return {
        ...state,
        selectedMemberIds: action.checkedMemberIds,
        selectedAllMembers: action.checkedAllMembers,
      };
    case constants.SET_DIVISION_ROSTER_COUNT:
      return {
        ...state,
        divisionRosterCount: {
          ...state.divisionRosterCount,
          [action.divisionId]: action.totalItems,
        },
      };
    case constants.SET_CURRENT_ROSTERING_DIVISION:
      return {
        ...state,
        currentDivisionId: action.divisionId,
      };
    case constants.TOGGLE_MEMBER_SEARCH_POPUP:
      return {
        ...state,
        openedMemberSearchPopupId: action.openedMemberSearchPopupId,
      };
    case constants.TOGGLE_ROSTER_ASSIGNMENT_VIEW:
      return {
        ...state,
        rosterAssignmentView: action.view,
      };
    case constants.TOGGLE_ROSTER_LIST_SORT:
      return {
        ...state,
        sortOn: action.sortOn,
        sortReverse: action.sortReverse,
      };
    case constants.TOGGLE_SHOW_TEAM_MEMBERS: {
      if (action.setShowing) {
        return {
          ...state,
          showTeamMembersForTeamId: [...state.showTeamMembersForTeamId, action.teamId],
        };
      }
      const teamsToShow = [...state.showTeamMembersForTeamId];
      const teamIndex = teamsToShow.indexOf(action.teamId);
      if (teamIndex > -1) {
        teamsToShow.splice(teamIndex, 1);
        return {
          ...state,
          showTeamMembersForTeamId: teamsToShow,
        };
      }
      return state;
    }
    case constants.TOGGLE_ROSTERING_PENDING_MODE: {
      return {
        ...state,
        pendingModeEnabled: action.pendingModeEnabled,
      };
    }
    case constants.UPDATE_HAS_UNAPPLIED_FILTERS: {
      return {
        ...state,
        currentFilter: {
          ...state.currentFilter,
          hasUnappliedFilters: action.value,
        },
      };
    }

    case constants.UPDATE_IS_PLAYER_TAB: {
      return {
        ...state,
        playerTabActive: action.playerTabActive,
      };
    }

    case constants.SET_REGISTRATION_VERSION:
      return {
        ...state,
        registrationVersion: action.registrationVersion,
      };

    default:
      return state;
  }
};

export const rosterReducer = combineReducers({
  data,
});

export default rosterReducer;
