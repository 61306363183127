import { Component } from 'react';
import PropTypes from 'prop-types';

class LeagueMembers extends Component {
  componentDidMount() {
    const { loadTeamNamesByDivisionId, loadDivisionsPreferences, activeDivisionId, rootDivisionId } = this.props;
    loadTeamNamesByDivisionId(activeDivisionId);
    loadDivisionsPreferences(rootDivisionId);
  }

  render() {
    return this.props.children;
  }
}

LeagueMembers.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  loadTeamNamesByDivisionId: PropTypes.func.isRequired,
  loadDivisionsPreferences: PropTypes.func.isRequired,
  activeDivisionId: PropTypes.number.isRequired,
  rootDivisionId: PropTypes.number.isRequired,
};

LeagueMembers.defaultProps = {
  children: null,
};

export default LeagueMembers;
