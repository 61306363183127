import { createSelector } from 'reselect';
import { select, selectAndSortBy } from 'state/teamsnap/selectors';
import _parseInt from 'lodash/parseInt';

const selectBatchInvoices = state => select(state, 'batchInvoices');

const selectAndSortBatchInvoicesByDivisionId = createSelector([selectBatchInvoices], invoices =>
  selectAndSortBy(invoices, 'divisionId'),
);

const selectBatchInvoiceById = state => id => selectBatchInvoices(state)[_parseInt(id)];

const selectBatchInvoicesByDivisionId = state => divisionId =>
  selectAndSortBatchInvoicesByDivisionId(state)[_parseInt(divisionId)] || [];

const selectBatchInvoicesByDivisionIdCount = (state, divisionId) =>
  selectBatchInvoicesByDivisionId(state)(divisionId).length;

export {
  selectBatchInvoices,
  selectBatchInvoicesByDivisionId,
  selectBatchInvoicesByDivisionIdCount,
  selectBatchInvoiceById,
};
