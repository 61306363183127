import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { featureRollout } from 'utils/window';
import { ModalConfirm } from 'shared/toolkit';
import { Button } from '@teamsnap/teamsnap-ui';
import DivisionAssignmentOverviewContainer from '../containers/DivisionAssignmentOverviewContainer';
import TeamListRowDivisionContainer from '../containers/TeamListRowDivisionContainer';
import TeamListRowTeamContainer from '../containers/TeamListRowTeamContainer';

import classes from './TeamList.module.scss';

class TeamList extends Component {
  static propTypes = {
    allPendingAssignmentsCount: PropTypes.number.isRequired,
    allPendingRemovalsCount: PropTypes.number.isRequired,
    assignPendingMembersByDivisionId: PropTypes.func.isRequired,
    currentRosterDivision: PropTypes.shape({}).isRequired,
    isPlayerTab: PropTypes.bool.isRequired,
    setIsPlayerTab: PropTypes.func,
    deletePendingMembersByDivisionId: PropTypes.func.isRequired,
    divisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    rosterAssignmentView: PropTypes.string,
    teamNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    allTeamsCount: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
  };

  showConfirmApproveAssignmentsModal = () => {
    const { currentRosterDivision } = this.props;
    const confirmFunction = this.props.assignPendingMembersByDivisionId;
    const modalProps = {
      confirmFunction,
      title: `Publish Rosters for ${currentRosterDivision.name}`,
      text: `<p><strong>Please confirm roster changes for ${currentRosterDivision.name}</strong></p>
        <ul class='u-padMd'>
          <li>All pending additions and removals will be finalized.</li>
          <li>Members will be able to see which team they are on.</li>
          <li>
            All statistics, availabilities, payments and other team-specific information
            will be deleted if the member’s team is changed or if they are unassigned.
          </li>
        </ul>`,
      confirmButtonText: 'Publish Rosters',
      overlay: true,
      className: 'ModalMedium',
    };
    return this.props.showModal(ModalConfirm, modalProps);
  };

  showCancelPendingAssignmentsModal = () => {
    const { currentRosterDivision } = this.props;
    const confirmFunction = this.props.deletePendingMembersByDivisionId;
    const modalProps = {
      confirmFunction,
      title: `Cancel Pending Assignments for ${currentRosterDivision.name}`,
      text: `<p>Are you sure you want to cancel the pending assignments for ${currentRosterDivision.name}?</p>`,
      confirmButtonText: 'Yes',
      confirmCancelButtonText: 'No',
      overlay: true,
      className: 'ModalMedium',
    };
    return this.props.showModal(ModalConfirm, modalProps);
  };

  render() {
    const {
      allPendingAssignmentsCount,
      allPendingRemovalsCount,
      currentRosterDivision,
      isPlayerTab,
      setIsPlayerTab,
      divisions,
      rosterAssignmentView,
      teamNames,
      allTeamsCount,
    } = this.props;

    return (
      <div className="TeamList__panel">
        <div className={ `TeamList__panel-header ${classes.flex}` }>
          <h3 className={ classes.teamListHeader }>{ currentRosterDivision.name }</h3>
          <div>
            <Button
              className="Button Button--small Button--primary u-spaceSidesSm"
              isDisabled={ allPendingAssignmentsCount === 0 && allPendingRemovalsCount === 0 }
              onClick={ this.showConfirmApproveAssignmentsModal }
            >
              Publish
            </Button>
            <Button
              className="Button Button--small Button--negative"
              isDisabled={ allPendingAssignmentsCount === 0 && allPendingRemovalsCount === 0 }
              onClick={ this.showCancelPendingAssignmentsModal }
            >
              Cancel Pending
            </Button>
          </div>
        </div>
        <div className={ classes['panel-body'] }>
          <DivisionAssignmentOverviewContainer
            division={ currentRosterDivision }
            isPlayerTab={ isPlayerTab }
            setIsPlayerTab={ setIsPlayerTab }
          />
          <table
            id="players_table"
            className={ classes['table--full-width'] }
            cellPadding={ 0 }
            data-league-roster-table
            role="grid"
          >
            <tbody>
              { rosterAssignmentView === 'team' &&
                teamNames &&
                teamNames.map(teamName =>
                  <TeamListRowTeamContainer
                    key={ teamName.id }
                    teamName={ teamName }
                    isPlayerTab={ isPlayerTab }
                    setIsPlayerTab={ setIsPlayerTab }
                  />
                )
              }
              { rosterAssignmentView === 'team' && allTeamsCount === 0 && divisions.length === 0 && (
                <tr>
                  <td className={ classes.noTeams }>
                    <i className={ classes['noTeamsIcon--ss-users'] } />
                    <p className={ classes.noTeamsText }>
                      Sorry No Sub-Divisions or Teams are Currently in this Division
                    </p>
                    <small className={ classes.noTeamSubText }>
                      Please visit the Divisions Tab to create your organization structure
                    </small>
                  </td>
                </tr>
              ) }
              { rosterAssignmentView === 'team' &&
                divisions &&
                divisions.map(division => <TeamListRowDivisionContainer key={ division.id } division={ division } />) }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TeamList;
