/**
 * Member Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { COLLECTIONS, getCollection, reset, deleteCollection } from 'state/snapi/slice';
import api from 'utils/api';

// Load Members
export const loadMembers = params => dispatch => dispatch(getCollection(COLLECTIONS.MEMBER, 'members/search', params));

// The loadMembers method above doesn't return members in a sub-division
// Leaving for now until we determine if it needs to be removed.
export const loadAdvancedDivisionMembers = params => dispatch =>
  dispatch(
    getCollection(COLLECTIONS.MEMBER, 'members/advanced_division_search', params, {
      replaceState: true,
    }),
  );

export const resetMemberCollection = () => dispatch => dispatch(reset(COLLECTIONS.MEMBER));

export const deleteMember = item => dispatch => dispatch(deleteCollection(COLLECTIONS.MEMBER, 'members', item));

export const moveMember = (memberId, divisionId, teamId = null) => () =>
  api
    .cmd('members/move_member', { memberId, divisionId, teamId })
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
