/* eslint-disable react/prop-types */
import React from 'react';

import ArrowLeftLarge from './assets/arrow_left-large.gif';
import ArrowRightMedium from './assets/arrow_right-medium.gif';
import BlankThumb from './assets/blank_thumb.gif';
import BtnResendInvitation from './assets/btn-resend_invitation.gif';
import CheckmarkSmall from './assets/checkmark-small.gif';
import CreditCardLogos from './assets/credit-cards.png';
import EnvelopeLarge from './assets/envelope-large.gif';
import EmailBig from './assets/icon-big-email.gif';
import IconTeamsnap from './assets/icon-teamsnap.gif';
import LoadingDots from './assets/loading-dots.gif';
import PaperClipSmall from './assets/paperclip-small.gif';
import PencilLarge from './assets/pencil-large.gif';
import PencilSmall from './assets/pencil-small.gif';
import PlusMedium from './assets/plus-medium.gif';
import ProgressBar from './assets/progress_bar.gif';
import RedXSmall from './assets/redx-small.gif';
import RedXLarge from './assets/redx-large.gif';
import RosterBig from './assets/icon-big-roster.gif';
import TableMedium from './assets/table-medium.gif';
import TeamSnapIcon from './assets/TeamSnap_Icon.png';
import TransferSmall from './assets/transfer-small.gif';

const Img = ({ className, alt, src, title, width, height }) => (
  <img className={ className } alt={ alt } src={ src } title={ title } width={ width } height={ height } />
);

const ImgArrowLeftLarge = params => Img({ ...params, src: ArrowLeftLarge });

const ImgArrowRightMedium = params => Img({ ...params, src: ArrowRightMedium });

const ImgBlankThumb = params => Img({ ...params, src: BlankThumb });

const ImgBtnResendInvitation = params => Img({ ...params, src: BtnResendInvitation });

const ImgCheckmarkSmall = params => Img({ ...params, src: CheckmarkSmall });

const ImgRedXLarge = params => Img({ ...params, src: RedXLarge });

const ImgRedXSmall = params => Img({ ...params, src: RedXSmall });

const ImgEnvelopeLarge = params => Img({ alt: 'envelope-large', ...params, src: EnvelopeLarge });

const ImgIconTeamsnap = params => Img({ ...params, src: IconTeamsnap });

const ImgPencilLarge = params => Img({ ...params, src: PencilLarge });

const ImgPencilSmall = params => Img({ ...params, src: PencilSmall });

const ImgPlusMedium = params => Img({ ...params, src: PlusMedium });

const ImgPaperclipSmall = params => Img({ ...params, src: PaperClipSmall });

const ImgProgressBar = params => Img({ ...params, src: ProgressBar });

const ImgEmailBig = params => Img({ ...params, src: EmailBig });

const ImgLoadingDots = params => Img({ ...params, src: LoadingDots });

const ImgRosterBig = params => Img({ ...params, src: RosterBig });

const ImgTableMedium = params => Img({ ...params, src: TableMedium });

const ImgTransferSmall = params => Img({ ...params, src: TransferSmall });

const ImgTeamSnapIcon = params => Img({ ...params, src: TeamSnapIcon });

const ImgCreditCardLogos = params => Img({ ...params, src: CreditCardLogos });

export {
  Img,
  ImgArrowLeftLarge,
  ImgArrowRightMedium,
  ImgBlankThumb,
  ImgBtnResendInvitation,
  ImgCheckmarkSmall,
  ImgRedXLarge,
  ImgRedXSmall,
  ImgEnvelopeLarge,
  ImgIconTeamsnap,
  ImgPencilLarge,
  ImgPencilSmall,
  ImgPlusMedium,
  ImgPaperclipSmall,
  ImgProgressBar,
  ImgEmailBig,
  ImgLoadingDots,
  ImgRosterBig,
  ImgTableMedium,
  ImgTransferSmall,
  ImgTeamSnapIcon,
  ImgCreditCardLogos,
};
