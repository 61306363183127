import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Feedback from 'shared/toolkit/Feedback';
import { hideFeedback } from 'state/feedback/actions';
import styles from './FeedbackRoot.module.scss';

class FeedbackRoot extends PureComponent {
  renderFeedback = props => (
    <div className={ styles['Feedback-wrapper'] }>
      <Feedback
        handleClose={ this.props.hideFeedback }
        title={ props.title }
        message={ props.message }
        type={ props.type }
        autoClose={ props.autoClose }
      />
    </div>
  );

  render() {
    const { visible, feedbackProps } = this.props;
    return <div>{ visible && this.renderFeedback(feedbackProps) }</div>;
  }
}

FeedbackRoot.propTypes = {
  visible: PropTypes.bool.isRequired,
  feedbackProps: PropTypes.shape({}).isRequired,
  hideFeedback: PropTypes.func.isRequired,
};

export default connect(state => state.feedback, { hideFeedback })(FeedbackRoot);
