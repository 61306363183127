import _min from 'lodash/min';
import _uniq from 'lodash/uniq';
import { ModalConfirm } from 'shared/toolkit';
import { SUCCESS } from 'shared/toolkit/Feedback/Feedback';
import { setAppError } from 'state/app/actions';
import { selectActiveDivisionId } from 'state/app/selectors';
import { showFeedback } from 'state/feedback/actions';
import {
  reSelectAndSortRosterMembersByTeamIdAndPendingTeamId,
  selectRosteringPendingMode,
  selectRegistrationVersion,
} from 'state/leagueRostering/selectors';
import { showModal } from 'state/modal/actions';
import {
  addItemsAction,
  loadItems,
  loadItemsErrorAction,
  loadItemsStartAction,
  loadItemsSuccessAction,
} from 'state/teamsnap/actions';
import { loadAncestorsAndSelfAndDescendantDivisionAggregates } from 'state/teamsnap/divisionAggregates/actions';
import {
  selectDescendantDivisions,
  selectDivisionAndChildDivisionIds,
  selectDivisionById,
} from 'state/teamsnap/divisions/selectors';
import { selectLeagueCustomFields } from 'state/teamsnap/leagueCustomFields/selectors';
import { selectMemberById, selectMembers } from 'state/teamsnap/members/selectors';
import { selectAsArray } from 'state/teamsnap/selectors';
import { selectTeamNamesById } from 'state/teamsnap/teamNames/selectors';
import localStorage from 'store';
import api from 'utils/api';
import * as DateService from 'utils/date-service';
import { formatDate } from 'utils/date-service';
import getErrorMessage from 'utils/errorMessage';
import teamsnapSdk from 'utils/teamsnapSdk';
import { camelize } from 'utils/utils';
import * as constants from '../constants';
import {
  selectCurrentFilter,
  selectFilteredMemberIds,
  selectFilteredMemberIdsNoCommissioners,
  selectFilteredMembersCountNoCommissioners,
  selectFilteredPlayersCount,
  selectIsTeamIdShowingMembers,
  selectPlayerTabActive,
  selectRosterAssignmentView,
  selectRosterListDivisionId,
  selectRosterListSortOn,
  selectRosterListSortReverse,
  selectSelectedMemberIds,
  selectSelectedMemberIdsNoCommissioners,
  selectSelectedMembersCount,
  selectSelectedMembersCountNoCommissioners,
  selectRegistrationFormsForDivision,
} from '../selectors';
import { applyRosterFilter, replaceCurrentFilter } from './memberFilters';

import {
  hasNoFilters,
  buildV2RosterList,
  checkMemberFirstName,
  checkMemberLastName,
  checkMemberGender,
  checkMemberBirthdayAfter,
  checkMemberBirthdayBefore,
  checkMemberDivisionId,
  checkMemberTeamId,
  checkHideRostered,
  checkHidePending,
  checkAdvancedFilters,
  checkMemberRegistration,
  checkParticipantGroups,
} from '../helpers';

import { selectParticipantGroups } from 'state/teamsnap/participantGroups/selectors';

const setRegistrationVersion = (version) => ({
  type: constants.SET_REGISTRATION_VERSION,
  registrationVersion: version,
});

const toggleMemberSearchPopup = (popup) => ({
  type: constants.TOGGLE_MEMBER_SEARCH_POPUP,
  openedMemberSearchPopupId: popup,
});

const rosterSearchStart = () => ({
  type: constants.ROSTER_SEARCH_START,
});

const rosterSearchEnd = () => ({
  type: constants.ROSTER_SEARCH_END,
});

const addFilteredMemberIds = (memberIds) => ({
  type: constants.ADD_FILTERED_MEMBER_IDS,
  memberIds,
});

const rosterLoadPlayers = (loadParams, applyToCurrentFilter) => (dispatch, getState) => {
  dispatch(loadItemsStartAction({ itemType: 'member', loadParams }));
  dispatch(rosterSearchStart());
  teamsnapSdk()
    .divisionAdvancedLoadMembers(loadParams)
    .then(
      (members) => {
        dispatch(
          loadItemsSuccessAction({
            itemType: 'member',
            loadParams,
            items: members,
          }),
        );
        dispatch(addItemsAction({ itemType: 'member', items: members, loadParams }));
        dispatch(rosterSearchEnd());

        const responseMemberIds = members.map((member) => member.id);
        let filteredMemberIds;

        if (applyToCurrentFilter && loadParams.id) {
          const currentFilteredMemberIds = selectFilteredMemberIds(getState());
          filteredMemberIds = loadParams.id.reduce((currentIds, memberId) => {
            if (currentIds.indexOf(memberId) === -1 && responseMemberIds.indexOf(memberId) > -1) {
              // memberId should be added to filtered results
              currentIds.push(memberId);
            } else if (currentIds.indexOf(memberId) > -1 && responseMemberIds.indexOf(memberId) === -1) {
              // memberId should be removed from filtered results
              currentIds.splice(currentIds.indexOf(memberId), 1);
            }
            return currentIds;
          }, currentFilteredMemberIds);

          // --- HERE BE HAX! --- //
          // Reload ids if isAssigned=false (since move_member does not return member objects)
          if (loadParams.isAssigned && loadParams.isAssigned === false) {
            const memberLoadParams = { id: loadParams.id };
            dispatch(loadItems('members', memberLoadParams));
          }
        } else {
          filteredMemberIds = responseMemberIds;
        }
        dispatch(addFilteredMemberIds(filteredMemberIds));
      },
      (error) => {
        dispatch(addFilteredMemberIds([]));
        const confirmFunction = () => true;
        dispatch(
          showModal(ModalConfirm, {
            confirmFunction,
            title: 'ERROR',
            text: 'Unable to retrieve members.',
            overlay: true,
            className: 'ModalSmall',
            confirmButtonText: 'Ok',
            hideCancel: true,
          }),
        );

        dispatch(loadItemsErrorAction({ itemType: 'member', loadParams, error }));
        dispatch(rosterSearchEnd());
      },
    );
};

const rosterLoadPlayersV2 = (loadParams, applyToCurrentFilter) => (dispatch, getState) => {
  const division = selectDivisionById(getState(), loadParams.divisionId);

  dispatch(loadItemsStartAction({ itemType: 'member', loadParams }));
  dispatch(rosterSearchStart());

  const participantGroups = selectParticipantGroups(getState());

  const reqUrl = `${teamsnapSdk().apiUrl}/registration_members?${
    division.parentId ? `sub_division_id` : `division_id`
  }=${loadParams.divisionId}`;

  teamsnapSdk()
    .request.get(reqUrl)
    .then(
      (response) => {
        const members = buildV2RosterList(response.data.data, Object.values(participantGroups));
        dispatch(
          loadItemsSuccessAction({
            itemType: 'member',
            loadParams,
            items: members,
          }),
        );
        dispatch(addItemsAction({ itemType: 'member', items: members, loadParams }));
        dispatch(rosterSearchEnd());

        const responseMemberIds = members.map((member) => member.id);
        let filteredMemberIds;

        if (applyToCurrentFilter && loadParams.id) {
          const currentFilteredMemberIds = selectFilteredMemberIds(getState());
          filteredMemberIds = loadParams.id.reduce((currentIds, memberId) => {
            if (currentIds.indexOf(memberId) === -1 && responseMemberIds.indexOf(memberId) > -1) {
              // memberId should be added to filtered results
              currentIds.push(memberId);
            } else if (currentIds.indexOf(memberId) > -1 && responseMemberIds.indexOf(memberId) === -1) {
              // memberId should be removed from filtered results
              currentIds.splice(currentIds.indexOf(memberId), 1);
            }
            return currentIds;
          }, currentFilteredMemberIds);

          // --- HERE BE HAX! --- //
          // Reload ids if isAssigned=false (since move_member does not return member objects)
          if (loadParams.isAssigned && loadParams.isAssigned === false) {
            const memberLoadParams = { id: loadParams.id };
            dispatch(loadItems('members', memberLoadParams));
          }
        } else {
          const currentFilters = selectCurrentFilter(getState());
          if (hasNoFilters(currentFilters)) {
            filteredMemberIds = members.map((m) => m.id);
          } else {
            const registrationForms = selectRegistrationFormsForDivision(getState());
            let formFieldsFromForm = Object.values(registrationForms).flatMap((form) => [...form.form_fields]);

            formFieldsFromForm = [...new Map(formFieldsFromForm.map((v) => [v.form_field_definition_id, v])).values()];

            const registrationFormFields = Object.values(formFieldsFromForm || {}).filter(
              (f) => f.form_field_definition.organization_id,
            );

            filteredMemberIds = members
              .filter((m) => checkMemberRegistration(m, currentFilters))
              .filter((m) => checkMemberFirstName(m.firstName, currentFilters))
              .filter((m) => checkMemberLastName(m.lastName, currentFilters))
              .filter((m) => checkMemberGender(m.gender, currentFilters))
              .filter((m) => checkMemberBirthdayAfter(m.birthday, currentFilters))
              .filter((m) => checkMemberBirthdayBefore(m.birthday, currentFilters))
              .filter((m) => checkMemberDivisionId(m, currentFilters))
              .filter((m) => checkMemberTeamId(m, currentFilters))
              .filter((m) => checkHideRostered(m, currentFilters))
              .filter((m) => checkHidePending(m, currentFilters))
              .filter((m) => checkAdvancedFilters(m, currentFilters, registrationFormFields))
              .filter((m) => checkParticipantGroups(m, currentFilters))
              .map((m) => m.id);
          }
        }

        dispatch(addFilteredMemberIds(filteredMemberIds));
      },
      (error) => {
        dispatch(addFilteredMemberIds([]));
        const confirmFunction = () => true;
        dispatch(
          showModal(ModalConfirm, {
            confirmFunction,
            title: 'ERROR',
            text: 'Unable to retrieve members.',
            overlay: true,
            className: 'ModalSmall',
            confirmButtonText: 'Ok',
            hideCancel: true,
          }),
        );

        dispatch(loadItemsErrorAction({ itemType: 'member', loadParams, error }));
        dispatch(rosterSearchEnd());
      },
    );
};

const buildSearchParams = (state) => {
  const currentFilter = selectCurrentFilter(state);
  const searchParams = {
    divisionId: selectActiveDivisionId(state),
  };
  let includeRostered = false;

  // Parse the current filter to build search query
  Object.keys(currentFilter).forEach((filterName) => {
    const filterValue = currentFilter[filterName];
    // Don't eval the param if it's an empty string
    if (typeof filterValue === 'string' && filterValue === '') {
      return;
    }
    // Format currentFilter params to build searchParams
    switch (filterName) {
      case 'gender':
        if (filterValue !== 'any') {
          // Don't filter on gender if "any"
          searchParams[filterName] = filterValue;
        }
        break;
      case 'is_unassigned':
        if (filterValue) {
          searchParams.isAssigned = false;
        }
        break;
      case 'is_assigned':
        break;
      case 'include_rostered':
        if (filterValue) {
          includeRostered = true;
        }
        break;
      case 'advancedFilters':
        // We will handle these last
        break;
      case 'born_after_date':
        if (DateService.isValidDate(new Date(filterValue))) {
          searchParams.born_after = true;
        }
        searchParams[camelize(filterName)] = filterValue;
        break;
      case 'born_before_date':
        if (DateService.isValidDate(new Date(filterValue))) {
          searchParams.born_before = true;
        }
        searchParams[camelize(filterName)] = filterValue;
        break;
      default:
        searchParams[camelize(filterName)] = filterValue;
    }
  });

  // birthday - Born Before
  if (searchParams.born_before && currentFilter.born_before_date) {
    const beforeDate = formatDate(new Date(currentFilter.born_before_date), 'YYYY-MM-DD');
    // HERE BE HAX!
    window.teamsnap.collections.members.queries.advancedDivisionSearch.params['birthday[0]'] = null;
    // ---------- /
    searchParams['birthday[0]'] = `operator:less_than_or_equal,value:${beforeDate}`;
  }
  // birthday - Born After
  if (searchParams.born_after && currentFilter.born_after_date) {
    const afterDate = formatDate(new Date(currentFilter.born_after_date), 'YYYY-MM-DD');
    // HERE BE HAX!
    window.teamsnap.collections.members.queries.advancedDivisionSearch.params['birthday[1]'] = null;
    // ---------- /
    searchParams['birthday[1]'] = `operator:greater_than_or_equal,value:${afterDate}`;
  }

  const customFields = Object.keys(currentFilter).filter((k) => k.match(/custom_field/));
  if (customFields.length > 0) {
    // We're in the new search UI so we dont have advanced filters but need them for search
    currentFilter.advancedFilters = [];

    // okay, we have custom fields in the new search ui, now to set them up as advanced filters
    customFields.forEach((key) => {
      currentFilter.advancedFilters.push({
        field: key,
        value: currentFilter[key],
        operator: 'equals',
      });
    });
  }

  // Advanced Filters
  if (currentFilter.advancedFilters) {
    Object.keys(currentFilter.advancedFilters).forEach((key) => {
      const advancedFilter = currentFilter.advancedFilters[key];
      const filterField = advancedFilter.field;
      const filterValue = advancedFilter.value;
      const filterOperator = advancedFilter.operator;
      if (!filterField || !filterValue || !filterOperator) {
        return;
      }
      const filterType = advancedFilter.type;
      const searchValue = `operator:${filterOperator},value:${filterValue}`;
      const isCustomField = /custom_field_/.test(filterField);
      let searchField = camelize(filterField);
      const customFieldId = filterField.replace('custom_field_', '');

      if (filterValue === '' || filterValue === null) {
        return;
      }

      // If we're searching on a specific division, set `searchSubdivisions`
      // to `false` so we only search that division
      if (filterField === 'division_id') {
        searchParams.searchSubdivisions = false;
      }

      // Custom Field
      if (isCustomField && filterOperator !== 'less_than_or_equal') {
        searchField = `custom_field[${customFieldId}]`;

        // HERE BE HAX!
        window.teamsnap.collections.members.queries.advancedDivisionSearch.params[searchField] = null;
        // ---------- /

        searchParams[searchField] = searchValue;
      } else if (isCustomField && filterOperator === 'less_than_or_equal' && filterType === 'date') {
        const searchField1 = `custom_field[${customFieldId}][0]`;
        const searchValue1 = 'operator:greater_than_or_equal,value:1900-01-01';

        const searchField2 = `custom_field[${customFieldId}][1]`;
        const searchValue2 = `operator:${filterOperator},value:${filterValue}`;

        // HERE BE HAX!
        window.teamsnap.collections.members.queries.advancedDivisionSearch.params[searchField1] = null;
        window.teamsnap.collections.members.queries.advancedDivisionSearch.params[searchField2] = null;
        // ---------- /

        searchParams[searchField1] = searchValue1;
        searchParams[searchField2] = searchValue2;
      } else {
        searchParams[searchField] = searchValue;
      }
    });
  }

  // Set default value
  if (!searchParams.isAssigned) {
    searchParams.isAssigned = false;
  }
  // Remove `isAssigned` if `includeRostered` is set
  if (includeRostered) {
    // We have to do this because some older stored filters may
    // still keep this value around. Yay.
    delete searchParams.isAssigned;
  }

  if (currentFilter.hideRostered && currentFilter.hideRostered === true) {
    searchParams.isAssigned = false;
  } else {
    delete searchParams.isAssigned;
  }

  searchParams.pendingAssignments = currentFilter.pending_assignments || 'include';

  if (currentFilter.first_name && currentFilter.first_name !== '') {
    searchParams.firstName = `operator:begins_with,value:${currentFilter.first_name}`;
  }

  if (currentFilter.last_name && currentFilter.last_name !== '') {
    searchParams.lastName = `operator:begins_with,value:${currentFilter.last_name}`;
  }

  if (currentFilter.divisionId && currentFilter.divisionId !== '') {
    if (selectRegistrationVersion(state) === 1) {
      searchParams.divisionId = currentFilter.divisionId;
    } else {
      searchParams.divisionId = selectActiveDivisionId(state);
    }

    searchParams.searchSubdivisions = false;
  }

  if (currentFilter.teamId && currentFilter.teamId !== '') {
    searchParams.divisionId = selectActiveDivisionId(state);
    searchParams.searchSubdivisions = true;
  }

  searchParams.isCommissioner = false;

  return searchParams;
};

const reapplyFilterToMemberIds = (memberIds) => (dispatch, getState) => {
  const searchParams = buildSearchParams(getState());
  searchParams.id = memberIds;
  dispatch(rosterLoadPlayers(searchParams, true));
};

const updateMemberSelection = (memberIds) => (dispatch, getState) => {
  const filteredMembersCountNoCommissioners = selectFilteredMembersCountNoCommissioners(getState());
  const isAllSelected =
    filteredMembersCountNoCommissioners > 0 && memberIds.length === filteredMembersCountNoCommissioners;
  dispatch({
    type: constants.UPDATE_MEMBER_SELECTION,
    selectedMemberIds: memberIds,
    selectedAllMembers: isAllSelected,
  });
};

const setAssignedMemberCount = (count, divisionId, teamId) => ({
  type: constants.SET_ASSIGNED_MEMBER_COUNT,
  count,
  divisionId,
  teamId,
});

const updateMembersToPending = (memberIds, teamId, divisionId, pending) => (dispatch, getState) => {
  const members = selectMembers(getState());
  const pendingMembers = memberIds.map((memberId) => {
    if (pending) {
      return {
        ...members[memberId],
        pendingTeamId: teamId,
        pendingDivisionId: divisionId,
        isPending: true,
      };
    }
    return {
      ...members[memberId],
      teamId,
      divisionId,
    };
  });

  return dispatch(addItemsAction({ itemType: 'member', items: pendingMembers }));
};

const assignMembers =
  (memberIds, divisionId, teamId, isManager = undefined) =>
  (dispatch, getState) => {
    const members = selectMembers(getState());

    const selectedMembers = memberIds.map((id) => members[id]);
    if (!selectedMembers.length) {
      return false;
    }

    const isPlayerTabActive = selectPlayerTabActive(getState());
    const pending = selectRosteringPendingMode(getState());

    const moveMemberParams = {
      memberId: memberIds,
      teamId,
      pending,
      divisionId,
      isNonPlayer: !isPlayerTabActive && !!teamId,
    };

    if (isManager !== undefined) {
      moveMemberParams.isManager = isManager;
    }

    return api
      .cmd('members/move_member', moveMemberParams)
      .then(
        (response) => {
          dispatch(
            loadAncestorsAndSelfAndDescendantDivisionAggregates(
              _min([divisionId, selectRosterListDivisionId(getState())]),
            ),
          );
          dispatch(reapplyFilterToMemberIds(memberIds));
          dispatch(updateMembersToPending(memberIds, teamId, divisionId, pending));
          dispatch(updateMemberSelection([]));
          dispatch(setAssignedMemberCount(memberIds.length, divisionId, teamId));

          const teamIds = _uniq(
            selectedMembers.reduce((arr, member) => {
              arr.push(member.teamId, member.pendingTeamId);
              return arr;
            }, []),
          );
          dispatch(loadItems('teamNames', { id: [...teamIds, teamId] }));
          // Clear message after a delay
          setTimeout(() => {
            dispatch(setAssignedMemberCount(0, divisionId, teamId));
          }, 2000);
        },
        (error) => {
          const errorMessage = getErrorMessage(error);
          dispatch(setAppError(errorMessage, true));
        },
      )
      .catch((error) => {
        console.log('error: members/move_member');
        console.dir(error);
      });
  };

const getUniqueTeamIdsAndPendingTeamIds = (members) => {
  const ids = members
    .filter((member) => member.pendingTeamId || member.pendingDivisionId)
    .reduce((arr, member) => arr.push(member.teamId, member.pendingTeamId) && arr, []);

  return [...new Set(ids)];
};

const assignPendingMembersByDivisionId = (divisionId) => (dispatch, getState) =>
  teamsnapSdk()
    .collections.members.exec('movePendingMember', { divisionId })
    .then(() => {
      dispatch(
        loadAncestorsAndSelfAndDescendantDivisionAggregates(_min([divisionId, selectRosterListDivisionId(getState())])),
      );

      const descendantDivisions = selectDescendantDivisions(getState(), divisionId);
      const descendantDivisionIds = descendantDivisions.map((division) => division.id);
      descendantDivisionIds.push(divisionId);

      const members = selectAsArray(getState(), 'members');

      const uniqueTeamIdsAndPendingTeamIds = getUniqueTeamIdsAndPendingTeamIds(members);
      dispatch(loadItems('teamNames', { id: [...uniqueTeamIdsAndPendingTeamIds] }));

      const updatedMembers = members
        .filter((member) => member.pendingDivisionId || member.pendingTeamId)
        .filter((member) =>
          descendantDivisionIds.some((descendantDivisionId) => descendantDivisionId === member.pendingDivisionId),
        )
        .map((member) => ({
          ...member,
          isPending: false,
          pendingTeamId: null,
          pendingDivisionId: null,
          teamId: member.pendingTeamId,
          divisionId: member.pendingDivisionId,
        }));

      dispatch(addItemsAction({ itemType: 'member', items: updatedMembers }));

      const division = selectDivisionById(getState(), divisionId);
      dispatch(
        showFeedback({
          message: `${division.name} pending members have been assigned.`,
          type: SUCCESS,
        }),
      );
    });

const assignPendingMembersByTeamId = (teamId) => (dispatch, getState) => {
  const team = selectTeamNamesById(getState(), teamId);

  return teamsnapSdk()
    .collections.members.exec('movePendingMember', { teamId })
    .then(() => {
      dispatch(
        loadAncestorsAndSelfAndDescendantDivisionAggregates(
          _min([team.divisionId, selectRosterListDivisionId(getState())]),
        ),
      );

      const members = reSelectAndSortRosterMembersByTeamIdAndPendingTeamId(getState(), teamId);

      const uniqueTeamIdsAndPendingTeamIds = getUniqueTeamIdsAndPendingTeamIds(members);
      dispatch(loadItems('teamNames', { id: [...uniqueTeamIdsAndPendingTeamIds] }));

      const updatedMembers = members
        .filter((member) => member.pendingDivisionId || member.pendingTeamId)
        .map((member) => ({
          ...member,
          isPending: false,
          pendingTeamId: null,
          pendingDivisionId: null,
          teamId: member.pendingTeamId,
          divisionId: member.pendingDivisionId,
        }));

      dispatch(addItemsAction({ itemType: 'member', items: updatedMembers }));
      dispatch(
        showFeedback({
          message: `${team.name} pending members have been assigned.`,
          type: SUCCESS,
        }),
      );
    });
};

const updateDeletedPendingMembers = (members, filterFunc) =>
  members.filter(filterFunc).map((member) => ({
    ...member,
    isPending: false,
    pendingTeamId: null,
    pendingDivisionId: null,
  }));

const deletePendingMemberByMemberId = (memberId) => (dispatch, getState) =>
  teamsnapSdk()
    .collections.members.exec('deletePendingMember', { memberId })
    .then(() => {
      const divisionId = selectActiveDivisionId(getState());
      dispatch(
        loadAncestorsAndSelfAndDescendantDivisionAggregates(_min([divisionId, selectRosterListDivisionId(getState())])),
      );

      const members = selectAsArray(getState(), 'members');

      const uniqueTeamIdsAndPendingTeamIds = getUniqueTeamIdsAndPendingTeamIds(members);
      dispatch(loadItems('teamNames', { id: [...uniqueTeamIdsAndPendingTeamIds] }));

      const updatedMembers = updateDeletedPendingMembers(members, (member) => member.id === memberId);
      dispatch(addItemsAction({ itemType: 'member', items: updatedMembers }));

      const deletedMember = selectMemberById(getState(), memberId);
      dispatch(
        showFeedback({
          message: `The pending assignment for ${deletedMember.firstName} ${deletedMember.lastName} has been cancelled.`,
          type: SUCCESS,
        }),
      );
    });

const deletePendingMembersByTeamId = (teamId) => (dispatch, getState) =>
  teamsnapSdk()
    .collections.members.exec('deletePendingMember', { teamId })
    .then(() => {
      const divisionId = selectActiveDivisionId(getState());
      dispatch(
        loadAncestorsAndSelfAndDescendantDivisionAggregates(_min([divisionId, selectRosterListDivisionId(getState())])),
      );

      const members = selectAsArray(getState(), 'members');

      const uniqueTeamIdsAndPendingTeamIds = getUniqueTeamIdsAndPendingTeamIds(members);
      dispatch(loadItems('teamNames', { id: [...uniqueTeamIdsAndPendingTeamIds] }));

      const updatedMembers = updateDeletedPendingMembers(
        members,
        (member) => member.pendingTeamId === teamId || member.teamId === teamId,
      );
      dispatch(addItemsAction({ itemType: 'member', items: updatedMembers }));

      const team = selectTeamNamesById(getState(), teamId);
      dispatch(
        showFeedback({
          message: `Pending assignments for ${team.name} have been cancelled.`,
          type: SUCCESS,
        }),
      );
    });

const deletePendingMembersByDivisionId = (divisionId) => (dispatch, getState) =>
  teamsnapSdk()
    .collections.members.exec('deletePendingMember', { divisionId })
    .then(() => {
      dispatch(
        loadAncestorsAndSelfAndDescendantDivisionAggregates(_min([divisionId, selectRosterListDivisionId(getState())])),
      );

      const members = selectAsArray(getState(), 'members');

      const uniqueTeamIdsAndPendingTeamIds = getUniqueTeamIdsAndPendingTeamIds(members);
      dispatch(loadItems('teamNames', { id: [...uniqueTeamIdsAndPendingTeamIds] }));

      const divisionIds = selectDivisionAndChildDivisionIds(getState(), divisionId);

      const updatedMembers = updateDeletedPendingMembers(
        members,
        (member) => divisionIds.includes(member.pendingDivisionId) || divisionIds.includes(member.divisionId),
      );
      dispatch(addItemsAction({ itemType: 'member', items: updatedMembers }));

      const division = selectDivisionById(getState(), divisionId);
      dispatch(
        showFeedback({
          message: `Pending assignments for ${division.name} have been cancelled.`,
          type: SUCCESS,
        }),
      );
    });

const assignMembersToDivision = (memberIds, divisionId, isManager) => (dispatch) =>
  dispatch(assignMembers(memberIds, divisionId, null, isManager));

const assignSelectedMembersToTeam = (teamId, isManager) => (dispatch, getState) =>
  dispatch(
    assignMembers(selectSelectedMemberIds(getState()), selectRosterListDivisionId(getState()), teamId, isManager),
  );

const assignMembersToActiveDivision = (memberIds, isManager) => (dispatch, getState) =>
  dispatch(assignMembersToDivision(memberIds, selectActiveDivisionId(getState()), isManager));

const assignSelectedMembersToDivision = (divisionId) => (dispatch, getState) =>
  dispatch(assignMembersToDivision(selectSelectedMemberIds(getState()), divisionId));

const assignSelectedMembersToActiveDivision = () => (dispatch, getState) =>
  dispatch(assignSelectedMembersToDivision(selectActiveDivisionId(getState())));

const addMemberSelection = (memberId, isAllSelected) => ({
  type: constants.ADD_MEMBER_SELECTION,
  memberId,
  isAllSelected,
});

const removeAllMemberSelections = () => ({
  type: constants.REMOVE_ALL_MEMBER_SELECTIONS,
});

const removeMemberSelection = (memberId) => ({
  type: constants.REMOVE_MEMBER_SELECTION,
  memberId,
});

const rosterShowTeamMembers = (teamId) => (dispatch, getState) => {
  const isShowing = selectIsTeamIdShowingMembers(getState(), teamId);
  dispatch({
    type: constants.TOGGLE_SHOW_TEAM_MEMBERS,
    setShowing: !isShowing,
    teamId,
  });
};

const selectAllFilteredMembers = () => ({
  type: constants.SELECT_ALL_FILTERED_MEMBERS,
});

const setCurrentRosteringDivision = (divisionId) => (dispatch) => {
  dispatch({
    type: constants.SET_CURRENT_ROSTERING_DIVISION,
    divisionId,
  });
};

const getRosterCountForDivision = (divisionId) => (dispatch) => {
  const reqUrl = `${teamsnapSdk().apiUrl}/members/division_search?division_id=${divisionId}&page_size=1&page_number=1`;
  teamsnapSdk()
    .request.get(reqUrl)
    .then(
      (response) => {
        const totalItems = response.data.collection.page_info.total_items;
        dispatch({
          type: constants.SET_DIVISION_ROSTER_COUNT,
          divisionId,
          totalItems,
        });
      },
      (error) => {
        dispatch(loadItemsErrorAction({ itemType: 'member', loadParams: null, error }));
      },
    );
};

const bulkActionToggleAll = (isSelected) => (dispatch, getState) => {
  const filteredPlayersCount = selectFilteredPlayersCount(getState());
  const selectedMembersCount = selectSelectedMembersCount(getState());
  if (isSelected && selectedMembersCount < filteredPlayersCount) {
    dispatch(selectAllFilteredMembers());
  } else if (!isSelected && selectedMembersCount === filteredPlayersCount) {
    dispatch(removeAllMemberSelections());
  }
};

const toggleIsMemberSelected = (memberId) => (dispatch, getState) => {
  const selectedMemberIdsNoCommissioners = selectSelectedMemberIdsNoCommissioners(getState());
  const filteredMembersCountNoCommissioners = selectFilteredMembersCountNoCommissioners(getState());
  if (selectedMemberIdsNoCommissioners.indexOf(memberId) > -1) {
    dispatch(removeMemberSelection(memberId));
  } else {
    const isAllSelected = selectedMemberIdsNoCommissioners.length + 1 === filteredMembersCountNoCommissioners;
    dispatch(addMemberSelection(memberId, isAllSelected));
  }
};

const toggleRosterAssignmentView = () => (dispatch, getState) => {
  let nextView = 'team';
  const currentView = selectRosterAssignmentView(getState());
  if (currentView === 'team') {
    nextView = 'member';
  }
  dispatch({
    type: constants.TOGGLE_ROSTER_ASSIGNMENT_VIEW,
    view: nextView,
  });
};

const toggleRosterListSort = (sortOn) => (dispatch, getState) => {
  const currentSort = selectRosterListSortOn(getState());
  const currentSortReverse = selectRosterListSortReverse(getState());
  let setSort;
  let setSortReverse;

  if (currentSort === sortOn) {
    setSort = currentSort;
    setSortReverse = !currentSortReverse;
  } else {
    setSort = sortOn;
    setSortReverse = false;
  }
  dispatch({
    type: constants.TOGGLE_ROSTER_LIST_SORT,
    sortOn: setSort,
    sortReverse: setSortReverse,
  });
};

const toggleSelectAllMembers = () => (dispatch, getState) => {
  let toggledMemberSelections = [];
  const filteredMembersNoCommissioners = selectFilteredMemberIdsNoCommissioners(getState());
  const selectedMembersCountNoCommissioners = selectSelectedMembersCountNoCommissioners(getState());
  if (selectedMembersCountNoCommissioners < filteredMembersNoCommissioners.length) {
    toggledMemberSelections = filteredMembersNoCommissioners;
  }
  dispatch(updateMemberSelection(toggledMemberSelections));
};

const applyFilteredResults = () => (dispatch, getState) => {
  const currentFilter = selectCurrentFilter(getState());
  dispatch(applyRosterFilter(currentFilter));
  const searchParams = buildSearchParams(getState());

  if (selectRegistrationVersion(getState()) === 1) {
    dispatch(rosterLoadPlayers(searchParams));
  } else {
    dispatch(rosterLoadPlayersV2(searchParams));
  }
};

const loadInitialPlayers =
  (count = 50) =>
  (dispatch, getState) => {
    const activeDivisionId = selectActiveDivisionId(getState());
    const storedSession = localStorage.get('roster_search_previous_session');
    let loadParams;
    if (storedSession) {
      // We should probably add some sort of date check here
      const session = JSON.parse(storedSession);
      dispatch(replaceCurrentFilter(session.filter));
      if (session.memberIds.length) {
        loadParams = {
          divisionId: activeDivisionId,
          id: session.memberIds,
        };
      }
      localStorage.remove('roster_search_previous_session');
    } else {
      loadParams = buildSearchParams(getState());
      loadParams.pageSize = count;
      loadParams.page = 1;
    }
    if (loadParams) {
      dispatch(rosterLoadPlayers(loadParams));
    }
  };

const loadMembersForTeam = (teamId) => (dispatch, getState) => {
  dispatch({
    type: constants.LOAD_MEMBERS_FOR_TEAM_START,
    teamId,
  });

  const loadParams = {
    divisionId: selectActiveDivisionId(getState()),
    teamId,
    pendingAssignments: 'include',
  };

  teamsnapSdk()
    .divisionAdvancedLoadMembers(loadParams)
    .then(
      (members) => {
        dispatch(
          loadItemsSuccessAction({
            itemType: 'member',
            loadParams,
            items: members,
          }),
        );
        dispatch(addItemsAction({ itemType: 'member', items: members, loadParams }));
        dispatch({
          type: constants.LOAD_MEMBERS_FOR_TEAM_SUCCESS,
          teamId,
        });
      },
      (error) => {
        const errorMessage = getErrorMessage(error);
        dispatch(setAppError(errorMessage, true));
        dispatch({
          type: constants.LOAD_MEMBERS_FOR_TEAM_END,
          teamId,
        });
      },
    );
};

const loadAllPlayers = () => (dispatch, getState) => {
  const activeDivisionId = selectActiveDivisionId(getState());
  const loadParams = {
    divisionId: activeDivisionId,
  };
  dispatch(rosterLoadPlayers(loadParams));
};

const useSavedFilter = (filter) => (dispatch) => {
  dispatch(replaceCurrentFilter(filter, filter.filterIndex));
  dispatch(applyFilteredResults());
};

const enableRosteringPendingMode = () => (dispatch) => {
  localStorage.set('rostering_pending_mode_enabled', true);
  dispatch({
    type: constants.TOGGLE_ROSTERING_PENDING_MODE,
    pendingModeEnabled: true,
  });
};

const disableRosteringPendingMode = () => (dispatch) => {
  localStorage.set('rostering_pending_mode_enabled', false);
  dispatch({
    type: constants.TOGGLE_ROSTERING_PENDING_MODE,
    pendingModeEnabled: false,
  });
};

const updatePlayerTabStatus = (playerTabActive) => (dispatch) =>
  dispatch({
    type: constants.UPDATE_IS_PLAYER_TAB,
    playerTabActive,
  });

export {
  applyFilteredResults,
  assignMembers,
  assignMembersToActiveDivision,
  assignPendingMembersByDivisionId,
  assignPendingMembersByTeamId,
  assignSelectedMembersToActiveDivision,
  assignSelectedMembersToDivision,
  assignSelectedMembersToTeam,
  bulkActionToggleAll,
  deletePendingMemberByMemberId,
  deletePendingMembersByDivisionId,
  deletePendingMembersByTeamId,
  disableRosteringPendingMode,
  enableRosteringPendingMode,
  getRosterCountForDivision,
  loadAllPlayers,
  loadInitialPlayers,
  loadMembersForTeam,
  reapplyFilterToMemberIds,
  removeAllMemberSelections,
  replaceCurrentFilter,
  rosterShowTeamMembers,
  setCurrentRosteringDivision,
  setAssignedMemberCount,
  toggleIsMemberSelected,
  toggleRosterAssignmentView,
  toggleRosterListSort,
  toggleSelectAllMembers,
  useSavedFilter,
  toggleMemberSearchPopup,
  updatePlayerTabStatus,
  setRegistrationVersion,
};
