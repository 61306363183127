import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _range from 'lodash/range';
import {
  getDate,
  formatDate,
  getMonthYear,
  getDateParts,
  getCalendarMonths,
  daysInMonth as getDaysInMonth,
  getYear,
} from 'utils/date-service';
import moment from 'moment';

import InputSelect from 'shared/components/Form/InputSelect';

import classes from './DateSelect.module.scss';

class DateSelect extends Component {
  static propTypes = {
    dateFormat: PropTypes.string,
    dateDisplayMonthFirst: PropTypes.bool,
    defaultDate: PropTypes.string,
    disabled: PropTypes.bool,
    mods: PropTypes.string,
    updateHandler: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = getDateParts();
  }

  getCalendarDays = () => {
    const { year, month } = this.state;
    let daysInMonth = 31;
    if (year && month) {
      daysInMonth = getDaysInMonth(getMonthYear(year, month));
    }
    return _range(1, daysInMonth + 1);
  };

  getCalendarMonthsForSelect = () => {
    const monthsForSelect = [];
    const months = getCalendarMonths();
    months.forEach((month, index) => {
      monthsForSelect.push({ label: month, value: index, key: month });
    });
    return monthsForSelect;
  };

  getCalendarYears = () => {
    const yearsForSelect = [];
    const maxYear = getYear() + 1;
    const years = _range(1916, maxYear).reverse();
    years.forEach((year, index) => {
      yearsForSelect.push({
        label: year,
        value: moment.utc().year() - index,
        key: year,
      });
    });
    return yearsForSelect;
  };

  updateDatePickerField = (value, fieldName) => {
    if (this.props.disabled) {
      return this.props.updateHandler(null);
    }
    const newState = { ...this.state };
    if (value && fieldName) {
      this.setState({
        [fieldName]: value,
      });
      newState[fieldName] = value;
    }

    let returnedDate = getDate(newState);
    if (this.props.dateFormat) {
      returnedDate = formatDate(returnedDate, this.props.dateFormat);
    }
    this.props.updateHandler(returnedDate);
  };

  renderDateField = () => (
    <InputSelect
      id="date-select"
      defaultValue={ this.state.date.toString() }
      disabled={ this.props.disabled }
      name="date"
      options={ this.getCalendarDays() }
      updateHandler={ this.updateDatePickerField }
      wrapperClass={ classes.day }
    />
  );

  componentDidMount() {
    if (this.props.defaultDate) {
      const dateParts = getDateParts(this.props.defaultDate);
      this.setState({ ...dateParts });
    } else if (!this.props.disabled) {
      this.updateDatePickerField();
    }
  }

  componentDidUpdate() {
    const dateParts = getDateParts(this.props.defaultDate);
    if (
      this.state.year !== dateParts.year &&
      this.state.month !== dateParts.month &&
      this.state.year !== dateParts.date
    ) {
      this.updateDatePickerField();
    }
  }

  render() {
    const { dateDisplayMonthFirst, disabled, mods } = this.props;

    return (
      <span className={ `${classes.wrapper} ${mods}` }>
        { !dateDisplayMonthFirst && this.renderDateField() }
        <InputSelect
          defaultValue={ this.state.month.toString() }
          disabled={ disabled }
          name="month"
          options={ this.getCalendarMonthsForSelect() }
          updateHandler={ this.updateDatePickerField }
          wrapperClass={ classes.month }
        />
        { dateDisplayMonthFirst && this.renderDateField() }
        <InputSelect
          defaultValue={ this.state.year.toString() }
          disabled={ disabled }
          name="year"
          options={ this.getCalendarYears() }
          updateHandler={ this.updateDatePickerField }
          wrapperClass={ classes.year }
        />
      </span>
    );
  }
}

export default DateSelect;
