// setup instructions
// https://my.appcues.com/install/spa/2343
const setupAppcues = () => {
  if (typeof window.Appcues === 'undefined') {
    // eslint-disable-next-line
    console.error('Appcues is expected to be installed.');
  }
};

const startAppcues = () => {
  if (typeof window.Appcues !== 'undefined') {
    window.Appcues.start();
  }
};

export { setupAppcues, startAppcues };
