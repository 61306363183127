import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppLoading from 'shared/components/AppLoading';
import { Button, Panel } from 'shared/toolkit';
import { linkLeagueMessagesListEmails, linkLeagueMessagesClubEmail } from 'utils/links';
import { formatLocalDate } from 'utils/date-service';

import styles from './ViewEmail.module.scss';

class ViewEmail extends Component {
  static propTypes = {
    broadcastEmail: PropTypes.object,
    division: PropTypes.object,
    loading: PropTypes.bool,
    initViewEmail: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.props.initViewEmail(this.props.params.id);
  }

  renderViewEmailFooter = () => (
    <footer className={ `u-flex u-flexJustifyCenter ${styles.viewEmailFooter}` }>
      <Button
        type="link"
        className="ButtonNegativeLarge"
        handleClick={ linkLeagueMessagesListEmails(this.props.division.id) }
        text="Back"
        iconLeft="dismiss"
      />
      <Button
        type="link"
        className="ButtonPrimaryLarge"
        handleClick={ linkLeagueMessagesClubEmail(this.props.division.id, this.props.broadcastEmail.id) }
        text="Send To Others"
        iconLeft="mail"
      />
    </footer>
  );

  render() {
    const { broadcastEmail, loading, division } = this.props;

    if (loading || !broadcastEmail || !division) {
      return <AppLoading loading />;
    }
    return (
      <div>
        <div className="u-flex u-flexJustifyCenter">
          <Panel
            footerComponent={ this.renderViewEmailFooter() }
            componentStyles={ styles }
            title={ `Email sent on ${formatLocalDate(broadcastEmail.createdAt, 'MM/DD/YYYY h:mm a')}` }
            iconLeft="dismiss"
          >
            <div className="u-padSm">
              <h4 className="u-padBottomSm">{ broadcastEmail.subject }</h4>
              <div className="u-padBottomSm" dangerouslySetInnerHTML={ { __html: broadcastEmail.body } } />
              { broadcastEmail.attachmentNames && broadcastEmail.attachmentNames.length > 0 && (
                <p>
                  <hr className="Divider" />
                  <strong className={ styles.sentToText }>Attachments:</strong>
                  <br />
                  { broadcastEmail.attachmentNames }
                </p>
              ) }
              <hr className="Divider" />
              <p className="u-padTopSm">
                <span className={ styles.sentToText }>
                  <strong>Sent To:</strong>
                </span>
                <br />
                { broadcastEmail.recipientNames }
              </p>
            </div>
          </Panel>
        </div>
      </div>
    );
  }
}

export default ViewEmail;
