/**
 * Heading Component
 *
 * Generic heading component that accepts a heading size as well as other props.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';
import defaultStyles from './Heading.module.scss';

class Heading extends PureComponent {
  renderIcon = (name, fontSize, color) => <Icon name={ name } style={ { fontSize, color } } />;

  renderHeading = styles => {
    const { title, iconLeftName, iconRightName, iconSize, iconColor } = this.props;

    let headingTextClass = '';

    if (iconLeftName && iconRightName) {
      headingTextClass = 'HeadingSpacer';
    } else if (iconLeftName) {
      headingTextClass = 'HeadingSpacerLeft';
    } else if (iconRightName) {
      headingTextClass = 'HeadingSpacerRight';
    }

    return (
      <span>
        { iconLeftName && this.renderIcon(iconLeftName, iconSize, iconColor) }
        <span className={ styles[headingTextClass] }>{ title }</span>
        { iconRightName && this.renderIcon(iconRightName, iconSize, iconColor) }
      </span>
    );
  };

  render() {
    const { title, children, size, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const HeadingTag = `h${size}`;

    return <HeadingTag className={ styles[className] }>{ title ? this.renderHeading(styles) : children }</HeadingTag>;
  }
}

Heading.propTypes = {
  title: PropTypes.string,
  iconLeftName: PropTypes.string,
  iconRightName: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Heading.defaultProps = {
  title: null,
  iconLeftName: null,
  iconRightName: null,
  iconSize: undefined,
  iconColor: null,
  size: 3,
  children: null,
  componentStyles: {},
  className: 'Heading',
};

export default Heading;
