import React from 'react';
import PropTypes from 'prop-types';

import classes from './AssignmentDropZone.module.scss';

const AssignmentDropZone = ({ assignMembers, division, isOver, teamName }) => (
  <div onClick={ assignMembers } className={ isOver ? [classes.PreDrop, classes.OverDrop].join(' ') : classes.PreDrop }>
    <p className={ classes.PreDropText }>
      - Drop Here to Assign to { division ? division.name : teamName ? teamName.name : 'Error' } -
      <small className={ classes.ClickAssignText }>Or click to assign</small>
    </p>
  </div>
);

AssignmentDropZone.propTypes = {
  division: PropTypes.shape({}),
  teamName: PropTypes.shape({}),
  assignMembers: PropTypes.func.isRequired,
  isOver: PropTypes.bool.isRequired,
};

export default AssignmentDropZone;
