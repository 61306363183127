"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function get() {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var teamsnap_ui_1 = require("@teamsnap/teamsnap-ui");
var stripe_identities_api_1 = require("../../services/stripe-identities-api");
var StripeIdentityBanner = function StripeIdentityBanner() {
  var _a = React.useState(false),
    isShowBanner = _a[0],
    setIsShowBanner = _a[1];
  var _b = React.useState(''),
    bannerTitle = _b[0],
    setBannerTitle = _b[1];
  var _c = React.useState(''),
    bannerMessage = _c[0],
    setBannerMessage = _c[1];
  var _d = React.useState(''),
    linkName = _d[0],
    setLinkName = _d[1];
  var _e = React.useState('start_stripe_verification'),
    verificationClickText = _e[0],
    setVerificationClickText = _e[1];
  var _f = React.useState('highlight'),
    bannerType = _f[0],
    setBannerType = _f[1];
  React.useEffect(function () {
    (0, stripe_identities_api_1.getStripeIdentityRequest)().then(function (data) {
      var status = data.find(function (item) {
        return item.name === 'status';
      }).value;
      setBannerDetails(status);
    });
  }, []);
  var setBannerDetails = function setBannerDetails(status) {
    if (status !== 'VERIFIED' && status !== 'PROCESSING' && status !== 'NOT_APPLICABLE' && status !== 'REQUIRES_INPUT') {
      setIsShowBanner(true);
      setBannerTitle('Identity Verification Required');
      setBannerMessage('Please complete identity verification on our mobile apps within the Invoicing menu to enable payouts for your team.');
      setLinkName('Start Verification');
      setVerificationClickText('start_stripe_verification');
      setBannerType('highlight');
    } else if (status === 'REQUIRES_INPUT') {
      setIsShowBanner(true);
      setBannerTitle('Identity Verification Error');
      setBannerMessage('We were unable to validate your identity. You cannot receive funds into your bank account until your identity is verified. Please retry identity verification on our mobile apps.');
      setLinkName('Retry Identity Verification');
      setVerificationClickText('retry_stripe_verification');
      setBannerType('negative');
    } else {
      setIsShowBanner(false);
    }
  };
  return React.createElement(React.Fragment, null, isShowBanner && React.createElement("div", {
    className: "Feedback Feedback--".concat(bannerType),
    role: "alert"
  }, React.createElement("div", {
    className: "Feedback-icon"
  }, React.createElement(teamsnap_ui_1.Icon, {
    name: 'notifications'
  })), bannerTitle && React.createElement("span", {
    className: "Feedback-title"
  }, bannerTitle), bannerMessage && bannerMessage, React.createElement(teamsnap_ui_1.TextLink, {
    location: 'https://teamsnap.onelink.me/Ea1W/svguhfvi',
    style: {
      marginLeft: '10px'
    }
  }, linkName)));
};
exports.default = StripeIdentityBanner;