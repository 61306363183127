import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { FieldWrapper, Icon } from '@teamsnap/teamsnap-ui';
import searchPillStyles from '../SearchPill.module.scss';

import './ParticipantGroupComboboxFilter.scss';

const OPTIONS_LIMIT = 7;

const searchOptions = (options, searchValue) => {
  const lowercaseFilter = searchValue?.toLowerCase();
  return options.filter((option) => option.label.toLowerCase().startsWith(lowercaseFilter));
};

export const ParticipantGroupComboboxFilter = ({ options, onUpdate, onClear, currentFilter, selected }) => {
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState(null);
  const [displayOptions, setDisplayOptions] = React.useState([]);
  const [registrationId, setRegistrationId] = React.useState(null);

  const { participantGroups, registration_form_id } = currentFilter;

  React.useEffect(() => {
    const filteredOptions = options.filter((o) => +o.formId === +registrationId);
    if (!searchValue || searchValue === '') {
      setDisplayOptions(filteredOptions);
    } else {
      setDisplayOptions(searchOptions(filteredOptions, searchValue));
    }

    if (selected && selected.length > 0) {
      setSelectedOptions(selected);
    }
  }, [searchValue, options, selected, registrationId]);

  React.useEffect(() => {
    if (participantGroups?.length === 0) {
      setSelectedOptions([]);
    }
    if (registrationId !== registration_form_id) {
      setSelectedOptions([]);
    }
    if (registration_form_id) {
      setRegistrationId(registration_form_id);
    } else {
      setRegistrationId(null);
    }
  }, [participantGroups, registration_form_id]);

  return (
    <div className="combobox-container">
      { !registrationId && (
        <div className="combobox-message">
          <p>
            Only groups from the selected registration forms are included. Please select at least one registration form
            in the registration filter.
          </p>
        </div>
      ) }
      { registrationId && (
        <div className="combobox-options">
          { (displayOptions.length > OPTIONS_LIMIT || searchValue) && (
            <div className="combobox-options__search-container">
              <div className="combobox-options__search">
                <Icon name="search" />
                <FieldWrapper
                  field="input"
                  name="participant_group_search"
                  fieldProps={ {
                    inputProps: {
                      placeholder: 'Search for a Participant Group',
                      value: searchValue || '',
                      onChange: ({ target: { value } }) => setSearchValue(value),
                    },
                  } }
                />
              </div>
            </div>
          ) }
          <div className="options">
            { displayOptions.map((option) => (
              <div className="Checkbox" key={ `option-${option.value}` }>
                <input
                  className={ `combobox-${option.value} u-padRightXs Checkbox-input Checkbox--inline Checkbox--large` }
                  aria-checked="true"
                  tabIndex="0"
                  type="checkbox"
                  id={ `combobox-${option.value}` }
                  checked={ selectedOptions.includes(option.value) }
                  name={ `combobox-${option.value}` }
                  value={ option.value }
                  onChange={ (e) => {
                    if (selectedOptions.includes(e.target.value)) {
                      onUpdate(selectedOptions.filter((e) => e !== option.value));
                      setSelectedOptions(selectedOptions.filter((e) => e !== option.value));
                    } else {
                      onUpdate([...selectedOptions, e.target.value]);
                      setSelectedOptions([...selectedOptions, e.target.value]);
                    }
                  } }
                />
                <label className="Checkbox-label" htmlFor={ `combobox-${option.value}` }>
                  { option.label }
                </label>
              </div>
            )) }
            { displayOptions.length <= 0 && (
              <div className="u-textCenter u-padMd">
                <p>
                  <strong>No results found</strong>
                </p>
              </div>
            ) }
          </div>
          <div className={ searchPillStyles['Popup-footer'] }>
            <a
              className="btn-clear-filters u-colorNegative u-textUnderline"
              onClick={ () => {
                setSelectedOptions([]);
                onClear();
              } }
            >
              Clear Filters
            </a>
          </div>
        </div>
      ) }
    </div>
  );
};

export default ParticipantGroupComboboxFilter;
