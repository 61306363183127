import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { linkDivisionRosterEdit } from 'utils/links';
import { ImgPlusMedium, ImgEmailBig } from 'shared/components/Img';

class ClubEmailNoEmails extends Component {
  static propTypes = {
    division: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
  };

  render() {
    const { division, member } = this.props;
    return (
      <table className="standard_table fullwidth" cellPadding={ 0 }>
        <tbody>
          <tr style={ { textAlign: 'left' } }>
            <td style={ { textAlign: 'left', padding: 40 } }>
              <h1 className="nospaceafter" style={ { textAlign: 'center', paddingRight: 30 } }>
                <span className="messages-email-icon" />
                <ImgEmailBig
                  style={ {
                    verticalAlign: 'middle',
                    position: 'relative',
                    top: '-2px',
                  } }
                  className="CLASSIC_ONLY"
                  width={ 56 }
                  height={ 33 }
                />
                Please Enter Your Email Address
              </h1>
              <p style={ { width: 350, margin: '12px auto 25px auto' } }>
                You won't be able to send email messages until you have entered your own email address. Otherwise, no
                one would be able to write back to you. How sad!
              </p>
              <p style={ { textAlign: 'center' } }>
                <a href={ linkDivisionRosterEdit(division.id, member) } className="button large">
                  <i>
                    <ImgPlusMedium />
                  </i>
                  <span>Enter your email address</span>
                </a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default ClubEmailNoEmails;
