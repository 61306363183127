import moment from 'moment';

// Looks like most of these uses are related to formatting by locale, could we save some 'space' and use
// something like this instead ->
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString

const isDateValid = (date, format) => moment(date, format, true).isValid();

const getLocalDate = date => moment(date);

const formatLocalDate = (date, format) => moment(date).format(format);

const getDate = date => moment.utc(date);

const formatDate = (date, format) => moment.utc(date).format(format);

const getYear = date => moment.utc(date).year();

const getMonth = date => moment.utc(date).month();

const getDay = date => moment.utc(date).date();

const getMonthYear = (year, month) =>
  moment
    .utc()
    .year(year)
    .month(month);

const daysInMonth = date => moment.utc(date).daysInMonth();

const fromNowDate = date => moment.utc(date).fromNow();

const isSameOrBeforeCurrentDate = date => moment.utc(date).isSameOrBefore();

const getDateDifference = (date, measurement, float) => moment.utc().diff(date, measurement, float);

const getDateParts = date => {
  const dateParts = moment.utc(date);
  return {
    year: dateParts.year(),
    month: dateParts.month(),
    date: dateParts.date(),
  };
};

const getDatePartsWithFormat = (dateString, dateFormatter = {}, originalFormat = 'MM-DD-YYYY') => {
  const date = moment.utc(dateString, originalFormat);

  const formatter = {
    year: 'YYYY',
    month: 'MM',
    day: 'DD',
    date: originalFormat,
    ...dateFormatter,
  };

  return {
    date: date.format(formatter.date),
    year: date.format(formatter.year),
    month: date.format(formatter.month),
    day: date.format(formatter.day),
  };
};

const isValidDate = date => date instanceof Date && !isNaN(date) && date.getTime() !== new Date('').getTime();

const formatDateRange = (firstDate, secondDate) => {
  const dates = [];

  const formatter = date => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  if (firstDate != null && isValidDate(new Date(firstDate))) {
    dates.push(formatter(firstDate));
  }
  if (secondDate != null && isValidDate(new Date(secondDate))) {
    dates.push(formatter(secondDate));
  }

  return dates.join(' - ');
};

const getCalendarMonths = () => [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export {
  isDateValid,
  getLocalDate,
  formatLocalDate,
  getDate,
  formatDate,
  getYear,
  getMonth,
  getDay,
  getMonthYear,
  daysInMonth,
  fromNowDate,
  isSameOrBeforeCurrentDate,
  getDateDifference,
  getDateParts,
  getDatePartsWithFormat,
  getCalendarMonths,
  isValidDate,
  formatDateRange,
};
