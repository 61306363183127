import { connect } from 'react-redux';

// Selectors
import {
  selectDivisionAggregateSelfAndDescendantPendingChangesCount,
  selectDivisionAggregateSelfAndDescendantsTeamCount,
  selectDivisionAggregateByDivisionIdOrEmpty,
} from 'state/teamsnap/divisionAggregates/selectors';
// Actions
import { setCurrentRosteringDivision } from 'state/leagueRostering/actions';
// Components
import TeamListRowDivision from '../components/TeamListRowDivision';

const mapDispatchToProps = (dispatch, ownProps) => ({
  selectDivision: () => {
    dispatch(setCurrentRosteringDivision(ownProps.division.id));
  },
});

const mapStateToProps = (state, ownProps) => {
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, ownProps.division.id);
  return {
    rosteredMembersCount: divisionAggregate.selfAndDescendantsAssignedMemberCount,
    unRosteredMembersCount: divisionAggregate.selfAndDescendantsUnassignedMemberCount,
    commissionerCount: divisionAggregate.selfAndDescendantsCommissionerCount,
    teamsCount: selectDivisionAggregateSelfAndDescendantsTeamCount(state, ownProps.division.id),
    pendingChangesCount: selectDivisionAggregateSelfAndDescendantPendingChangesCount(state, ownProps.division.id),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamListRowDivision);
