import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import LabelIndicator from './LabelIndicator';
import classes from './Widget.module.scss';

class WidgetCounts extends PureComponent {
  renderItems = () => {
    const { items, mods } = this.props;

    return items.map(item => (
      <Cell key={ item.label } mods={ mods }>
        <LabelIndicator
          indicatorColor={ item.indicatorColor }
          label={ item.label }
          value={ item.value }
          info={ item.info } />
      </Cell>
    ));
  };

  render() {
    return <Grid mods={ [classes.fullHeight, 'u-flexAlignContentCenter'].join(' ') }>{ this.renderItems() }</Grid>;
  }
}

WidgetCounts.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mods: PropTypes.string,
};

WidgetCounts.defaultProps = {
  mods: 'u-sizeFill',
};

export default WidgetCounts;
