import { connect } from 'react-redux';

// Actions
import { deleteAdvancedFilter, resetAdvancedFilter, updateAdvancedFilter } from 'state/leagueRostering/actions';
// Selectors
import { selectActiveDivisionId } from 'state/app/selectors';
import { selectActiveDivisionRootId, selectDivisionTreeForDivisionId } from 'state/teamsnap/divisions/selectors';
import { selectLeagueCustomFieldsForDivisionId } from 'state/teamsnap/leagueCustomFields/selectors';
import { selectCheckoutTypes, selectSignupStatuses } from 'state/leagueRostering/selectors';
import { selectAllTeamNamesUnderDivisionId } from 'state/teamsnap/teamNames/selectors';
// Components
import AdvancedFilter from '../components/AdvancedFilter';

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateAdvancedField: (value, fieldName) => {
    dispatch(updateAdvancedFilter(ownProps.advancedFilterIndex, fieldName, value));
  },
  deleteFilter: filterIndex => {
    dispatch(deleteAdvancedFilter(filterIndex));
  },
  resetAdvancedFilter: filterIndex => {
    dispatch(resetAdvancedFilter(filterIndex));
  },
});

const mapStateToProps = (state, ownProps) => ({
  advancedFilterIndex: ownProps.advancedFilterIndex,
  checkoutTypes: selectCheckoutTypes(),
  customFields: selectLeagueCustomFieldsForDivisionId(state)(selectActiveDivisionRootId(state)),
  defaultField: ownProps.field,
  defaultOperator: ownProps.operator,
  defaultValue: ownProps.value,
  divisionTree: selectDivisionTreeForDivisionId(state)(selectActiveDivisionId(state)),
  signupStatuses: selectSignupStatuses(),
  teamNames: selectAllTeamNamesUnderDivisionId(state, selectActiveDivisionId(state)) || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter);
