import { loadItems } from 'state/teamsnap/actions';

const loadRegistrationForms = params => dispatch => {
  dispatch(loadItems('registrationForms', params));
};

const loadRegistrationFormsByDivisionId = divisionId => dispatch => {
  dispatch(loadRegistrationForms({ divisionId }));
};

export { loadRegistrationForms, loadRegistrationFormsByDivisionId };
