import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PanelRow, PanelCell } from '@teamsnap/teamsnap-ui';
import { Panel, Button } from 'shared/toolkit';

import { linkLeagueMessagesClubEmail } from 'utils/links';

import ListEmailRow from './ListEmailRow';

class ListEmailsContent extends Component {
  static propTypes = {
    division: PropTypes.object.isRequired,
    broadcastEmails: PropTypes.array,
    deleteAllEmails: PropTypes.bool,
    deleteEmailIds: PropTypes.object,
    clickDeleteAllEmails: PropTypes.func.isRequired,
    clickDeleteEmail: PropTypes.func.isRequired,
    deleteSelectedEmails: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  };

  onClickDeleteSelectedEmails = event => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Really delete? This cannot be undone.')) {
      this.props.deleteSelectedEmails();
    }
  };

  onClickDeleteAllEmails = event => {
    this.props.clickDeleteAllEmails(
      event.target.checked,
      // this.props.location has changed with the deletion of react-router-redux. there is no query.page
      this.props.location.state?.page || 1,
    );
  };

  renderListEmailsFooter = () => (
    <div className="u-flex u-flexJustifyBetween">
      <Button
        type="link"
        className="ButtonPrimary"
        handleClick={ linkLeagueMessagesClubEmail(this.props.division.id) }
        text={ `Email ${this.props.division.name}` }
        iconLeft="mail"
      />
      <Button
        type="button"
        className="ButtonSmall"
        handleClick={ this.onClickDeleteSelectedEmails }
        text="Delete Selected"
      />
    </div>
  );

  render() {
    const { division, broadcastEmails, deleteEmailIds, clickDeleteEmail, deleteAllEmails } = this.props;

    return (
      <div>
        <form>
          <Panel footerComponent={ this.renderListEmailsFooter() }>
            <PanelRow isWithCells>
              <PanelCell isTitle>Email Subject</PanelCell>
              <PanelCell isTitle>Email Sent To</PanelCell>
              <PanelCell isTitle>Email Sent Date</PanelCell>
              <PanelCell isTitle>Email Attachments</PanelCell>
              <PanelCell className="Panel-cell u-flex u-flexJustifyEnd">
                <label className="hrs__checkbox--inline" style={ { paddingRight: 40 } }>
                  <input
                    onClick={ this.onClickDeleteAllEmails }
                    title="Select All"
                    type="checkbox"
                    checked={ deleteAllEmails || false }
                  />
                  <span className="hrs__checkbox-label" />
                </label>
              </PanelCell>
            </PanelRow>
            { broadcastEmails.map(broadcastEmail => (
              <ListEmailRow
                key={ broadcastEmail.id }
                broadcastEmail={ broadcastEmail }
                division={ division }
                deleteIsChecked={ deleteEmailIds[broadcastEmail.id] || false }
                clickDeleteEmail={ clickDeleteEmail }
              />
            )) }
          </Panel>
        </form>
      </div>
    );
  }
}

export default ListEmailsContent;
