import { selectBroadcastEmailsByMemberId } from 'state/teamsnap/broadcastEmails/selectors';
import { selectBroadcastEmailAttachmentsByBroadcastEmailId } from 'state/teamsnap/broadcastEmailAttachments/selectors';
import { selectLoggedInMemberId } from 'state/teamsnap/members/selectors';
import { selectLoaded, selectLoading } from 'state/loading/selectors';
import _parseInt from 'lodash/parseInt';

import { totalPages } from 'utils/totalPages';

// ------------------------------------
// Club Email Selectors
// ------------------------------------
const selectLoadingLeagueMessages = state => selectLoading(state, 'leagueMessages');
const selectLeagueMessagesLoaded = state => selectLoaded(state, 'leagueMessages');
const selectLoadingClubEmail = state => selectLoading(state, 'clubEmail');
const selectClubEmailLoaded = state => selectLoaded(state, 'clubEmail');
const selectLoadingViewEmail = state => selectLoading(state, 'viewEmail');
const selectViewEmailLoaded = state => selectLoaded(state, 'viewEmail');

const listEmailsPerPage = state => state.leagueMessages.listEmails.perPage;
const currentBroadcastEmail = state => state.leagueMessages.broadcastEmail;
const attachments = state => state.leagueMessages.attachments;

export const selectListEmailsDeleteEmailIds = state => state.leagueMessages.listEmails.deleteEmailIds;

export const selectAttachment = (state, attachmentId) =>
  attachments(state).find(attachment => attachment.id === attachmentId);

export function selectEmailsByPage(state, currentPage) {
  const broadcastEmails = selectBroadcastEmailsByMemberId(state, selectLoggedInMemberId(state));
  const perPage = listEmailsPerPage(state);
  const start = (_parseInt(currentPage) - 1) * perPage;

  return broadcastEmails.slice(start, start + perPage);
}

export function selectTotalEmailPages(state) {
  const broadcastEmails = selectBroadcastEmailsByMemberId(state, selectLoggedInMemberId(state) || []);
  return totalPages(broadcastEmails.length, listEmailsPerPage(state));
}

export function currentBroadcastEmailId(state) {
  const email = currentBroadcastEmail(state);
  if (email) {
    return email.id;
  }
  return null;
}

export function attachmentsSize(state) {
  const attachments = state.leagueMessages.attachments || [];
  const uploadedAttachments = selectBroadcastEmailAttachmentsByBroadcastEmailId(state, currentBroadcastEmailId(state));

  let size = 0;
  attachments.forEach(attachment => {
    if (attachment.fileList) {
      for (let i = 0; i < attachment.fileList.length; i += 1) {
        size += attachment.fileList[i].size;
      }
    }
  });
  if (uploadedAttachments && uploadedAttachments.length > 0) {
    uploadedAttachments.forEach(attachment => {
      size += attachment.fileFileSize;
    });
  }
  return size;
}

export {
  selectLoadingLeagueMessages,
  selectLeagueMessagesLoaded,
  selectLoadingClubEmail,
  selectClubEmailLoaded,
  selectLoadingViewEmail,
  selectViewEmailLoaded,
};
