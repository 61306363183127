import { connect } from 'react-redux';

import { initLeagueMessages } from 'state/leagueMessages/actions';

import { selectLoadingLeagueMessages } from 'state/leagueMessages/selectors';

import { withRouter } from 'react-router-dom';

import LeagueMessages from '../components/LeagueMessages';

const mapDispatchToProps = {
  initLeagueMessages,
};

const mapStateToProps = state => ({
  loading: selectLoadingLeagueMessages(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeagueMessages));
