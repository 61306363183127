import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { featureRollout } from 'utils/window';
import { FontIconMember, FontIconMembers, FontIconPendingChanges } from 'shared/components/ReusedFontIcons';
import classes from './TeamListRowDivision.module.scss';

class TeamListRowDivision extends Component {
  static propTypes = {
    teamsCount: PropTypes.number,
    division: PropTypes.object.isRequired,
    selectDivision: PropTypes.func.isRequired,
    rosteredMembersCount: PropTypes.number,
    unRosteredMembersCount: PropTypes.number,
    pendingChangesCount: PropTypes.number,
  };

  render() {
    const {
      teamsCount,
      division,
      selectDivision,
      rosteredMembersCount,
      unRosteredMembersCount,
      commissionerCount,
      pendingChangesCount,
    } = this.props;
    return (
      <tr className={ classes.divisionListRow }>
        <td className={ classes.divisionListItem }>
          <strong>
            <a onClick={ selectDivision }>
              { division.name }
              <i className={ classes['indicator--ss-navigateright'] } />
            </a>
          </strong>
          <div className="pull-right">
            <span className={ classes.count }>
              { teamsCount }
              <FontIconMembers />
            </span>
            <span className={ classes.count }>
              { rosteredMembersCount + unRosteredMembersCount - commissionerCount }
              <FontIconMember title="Members" />
            </span>
            <span className={ classes.count }>
              { pendingChangesCount }
              <FontIconPendingChanges title="Pending Changes" className="u-colorHighlight" />
            </span>
          </div>
        </td>
      </tr>
    );
  }
}

export default TeamListRowDivision;
