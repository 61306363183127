import _values from 'lodash/values';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.HEALTH_CHECK_QUESTIONNAIRE];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectHealthCheckQuestionnaireIsFetching = state => isFetchingSelector(state);
export const selectHealthCheckQuestionnaireValues = state => _values(itemSelector(state))[0] || {};
export const selectHealthCheckQuestionnaire = state => state.healthCheckQuestionnaire;

export const sortedHealthCheckQuestionnaire = state => state.healthCheckQuestionnaire.sort(
    (healthCheckA, healthCheckB) =>
      healthCheckA.displayOrder -
      healthCheckB.displayOrder
    )|| [];
