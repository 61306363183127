import _sortBy from 'lodash/sortBy';
import { displayMemberAge, memberName } from 'utils/member';

export const sortTeamsByNameAndDivision = teams => _sortBy(teams, ['name', 'divisionName']);

const lowestText = String.fromCharCode(1);
const highestText = String.fromCharCode(127);

// We want the null values to always be at the botton
// Returning the largest or lowest value we make sure the null
// options are below eny existing option
// we are converting to lowercase for sorting. If this is a performance block
// we might want to move the toLowerCase into the object being sorted
const sortNullText = (text, reverse) => {
  if (text) {
    return text.toLowerCase();
  }
  return reverse ? lowestText : highestText;
};

// Always
const sortNullNumber = (number, reverse) => {
  if (number) {
    return number;
  }
  return reverse ? Number.MIN_VALUE : Number.MAX_VALUE;
};

export const memberSorts = (members, sortOn, reverse = false) => {
  if (!members) {
    return [];
  }
  const sorts = {
    age: member => sortNullNumber(displayMemberAge(member, { isManager: true }, 0), reverse),
    firstName: member => sortNullText(member.firstName, reverse),
    gender: member => sortNullText(member.gender, reverse),
    lastName: member => sortNullText(member.lastName, reverse),
    name: member => sortNullText(memberName(member), reverse),
    team: member => sortNullText(member.team ? member.team.name : '', reverse),
  };
  let result = [...members];
  if (sorts[sortOn] && members.length) {
    result = _sortBy(members, sorts[sortOn]);
    if (reverse) {
      result.reverse();
    }
  }
  return result;
};
