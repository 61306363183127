/**
 * Registration Form Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.REGISTRATION_FORM];
const itemSelector = state => stateSelector(state).items || {};
const isFetchingSelector = state => stateSelector(state).isFetching;
const isErrorSelector = state => stateSelector(state).isError;

// Select registrationForm fetching or error states
export const selectRegistrationFormIsFetching = state => isFetchingSelector(state);
export const selectRegistrationFormIsError = state => isErrorSelector(state);

// Select registrationForm by id
export const selectRegistrationFormById = (state, id) => itemSelector(state)[id] || {};

// Select all registrationForms from division
export const selectRegistrationForms = state => itemSelector(state) || {};
