import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Paginate } from 'shared/toolkit';
import { ERROR } from 'shared/toolkit/Feedback/Feedback';
import { Icon } from '@teamsnap/teamsnap-ui';
import { linkMemberNew, linkMemberImports, linkMemberExport } from 'utils/links';
import { LeagueMembersTable } from './LeagueMembersTable';
import { LeagueMembersSearch } from './LeagueMembersSearch';
import { setDivisionIdForBackgroundChecks } from './BackgroundCheckButton';

// eslint-disable-next-line no-unused-vars
import './MembersTable.module.scss';

export default class LeagueMembersList extends Component {
  static propTypes = {
    loadProgramMembersForPage: PropTypes.func.isRequired,
    clearCachedPages: PropTypes.func.isRequired,
    programMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
    paginationCurrentPage: PropTypes.number.isRequired,
    paginationTotalPages: PropTypes.number.isRequired,
    lastSearchParams: PropTypes.object,
    saveListViewCurrentPage: PropTypes.func.isRequired,
    activeDivisionId: PropTypes.number.isRequired,
    isFetching: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired,
    triggerMemberDedup: PropTypes.func.isRequired,
    showFeedback: PropTypes.func.isRequired,
    totalProgramMemberCount: PropTypes.number,
    programMemberCountRange: PropTypes.string.isRequired,
    analyticEvent: PropTypes.func.isRequired,
  };

  state = {
    disableButton: false,
    searchParams: null,
  };

  // makes first api call to load all program members in the division
  UNSAFE_componentWillMount() {
    this.props.loadProgramMembersForPage(1);
  }

  componentDidMount() {
    setDivisionIdForBackgroundChecks(this.props.activeDivisionId, 'bg-check-modal');
  }

  setPage = clickEvent => {
    clickEvent.preventDefault();
    const nextPage = clickEvent.target.hash.substr(1);
    const currentPage = Number(nextPage);
    this.loadProgramMembersByPage(currentPage);
  };

  triggerDedup = () => {
    this.setState({ disableButton: true });
    return this.props
      .triggerMemberDedup({ divisionIds: this.props.activeDivisionId })
      .then(() => {
        window.Appcues.show('-Lf6tS0hY8nZD_TBTxIQ');
      })
      .catch(() => {
        this.props.showFeedback({
          title: 'Something has gone wrong. Please try again later',
          type: ERROR,
        });
      });
  };

  searchProgramMembers = searchParams => {
    this.props.clearCachedPages();
    this.setState({ searchParams }, () => this.loadProgramMembersByPage(1));
  };

  loadProgramMembersByPage = currentPage => {
    this.props.saveListViewCurrentPage(currentPage);
    this.props.loadProgramMembersForPage(currentPage, this.state.searchParams);
  };

  render() {
    const {
      paginationCurrentPage,
      paginationTotalPages,
      activeDivisionId,
      showModal,
      programMembers,
      isFetching,
      totalProgramMemberCount,
      programMemberCountRange,
      analyticEvent,
      isLoggedInMemberEmailable,
      showFeedback,
    } = this.props;

    const countDisplay = `${programMemberCountRange} of ${totalProgramMemberCount}`;

    return (
      <div>
        <div className="Grid">
          <h2 className="Grid-cell u-size6of24">
            Members
          </h2>
          <div className="Grid-cell u-size18of24 u-textRight">
            <a href={ linkMemberNew(activeDivisionId) } className="Button Button--primary">
              <Icon name="plus" mods="u-spaceRightSm" />
              Add Member
            </a>
            <a href={ linkMemberImports(activeDivisionId) } className="Button u-spaceLeftSm">
              <Icon name="import" mods="u-spaceRightSm" />
              Import Members
            </a>
            { isLoggedInMemberEmailable ? (
              <a href={ linkMemberExport(activeDivisionId) } className="Button u-spaceLeftSm">
                <Icon name="export" mods="u-spaceRightSm" />
                Export Members{ ' ' }
              </a>
            ) : (
              <button
                className="u-spaceLeftSm"
                title="You must add an email address to your own roster in order to export members."
                onClick={ () =>
                  showFeedback({
                    title: 'You must add an email address to your own roster in order to export members.',
                    type: ERROR,
                  })
                }
              >
                <Icon name="export" mods="u-spaceRightSm" />
                Export Members
              </button>
            ) }
            <button onClick={ this.triggerDedup } disabled={ this.state.disableButton } className="u-spaceLeftSm">
              <Icon name="refresh" mods="u-spaceRightSm" />
              Sync Members
            </button>
          </div>
        </div>
        <div className="u-padBottomSm u-spaceTopLg">
          <div className="Grid">
            <div className="Grid-cell u-sizeFit">
              <LeagueMembersSearch
                searchProgramMembers={ this.searchProgramMembers }
                lastSearchParams={ this.props.lastSearchParams }
                analyticEvent={ analyticEvent }
              />
            </div>
            <div className="Grid-cell  u-sizeFill u-textCenter">
              <Paginate
                className="PaginateRight"
                currentPage={ paginationCurrentPage }
                totalPages={ paginationTotalPages }
                onClick={ this.setPage }
                showFirst
                showLast
                showPrev
                showNext
                countDisplay={ countDisplay }
                totalProgramMemberCount={ totalProgramMemberCount }
              />
            </div>
          </div>
        </div>
        <LeagueMembersTable
          isFetching={ isFetching }
          showModal={ showModal }
          programMembers={ programMembers }
          activeDivisionId={ activeDivisionId }
          analyticEvent={ analyticEvent }
        />
        <Paginate
          currentPage={ paginationCurrentPage }
          totalPages={ paginationTotalPages }
          onClick={ this.setPage }
          showFirst
          showLast
          showPrev
          showNext
          countDisplay={ countDisplay }
          totalProgramMemberCount={ totalProgramMemberCount }
        />
        <div id="bg-check-modal" />
      </div>
    );
  }
}
