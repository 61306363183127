import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadDivisionStructureHealth } from 'state/divisionStructureHealth/actions';
import {
  selectDivisionStructureHealthValues,
  selectDivisionStructureHealthIsFetching,
} from 'state/divisionStructureHealth/selectors';
import { selectTotalProgramMemberCount } from 'state/leagueMembers/selectors';
import { linkDivisionTeams, linkLeagueRostering } from 'utils/links';
import { calculatePercent } from 'utils/utils';
import InitialLayout from 'shared/components/Widget/InitialLayout';
import AlertLayout from 'shared/components/Widget/AlertLayout';
import CompleteLayout from 'shared/components/Widget/CompleteLayout';
import WidgetContent from 'shared/components/Widget/WidgetContent';
import WidgetCounts from 'shared/components/Widget/WidgetCounts';

class WidgetOrganization extends PureComponent {
  UNSAFE_componentWillMount() {
    const { loadDivisionStructureHealth: loadDivisionHealth, divisionId, isReady } = this.props;

    if (isReady) {
      loadDivisionHealth({ divisionId });
    }
  }

  setInitialProps = sharedProps => ({
    ...sharedProps,
    buttonText: 'Set up Your Organization',
    buttonLocation: linkDivisionTeams(this.props.divisionId),
    buttonColor: 'primary',
    counters: sharedProps.counters.filter(counter => ['Divisions', 'Teams'].includes(counter.label)),
    children: (
      <WidgetContent
        heading="Build out your organization"
        subheading="Start getting ready to roster members.  Easily get organized with divisions and teams."
      />
    ),
  });

  setCompleteProps = sharedProps => {
    const {
      divisionId,
      divisionStructureHealth: { assignedActiveMembersCount, totalActiveMembersCount },
    } = this.props;
    const chartProgress = calculatePercent(totalActiveMembersCount, assignedActiveMembersCount);

    return {
      ...sharedProps,
      chartProgress,
      chartType: assignedActiveMembersCount === 0 ? null : 'radial',
      infoTop: assignedActiveMembersCount === 0 && this.renderInfoMessage('top'),
      infoComponent: assignedActiveMembersCount > 0 && this.renderInfoMessage(),
      buttonText: assignedActiveMembersCount === 0 ? 'Manage Your Organization' : 'Roster Your Members',
      buttonLocation:
        assignedActiveMembersCount === 0 ? linkDivisionTeams(divisionId) : linkLeagueRostering(divisionId),
      buttonColor: assignedActiveMembersCount === 0 ? 'primary' : 'neutral',
      counters: sharedProps.counters.filter(counter => ['Rostered', 'Not Rostered'].includes(counter.label)),
      children: (
        <WidgetCounts
          items={ sharedProps.counters.filter(counter =>
            ['Members', 'Teams', 'Sub-Divisions', 'Divisions'].includes(counter.label),
          ) }
        />
      ),
    };
  };

  // TODO: Add ALert details once added to api
  setAlertProps = sharedProps => {
    const {
      divisionStructureHealth: { totalActiveMembersCount, assignedActiveMembersCount },
    } = this.props;
    const chartProgress = calculatePercent(totalActiveMembersCount, assignedActiveMembersCount);

    return {
      ...sharedProps,
      chartProgress,
      buttonText: 'Setup My Orgination Structure',
      buttonLocation: null,
      buttonColor: 'primary',
      countersTop: sharedProps.counters.filter(counter => ['Rostered', 'Not Rostered'].includes(counter.label)),
      countersBottom: sharedProps.counters.filter(counter => counter.label === 'Members'),
      children: <div>Something went wrong, please try again.</div>,
    };
  };

  renderInfoMessage = position => {
    const { divisionId } = this.props;

    if (position === 'top') {
      return (
        <p>
          Quickly roster members onto teams using the <a href={ linkLeagueRostering(divisionId) }>Rostering tab</a>
        </p>
      );
    }

    return (
      <p>
        Manage your organization on the <a href={ linkDivisionTeams(divisionId) }>Divisions tab</a>
      </p>
    );
  };

  render() {
    const {
      isReady,
      isFetching,
      totalProgramMemberCount,
      divisionStructureHealth: {
        assignedActiveMembersCount,
        unassignedActiveMembersCount,
        activeChildrenCount,
        activeChildrenDescendantsCount,
        activeTeamsCount,
        hasDivisionAlert, // temporary placeholder field until added to api
      },
    } = this.props;

    let isLoading = isFetching;

    if (!isReady) {
      isLoading = false;
    }

    const props = {
      headerText: 'Organization Overview',
      isLoading,
      iconName: 'team',
      counters: [
        {
          label: 'Rostered',
          value: assignedActiveMembersCount || '0',
          indicatorColor: 'primary',
        },
        {
          label: 'Not Rostered',
          value: unassignedActiveMembersCount || '0',
          indicatorColor: 'neutral',
        },
        { label: 'Members', value: totalProgramMemberCount || '0' },
        { label: 'Teams', value: activeTeamsCount || '0' },
        {
          label: 'Sub-Divisions',
          value: activeChildrenDescendantsCount || '0',
        },
        { label: 'Divisions', value: activeChildrenCount || '0' },
      ],
    };

    if (!isReady) {
      return <InitialLayout { ...this.setInitialProps(props) } />;
    }
    if (hasDivisionAlert) {
      return <AlertLayout { ...this.setAlertProps(props) } />;
    }

    return <CompleteLayout { ...this.setCompleteProps(props) } />;
  }
}

WidgetOrganization.propTypes = {
  divisionId: PropTypes.string.isRequired,
  loadDivisionStructureHealth: PropTypes.func.isRequired,
  divisionStructureHealth: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
};

WidgetOrganization.defaultProps = {};

const mapStateToProps = (state, ownProps) => ({
  divisionStructureHealth: selectDivisionStructureHealthValues(state, ownProps.forceView),
  isFetching: selectDivisionStructureHealthIsFetching(state),
  totalProgramMemberCount: selectTotalProgramMemberCount(state),
});

export default connect(mapStateToProps, { loadDivisionStructureHealth })(WidgetOrganization);
