import React from 'react';
import sortBy from 'utils/sorter';
import _isEmpty from 'lodash/isEmpty';
import { Button, FieldWrapper, Icon } from '@teamsnap/teamsnap-ui';
import SimpleDateSelect from 'shared/components/SimpleDateSelect/SimpleDateSelect';
import searchPillStyles from '../SearchPill.module.scss';

import './AdditionalQuestionsFilters.scss';

export const MultiSelectPicker = ({ index, options, updateSelectedOptions, selected }) => {
  const [showOptions, toggleShowOptions] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  React.useEffect(() => {
    updateSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  React.useEffect(() => {
    if (selected && selected.length > 0) {
      setSelectedOptions(selected);
    }
  }, [selected]);

  const handleBodyClick = React.useCallback((e) => {
    e.stopPropagation();
    const isTargetingPopup = e.target.closest(`.multiselect-picker-container`) != null;
    if (!isTargetingPopup) {
      toggleShowOptions(false);
    }
  }, []);

  React.useEffect(() => {
    if (!showOptions) {
      window.removeEventListener('click', handleBodyClick, false);
    } else {
      window.addEventListener('click', handleBodyClick, false);
    }
  }, [showOptions]);

  return (
    <div className="multiselect-picker-container">
      <div className="multiselect-picker-selected" onClick={ () => toggleShowOptions(true) }>
        { selectedOptions.map((option) => (
          <div
            className={ `selected selected-${option.replace(/ /g, '')}-${index}` }
            key={ `selected-${option.replace(/ /g, '')}-${index}` }
          >
            <span title={ option }>{ option }</span>
            <button
              type="button"
              onClick={ (e) => {
                e.stopPropagation();
                setSelectedOptions(selectedOptions.filter((e) => e !== option));
              } }
            >
              <Icon name="dismiss" />
            </button>
          </div>
        )) }
      </div>
      { showOptions && (
        <div className="multiselect-picker-options" key={ `options-${index}` }>
          <div className="options">
            { options.map((option) => (
              <div className="Checkbox" key={ `option-${option.replace(/ /g, '')}-${index}` }>
                <input
                  className={ `multiselect-picker-${option.replace(
                    / /g,
                    '',
                  )} u-padRightXs Checkbox-input Checkbox--inline Checkbox--large` }
                  aria-checked="true"
                  tabIndex="0"
                  type="checkbox"
                  id={ `multiselect-picker-${option.replace(/ /g, '')}` }
                  checked={ selectedOptions.includes(option) }
                  name={ `multiselect-picker-${option.replace(/ /g, '')}` }
                  value={ option }
                  onChange={ (e) => {
                    if (selectedOptions.includes(e.target.value)) {
                      setSelectedOptions(selectedOptions.filter((e) => e !== option));
                    } else {
                      setSelectedOptions([...selectedOptions, e.target.value]);
                    }
                  } }
                />
                <label className="Checkbox-label" htmlFor={ `multiselect-picker-${option.replace(/ /g, '')}` }>
                  { option }
                </label>
              </div>
            )) }
          </div>
          <div className="actions">
            <Button
              mods="btn-clear"
              onClick={ () => {
                setSelectedOptions([]);
              } }
            >
              Clear
            </Button>
            <Button
              mods="btn-save"
              onClick={ () => {
                toggleShowOptions(false);
              } }
            >
              Save
            </Button>
          </div>
        </div>
      ) }
    </div>
  );
};

export const AdditionalQuestionFilter = ({
  updateField,
  removeCustomField,
  setFieldFilters,
  fields,
  index,
  globalComparison,
  setGlobalComparison,
  registrationForms,
  registrationId,
}) => {
  let formFieldsFromForm = Object.values(registrationForms || {}).flatMap((form) => [...form.form_fields]);

  const filteredFormFields =
    formFieldsFromForm.filter((question) => question.form_id === parseInt(registrationId));
  const field = fields[index].question || null;
  const operator = fields[index].condition || null;
  const answer = fields[index].answer || null;

  const registrationFormFields = sortBy('label', Object.values(filteredFormFields || {})).filter(
    (f) => f.form_field_definition.organization_id,
  );

  const onRemove = (indexToRemove) => {
    let fieldFiltersCopy = [...fields];
    fieldFiltersCopy.splice(indexToRemove, 1);
    setFieldFilters(fieldFiltersCopy);
  };

  const isMultiSelect = () => {
    if (
      field &&
      registrationFormFields.find((f) => +f.id === +field) &&
      registrationFormFields.find((f) => +f.id === +field).multiselect
    ) {
      return true;
    }
    return false;
  };

  const isType = (type) => {
    if (field && registrationFormFields.find((f) => +f.id === +field)) {
      if (Array.isArray(type)) {
        for (let i = 0; i < type.length; i++) {
          if (registrationFormFields.find((f) => +f.id === +field).type === type[i]) {
            return true;
          }
        }
      } else {
        if (registrationFormFields.find((f) => +f.id === +field).type === type) {
          return true;
        }
      }
    }
    return false;
  };

  const updateFilter = (index, field, value, operator, comparison) => {
    updateField(
      {
        field,
        value,
        operator,
        comparison,
      },
      `custom_field_${index}`,
    );

    let fieldFiltersCopy = [...fields];
    fieldFiltersCopy[index] = { question: field, condition: operator, answer: value };
    setFieldFilters(fieldFiltersCopy);
  };

  React.useEffect(() => {
    updateFilter(index, field, answer, operator, globalComparison);
  }, [globalComparison]);

  return (
    <React.Fragment>
      { index > 0 && (
        <div className={ `u-flex u-spaceTopSm` }>
          <div className="u-size1of4 u-padRightSm u-spaceBottomSm">
            <div className="SelectBox">
              <select
                className="SelectBox-options"
                value={ globalComparison }
                onChange={ (e) => {
                  setGlobalComparison(e.target.value);
                } }
              >
                <option value="and">AND</option>
                <option value="or">OR</option>
              </select>
            </div>
          </div>
        </div>
      ) }
      <div>
        <div className="SelectBox">
          <select
            name={ `question_${index}` }
            className="SelectBox-options"
            value={ field || '' }
            onChange={ (e) => updateFilter(index, e.target.value, '', '', globalComparison) }
          >
            <option value="">Select question</option>
            { registrationFormFields.map((e, i) => (
              <option key={ `${e.id}-${i}` } value={ `${e.id}` }>
                { e.name === 'first_name' || e.name === 'last_name'
                  ? `${e.name} (${e.target.charAt(0).toUpperCase() + e.target.slice(1)})`
                  : e.label }
              </option>
            )) }
          </select>
        </div>
      </div>
      <div className="u-flex u-spaceEndsSm">
        <div className="u-size9of24 u-padRightSm">
          <div>
            <div className="SelectBox">
              <select
                name={ `condition_${index}` }
                className="SelectBox-options"
                disabled={ !field }
                value={ operator || '' }
                onChange={ (e) => updateFilter(index, field, answer, e.target.value, globalComparison) }
              >
                <option value="">Select condition</option>
                { (isType(['text', 'textarea', 'email', 'phone', 'date', 'numeric', 'file']) ||
                  (isType('select') && !isMultiSelect())) && (
                  <>
                    <option value="eq">Is</option>
                    <option value="neq">Is Not</option>
                  </>
                ) }
                { isType(['date']) && (
                  <>
                    <option value="gt">Is After</option>
                    <option value="gte">Is On or After</option>
                    <option value="lt">Is Before</option>
                    <option value="lte">Is On or Before</option>
                  </>
                ) }
                { isType(['numeric']) && (
                  <>
                    <option value="gt">Greater Than</option>
                    <option value="gte">Greater Than or Equal To</option>
                    <option value="lt">Less Than</option>
                    <option value="lte">Less Than or Equal To</option>
                  </>
                ) }

                { (isType(['text', 'textarea']) || (isType('select') && isMultiSelect())) && (
                  <>
                    <option value="contains">{ !isMultiSelect() ? 'Contains' : 'Includes' }</option>
                    <option value="not_contains">{ !isMultiSelect() ? 'Does not contain' : 'Does not include' }</option>
                  </>
                ) }
              </select>
            </div>
          </div>
        </div>
        <div className="u-size15of24">
          <div>
            { !field && (
              <div className="SelectBox">
                <select name={ `answer_${index}` } className={ `SelectBox-options` } disabled>
                  <option value="">Select answer</option>
                </select>
              </div>
            ) }
            { isType(['text', 'textarea', 'email', 'phone', 'numeric']) && (
              <FieldWrapper
                field="input"
                fieldProps={ {
                  inputProps: {
                    value: answer || '',
                    onChange: (e) => updateFilter(index, field, e.target.value, operator, globalComparison),
                  },
                } }
                name={ `answer_${index}` }
              />
            ) }
            { isType('select') && isMultiSelect() && (
              <MultiSelectPicker
                index={ index }
                options={ registrationFormFields.find((f) => +f.id === +field)?.options || [] }
                selected={ answer || [] }
                updateSelectedOptions={ (selectedOptions) => {
                  updateFilter(
                    index,
                    field,
                    selectedOptions.length > 0 ? selectedOptions : null,
                    operator,
                    globalComparison,
                  );
                } }
              />
            ) }
            { isType('select') && !isMultiSelect() && (
              <div className="SelectBox">
                <select
                  name={ `answer_${index}` }
                  className={ `SelectBox-options` }
                  disabled={ !field }
                  value={ answer || '' }
                  onChange={ (e) => updateFilter(index, field, e.target.value, operator, globalComparison) }
                >
                  <option value="">Select answer</option>
                  { registrationFormFields
                    .find((f) => +f.id === +field)
                    ?.options?.map((e, i) => (
                      <option title={ e } key={ `${e}-${i}` } value={ e }>
                        { e.length > 50 ? `${e.substring(0, 50)}...` : e }
                      </option>
                    )) }
                </select>
              </div>
            ) }
            { isType('file') && (
              <div className="SelectBox">
                <select
                  name={ `answer_${index}` }
                  className={ `SelectBox-options` }
                  disabled={ !field }
                  value={ answer || '' }
                  onChange={ (e) => updateFilter(index, field, e.target.value, operator, globalComparison) }
                >
                  <option value="">Select answer</option>
                  <option value="Uploaded">Uploaded</option>
                  <option value="No File">No File</option>
                </select>
              </div>
            ) }
            { isType('date') && (
              <div className="date-picker">
                <SimpleDateSelect
                  mods="u-spaceRightNone"
                  name={ `custom_field_${index}` }
                  updateDateFilter={ (key, date) => {
                    updateFilter(index, field, date !== 'Invalid date' ? date : null, operator, globalComparison);
                  } }
                  dateFormat="YYYY-MM-DD"
                  defaultDate={ answer }
                />
              </div>
            ) }
          </div>
        </div>
      </div>
      { fields.length > 1 && (
        <div className="u-flex u-flexJustifyEnd u-padSidesXs u-spaceEndsMd">
          <Button
            icon="trash"
            mods="btn-delete-condition"
            onClick={ () => {
              onRemove(index);
              removeCustomField(`custom_field_${index}`);
            } }
          >
            Delete
          </Button>
        </div>
      ) }
    </React.Fragment>
  );
};

const AdditionalQuestionsFilters = ({
  clearFilter,
  updateField,
  removeCustomField,
  currentFilter,
  savedAdvancedFilters,
  registrationForms,
}) => {
  const [globalComparison, setGlobalComparison] = React.useState('and');
  const [fieldFilters, setFieldFilters] = React.useState([]);

  const { registration_form_id: registrationId } = currentFilter || {};
  
  React.useEffect(() => {
    if (savedAdvancedFilters && savedAdvancedFilters.length > 0) {
      setFieldFilters(
        savedAdvancedFilters.map((filter) => ({
          question: filter.field,
          condition: filter.operator,
          answer: filter.value,
          label: filter.label,
        })),
      );
    } else {
      setFieldFilters([
        {
          question: '',
          condition: '',
          answer: '',
          label: '',
        },
      ]);
    }
  }, []);

  React.useEffect(() => {
    const advancedFilters = currentFilter?.advancedFilters;
    if (
      !advancedFilters ||
      (advancedFilters &&
        !advancedFilters['custom_field_0']?.field &&
        !advancedFilters['custom_field_0']?.operator &&
        !advancedFilters['custom_field_0']?.value &&
        !advancedFilters['custom_field_0']?.label &&
        !advancedFilters['custom_field_1'])
    ) {
      setFieldFilters([
        {
          question: '',
          condition: '',
          answer: '',
          label: '',
        },
      ]);
    }
  }, [currentFilter?.advancedFilters]);

  const addField = () => {
    setFieldFilters([
      ...fieldFilters,
      {
        question: '',
        condition: '',
        answer: '',
        label: '',
      },
    ]);
  };

  return (
    <div className="conditional-logic__container">
      { !registrationId ? (
        <div className="combobox-message">
          <p>
            Only custom questions from the selected registration form are included. 
            Please select a registration form in the registration filter.
          </p>
        </div>
      ) : (
      <React.Fragment>
        <div className="conditional-logic u-padMd">
          { fieldFilters.map((fieldFilter, index) => (
            <AdditionalQuestionFilter
              key={ `fieldFilter-${index}` }
              registrationForms={ registrationForms }
              index={ index }
              fields={ fieldFilters }
              setFieldFilters={ setFieldFilters }
              updateField={ updateField }
              removeCustomField={ removeCustomField }
              globalComparison={ globalComparison }
              setGlobalComparison={ setGlobalComparison }
              currentFilter={ currentFilter }
              registrationId={ registrationId }
            />
          )) }
          <Button icon="plus" mods="btn-add-filter u-padSidesXs btn-add-condition u-spaceTopSm" onClick={ addField }>
            Add Filter
          </Button>
        </div>
        <div className={ searchPillStyles['Popup-footer'] }>
          <a
            className="btn-clear-filters u-colorNegative u-textUnderline"
            onClick={ () => {
              setFieldFilters([
                {
                  question: '',
                  condition: '',
                  answer: '',
                  label: '',
                },
              ]);
              clearFilter('additionalQuestions');
            } }
          >
            Clear Filters
          </a>
        </div>
      </React.Fragment>
    ) }
    </div>
  );
};

export default AdditionalQuestionsFilters;
