import initialState from 'state/initialState';
import * as constants from './constants';

const healthCheckQuestionnaireReducer = (state = initialState.healthCheckQuestionnaire, action) => {
  switch (action.type) {
    case constants.HEALTH_CHECK_QUESTIONNAIRE:
      return action.payload;
    default:
      return state;

    case constants.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION: {
      const mostRecentTemplateId = Math.max(...state.map(e => +e.healthCheckQuestionnaireTemplateId));
      return [...state, action.payload].filter(e => mostRecentTemplateId === +e.healthCheckQuestionnaireTemplateId);
    }
  }
};

export default healthCheckQuestionnaireReducer;
