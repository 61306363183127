import React from 'react';

// Components
import { Button } from '@teamsnap/teamsnap-ui';
import DivisionOrTeam from './search/DivisionOrTeam';
import MemberInfo from './search/MemberInfo';
import MemberStatus from './search/MemberStatus';
import RegistrationStatus from './search/RegistrationStatus';
import CustomFields from './search/CustomFields';
import AdditionalQuestionsFilters from './search/AdditionalQuestionsFilters';
import ParticipantGroupComboboxFilter from './search/ParticipantGroupComboboxFilter';
import SearchPill from './SearchPill';
import searchPillStyles from './SearchPill.module.scss';

import styles from './SearchMembers.module.scss';

const SearchMembers = ({
  clearAllFilters,
  clearFilter,
  currentFilter,
  divisionTeams,
  divisionTree,
  hasUnappliedSearchFilters,
  isSearching,
  searchFilters,
  openedPopupId,
  submitSearch,
  toggleMemberSearchPopup,
  updateField,
  registrationForms,
  registrationFormLineItems,
  registrationFormLineItemOptions,
  leagueCustomFields,
  selectedDivisionId,
  removeCustomField,
  registrationVersion,
  participantGroups,
}) => {
  const updateCurrentFilter = (event) => {
    const { name } = event.target;
    let value;

    if (event.target.type === 'checkbox') {
      if (name === 'pending_assignments') {
        value = event.target.checked ? 'exclude' : 'include';
      } else {
        value = event.target.checked;
      }
    } else {
      value = event.target.value;
    }

    updateField(value, name);
  };

  const getDefaultValue = (fieldName, defaultValue = '') => currentFilter[fieldName] || defaultValue;

  const getActiveFilterValues = (key) => {
    const values = [];

    Object.values(searchFilters[key].filters).forEach((filter) => {
      if (filter.value !== '' && !!filter.valueDisplay) {
        values.push(filter.valueDisplay[filter.value]);
      } else if (filter.value !== '') {
        values.push(filter.value);
      }
    });

    return values;
  };

  const formatButtonText = (key) => {
    const filterValues = getActiveFilterValues(key).filter((item) => item);
    const dot = String.fromCharCode(183);

    if (!filterValues.length) {
      return searchFilters[key].defaultButtonText;
    }

    return filterValues.join(` ${dot} `);
  };

  const getPillStatus = (key) => getActiveFilterValues(key).filter((item) => item).length > 0;

  const pills = [
    {
      name: 'memberStatus',
      buttonText: formatButtonText('memberStatus'),
      popupTitle: 'Member Status',
      body: <MemberStatus updateCurrentFilter={ updateCurrentFilter } getDefaultValue={ getDefaultValue } />,
      active: getPillStatus('memberStatus'),
      popupStyle: { width: '360px' },
    },
    {
      name: 'memberInfo',
      buttonText: formatButtonText('memberInfo'),
      popupTitle: 'Member Information',
      body: (
        <MemberInfo
          updateCurrentFilter={ updateCurrentFilter }
          getDefaultValue={ getDefaultValue }
          updateField={ updateField }
        />
      ),
      active: getPillStatus('memberInfo'),
      popupStyle: { width: '360px' },
    },
    {
      name: 'divisionOrTeam',
      buttonText: formatButtonText('divisionOrTeam'),
      popupTitle: 'Division / Team',
      body: (
        <DivisionOrTeam
          divisionTeams={ divisionTeams }
          divisionTree={ divisionTree }
          selectedDivisionId={ selectedDivisionId }
          updateCurrentFilter={ updateCurrentFilter }
          getDefaultValue={ getDefaultValue }
        />
      ),
      active: getPillStatus('divisionOrTeam'),
      popupStyle: { width: '400px' },
    },
    {
      name: 'registrationStatus',
      buttonText: formatButtonText('registrationStatus'),
      popupTitle: 'Registration',
      body: (
        <RegistrationStatus
          registrationForms={ registrationForms }
          registrationFormLineItems={ registrationFormLineItems }
          registrationFormLineItemOptions={ registrationFormLineItemOptions }
          updateCurrentFilter={ updateCurrentFilter }
          getDefaultValue={ getDefaultValue }
        />
      ),
      active: getPillStatus('registrationStatus'),
      popupStyle: { width: '360px' },
    },
  ];

  if (Object.keys(leagueCustomFields || {}).length > 0 && registrationVersion === 1) {
    pills.push({
      name: 'customFields',
      buttonText: formatButtonText('customFields'),
      popupTitle: 'Custom Fields',
      body: (
        <CustomFields
          currentFilter={ currentFilter }
          leagueCustomFields={ leagueCustomFields }
          updateCurrentFilter={ updateCurrentFilter }
          getDefaultValue={ getDefaultValue }
          updateField={ updateField }
          removeCustomField={ removeCustomField }
        />
      ),
      active: getPillStatus('customFields'),
      popupStyle: { width: '550px' },
    });
  }

  if (Object.keys(participantGroups || {}).length > 0 && registrationVersion === 2) {
    const participantGroupsOptions = Object.keys(participantGroups).map((key) => ({
      label: participantGroups[key].name,
      value: key,
      formId: participantGroups[key].form_id,
    }));

    const participantGroupsValues = getDefaultValue('participantGroups');

    pills.push({
      name: 'participantGroups',
      buttonText: formatButtonText('participantGroups'),
      popupTitle: 'Participant Group Filters',
      body: (
        <ParticipantGroupComboboxFilter
          options={ participantGroupsOptions }
          selected={ participantGroupsValues }
          currentFilter={ currentFilter }
          onClear={ () => clearFilter('participantGroups') }
          onUpdate={ (selected) => updateField(selected, 'participantGroups') }
        />
      ),
      active: getPillStatus('participantGroups'),
      popupStyle: { width: '320px' },
    });
  }

  if (Object.keys(leagueCustomFields || {}).length > 0 && registrationVersion === 2) {
    const savedAdvancedFilters = getDefaultValue('advancedFilters');
    pills.push({
      name: 'additionalQuestions',
      buttonText: formatButtonText('additionalQuestions'),
      popupTitle: 'Additional Questions Filters',
      body: (
        <AdditionalQuestionsFilters
          currentFilter={ currentFilter }
          registrationForms={ registrationForms }
          updateField={ updateField }
          removeCustomField={ removeCustomField }
          savedAdvancedFilters={ Object.values(savedAdvancedFilters) }
          clearFilter={ () => clearFilter('additionalQuestions') }
        />
      ),
      active: getPillStatus('additionalQuestions'),
      popupStyle: { width: '400px' },
    });
  }

  const popupFooter = (popupName) => (
    <div className={ searchPillStyles['Popup-footer'] }>
      <a className="u-colorNegative u-textUnderline" onClick={ clearFilter.bind(this, popupName) }>
        Clear Filters
      </a>
    </div>
  );

  const renderSearchButtons = () => {
    if (hasUnappliedSearchFilters || hasUnappliedSearchFilters === 'noFilters' || isSearching) {
      return (
        <Button
          label={ `${isSearching ? 'Applying Filters' : 'Apply Filters'}` }
          icon={ `${isSearching ? 'loader' : 'search'}` }
          isDisabled={ isSearching }
          color="primary"
          onClick={ submitSearch }
          style={ { marginTop: '-5px' } }
        />
      );
    }

    if (!isSearching && !hasUnappliedSearchFilters) {
      return (
        <Button
          label="Clear All"
          icon="dismiss"
          color="negative"
          onClick={ clearAllFilters }
          style={ { marginTop: '-5px' } }
        />
      );
    }
  };

  return (
    <section className={ styles.searchMembersContainer }>
      <div className={ styles.searchPillsContainer }>
        { pills.map((pill) => {
          const id = pill.name;
          return (
            <SearchPill
              buttonText={ pill.buttonText }
              popupTrigger={
                <a
                  className={ `${searchPillStyles.searchPill} ${
                    pill.active ? searchPillStyles['searchPill--active'] : ''
                  }` }
                  title={ pill.buttonText }
                >
                  { pill.buttonText } <span className="u-padLeftXs ss-dropdown u-fontSizeXs" />
                </a>
              }
              popupTitle={ pill.popupTitle }
              popupStyle={ pill.popupStyle }
              popupFooter={
                pill.name !== 'additionalQuestions' && pill.name !== 'participantGroups' ? popupFooter(pill.name) : null
              }
              direction={ pill.direction || ['down', 'left'] }
              openedPopupId={ openedPopupId }
              togglePopup={ toggleMemberSearchPopup }
              key={ id }
              popupId={ id }
            >
              { pill.body }
            </SearchPill>
          );
        }) }
      </div>
      { renderSearchButtons() }
    </section>
  );
};

export default SearchMembers;
