import { loadItems } from 'state/teamsnap/actions';

const loadRegistrationFormLineItems = params => dispatch => {
  dispatch(loadItems('registrationFormLineItems', params));
};

const loadRegistrationFormItemsByDivisionId = divisionId => dispatch =>
  dispatch(loadRegistrationFormLineItems({ divisionId }));

export { loadRegistrationFormLineItems, loadRegistrationFormItemsByDivisionId };
