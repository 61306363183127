import * as Sentry from '@sentry/browser';
import { RichTextEditor } from '@teamsnap/snap-ui';
import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Panel } from 'shared/toolkit';
import { org_axios } from 'utils/api/api';

import AppLoading from 'shared/components/AppLoading';
import AppMessageContainer from 'shared/components/AppMessage/AppMessageContainer';
import { ImgRedXLarge } from 'shared/components/Img';

import { linkLeagueMessagesListEmails } from 'utils/links';

import ClubEmailAttachmentsContainer from '../containers/ClubEmailAttachmentsContainer';

import ClubEmailNoEmails from './ClubEmailNoEmails';
import ClubEmailRecipientList from './ClubEmailRecipientList';
import SavingEmailModal from './SavingEmailModal';

import styles from './ClubEmail.module.scss';

// TODO move to shared util
const NEWLINES_NOT_INSIDE_TAGS = /(?:\r\n|\r|\n)(?![^<]*>)/g; // Matches newlines not inside HTML tags

class ClubEmail extends React.Component {
  constructor(props) {
    super(props);
    const { body } = props.broadcastEmail || {};
    this.inititalBody = body;
  }
  static propTypes = {
    params: PropTypes.shape({ id: PropTypes.string }),
    teamNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    division: PropTypes.shape({ id: PropTypes.number }).isRequired,
    divisionsByParentId: PropTypes.shape({}).isRequired,
    loggedInMember: PropTypes.shape({}).isRequired,
    loading: PropTypes.bool.isRequired,
    memberEmailAddresses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loadingDivisionTeamNames: PropTypes.bool.isRequired,
    broadcastEmail: PropTypes.shape({}),
    selectedRecipients: PropTypes.shape({}).isRequired,
    modalMessage: PropTypes.string.isRequired,
    showSavingModal: PropTypes.bool.isRequired,
    saveEmail: PropTypes.func.isRequired,
    setSubject: PropTypes.func.isRequired,
    setBody: PropTypes.func.isRequired,
    setFromEmailAddress: PropTypes.func.isRequired,
    toggleRecipients: PropTypes.func.isRequired,
    setAppError: PropTypes.func.isRequired,
    clearAppMessages: PropTypes.func.isRequired,
    attachments: PropTypes.array,
    initClubEmail: PropTypes.func.isRequired,
    divisionLeaves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    teamNamesByDivisionId: PropTypes.func.isRequired,
  };

  selectedAnyRecipients() {
    const { selectedRecipients } = this.props;

    if (Object.keys(selectedRecipients).length > 0) {
      return true;
    }
    return false;
  }

  validateEmail(isDraft) {
    const {
      broadcastEmail: { subject, body },
      setAppError,
      clearAppMessages,
    } = this.props;
    let validationMessage = '';

    if (subject.length < 1) {
      validationMessage += 'You must enter a subject for the email. ';
    }
    if (body.length < 1) {
      validationMessage += "You didn't enter any text for your email. ";
    }
    if (!isDraft && !this.selectedAnyRecipients()) {
      validationMessage += 'You must specify at least one recipient';
    }

    if (validationMessage.length > 0) {
      setAppError(validationMessage, true);
      return false;
    }
    clearAppMessages();
    return true;
  }

  onSendEmail = () => {
    this.submitEmail(false);
  };

  onSaveDraft = () => {
    this.submitEmail(true);
  };

  submitEmail = (isDraft) => {
    if (this.validateEmail(isDraft)) {
      this.props.saveEmail({ draft: isDraft });
    }
  };

  onSubjectChange = (event) => {
    this.props.setSubject(event.target.value);
  };

  onBodyChange = (event) => {
    const content = event.target.getContent();
    this.props.setBody(content.replace(NEWLINES_NOT_INSIDE_TAGS, ''));
  };

  onFromEmailChange = (event) => {
    event.preventDefault();
    this.props.setFromEmailAddress(event.target.value);
  };

  UNSAFE_componentWillMount() {
    // if there is no id create an email. if there is, open the draft.
    this.props.initClubEmail(this.props.params && this.props.params.id ? this.props.params.id : null);
  }

  renderClubEmailFooter = ({ emailSizeInBytes }) => (
    <div className={styles.clubEmailFooter}>
      <Link to={linkLeagueMessagesListEmails(this.props.division.id)} className="button ss-delete">
        <ImgRedXLarge />
        Cancel
      </Link>
      <Button type="button" className="Button" handleClick={this.onSaveDraft} text="Save as Draft" iconLeft="edit" />
      <Button
        type="button"
        className="ButtonPrimary"
        handleClick={this.onSendEmail}
        text="Send Email"
        iconLeft="mail"
        disabled={emailSizeInBytes > MAX_BYTES_EMAIL_BODY_SIZE}
      />
    </div>
  );

  handleImagesUpload = async (blobInfo, progress) => {
    const blob = blobInfo.blob();
    const fileSize = blob.size;
    if (fileSize >= MAX_BYTES_EMAIL_BODY_SIZE) {
      Sentry.captureMessage('Image size too large for RichTextEditor', {
        extra: {
          divisionId: this.props.division.id,
          fileSize: `${(fileSize / 1000000).toFixed(2)} MB`,
        },
      });
      return Promise.reject({
        message: 'Image size is too large! (25 MB max)',
        remove: true,
      });
    }
    try {
      const file_name = blob.name || 'Uploaded image';
      const response = await org_axios.post(
        `/organizations/legacy_org_info/${this.props.division.id}/broadcast_email_generate_signed_url`,
        {
          file_name,
          content_type: blob.type,
        },
      );
      const signedUrlstring = response.data.data.signedUrl;
      const signedUrl = new URL(signedUrlstring);
      await axios.put(signedUrlstring, blob, {
        headers: { 'Content-Type': blob.type },
      });
      return `${signedUrl.protocol}//${signedUrl.hostname}${signedUrl.pathname}`;
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          divisionId: this.props.division.id,
        },
      });
    }
  };

  render() {
    const {
      broadcastEmail,
      division,
      loading,
      loadingDivisionTeamNames,
      toggleRecipients,
      selectedRecipients,
      modalMessage,
      showSavingModal,
      attachments,
      loggedInMember,
      memberEmailAddresses,
      divisionsByParentId,
      divisionLeaves,
      teamNamesByDivisionId,
    } = this.props;

    if (loading || !division) {
      return <AppLoading loading />;
    }

    if (memberEmailAddresses.length <= 0) {
      return <ClubEmailNoEmails division={division} member={loggedInMember} />;
    }

    const { body, subject, fromEmailAddress } = broadcastEmail || {};

    const emailSizeInBytes = new Blob([subject, body]).size;
    const emailSizeInMB = emailSizeInBytes / 1024 / 1024;

    return (
      <div>
        <div id="content">
          <AppMessageContainer />
          {showSavingModal && <SavingEmailModal title={modalMessage} attachments={attachments} />}
          <form>
            <Panel
              title={`Email ${division.name}`}
              componentStyles={styles}
              footerComponent={this.renderClubEmailFooter({
                emailSizeInBytes,
              })}
            >
              <div className="u-padMd">
                <div className="FormGroup">
                  <label className="FormGroup-label">Subject:</label>
                  <input
                    id="subject"
                    name="subject"
                    type="text"
                    className="u-sizeFull"
                    value={subject}
                    onChange={this.onSubjectChange}
                  />
                </div>
                {memberEmailAddresses.length > 0 && (
                  <div className="FormGroup">
                    <label key="email_from" className="FormGroup-label">
                      Reply To:
                    </label>
                    <select
                      value={fromEmailAddress}
                      onChange={this.onFromEmailChange}
                      key="email_from_select"
                      data-testId="ClubEmail--replyTo"
                    >
                      {memberEmailAddresses.map((memberEmail) => (
                        <option key={memberEmail.id} value={memberEmail.email}>
                          {memberEmail.email}
                        </option>
                      ))}
                      <option value={'donotreply@email.teamsnap.com'}>Do not allow replies to this message</option>
                    </select>
                  </div>
                )}
                <div className={['FormGroup', styles.clubEmailEditor].join(' ')}>
                  <label className="FormGroup-label">Text of your email:</label>
                  <div className="tiny-mce-custom-style-for-emails">
                    <RichTextEditor
                      tinymceApiKey={window.TeamSnap.tinyMceKey}
                      initialValue={this.inititalBody}
                      onChange={this.onBodyChange}
                      imagesUploadHandler={this.handleImagesUpload}
                      height={'850'}
                    />
                  </div>
                </div>

                {emailSizeInBytes > MAX_BYTES_EMAIL_BODY_SIZE && (
                  <div className={styles.errorBanner}>
                    <h4>Embedded content is too large</h4>
                    <p>
                      Limit files and images to 25MB or smaller. Currently email size is: {emailSizeInMB.toFixed(2)}MB
                    </p>
                  </div>
                )}

                <ClubEmailRecipientList
                  loading={loadingDivisionTeamNames}
                  division={division}
                  selectedRecipients={selectedRecipients}
                  toggleRecipients={toggleRecipients}
                  divisionsByParentId={divisionsByParentId}
                  divisionLeaves={divisionLeaves}
                  teamNamesByDivisionId={teamNamesByDivisionId}
                />

                <hr className="Divider" />

                <ClubEmailAttachmentsContainer />
              </div>
            </Panel>
          </form>
        </div>
      </div>
    );
  }
}

export default ClubEmail;

const MAX_BYTES_EMAIL_BODY_SIZE = 25000000; // 25 MB in bytes
