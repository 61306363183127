import * as DateService from 'utils/date-service';

export const hasNoFilters = (currentFilters) =>
  !currentFilters.first_name &&
  !currentFilters.last_name &&
  !currentFilters.gender &&
  currentFilters.born_before_date === 'Invalid date' &&
  currentFilters.born_after_date === 'Invalid date' &&
  !currentFilters.divisionId &&
  !currentFilters.teamId &&
  !currentFilters.registration_form_id &&
  !currentFilters.participantGroups &&
  !('hideRostered' in currentFilters) &&
  !('pending_assignments' in currentFilters) &&
  (!currentFilters?.advancedFilters ||
    !currentFilters.advancedFilters['custom_field_0']?.field ||
    !currentFilters.advancedFilters['custom_field_0']?.operator ||
    !currentFilters.advancedFilters['custom_field_0']?.value);

export const buildV2RosterList = (members, participantGroups) => members
  .filter((m) => !m.is_commissioner)
  .map((member) => ({
    href: '',
    id: member.roster_id,
    type: 'member',
    addressCity: '',
    addressState: '',
    addressStreet1: '',
    addressStreet2: null,
    addressZip: '',
    birthday: member.birthdate,
    divisionId: member.division_id,
    firstName: member.first_name,
    gender: member.gender,
    hasFacebookPostScoresEnabled: false,
    hideAddress: false,
    isAddressHidden: false,
    hideAge: false,
    isAgeHidden: false,
    invitationCode: null,
    invitationDeclined: null,
    isActivated: false,
    isCommissioner: member.is_commissioner,
    isLeagueOwner: false,
    isPushable: false,
    isSelectableForChat: false,
    isShownUnreachableForChatBanner: true,
    isAlertable: false,
    isEmailable: false,
    isInvitable: false,
    isInvited: false,
    isCoach: member.is_coach,
    isManager: member.is_manager,
    isNonPlayer: member.is_non_player,
    isOwner: member.is_owner,
    isOwnershipPending: false,
    isPending: member.is_pending,
    isEditable: true,
    isDeletable: false,
    jerseyNumber: '',
    lastLoggedInAt: null,
    lastName: member.last_name,
    position: '',
    pendingTeamId: member.pending_team_id,
    pendingDivisionId: member.pending_division_id,
    registrationFormId: member.form_id,
    teamId: member.team_id,
    userId: null,
    personUuid: null,
    roleUuid: null,
    updatedAt: '',
    createdAt: '',
    sourceMemberId: null,
    phoneNumbers: [],
    emailAddresses: [],
    answers: member.answers,
    participantGroupId: member.participant_group_id,
  }));

export const convertReg2Items2Reg1Items = (items) =>
  items.map((item) => ({
    balance: 0,
    closedAt: item.closed_at,
    collected: 0,
    createdAt: item.opened_at,
    links: {},
    href: '',
    id: item.id,
    isActive: item.open,
    isRegsaverEnabled: false,
    // isRetired: item.open,
    isRetired: false,
    isVerifyingHockeyCaMembership: false,
    name: item.name,
    participantCount: 0,
    participantLimit: 0,
    seasonEndDate: null,
    totalFeesDue: 0,
    totalRefunds: 0,
    type: 'registrationForm',
    ...item
  }));

export const hasBornBeforeFilter = (currentFilters) =>
  'born_before_date' in currentFilters && currentFilters.born_before_date !== 'Invalid date';

export const hasBornAfterFilter = (currentFilters) =>
  'born_after_date' in currentFilters && currentFilters.born_after_date !== 'Invalid date';

export const checkMemberFirstName = (firstName, currentFilters) => {
  if (!('first_name' in currentFilters)) {
    return true;
  } else if (
    'first_name' in currentFilters &&
    firstName.toLowerCase().startsWith(currentFilters.first_name.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkMemberLastName = (lastName, currentFilters) => {
  if (!('last_name' in currentFilters)) {
    return true;
  } else if (
    'last_name' in currentFilters &&
    lastName.toLowerCase().startsWith(currentFilters.last_name.toLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkMemberGender = (gender, currentFilters) => {
  // // When a user selects 'any' from the gender options, it returns an empty string. This varies from not selecting
  // // gender to filter which would mean that gender does not exist in the filters
  if (currentFilters.gender === '' || !currentFilters.gender) {
    return true;
  }
  if (
    'gender' in currentFilters &&
    gender &&
    currentFilters.gender &&
    gender.toLowerCase() === currentFilters.gender.toLowerCase()
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkMemberBirthdayAfter = (birthday, currentFilters) => {
  if (!hasBornAfterFilter(currentFilters)) {
    return true;
  }
  if (!birthday) {
    return false;
  }
  if (new Date(birthday) >= new Date(currentFilters.born_after_date)) {
    return true;
  }
  return false;
};

export const checkMemberBirthdayBefore = (birthday, currentFilters) => {
  if (!hasBornBeforeFilter(currentFilters)) {
    return true;
  }
  if (!birthday) {
    return false;
  }
  if (new Date(birthday) <= new Date(currentFilters.born_before_date)) {
    return true;
  }
  return false;
};

export const checkMemberDivisionId = (member, currentFilters) => {
  if (!('divisionId' in currentFilters) || ('divisionId' in currentFilters && currentFilters.teamId)) {
    return true;
  }
  const { divisionId, pendingDivisionId } = member;
  if ('divisionId' in currentFilters && !currentFilters.teamId) {
    if (!divisionId && !pendingDivisionId) {
      return false;
    } else {
      return +divisionId === +currentFilters.divisionId || +pendingDivisionId === +currentFilters.divisionId;
    }
  }
};

export const checkMemberTeamId = (member, currentFilters) => {
  if (!('teamId' in currentFilters) || !currentFilters.teamId) {
    return true;
  }
  const { divisionId, pendingDivisionId, teamId, pendingTeamId } = member;
  if ('divisionId' in currentFilters && currentFilters.teamId) {
    if (!divisionId && !pendingDivisionId && !teamId && !pendingTeamId) {
      return false;
    } else {
      return +teamId === +currentFilters.teamId || +pendingTeamId === +currentFilters.teamId;
    }
  }
};

export const checkHideRostered = (member, currentFilters) => {
  const { teamId } = member;
  if (!('hideRostered' in currentFilters)) {
    return true;
  }
  if ('hideRostered' in currentFilters && currentFilters.hideRostered === true) {
    return !teamId;
  }
};

export const checkHidePending = (member, currentFilters) => {
  const { isPending } = member;
  if (!('pending_assignments' in currentFilters)) {
    return true;
  }
  if ('pending_assignments' in currentFilters && currentFilters.pending_assignments === 'exclude') {
    return !isPending;
  }
};

export const checkAdvancedFilters = (member, currentFilters, customFields) => {
  if (
    !('advancedFilters' in currentFilters) ||
    (!currentFilters.advancedFilters['custom_field_0']?.field &&
      !currentFilters.advancedFilters['custom_field_0']?.operator &&
      !currentFilters.advancedFilters['custom_field_0']?.value)
  ) {
    return true;
  }

  const advancedFilters = currentFilters.advancedFilters;
  const comparison = advancedFilters['custom_field_0'].comparison;
  let filterFieldsObject = {};
  let filterReults = [];

  for (let key in advancedFilters) {
    if (key.includes('custom_field')) {
      filterFieldsObject[key] = advancedFilters[key];
    }
  }

  for (let key in filterFieldsObject) {
    const answer = member?.answers?.find((answer) => +answer.form_field_id === +filterFieldsObject[key].field);
    const answerValue = answer ? answer.value : '';
    const filterValue = filterFieldsObject[key].value;
    const answerValueDate = answerValue ? answerValue.split("T")[0] : null;
    const answerParts = answerValueDate ? answerValueDate.split("-") : null;
    const answerDate = answerParts ? new Date(answerParts[0], answerParts[1] - 1, answerParts[2]) : null;
    const filterDateParts = !Array.isArray(filterValue) ? filterValue.split("-") : null;
    const filterDate = filterDateParts ?
      new Date(filterDateParts[0], filterDateParts[1] - 1, filterDateParts[2]) : null;

    const formFieldDefinition = answer
      ? Object.values(customFields).find((f) => +f.id === +answer.form_field_id)
      : null;

    switch (filterFieldsObject[key].operator) {
      case 'eq':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate.getTime() === filterDate.getTime());
        }
        else if (Array.isArray(filterValue)) {
          filterReults.push(filterValue.includes(answerValue));
        }
        else if (formFieldDefinition?.type === 'file') {
          filterReults.push(filterValue === 'Uploaded' ? !!answerValue : !answerValue);
        }
        else {
          filterReults.push(answerValue.trim().toLowerCase() === filterValue.trim().toLowerCase());
        }
        break;
      case 'neq':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate.getTime() !== filterDate.getTime());
        } else if (Array.isArray(filterValue)) {
          filterReults.push(!filterValue.includes(answerValue));
        }
        else if (formFieldDefinition?.type === 'file') {
          filterReults.push(filterValue === 'Uploaded' ? !answerValue : !!answerValue);
        }
        else {
          filterReults.push(answerValue.trim().toLowerCase() !== filterValue.trim().toLowerCase());
        }
        break;
      case 'gt':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate > filterDate);
        } else {
          filterReults.push(+answerValue > +filterValue);
        }
        break;
      case 'gte':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate.getTime() >= filterDate.getTime());
        } else {
          filterReults.push(+answerValue >= +filterValue);
        }
        break;
      case 'lt':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate.getTime() < filterDate.getTime());
        } else {
          filterReults.push(+answerValue < +filterValue);
        }
        break;
      case 'lte':
        if (DateService.isValidDate(answerDate)) {
          filterReults.push(answerDate.getTime() <= filterDate.getTime());
        } else {
          filterReults.push(+answerValue <= +filterValue);
        }
        break;
      case 'contains':
        if (Array.isArray(filterValue)) {
          const answers = answerValue.split('|');
          const isSubset = answers[0] !== '' ? filterValue.some((e) => answers.includes(e)) : false;
          filterReults.push(isSubset);
        } else {
          filterReults.push(answerValue.trim().toLowerCase().indexOf(filterValue.trim().toLowerCase()) >= 0);
        }
        break;
      case 'not_contains':
        if (Array.isArray(filterValue)) {
          if (!answerValue) {
            filterReults.push(true);
          } else {
            const answers = answerValue.split('|');
            const isNotSubset = answers[0] !== '' ? filterValue.every((e) => !answers.includes(e)) : false;
            filterReults.push(isNotSubset);
          }
        } else {
          filterReults.push(answerValue.trim().toLowerCase().indexOf(filterValue.trim().toLowerCase()) < 0);
        }
        break;
    }
  }

  return comparison === 'and' ? filterReults.every((result) => result) : filterReults.some((result) => result);
};

export const checkMemberRegistration = (member, currentFilters) => {
  if ('registration_form_id' in currentFilters && currentFilters.registration_form_id.length > 0) {
    return +currentFilters.registration_form_id === member.registrationFormId;
  }

  return true;
};

export const checkParticipantGroups = (member, currentFilters) => {
  if (!('participantGroups' in currentFilters)) {
    return true;
  }

  const { participantGroups } = currentFilters;
  if (participantGroups.length > 0) {
    return participantGroups.includes(`${member.participantGroupId}`);
  }

  return true;
};
