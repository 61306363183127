import { connect } from 'react-redux';

import { selectStartupMessage, selectErrorMessage } from 'state/app/selectors';

import LoadingMessage from './LoadingMessage';

const mapDispatchToProps = {};

export const mapStateToProps = state => ({
  startupMessage: selectStartupMessage(state),
  errorMessage: selectErrorMessage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingMessage);
