import {
  loadItemsStartAction,
  addItemsAction,
  loadItemsSuccessAction,
  loadItemsErrorAction,
  addItems,
} from 'state/teamsnap/actions';

import { selectMemberById } from 'state/teamsnap/members/selectors';

import getErrorMessage from 'utils/errorMessage';
import teamsnapSdk from 'utils/teamsnapSdk';

const loadDivisionMembers = loadParams => (dispatch, getState) => {
  const itemType = 'member';
  dispatch(loadItemsStartAction({ itemType, loadParams }));
  return teamsnapSdk()
    .divisionAdvancedLoadMembers(loadParams)
    .then(
      items => {
        dispatch(addItemsAction({ itemType, items, loadParams }));
        return dispatch(loadItemsSuccessAction({ itemType, loadParams, items }));
      },
      error => {
        const errorMessage = getErrorMessage(error);
        return dispatch(loadItemsErrorAction({ itemType, loadParams, error: errorMessage }));
      },
    );
};

const loadMemberInfo = memberId => (dispatch, getState) => {
  const member = selectMemberById(getState(), memberId);
  if (!member) {
    const params = {
      types: 'member,contact_email_address,member_email_address',
      member__id: memberId,
      contact_email_address__member_id: memberId,
      member_email_address__member_id: memberId,
    };
    // HACKS to be able to send params that don't exist in the serializer
    window.teamsnap.collections.root.queries.query.params.member__id = null;
    window.teamsnap.collections.root.queries.query.params.contact_email_address__member_id = null;
    window.teamsnap.collections.root.queries.query.params.member_email_address__member_id = null;

    return teamsnapSdk()
      .collections.root.queryItems('query', params)
      .then(items => {
        dispatch(addItems({ items, loadParams: params }));
      });
  }
};

const loadEmailAndPhoneNumbersForMemberIds = memberIds => (dispatch, getState) => {
  const params = {
    types: 'contact,contact_email_address,member_email_address,contact_phone_number,member_phone_number',
    contact__member_id: memberIds,
    contact_phone_number__member_id: memberIds,
    member_phone_number__member_id: memberIds,
    contact_email_address__member_id: memberIds,
    member_email_address__member_id: memberIds,
  };

  // HACKS to be able to send params that don't exist in the serializer
  window.teamsnap.collections.root.queries.query.params.contact__member_id = null;
  window.teamsnap.collections.root.queries.query.params.contact_email_address__member_id = null;
  window.teamsnap.collections.root.queries.query.params.member_email_address__member_id = null;
  window.teamsnap.collections.root.queries.query.params.contact_phone_number__member_id = null;
  window.teamsnap.collections.root.queries.query.params.member_phone_number__member_id = null;

  return teamsnapSdk()
    .collections.root.queryItems('query', params)
    .then(items => {
      dispatch(addItems({ items, loadParams: params }));
    });
};

const loadMembersForDivisionId = divisionId => (dispatch, getState) => {
  const maxItemsPerRequest = 200;
  let currentPageNumber = 1;
  const loadParams = {
    divisionId,
  };

  const triggerPaginatedLoad = page => {
    loadParams.pageNumber = page;
    loadParams.pageSize = maxItemsPerRequest;
    const itemType = 'member';
    dispatch(loadItemsStartAction({ itemType, loadParams }));
    teamsnapSdk()
      .divisionAdvancedLoadMembers(loadParams)
      .then(
        items => {
          dispatch(addItemsAction({ itemType, items, loadParams }));
          if (items.length === maxItemsPerRequest) {
            currentPageNumber += 1;
            triggerPaginatedLoad(currentPageNumber);
          } else {
            dispatch(loadItemsSuccessAction({ itemType, loadParams, items }));
          }
        },
        error => {
          const errorMessage = getErrorMessage(error);
          return dispatch(loadItemsErrorAction({ itemType, loadParams, error: errorMessage }));
        },
      );
  };

  triggerPaginatedLoad(currentPageNumber);
};

export { loadDivisionMembers, loadMembersForDivisionId, loadMemberInfo, loadEmailAndPhoneNumbersForMemberIds };
