import _parseInt from 'lodash/parseInt';
import { select } from 'state/teamsnap/selectors';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';

const selectDivisionsPreferences = state => select(state, 'divisionsPreferences');
const selectDivisionPreferencesById = (state, id) => selectDivisionsPreferences(state)[_parseInt(id)];

const selectRootDivisionPreferences = state => selectDivisionPreferencesById(state, selectActiveDivisionRootId(state));

export { selectDivisionsPreferences, selectDivisionPreferencesById, selectRootDivisionPreferences };
