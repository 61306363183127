import { connect } from "react-redux";
import { loadDivisionBehaviors } from "state/divisionBehaviors/actions";
import { selectDivisionBehaviors } from "state/divisionBehaviors/selectors";
import {
  selectDivisionPaymentProvider,
  selectDivisionHasActiveStripeAccount,
  selectIsArchived
} from "state/divisions/selectors";
import { loadDivision } from "state/divisions/actions";
import { loadProgramMembers } from "state/programMembers/actions";
import { setListViewTotalProgramMemberCount } from "state/leagueMembers/actions";
import { analyticEvent } from "state/analyticEvent/actions";
import { withRouter } from "react-router";

import PageDashboard from "./PageDashboard";

const setTotalProgramMemberCount = divisionId => dispatch => {
  dispatch(
    loadProgramMembers(
      { divisionId, pageSize: 1, pageNumber: 1 },
      { fullResponse: true }
    )
  ).then(({ pageInfo }) =>
    dispatch(setListViewTotalProgramMemberCount(pageInfo.totalItems))
  );
};

const mapDispatchToProps = {
  loadDivisionBehaviors,
  loadDivision,
  loadProgramMembers,
  setListViewTotalProgramMemberCount,
  setTotalProgramMemberCount,
  analyticEvent
};

const mapStateToProps = (state, ownProps) => ({
  divisionId: ownProps.divisionId,
  behaviors: selectDivisionBehaviors(state)[0] || {},
  paymentProvider: selectDivisionPaymentProvider(state, ownProps.divisionId),
  hasActiveStripeAccount: selectDivisionHasActiveStripeAccount(
    state,
    ownProps.divisionId
  ),
  isArchivedDivision: selectIsArchived(state, ownProps.divisionId)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageDashboard)
);
