import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _size from 'lodash/size';
import SavedFilterButton from './SavedFilterButton';
import classes from './SavedFilters.module.scss';

class SavedFilters extends Component {
  static propTypes = {
    activeSavedFilterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    savedFilters: PropTypes.array,
    removeSavedFilter: PropTypes.func,
    useSavedFilter: PropTypes.func,
  };

  render() {
    const { activeSavedFilterId, savedFilters, removeSavedFilter, useSavedFilter } = this.props;

    if (_size(savedFilters) === 0) {
      return null;
    }

    return (
      <section className="u-spaceMd">
        <h5 className={ classes['sub-title'] }>Saved Filters</h5>
        <div className={ classes['sub-panel'] } style={ { display: 'block' } }>
          { savedFilters.length &&
            savedFilters.map(savedFilter => {
              const isActive = savedFilter.filterIndex !== null && savedFilter.filterIndex === activeSavedFilterId;
              return (
                <SavedFilterButton
                  key={ savedFilter.filterIndex }
                  clickHandler={ useSavedFilter }
                  filter={ savedFilter }
                  removeHandler={ removeSavedFilter }
                  isActive={ isActive }
                />
              );
            }) }
        </div>
      </section>
    );
  }
}

export default SavedFilters;
