/**
 * Division Behaviors
 *
 * Various selectors using reselect for selecting data from state
 *
 */
import _values from 'lodash/values';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.DIVISION_BEHAVIORS];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectDivisionBehaviorsIsFetching = state => isFetchingSelector(state);

export const selectDivisionBehaviors = state => _values(itemSelector(state));
