import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'shared/toolkit';
import { Button } from '@teamsnap/teamsnap-ui';
import { SUCCESS, ERROR } from 'shared/toolkit/Feedback/Feedback';
import styles from '../teamsAndDivisionsModals/TeamsAndDivisionsModal.module.scss';

export default class DeleteMembershipModal extends PureComponent {
  state = {
    disableButton: false,
  };

  handleClose = () => {
    this.props.hideModal();
  };

  handleConfirm = () => {
    this.props.hideModal();
    this.sendDeleteMemberRequest();
    this.setState({
      disableButton: true,
    });
  };

  sendDeleteMemberRequest = () => {
    const { programMembership, showFeedback, deleteMember, loadProgramMemberships } = this.props;
    const item = {
      id: programMembership.memberId,
    };

    const successFeedback = {
      title: 'Member deleted',
      type: SUCCESS,
    };
    const failureFeedback = {
      title: 'Member could not be deleted.',
      type: ERROR,
    };
    return deleteMember(item)
      .then(() => {
        loadProgramMemberships({ programMemberId: this.props.programMember.id }, { replaceState: true });
        showFeedback(successFeedback);
      })
      .catch(() => showFeedback(failureFeedback));
  };

  displayTeamOrDivisionName = () => {
    const { programMembership } = this.props;
    return programMembership.teamId ? programMembership.teamName : programMembership.divisionName;
  };

  render() {
    const { disableButton } = this.state;
    const { hideModal, programMember } = this.props;

    return (
      <Modal handleClose={ hideModal } title="Delete" componentStyles={ styles } overlay>
        <div className={ styles.ModalBody }>
          Are you sure you want to delete
          { programMember.fullName } from { this.displayTeamOrDivisionName() }?
          All member information will be permanently deleted.
        </div>
        <footer className={ styles.ModalFooter }>
          <Button
            label="Cancel"
            color="primary"
            icon="dismiss"
            mods="u-spaceRightSm"
            onClick={ this.handleClose }
            isDisabled={ disableButton }
          />
          <Button
            label="Confirm Delete"
            color="negative"
            icon="check"
            mods="u-spaceRightSm"
            onClick={ this.handleConfirm }
            isDisabled={ disableButton }
          />
        </footer>
      </Modal>
    );
  }
}

DeleteMembershipModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  showFeedback: PropTypes.func,
  programMember: PropTypes.object,
  programMmbership: PropTypes.object,
  deleteMember: PropTypes.func.isRequired,
  loadProgramMemberships: PropTypes.func.isRequired,
};

DeleteMembershipModal.defaultProps = {
  showFeedback: null,
};
