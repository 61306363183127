import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './Widget.module.scss';

class WidgetActionItems extends PureComponent {
  renderActionItem = actionItem => (
    <li key={ actionItem.description } className={ styles.ActionItemList }>
      <span className={ `${styles.ActionItemStatus} ${styles[`ActionItemStatus--${actionItem.statusColor}`]}` } />
      <span className="u-padLeftSm">{ actionItem.description }</span>
    </li>
  );

  renderItems = actionItems => (
    <ul className="u-padLeftXs u-padTopMd" style={ { listStyleType: 'none' } }>
      { actionItems.map(this.renderActionItem) }
    </ul>
  );

  render() {
    const { title, status, actionItems } = this.props;

    return (
      <div>
        <h4 className={ styles.ActionItemHeader }>
          { title }
          { status && <span className={ `${styles.ActionItemInfo} u-spaceLeftSm` }>({ status })</span> }
        </h4>

        { actionItems.length > 0 && this.renderItems(actionItems) }
      </div>
    );
  }
}

WidgetActionItems.propTypes = {
  title: PropTypes.string.isRequired,
  actionItems: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.string,
};

WidgetActionItems.defaultProps = {
  actionItems: [],
  status: null,
};

export default WidgetActionItems;
