import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { selectOwnerByTeamId } from 'state/teamsnap/members/selectors';
import { select, selectAsArray, selectAndSortBy } from 'state/teamsnap/selectors';

const selectContactEmailAddresses = state => select(state, 'contactEmailAddresses');
const selectContactEmailAddressById = (state, id) => selectContactEmailAddresses(state)[_parseInt(id)];
const selectContactEmailAddressesAsArray = state => selectAsArray(state, 'contactEmailAddresses');

const selectAndSortContactEmailAddressesByMemberId = createSelector(
  [selectContactEmailAddresses],
  contactEmailAddresses => selectAndSortBy(contactEmailAddresses, 'memberId'),
);

const selectContactEmailAddressesByMemberId = state => memberId =>
  selectAndSortContactEmailAddressesByMemberId(state)[_parseInt(memberId)] || [];

const selectOwnerContactEmailAddressesByTeamId = (state, teamId) => {
  const selectedContactEmailAddresses = {};
  const teamOwner = selectOwnerByTeamId(state, teamId, true);
  const contactEmailAddresses = selectContactEmailAddresses(state);

  Object.keys(contactEmailAddresses).forEach(key => {
    const contactEmailAddress = contactEmailAddresses[key];
    if (contactEmailAddress.teamId === teamId && contactEmailAddress.memberId === teamOwner.id) {
      selectedContactEmailAddresses[key] = contactEmailAddress;
    }
  });
  return selectedContactEmailAddresses;
};

export {
  selectContactEmailAddresses,
  selectContactEmailAddressById,
  selectContactEmailAddressesAsArray,
  selectAndSortContactEmailAddressesByMemberId,
  selectContactEmailAddressesByMemberId,
  selectOwnerContactEmailAddressesByTeamId,
};
