/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 5
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'prodteamsnapforbusiness'|'devteamsnapforbusiness'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} prodteamsnapforbusiness
 * @property {string} devteamsnapforbusiness
 */
export const ApiKey = {
  prodteamsnapforbusiness: '2061a24721515ff95b7915e1fec20ef6',
  devteamsnapforbusiness: '76261d25d4907e3750f0a8a62de1578d'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '5',
    branch: 'main',
    source: 'web',
    versionId: '3416a9d3-db03-4729-85c7-30d167943803'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class EndUserRegistrationFormViewed {
  constructor(properties) {
    this.event_type = 'End User Registration Form Viewed';
    this.event_properties = properties;
  }
}

export class RegWizardStepCompleted {
  constructor(properties) {
    this.event_type = 'Reg Wizard Step Completed';
    this.event_properties = properties;
  }
}

export class RegWizardStepSkipped {
  constructor(properties) {
    this.event_type = 'Reg Wizard Step Skipped';
    this.event_properties = properties;
  }
}

export class RegistrationWizardViewed {
  constructor(properties) {
    this.event_type = 'Registration Wizard Viewed';
    this.event_properties = properties;
  }
}

export class ScreenViewed {
  constructor(properties) {
    this.event_type = 'Screen Viewed';
    this.event_properties = properties;
  }
}

export class TsbHelpLinkClicked {
  constructor(properties) {
    this.event_type = 'TSB Help Link Clicked';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.email] The email address of the current user
   * @param {string} [properties.selected_org_id] The users active org id.
   * @param {'Organization Owner'|'Organization Admin'|'Program Admin'|'Program Coordinator'} [properties.selected_org_staff_role] The role of the staff member who triggers the event.
   * @param {string} [properties.selected_program_id] The unique identifier for the program the user has loaded when the user is identified to amplitude.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
  * Flush the event.
  */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.flush();
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * End User Registration Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/End%20User%20Registration%20Form%20Viewed)
   *
   * Event to track when a user views the registration form for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.form_id Unique registration form id assigned to each from in TSB registration.
   * @param {string} properties.organization_id The unique identifier of the organization a team is administered by.
   * @param {string} properties.organization_name The name of the TeamSnap for Business organization.
   * @param {string} properties.program_id The unique identifier of a program the team is associated with.
   * @param {string} properties.season_id The unique identifier of an organization season the team is associated with
   * @param {EventOptions} [options] Options for this track call.
   */
  endUserRegistrationFormViewed(properties, options) {
    return this.track(new EndUserRegistrationFormViewed(properties), options);
  }

  /**
   * Reg Wizard Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Reg%20Wizard%20Step%20Completed)
   *
   * Event to track completion of a step in the registration wizard process for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.form_id Unique registration form id assigned to each from in TSB registration.
   * @param {string} properties.organization_id The unique identifier of the organization a team is administered by.
   * @param {string} properties.organization_name The name of the TeamSnap for Business organization.
   * @param {string} properties.program_id The unique identifier of a program the team is associated with.
   * @param {'Program'|'Season'|'Name'|'Schedule'|'Group Names'|'Registration Fees'|'Eligibility Rules & Auto-Rostering'|'Capacity Limit & Waitlist'|'Questions & Logic'|'Digital Agreements'|'Deposit Account'|'Installment Plans'|'Discounts'|'Confirmation Message'} properties.registration_wizard_chapter \- Program  \- Season  \- Name  \- Schedule  \- Group Names  \- Registration Fees  \- Eligibility Rules & Auto-Rostering  \- Capacity Limit & Waitlist  \- Questions & Logic  \- Digital Agreements  \- Deposit Account  \- Installment Plans  \- Discounts  \- Confirmation Message
   * @param {string} properties.registration_wizard_step This property will be used to identify the specific step within all chaperts of the setup wizard (Ie. Program/Season/Name/Schedule are all steps under the "General" chapter.)
   * @param {string} properties.season_id The unique identifier of an organization season the team is associated with
   * @param {EventOptions} [options] Options for this track call.
   */
  regWizardStepCompleted(properties, options) {
    return this.track(new RegWizardStepCompleted(properties), options);
  }

  /**
   * Reg Wizard Step Skipped
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Reg%20Wizard%20Step%20Skipped)
   *
   * Event to track completion of a step in the registration wizard process for TeamSnap for Business
   *
   * Owner: Alan Hull
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.form_id Unique registration form id assigned to each from in TSB registration.
   * @param {string} properties.organization_id The unique identifier of the organization a team is administered by.
   * @param {string} properties.organization_name The name of the TeamSnap for Business organization.
   * @param {string} properties.program_id The unique identifier of a program the team is associated with.
   * @param {'General'|'Registration Options'|'Form Builder'|'Checkout Settings'|'Confirmation Message'} properties.registration_wizard_chapter Chapter is defined in code by "breadcrumb", this is the grouping of steps a user navigates to complete registration form setup in TSB Reg 2.
   * @param {string} properties.registration_wizard_step This property will be used to identify the specific step within all chaperts of the setup wizard (Ie. Program/Season/Name/Schedule are all steps under the "General" chapter.)
   * @param {string} properties.season_id The unique identifier of an organization season the team is associated with
   * @param {EventOptions} [options] Options for this track call.
   */
  regWizardStepSkipped(properties, options) {
    return this.track(new RegWizardStepSkipped(properties), options);
  }

  /**
   * Registration Wizard Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Registration%20Wizard%20Viewed)
   *
   * A TSB staff member views the Reg form setup page.
   *
   * Owner: Alan Hull
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.organization_id The unique identifier of the organization a team is administered by.
   * @param {string} properties.organization_name The name of the TeamSnap for Business organization.
   * @param {string} properties.program_id The unique identifier of a program the team is associated with.
   * @param {string} properties.season_id The unique identifier of an organization season the team is associated with
   * @param {EventOptions} [options] Options for this track call.
   */
  registrationWizardViewed(properties, options) {
    return this.track(new RegistrationWizardViewed(properties), options);
  }

  /**
   * Screen Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/Screen%20Viewed)
   *
   * An event to help us understand [which pages](https://miro.com/app/board/uXjVKHTMMoQ=/) users are viewing in a given session in the TSB web applications.
   *
   * @param {Object} properties The event's properties.
   * @param {'Boom Screen'|'Org Programs List'|'Org Programs Report'|'Program Seasons List'|'Program Wizard: Program Type'|'Season Wizard: Create Season'|'Org Financials: Outstanding'|'Org Financials: Transactions'|'Org Calendar'|'Org Member Directory'|'Org Member Profile'|'Org Staff List'|'Org Registration List'|'Reg 2: Registration Report'|'Reg 1: Registration Report'|'Reg 1: Registration List'|'Reg 1: Registration Transaction Summary'|'Reg 1: Form Overview'|'Reg 1: Form Preview'|'Reg 2: Setup Wizard - Select Program'|'Reg 1: New Registration Form'|'Org Messages List'|'Org Settings General'|'Merchant Accounts List'|'Season Divisions List'|'New Division'|'Season Financial Settings'|'Season Financial Reporting: Payments'|'Season Invoicing: Batches'|'Season Invoicing: Invoices'|'New Invoice'|'Season Registration List'|'Season Rostering'|'Season Participants List'|'Season Member Profile'|'Season Add Participant'|'Season Import Participants'|'Season Schedule'|'Season Schedule Import'|'Season Locations List'|'Season New Event'|'Season Messages List'|'Season New Message'|'Health Check Questions'|'Season Settings'|'Org New Message'} properties.screen_name Property to define screen views in TSB.  TSB screen view pages with corresponding screen name can be found [here](https://miro.com/app/board/uXjVKHTMMoQ=/?userEmail=alan.hull@teamsnap.com&openComment=3458764590075449749&mid=12934428&utm_source=notification&utm_medium=email&utm_campaign=&utm_content=reply-to-mention).
   * @param {string} properties.url The url of the link tapped
   * @param {EventOptions} [options] Options for this track call.
   */
  screenViewed(properties, options) {
    return this.track(new ScreenViewed(properties), options);
  }

  /**
   * TSB Help Link Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20For%20Business/events/main/latest/TSB%20Help%20Link%20Clicked)
   *
   * Event to track when users click on the help link within Teamsnap for Business
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.organization_id The unique identifier of the organization a team is administered by.
   * @param {string} properties.organization_name The name of the TeamSnap for Business organization.
   * @param {string} properties.page_viewed_url Captures the URL of the page the user is on.
   * @param {EventOptions} [options] Options for this track call.
   */
  tsbHelpLinkClicked(properties, options) {
    return this.track(new TsbHelpLinkClicked(properties), options);
  }
}

export const ampli = new Ampli();
