import { SHOW_MODAL, HIDE_MODAL } from '../app/constants';

export const showModal = (component, props) => ({
  type: SHOW_MODAL,
  payload: {
    component,
    props,
  },
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
