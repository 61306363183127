import { createSelector } from 'reselect';
import { select, selectAndSortBy } from 'state/teamsnap/selectors';
import _parseInt from 'lodash/parseInt';

const selectBroadcastEmailAttachments = state => select(state, 'broadcastEmailAttachments');

const selectBroadcastEmailAttachmentById = (state, id) => selectBroadcastEmailAttachments(state)[_parseInt(id)];

const selectAndSortBroadcastEmailAttachmentsByBroadcastEmailId = createSelector(
  [selectBroadcastEmailAttachments],
  broadcastEmailAttachments => selectAndSortBy(broadcastEmailAttachments, 'broadcastEmailId'),
);

const selectBroadcastEmailAttachmentsByBroadcastEmailId = (state, broadcastEmailId) =>
  selectAndSortBroadcastEmailAttachmentsByBroadcastEmailId(state)[_parseInt(broadcastEmailId)];

export {
  selectBroadcastEmailAttachments,
  selectBroadcastEmailAttachmentById,
  selectAndSortBroadcastEmailAttachmentsByBroadcastEmailId,
  selectBroadcastEmailAttachmentsByBroadcastEmailId,
};
