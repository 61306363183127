import * as React from 'react';
import { connect } from 'react-redux';
import { Panel, PanelBody } from '@teamsnap/teamsnap-ui';
import { selectLoggedInMember } from 'state/teamsnap/members/selectors';

const WidgetAdvertisements = ({ currentMember, divisionId }) => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  React.useEffect(() => {
    const s = window.document.createElement('script');
    s.type = 'text/javascript';
    s.src = `${window.TeamSnap.organizationJsUrl || 'http://localhost:5502'}/embed.js`;
    document.head.appendChild(s);
    s.onload = () => {
      setIsLoaded(true);
    };
  }, []);

  React.useEffect(() => {
    if (window.Organization && isLoaded) {
      window.Organization.mountComponent('advertisement-container', window.Organization.components.Advertisement, {
        divisionId,
        firstName: currentMember.firstName,
        lastName: currentMember.lastName,
        email: currentMember.emailAddresses[0],
      });
    }
  }, [isLoaded]);

  return (
    <Panel mods="u-spaceBottomNone" style={ { height: '100%' } }>
      <PanelBody mods="u-padLg">
        <div id="advertisement-container"></div>
      </PanelBody>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
    currentMember: selectLoggedInMember(state) || {},
  });

export default connect(mapStateToProps)(WidgetAdvertisements);
