import React from 'react';

import { Cell, FieldWrapper, Grid, Input } from '@teamsnap/teamsnap-ui';
import SimpleDateSelect from 'shared/components/SimpleDateSelect/SimpleDateSelect';

const MemberInfo = ({ updateCurrentFilter, getDefaultValue, updateField }) => {
  const genderOptions = [
    { label: 'Any', value: '' },
    { label: 'Female', value: 'female' },
    { label: 'Male', value: 'male' },
  ];

  const updateBornWhen = (bornWhen, date) => {
    updateField(date, `${bornWhen}_date`);
  };

  const renderFirstAndLastNames = () => (
      <Grid className="Grid Grid--fit Grid--withGutter">
        <Cell>
          <FieldWrapper
            field="input"
            fieldProps={ {
              inputProps: {
                onInput: updateCurrentFilter,
                value: getDefaultValue('first_name', ''),
              },
            } }
            label="First Name"
            name="first_name"
          />
        </Cell>
        <Cell>
          <FieldWrapper
            field="input"
            fieldProps={ {
              inputProps: {
                onInput: updateCurrentFilter,
                value: getDefaultValue('last_name', ''),
              },
            } }
            label="Last Name"
            name="last_name"
          />
        </Cell>
      </Grid>
    );

  const renderGender = () => (
      <div className="gender u-spaceBottomMd">
        <label className="FieldGroup-label" htmlFor="gender">
          Gender
        </label>

        <div className="SelectBox">
          <select className="SelectBox-options" name="gender" id="gender" onChange={ updateCurrentFilter }>
            { genderOptions.map(option => (
              <option key={ option.value } value={ option.value } selected={ getDefaultValue('gender') === option.value }>
                { option.label }
              </option>
            )) }
          </select>
        </div>
      </div>
    );

  const renderBornBetween = () => (
      <div className="born-between">
        <label className="FieldGroup-label" htmlFor="born-before">
          Born Between
        </label>
        <div className="born-after u-spaceBottomSm">
          <Grid>
            <Cell className="u-size1of8 u-padTopSm">From</Cell>
            <Cell className="u-size7of8">
              <SimpleDateSelect
                mods="u-spaceRightNone"
                name="born_after"
                updateDateFilter={ updateBornWhen }
                dateFormat="YYYY-MM-DD"
                defaultDate={ getDefaultValue('born_after_date') }
              />
            </Cell>
          </Grid>
        </div>
        <div className="born-before">
          <Grid>
            <Cell className="u-size1of8 u-padTopSm">To</Cell>
            <Cell className="u-size7of8">
              <SimpleDateSelect
                mods="u-spaceRightNone"
                name="born_before"
                updateDateFilter={ updateBornWhen }
                dateFormat="YYYY-MM-DD"
                defaultDate={ getDefaultValue('born_before_date') }
              />
            </Cell>
          </Grid>
        </div>
      </div>
    );

  return (
    <form className="u-spaceMd">
      <div className="member-info">
        { renderFirstAndLastNames() }
        { renderGender() }
        { renderBornBetween() }
      </div>
    </form>
  );
};

export default MemberInfo;
