/**
 * ButtonGroup Component
 *
 * A ButtonGroup component accepts an array of objects that represent individual Buttons.
 * See Button.js toolkit component, for details on possible props.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'shared/toolkit';
import defaultStyles from './ButtonGroup.module.scss';

class ButtonGroup extends PureComponent {
  state = {
    active: null,
  };

  UNSAFE_componentWillMount() {
    const { buttons } = this.props;

    this.setState({
      active: buttons.findIndex(button => button.isActive),
    });
  }

  onButtonClick = index => e => {
    const button = this.props.buttons[index];

    this.setState(
      {
        active: index,
      },
      button.handleClick(),
    );
  };

  renderButtons = buttons => {
    const { buttonClassName } = this.props;
    const { active } = this.state;

    return buttons.map((button, index) => (
      <Button
        { ...button }
        key={ button.text }
        handleClick={ this.onButtonClick(index) }
        className={ active === index ? `${buttonClassName}IsActive` : buttonClassName }
      />
    ));
  };

  render() {
    const { buttons, className, componentStyles } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return <div className={ styles[className] }>{ this.renderButtons(buttons) }</div>;
  }
}

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  componentStyles: PropTypes.shape({}),
};

ButtonGroup.defaultProps = {
  className: 'ButtonGroup',
  buttonClassName: 'Button',
  componentStyles: {},
};

export default ButtonGroup;
