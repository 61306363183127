import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@teamsnap/teamsnap-ui';
import _isEqual from 'lodash/isEqual';
import { LeagueMembersFilter } from './LeagueMembersFilter';

import classes from './LeagueMembersSearch.module.scss';

export class LeagueMembersSearch extends Component {
  static propTypes = {
    searchProgramMembers: PropTypes.func.isRequired,
    lastSearchParams: PropTypes.object,
    analyticEvent: PropTypes.func.isRequired,
  };

  state = {
    searchField: '',
    roleField: 'Everyone',
    searchType: 'Name',
  };

  getSearchParams() {
    return this.state.searchField ? { [this.getSearchType()]: this.state.searchField } : null;
  }

  getSearchType = () => {
    const { searchType } = this.state;
    switch (searchType) {
      case 'Name':
        return 'memberName';
      case 'Team':
        return 'teamName';
      case 'Email':
        return 'emailAddress';
      default:
        return null;
    }
  };

  getSearchAndRoleParams = () => {
    const searchParams = this.getSearchParams();
    const roleParams = this.getRoleParams();
    return { ...searchParams, ...roleParams };
  };

  getRoleParams() {
    const { roleField } = this.state;
    switch (roleField) {
      case 'Commissioners':
        return { isCommissioner: true };
      case 'Team Owners':
        return { isTeamOwner: true };
      case 'Managers':
        return { isManager: true };
      case 'Everyone':
        return null;
      default:
        return null;
    }
  }

  handleSearch = () => {
    this.props.searchProgramMembers(this.getSearchAndRoleParams());
    this.sendAnalytics();
  };

  clearSearchAndFilter = () => {
    this.setState({ searchField: '', roleField: 'Everyone' }, this.handleSearch);
  };

  updateSearchField = e => {
    const searchTerm = e.target.value;
    const fieldName = e.target.name;
    const newState = { ...this.state };

    this.setState({
      [fieldName]: searchTerm,
    });
    newState[fieldName] = searchTerm;
  };

  isNewSearch() {
    return !_isEqual(this.props.lastSearchParams, this.getSearchAndRoleParams()) || !this.props.lastSearchParams;
  }

  handleSearchKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleSearch(this.state.searchField);
      this.sendAnalytics();
    }
  };

  handleClearKeyPress = e => {
    if (e.key === 'Enter') {
      this.clearSearchAndFilter();
    }
  };

  sendAnalytics = () => {
    const event = {
      eventCategory: 'members_tab',
      eventAction: 'filter',
      eventLabel: this.state.roleField,
    };

    this.props.analyticEvent(event);
  };

  renderSearchButton() {
    return (
      <Button
        type="submit"
        mods="Button--primary Button--small u-spaceTopXs"
        onClick={ this.handleSearch }
        onKeyPress={ this.handleSearchKeyPress }
        isDisabled={ this.isDisabled() }
      >
        <Icon name="search" mods="u-spaceRightNone" />
      </Button>
    );
  }

  renderClearButton() {
    return (
      <Button
        type="submit"
        mods="Button--negative Button--small u-spaceTopXs"
        onClick={ this.clearSearchAndFilter }
        onKeyPress={ this.handleClearKeyPress }
      >
        <Icon name="dismiss" mods="u-spaceRightNone" />
      </Button>
    );
  }

  changeSearchType = e => {
    this.setState({ searchType: e.target.value });
  };

  getPlaceholderText = () => {
    const { searchType } = this.state;
    switch (searchType) {
      case 'Name':
        return 'Search members by name';
      case 'Team':
        return 'Search by team name';
      case 'Email':
        return 'Search members by email address';
      default:
        return null;
    }
  };

  updateRoleField = role => {
    this.setState({ roleField: role });
  };

  isDisabled = () => this.state.searchField.length === 1;

  render() {
    return (
      <div className="Grid Grid--withGutter">
        <div className="Grid-cell u-sizeFill">
          <div className="InputGroup Grid u-flexNoWrap">
            <div className={ ` ${classes.InputGroupSelect} Grid-cell u-sizeFit` }>
              <label className={ classes.InputGroupSelectLabel } htmlFor="searchType">
                { this.state.searchType }
              </label>
              <select
                className={ classes.InputGroupSelectOptions }
                name="searchType"
                id="searchType"
                onChange={ this.changeSearchType }
              >
                <option value="Name">Name</option>
                <option value="Email">Email</option>
                <option value="Team">Team</option>
              </select>
            </div>
            <input
              value={ this.state.searchField }
              name="searchField"
              className={ `Input ${classes.InputGroupSelectInput} Grid-cell u-sizeFill` }
              placeholder={ this.getPlaceholderText() }
              onChange={ this.updateSearchField }
              onKeyPress={ this.handleSearchKeyPress }
            />
          </div>
        </div>
        <div className="Grid-cell u-sizeFit u-padLeftNone">
          <LeagueMembersFilter updateRoleField={ this.updateRoleField } roleField={ this.state.roleField } />
        </div>
        <div className="Grid-cell u-sizeFit u-padLeftNone">
          { this.isNewSearch() ? this.renderSearchButton() : this.renderClearButton() }
        </div>
      </div>
    );
  }
}
