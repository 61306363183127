import _values from 'lodash/values';
import { createSelector } from 'reselect';
import { getDateDifference } from 'utils/date-service';
import { selectAndSortProgramMembershipsByProgramMemberId } from 'state/programMemberships/selectors';
import { selectAndSortPhotoByProgramMemberId } from 'state/programMemberPhotos/selectors';
import { selectDivisionsPreferences } from 'state/teamsnap/divisionsPreferences/selectors';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.PROGRAM_MEMBERS];
const isFetchingSelector = state => stateSelector(state).isFetching;
const isErrorSelector = state => stateSelector(state).isError;
const itemSelector = state => stateSelector(state).items;

// Select members Fetching or Error states
export const selectProgramMemberIsFetching = state => isFetchingSelector(state);
export const selectProgramMemberIsError = state => isErrorSelector(state);

export const selectProgramMemberIsLoading = state => stateSelector(state).status === 'loading';

export const selectRawProgramMembers = state => itemSelector(state) || {};
export const selectProgramMemberValues = state => _values(itemSelector(state));

export const getProgramMemberRoles = (programMember = {}) => {
  const roles = [];
  if (programMember.isLeagueOwner) {
    roles.push('League Owner');
  }
  if (programMember.isCommissioner) {
    roles.push('Commissioner');
  }
  if (programMember.isTeamOwner) {
    roles.push('Team Owner');
  }
  if (programMember.isManager) {
    roles.push('Manager');
  }
  if (programMember.isCoach) {
    roles.push('Coach');
  }
  if (programMember.isPlayer) {
    roles.push('Player');
  }
  if (programMember.isNonPlayer) {
    roles.push('Non-Player');
  }
  return roles;
};

const displayLastNameFirst = ({ firstName, lastName }) => {
  let name = '';
  if (firstName && firstName !== null) {
    name = `${firstName}`;
  }
  if (lastName && lastName !== null) {
    name = `${lastName}, ${name}`;
  }
  return name;
};

const fullName = ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`.trim();
  }
  if (firstName && !lastName) {
    return `${firstName}`.trim();
  }
  if (!firstName && lastName) {
    return `${lastName}`.trim();
  }
};

export const getAge = ({ birthday }) => {
  if (!birthday || !birthday.match(/(\d{4})-(\d{2})-(\d{2})/)) {
    return null;
  }
  const age = getDateDifference(birthday, 'years');

  // hack to disregard faulty birthday data coming from SNAPI
  return age <= 100 ? age : null;
};

const formatBirthday = ({ birthday }, { hasInternationalDate } = false) => {
  // eslint-disable-next-line no-useless-escape
  const pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
  if (!birthday || !birthday.match(pattern)) {
    return null;
  }
  const age = getDateDifference(birthday, 'years');
  if (age < 1 || age >= 100) {
    return null;
  }
  return hasInternationalDate ? birthday.replace(pattern, '$3/$2/$1') : birthday.replace(pattern, '$2/$3/$1');
};

// Creates complete program member objects whenever the id's change
// Add any new properties for the program member here.

export const selectProgramMembers = createSelector(
  selectRawProgramMembers,
  selectAndSortProgramMembershipsByProgramMemberId,
  selectAndSortPhotoByProgramMemberId,
  selectDivisionsPreferences,
  (programMembers, programMembershipsByProgramMemberId, photos, divisionPreferences) => {
    const newProgramMembers = {};
    _values(programMembers).forEach(programMember => {
      const rootDivisionPreferences = divisionPreferences[programMember.rootDivisionId];
      newProgramMembers[programMember.id] = {
        ...programMember,
        displayName: displayLastNameFirst(programMember),
        fullName: fullName(programMember),
        role: getProgramMemberRoles(programMember),
        programMemberships: programMembershipsByProgramMemberId[programMember.id] || [],
        age: getAge(programMember),
        formattedBirthday: formatBirthday(programMember, rootDivisionPreferences),
        photo: photos[programMember.id],
      };
    });
    return newProgramMembers;
  },
);
