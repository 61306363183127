import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './ClubEmailAttachment.module.scss';

class ClubEmailAttachment extends Component {
  static propTypes = {
    attachment: PropTypes.object.isRequired,
    updateAttachment: PropTypes.func.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
  };

  updateAttachment = event => {
    this.props.updateAttachment(this.props.attachment.id, event.target.files);
  };

  deleteAttachment = event => {
    this.props.deleteAttachment(this.props.attachment);
  };

  render() {
    return (
      <div className={ styles.clubEmailAddAttachment }>
        <input onChange={ this.updateAttachment } size={ 30 } type="file" />
        <a className="button small danger" style={ { float: 'right' } } onClick={ this.deleteAttachment }>
          Cancel
        </a>
      </div>
    );
  }
}

export default ClubEmailAttachment;
