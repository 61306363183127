import _parseInt from 'lodash/parseInt';
import _orderBy from 'lodash/orderBy';
import _values from 'lodash/values';
import { createSelector } from 'reselect';
import { select } from 'state/teamsnap/selectors';

const selectRawRegistrationForms = state => select(state, 'registrationForms');
const selectRegistrationForms = createSelector([selectRawRegistrationForms], registrationForms => {
  const newRegistrationForms = {};
  _values(registrationForms).forEach(registrationForm => {
    newRegistrationForms[registrationForm.id] = {
      ...registrationForm,
      name: `${registrationForm.name}${registrationForm.isRetired ? ' (Retired)' : ''}`,
    };
  });
  return newRegistrationForms;
});

const selectRegistrationFormById = (state, id) => selectRegistrationForms(state)[_parseInt(id)];
const selectRegistrationFormsAsArray = state => _values(selectRegistrationForms(state));

const selectRegistrationFormsAsArraySortedByActive = createSelector(
  [selectRegistrationFormsAsArray],
  registrationForms => _orderBy(registrationForms, 'isRetired', 'asc'),
);

export {
  selectRawRegistrationForms,
  selectRegistrationForms,
  selectRegistrationFormById,
  selectRegistrationFormsAsArray,
  selectRegistrationFormsAsArraySortedByActive,
};
