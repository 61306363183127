import { connect } from 'react-redux';
// Actions
import { assignSelectedMembersToTeam, loadMembersForTeam, rosterShowTeamMembers } from 'state/leagueRostering/actions';
// Selectors
import {
  selectCountAssignedToTeam, selectHasLoadedMembersForTeamId,
  selectIsLoadingMembersForTeamId,
  selectIsTeamIdShowingMembers, selectRosteringPendingMode, selectSelectedMembersCount
} from 'state/leagueRostering/selectors';
import { selectMemberCountByTeamId, selectPendingChangesCountByTeamId } from 'state/teamsnap/teamNames/selectors';
// Components
import TeamListRowTeam from '../components/TeamListRowTeam';


const mapDispatchToProps = (dispatch, ownProps) => ({
  showTeamMembers: () => {
    dispatch(rosterShowTeamMembers(ownProps.teamName.id));
  },
  assignMembers: () => {
    dispatch(assignSelectedMembersToTeam(ownProps.teamName.id));
  },
  loadMembersForTeam: () => {
    dispatch(loadMembersForTeam(ownProps.teamName.id));
  },
});

const mapStateToProps = (state, ownProps) => ({
  countAssignedMembers: selectCountAssignedToTeam(state),
  countSelectedMembers: selectSelectedMembersCount(state),
  hasLoadedMembersForTeam: selectHasLoadedMembersForTeamId(state, ownProps.teamName.id),
  isLoadingMembersForTeam: selectIsLoadingMembersForTeamId(state, ownProps.teamName.id),
  isShowingMembers: selectIsTeamIdShowingMembers(state, ownProps.teamName.id),
  memberCount: selectMemberCountByTeamId(state, ownProps.teamName.id,
    ownProps.isPlayerTab || false, selectRosteringPendingMode(state)),
  pendingChangesCount: selectPendingChangesCountByTeamId(state, ownProps.teamName.id),
  isPlayerTab: ownProps.isPlayerTab || false,
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamListRowTeam);
