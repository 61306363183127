/**
 * Invoicing Health Data
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, COLLECTIONS } from 'state/snapi/slice';

export const loadInvoicingHealth = params => dispatch =>
  dispatch(getCollection(COLLECTIONS.INVOICING_HEALTH, 'invoicing_health_data/search', params));
