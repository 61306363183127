/**
 * Division Selector
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import { createSelector } from 'reselect';
import { COLLECTIONS } from 'state/snapi/slice';
import _values from 'lodash/values';

const stateSelector = state => state.snapi[COLLECTIONS.DIVISION];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectDivisionIsFetching = state => isFetchingSelector(state);

export const selectDivisions = state => itemSelector(state) || {};
export const selectDivisionValues = state => _values(itemSelector(state));

const selectDivision = (state, id) => itemSelector(state) && itemSelector(state)[id];

export const selectDivisionPaymentProvider = (state, id) =>
  selectDivision(state, id) && selectDivision(state, id).defaultPaymentProvider;

export const selectDivisionHasActiveStripeAccount = (state, id) =>
  selectDivision(state, id) ? selectDivision(state, id).activePaymentProvider === 'stripe' : false;

export const selectDivisionsForDropdown = createSelector(selectDivisionValues, divisions =>
  divisions.map(division => ({ title: division.name, value: division.id })),
);

export const selectIsArchived = (state, id) => selectDivision(state, id) && selectDivision(state, id).isArchived;
