import { connect } from 'react-redux';

// Actions
import {
  clearMemberSearchFilters,
  updateHasUnappliedFilters,
  toggleMemberSearchPopup,
  updateCurrentFilterField,
  addCurrentAdvancedFilter,
  deleteCurrentAdvancedFilter,
} from 'state/leagueRostering/actions';
// Selectors
import { selectActiveDivisionId } from 'state/app/selectors';
import {
  selectCurrentFilter,
  selectDivisionOrTeamFilters,
  selectHasUnappliedSearchFilters,
  selectMemberInfoFilters,
  selectMemberStatusFilters,
  selectOpenedMemberSearchPopupId,
  selectRegistrationStatusFilters,
  selectRegistrationFormsForDivision,
  selectRegistrationFormLineItemsForDivision,
  selectRegistrationFormLineItemOptionsForDivision,
  selectSelectedDivisionId,
  selectCustomFieldFilters,
  selectRegistrationVersion,
  selectParticipantGroupFilters,
} from 'state/leagueRostering/selectors';
import { selectDivisionTreeForDivisionId } from 'state/teamsnap/divisions/selectors';
import { selectAllTeamNamesUnderDivisionId } from 'state/teamsnap/teamNames/selectors';
import { selectLeagueCustomFields } from 'state/teamsnap/leagueCustomFields/selectors';
import { selectParticipantGroups } from 'state/teamsnap/participantGroups/selectors';

// Components
import SearchMembers from '../components/SearchMembers';

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearAllFilters: () => {
    localStorage.removeItem('rosterFiltering');
    dispatch(clearMemberSearchFilters());
    dispatch(updateHasUnappliedFilters('noFilters'));
  },
  clearFilter: (popupName) => {
    dispatch(clearMemberSearchFilters(popupName));
    dispatch(updateHasUnappliedFilters(true));
  },
  submitSearch: () => {
    dispatch(ownProps.submitSearch);
    dispatch(updateHasUnappliedFilters(false));
  },
  toggleMemberSearchPopup: (popup) => {
    dispatch(toggleMemberSearchPopup(popup));
  },
  updateField: (value, fieldName) => {
    if (value === null || fieldName === 'registration_form_id') {
      dispatch(updateCurrentFilterField('registration_form_line_item_id', ''));
      dispatch(updateCurrentFilterField('registration_form_line_item_option_id', ''));
    }

    if (fieldName === 'divisionId') {
      dispatch(updateCurrentFilterField('teamId', ''));
    }

    if (fieldName.match(/custom_field/)) {
      dispatch(
        addCurrentAdvancedFilter({
          filterIndex: fieldName,
          filterParams: value,
        }),
      );
    } else {
      dispatch(updateCurrentFilterField(fieldName, value));
    }

    dispatch(updateHasUnappliedFilters(true));
  },
  removeCustomField: (index) => dispatch(deleteCurrentAdvancedFilter(index)),
});

const getFilters = (filters) => {
  // Grab localStorage filters
  const localStorageFilters = JSON.parse(localStorage.getItem('rosterFiltering'));

  return {
    ...filters,
    ...localStorageFilters,
    advancedFilters: localStorageFilters?.advancedFilters || filters.advancedFilters,
  };
};

const removeTeamsnapCustomFields = (fields, registrationVersion) => {
  try {
    if (fields && registrationVersion === 2) {
      return Object.values(fields).filter(field => field.organization_id);
    }

    // If it's empty/ null return as is
    return fields;
  } catch (e) {
    console.error('SearchMembersContainer.js::removeTeamsnapCustomFields', e);
  }
};

const mapStateToProps = (state, ownProps) => ({
  currentFilter: getFilters(selectCurrentFilter(state)) || {},
  divisionTeams: selectAllTeamNamesUnderDivisionId(state, selectSelectedDivisionId(state)),
  selectedDivisionId: selectSelectedDivisionId(state),
  divisionTree: selectDivisionTreeForDivisionId(state)(selectActiveDivisionId(state)),
  hasUnappliedSearchFilters: selectHasUnappliedSearchFilters(state),
  isSearching: ownProps.isSearching,
  openedPopupId: selectOpenedMemberSearchPopupId(state),
  registrationForms: selectRegistrationFormsForDivision(state),
  registrationFormLineItems: selectRegistrationFormLineItemsForDivision(state),
  registrationFormLineItemOptions: selectRegistrationFormLineItemOptionsForDivision(state),
  leagueCustomFields: removeTeamsnapCustomFields(selectLeagueCustomFields(state), selectRegistrationVersion(state)),
  registrationVersion: selectRegistrationVersion(state),
  participantGroups: selectParticipantGroups(state),
  searchFilters: {
    divisionOrTeam: {
      defaultButtonText: 'Division / Team',
      filters: selectDivisionOrTeamFilters(state),
    },
    memberInfo: {
      defaultButtonText: 'Member Info',
      filters: selectMemberInfoFilters(state),
    },
    memberStatus: {
      defaultButtonText: 'Member Status',
      filters: selectMemberStatusFilters(state),
    },
    registrationStatus: {
      defaultButtonText: 'Registration',
      filters: selectRegistrationStatusFilters(state),
    },
    customFields: {
      defaultButtonText: 'Custom Fields',
      filters: selectCustomFieldFilters(state),
    },
    additionalQuestions: {
      defaultButtonText: 'Additional Questions',
      filters: selectCustomFieldFilters(state),
    },
    participantGroups: {
      defaultButtonText: 'Participant Groups',
      filters: selectParticipantGroupFilters(state),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchMembers);
