const divisionMenuOptions = (divisionTree, selectedDivisionId) => {
  const divisionChildren = divisionTree[0].childDivisions;
  const recurse = (divisions, options = [], level = 1) => {
    divisions.forEach(division => {
      // insert spaces and dashes before division name
      const label = `\xA0\xA0${Array(level).join('-')} ${division.name}`;
      options.push({
        label,
        value: division.id,
        selected: division.id === parseInt(selectedDivisionId, 10),
      });
      if (division.childDivisions.length) {
        return recurse(division.childDivisions, options, level + 1);
      }
    });

    return options;
  };

  return [...recurse(divisionChildren)];
};

export default divisionMenuOptions;
