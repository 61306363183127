import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeagueMessagesPlaceholder from 'shared/components/LeagueMessagesPlaceholder';

import { linkLeagueMessagesClubEmail } from 'utils/links';

class ListEmailsNoData extends Component {
  static propTypes = {
    division: PropTypes.object.isRequired,
  };

  render() {
    const { division } = this.props;
    return (
      <LeagueMessagesPlaceholder
        title="Send Emails"
        bodyText="You can send emails to everyone in your league,
          or only to selected teams or team owners. Every email you send gets saved
          so you can refer back to it, or re-send later to others. Just click
          on the New Email button below to send out your first email."
        buttonText={ `Email ${division.name}` }
        icon="mail"
        link={ linkLeagueMessagesClubEmail(division.id) }
      />
    );
  }
}

export default ListEmailsNoData;
