import { createSelector } from 'reselect';
import { select, selectAndSortBy } from 'state/teamsnap/selectors';
import _parseInt from 'lodash/parseInt';

const selectRegistrationFormLineItemOptions = state => select(state, 'registrationFormLineItemOptions');

const selectAndSortLineItemOptionsByLineItemId = createSelector(
  [selectRegistrationFormLineItemOptions],
  lineItemOptions => selectAndSortBy(lineItemOptions, 'registrationFormLineItemId'),
);

const selectRegistrationLineItemOptionsByLineItemId = state => registrationFormLineItemId =>
  selectAndSortLineItemOptionsByLineItemId(state)[_parseInt(registrationFormLineItemId)] || [];

export { selectRegistrationFormLineItemOptions, selectRegistrationLineItemOptionsByLineItemId };
