import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _parseInt from 'lodash/parseInt';

import InputCheckbox from 'shared/components/Form/InputCheckbox';
import InputSelect from 'shared/components/Form/InputSelect';
import InputText from 'shared/components/Form/InputText';
import DateSelectContainer from 'shared/components/DateSelect/DateSelectContainer';

import classes from './AdvancedFilter.module.scss';

class AdvancedFilter extends Component {
  static propTypes = {
    advancedFilterIndex: PropTypes.string.isRequired,
    checkoutTypes: PropTypes.array,
    customFields: PropTypes.array,
    defaultField: PropTypes.string,
    defaultOperator: PropTypes.string,
    defaultValue: PropTypes.any,
    deleteFilter: PropTypes.func.isRequired,
    divisionTree: PropTypes.array,
    resetAdvancedFilter: PropTypes.func,
    signupStatuses: PropTypes.array,
    teamNames: PropTypes.array,
    updateAdvancedField: PropTypes.func.isRequired,
  };

  getCustomFieldOperatorLookup = () => ({
    'Check Box': 'checkbox',
    Date: 'date',
    Menu: 'select',
    Text: 'string',
  });

  getTeamMenuOptions = () => {
    const teamOptions = this.props.teamNames.map(teamName => ({
      label: teamName.name,
      value: teamName.id,
    }));
    return teamOptions;
  };

  getDivisionMenuOptions = () => {
    const divisionChildren = this.props.divisionTree[0].childDivisions;
    const recurse = (divisions, options = [], level = 1) => {
      divisions.forEach(division => {
        // insert spaces and dashes before division name
        const label = `\xA0\xA0 ${Array(level).join('-')} ${division.name}`;
        options.push({ label, value: division.id });
        if (division.childDivisions.length) {
          return recurse(division.childDivisions, options, level + 1);
        }
      });
      return options;
    };
    const activeDivision = this.props.divisionTree[0];
    return [
      { label: activeDivision.name, value: activeDivision.id },
      { label: 'Subdivisions', isOptgroup: true },
      ...recurse(divisionChildren),
    ];
  };

  getCustomFieldMenuOptions = () => {
    const customFields = this.getCustomFields();
    const currentField = customFields[this.props.defaultField];
    if (currentField) {
      let options = [];
      const customFieldId = _parseInt(currentField.value.replace('custom_field_', ''));
      this.props.customFields.some(customField => {
        if (customField.id === customFieldId) {
          options = customField.options;
          return true;
        }
        return false;
      });
      return options;
    }
  };

  getFields = () => {
    const { customFields } = this.props;
    const fields = [
      { label: 'Last Name', value: 'last_name', operators: 'string' },
      { label: 'First Name', value: 'first_name', operators: 'string' },
      { label: 'Team', value: 'team_id', operators: 'teamSelect' },
      { label: 'Division', value: 'division_id', operators: 'divisionSelect' },
      { label: 'Email Address', value: 'email', operators: 'string' },
      { label: 'Is Manager', value: 'is_manager', operators: 'booleanSelect' },
      {
        label: 'Is Commissioner',
        value: 'is_commissioner',
        operators: 'booleanSelect',
      },
    ];
    if (customFields.length) {
      customFields.forEach(customField => {
        const fieldObject = {
          label: `* ${customField.name}`,
          isCustomField: true,
          operators: this.getCustomFieldOperatorLookup()[customField.kind],
          value: `custom_field_${customField.id}`,
        };
        fields.push(fieldObject);
      });
    }
    return fields;
  };

  getCustomFields = () => {
    const customFields = {};
    this.getFields().forEach(field => {
      if (field.isCustomField) {
        customFields[field.value] = field;
      }
    });
    return customFields;
  };

  getOperators = fieldName => {
    // This is just to try and clean up the duplicate code below.
    const presetOperatorGroups = {
      isIsNot: [
        { value: 'equals', label: 'Is' },
        { value: 'not_equals', label: 'Is Not' },
      ],
      is: [{ value: 'equals', label: 'Is' }],
    };
    const operators = {
      string: [
        { value: 'equals', label: 'Is' },
        { value: 'contains', label: 'Contains' },
        { value: 'begins_with', label: 'Begins with' },
        { value: 'greater_than', label: 'Greater than' },
        { value: 'less_than', label: 'Less than' },
      ],
      date: [
        { value: 'equals', label: 'Is' },
        { value: 'less_than_or_equal', label: 'Is on or Before' },
        { value: 'greater_than_or_equal', label: 'Is on or After' },
      ],
      select: presetOperatorGroups.isIsNot,
      isIsNot: [
        { value: 'equals', label: 'Is' },
        { value: 'not_equals', label: 'Is Not' },
      ],
      checkoutStatus: presetOperatorGroups.isIsNot,
      signupStatus: presetOperatorGroups.isIsNot,
      boolean: presetOperatorGroups.isIsNot,
      booleanSelect: presetOperatorGroups.is,
      checkbox: presetOperatorGroups.isIsNot,
      teamSelect: presetOperatorGroups.is,
      divisionSelect: presetOperatorGroups.is,
    };

    if (!fieldName) {
      fieldName = this.props.defaultField;
    }

    let returnOperators = [];
    this.getFields().some(field => {
      if (field.value === fieldName) {
        returnOperators = operators[field.operators];
      }
    });
    return returnOperators;
  };

  getValueInputType = fieldName => {
    let inputType = null;
    const fields = this.getFields();
    const currentFieldName = fieldName || this.props.defaultField;
    fields.some(field => {
      if (field.value === currentFieldName) {
        inputType = field.operators;
      }
    });
    return inputType;
  };

  deleteFilter = () => {
    this.props.deleteFilter(this.props.advancedFilterIndex);
  };

  updateFilterField = value => {
    this.props.resetAdvancedFilter(this.props.advancedFilterIndex);
    this.props.updateAdvancedField(value, 'field');
    const operators = this.getOperators(value);
    const valueInputType = this.getValueInputType(value);

    this.props.updateAdvancedField(operators[0].value, 'operator');
    this.props.updateAdvancedField(valueInputType, 'type');

    // Set a default value for checkboxes
    const defaultValue =
      this.props.defaultValue === true || this.props.defaultValue === '1' || this.props.defaultValue === 'true';
    if (valueInputType === 'boolean' || valueInputType === 'booleanSelect') {
      this.updateBooleanField(defaultValue);
    } else if (valueInputType === 'checkbox') {
      this.updateCustomCheckboxField(defaultValue);
    }
  };

  updateDateField = date => {
    this.props.updateAdvancedField(date, 'value');
  };

  updateBooleanField = checked => {
    let value = false;
    if (checked === 'true' || checked === true) {
      value = true;
    }
    this.props.updateAdvancedField(value, 'value');
  };

  updateCustomCheckboxField = checked => {
    let value = '0';
    if (checked) {
      value = '1';
    }
    this.props.updateAdvancedField(value, 'value');
  };

  render() {
    const {
      checkoutTypes,
      defaultField,
      updateAdvancedField,
      defaultOperator,
      defaultValue,
      signupStatuses,
    } = this.props;

    return (
      <div className={ classes['advanced-filter'] }>
        <div className={ classes['filter-type'] }>
          <InputSelect
            defaultValue={ defaultField }
            name="field"
            options={ this.getFields() }
            updateHandler={ this.updateFilterField }
            placeholder="-- Field --"
            wrapperClass={ classes['filter-name'] }
          />
          <InputSelect
            defaultValue={ defaultOperator }
            name="operator"
            options={ this.getOperators() }
            updateHandler={ updateAdvancedField }
            wrapperClass={ classes['filter-qualifier'] }
          />
        </div>
        <div className={ classes['filter-content'] }>
          { (() => {
            switch (this.getValueInputType()) {
              case 'checkoutStatus':
                return (
                  <InputSelect
                    defaultValue={ defaultValue }
                    name="value"
                    options={ checkoutTypes }
                    placeholder="-- status --"
                    updateHandler={ updateAdvancedField }
                    wrapperClass="full-width"
                  />
                );
              case 'date':
                return (
                  <DateSelectContainer
                    dateFormat="YYYY-MM-DD"
                    defaultDate={ defaultValue }
                    updateHandler={ this.updateDateField }
                  />
                );
              case 'select':
                return (
                  <InputSelect
                    defaultValue={ defaultValue }
                    name="value"
                    options={ this.getCustomFieldMenuOptions() }
                    placeholder="-- value --"
                    updateHandler={ updateAdvancedField }
                    wrapperClass="full-width"
                  />
                );
              case 'checkbox':
                return (
                  <InputCheckbox
                    checked={ defaultValue === '1' }
                    name="value"
                    updateHandler={ this.updateCustomCheckboxField }
                  />
                );
              case 'boolean':
                return (
                  <InputCheckbox checked={ defaultValue === true } name="value" updateHandler={ this.updateBooleanField } />
                );
              case 'signupStatus':
                return (
                  <InputSelect
                    defaultValue={ defaultValue }
                    name="value"
                    options={ signupStatuses }
                    placeholder="-- status --"
                    updateHandler={ updateAdvancedField }
                    wrapperClass="full-width"
                  />
                );
              case 'booleanSelect':
                return (
                  <InputSelect
                    defaultValue={ defaultValue === true ? 'true' : 'false' }
                    name="value"
                    options={ [
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ] }
                    updateHandler={ this.updateBooleanField }
                  />
                );
              case 'teamSelect':
                return (
                  <InputSelect
                    defaultValue={ defaultValue }
                    name="value"
                    options={ this.getTeamMenuOptions() }
                    placeholder="-- select team --"
                    updateHandler={ updateAdvancedField }
                  />
                );
              case 'divisionSelect':
                return (
                  <InputSelect
                    defaultValue={ defaultValue }
                    name="value"
                    options={ this.getDivisionMenuOptions() }
                    placeholder="-- select division --"
                    updateHandler={ updateAdvancedField }
                  />
                );
              default:
                return (
                  <InputText
                    defaultValue={ defaultValue }
                    name="value"
                    placeholder="-- search text --"
                    updateHandler={ updateAdvancedField }
                  />
                );
            }
          })() }
        </div>
        <div className={ classes['filter-dismissal'] }>
          <button className="Roster-Search__icon-button--ss-delete" onClick={ this.deleteFilter } />
        </div>
      </div>
    );
  }
}

export default AdvancedFilter;
