import { connect } from 'react-redux';

// Actions
import { toggleIsMemberSelected } from 'state/leagueRostering/actions';
// selectors
import { selectIsMemberSelected } from 'state/leagueRostering/selectors';
import { selectLoggedInMember } from 'state/teamsnap/members/selectors';
import {
  selectPendingOrAssignedTeamNameForMemberId,
  selectTeamNamesById,
  selectTeamNameForMemberId,
} from 'state/teamsnap/teamNames/selectors';
import { selectDivisionForMemberId, selectDivisionById } from 'state/teamsnap/divisions/selectors';
// Components
import RosterListRow from '../components/RosterListRow';

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSelectMember: () => {
    if (!ownProps.member.isCommissioner) {
      dispatch(toggleIsMemberSelected(ownProps.member.id));
    }
  },
});

const mapStateToProps = (state, ownProps) => {
  let pendingDivision = null;
  if (ownProps.member.pendingDivisionId) {
    pendingDivision = selectDivisionById(state, ownProps.member.pendingDivisionId);
  }

  return {
    currentMember: selectLoggedInMember(state) || {},
    isSelected: selectIsMemberSelected(state)(ownProps.member.id),
    team: ownProps.team || selectTeamNameForMemberId(state, ownProps.member.id),
    teamName: selectPendingOrAssignedTeamNameForMemberId(state, ownProps.member.id),
    division: selectDivisionForMemberId(state, ownProps.member.id),
    pendingDivision,
    pendingTeam: ownProps.member.pendingTeamId ? selectTeamNamesById(state, ownProps.member.pendingTeamId) : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterListRow);
