import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Widget.module.scss';

class WidgetContent extends Component {
  render() {
    const { heading, subheading } = this.props;

    return (
      <div>
        <h3 className={ styles.ContentHeader }>{ heading }</h3>
        <p className={ styles.ContentSubheading }>{ subheading }</p>
      </div>
    );
  }
}

WidgetContent.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
};

WidgetContent.defaultProps = {};

export default WidgetContent;
