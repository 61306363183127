/**
 * Filter Component
 *
 * Filter component is used to 'Filter' or 'Search' data.  This can be applied to a
 * table as a quick search or added to a more advanced filter.
 *
 * TODO:
 *  -> Need to load styles from TeamSnap UI (once created)
 *  -> Add a 'criteria' field.  This would be something like field 'contains, start with, ect'?
 *  -> This is an initial filter component, once we get to building advanced filters this
 *     will likely need to be updated.
 *
 */

import React, { PureComponent } from 'react';
import { Input, Select, Grid, Cell } from 'shared/toolkit';
import PropTypes from 'prop-types';
import defaultStyles from './Filter.module.scss';

class Filter extends PureComponent {
  state = { inputValue: '' };

  handleChange = e => this.setState({ inputValue: e.target.value });

  render() {
    const { inputValue } = this.state;
    const { name, onSelectChange, onKeyUp, selectValue, categories, size, componentStyles } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    const selectInput = {
      name: 'filterCategories',
      onChange: onSelectChange,
      value: selectValue,
    };

    const input = {
      name,
      onKeyUp,
      value: inputValue,
      onChange: this.handleChange,
    };

    return (
      <Cell className="CellMiddle" size={ size } componentStyles={ styles }>
        <Grid classModifiers="Grid--withGutter">
          <Select input={ selectInput } options={ categories } size="1of2" label={ null } componentStyles={ styles } />
          <Input input={ input } size="1of2" placeholder="Search Filter" label={ null } componentStyles={ styles } />
        </Grid>
      </Cell>
    );
  }
}

Filter.propTypes = {
  name: PropTypes.string.isRequired,
  selectValue: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
};

Filter.defaultProps = {
  size: 'fill',
  componentStyles: {},
};

export default Filter;
