import { connect } from 'react-redux';
import { selectActiveDivisionId } from 'state/app/selectors';
import { loadTeamNamesByDivisionId } from 'state/teamsnap/teamNames/actions';
import { loadDivisionsPreferences } from 'state/teamsnap/divisionsPreferences/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import { selectRootDivisionPreferences } from 'state/teamsnap/divisionsPreferences/selectors';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';
import LeagueMembers from '../components/LeagueMembers';

const mapDispatchToProps = {
  loadTeamNamesByDivisionId,
  loadDivisionsPreferences,
  analyticEvent,
};
const mapStateToProps = state => ({
  activeDivisionId: selectActiveDivisionId(state),
  rootDivisionId: selectActiveDivisionRootId(state),
  rootDivisionPreferences: selectRootDivisionPreferences(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueMembers);
