import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Cell, Panel, PanelHeader, PanelBody, PanelFooter, PanelRow } from '@teamsnap/teamsnap-ui';
import Loader from 'shared/toolkit/Loader';
import styles from './Widget.module.scss';

class WidgetBase extends Component {
  renderPanelBody = () => {
    const { isLoading, children } = this.props;

    if (isLoading) {
      const loaderStyles = {
        position: 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        right: '0',
        margin: 'auto',
        height: '30px',
      };

      return (
        <PanelBody className={ styles.widgetPanelBody }>
          <Loader type="spin" text="Loading" style={ loaderStyles } />
        </PanelBody>
      );
    }

    return (
      <PanelBody className={ styles.widgetPanelBody }>
        { React.Children.map(children, (child, i) => (
          <PanelRow mods="u-padLeftNone u-padRightNone" key={ i }>
            { child }
          </PanelRow>
        )) }
      </PanelBody>
    );
  };

  renderPanelFooter = () => {
    const { infoComponent, buttonText, buttonLocation, buttonColor } = this.props;

    return (
      <PanelFooter mods={ ['u-borderNone', 'u-textLeft', 'u-padTopNone', styles.widgetPanelFooter].join(' ') }>
        { infoComponent && <span className={ styles.WidgetFooterInfo }>{ infoComponent }</span> }
        { buttonText && (
          <Button location={ buttonLocation } color={ buttonColor } mods="u-sizeFull u-textCenter">
            { buttonText }
          </Button>
        ) }
      </PanelFooter>
    );
  };

  render() {
    const { isLoading, headerText } = this.props;

    return (
      <Cell mods="u-size1of3" style={ { minHeight: '250px' } }>
        <Panel mods="u-flex u-flexCol u-spaceBottomNone" style={ { height: '100%', position: 'relative' } }>
          <PanelHeader mods={ styles.widgetPanelHeader }>
            <span className="Panel-title">{ headerText }</span>
          </PanelHeader>

          { this.renderPanelBody() }

          { !isLoading && this.renderPanelFooter() }
        </Panel>
      </Cell>
    );
  }
}

WidgetBase.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  infoComponent: PropTypes.node,
  buttonText: PropTypes.string,
  buttonLocation: PropTypes.string,
  buttonColor: PropTypes.string,
};

WidgetBase.defaultProps = {
  isLoading: null,
  infoComponent: null,
  buttonText: null,
  buttonLocation: null,
  buttonColor: null,
};

export default WidgetBase;
