import { selectBroadcastEmailAttachmentsByBroadcastEmailId } from 'state/teamsnap/broadcastEmailAttachments/selectors';
import teamsnapSdk from 'utils/teamsnapSdk';
import { loadItems, addItems, deleteItem } from 'state/teamsnap/actions';
import * as constants from 'state/teamsnap/broadcastEmailAttachments/constants';
import getErrorMessage from 'utils/errorMessage';

const findBroadcastEmailAttachmentsbyBroadcastEmailId = broadcastEmailId => (dispatch, getState) => {
  const attachments = selectBroadcastEmailAttachmentsByBroadcastEmailId(getState(), broadcastEmailId);
  if (!(attachments && attachments.length > 0)) {
    return dispatch(loadItems('broadcastEmailAttachments', { broadcastEmailId }));
  }
  return null;
};

const uploadbroadcastEmailAttachmentsStart = (broadcastEmailId, memberId, file) => ({
  type: constants.UPLOAD_BROADCAST_EMAIL_ATTACHMENT_START,
  broadcastEmailId,
  memberId,
  file,
});

const uploadBroadcastEmailAttachmentsSuccess = (broadcastEmailId, memberId, file, broadcastEmailAttachment) => ({
  type: constants.UPLOAD_BROADCAST_EMAIL_ATTACHMENT_SUCCESS,
  broadcastEmailId,
  memberId,
  file,
  broadcastEmailAttachment,
});

const uploadBroadcastEmailAttachmentsError = (broadcastEmailId, memberId, error) => ({
  type: constants.UPLOAD_BROADCAST_EMAIL_ATTACHMENT_ERROR,
  broadcastEmailId,
  memberId,
  error,
});

const uploadBroadcastEmailAttachment = (
  broadcastEmailId,
  memberId,
  file,
  progressCallback,
  resolve,
  reject,
) => dispatch => {
  dispatch(uploadbroadcastEmailAttachmentsStart(broadcastEmailId, memberId, file));

  return teamsnapSdk()
    .uploadBroadcastEmailAttachment(broadcastEmailId, memberId, file, progressCallback)
    .then(
      broadcastEmailAttachment => {
        dispatch(addItems({ items: broadcastEmailAttachment })).then(() => {
          dispatch(
            uploadBroadcastEmailAttachmentsSuccess(broadcastEmailId, memberId, file, broadcastEmailAttachment[0]),
          );
          return resolve(broadcastEmailAttachment);
        });
      },
      error => {
        const errorMessage = getErrorMessage(error);
        uploadBroadcastEmailAttachmentsError(errorMessage);
        return reject();
      },
    );
};

const deleteBroadcastEmailAttachment = broadcastEmailAttachment => dispatch => {
  dispatch(deleteItem(broadcastEmailAttachment));
};

export {
  findBroadcastEmailAttachmentsbyBroadcastEmailId,
  uploadBroadcastEmailAttachment,
  deleteBroadcastEmailAttachment,
};
