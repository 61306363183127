import { connect } from 'react-redux';

// Selectors
import { selectActiveDivisionId } from 'state/app/selectors';
import { selectDivisionHierarchyUpToActiveDivision, selectDivisionById } from 'state/teamsnap/divisions/selectors';
import { selectRosterListDivisionId } from 'state/leagueRostering/selectors';
// Actions
import { setCurrentRosteringDivision } from 'state/leagueRostering/actions';

import RosterAssignmentDivisionBreadcrumb from '../components/RosterAssignmentDivisionBreadcrumb';

const mapDispatchToProps = dispatch => ({
  selectDivision: divisionId => {
    dispatch(setCurrentRosteringDivision(divisionId));
  },
});

const mapStateToProps = state => ({
  hierarchy: selectDivisionHierarchyUpToActiveDivision(state)(selectRosterListDivisionId(state)),
  activeDivisionId: selectActiveDivisionId(state),
  activeDivision: selectDivisionById(state, selectActiveDivisionId(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterAssignmentDivisionBreadcrumb);
