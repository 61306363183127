// ------------------------------------
// General Links
// ------------------------------------
const linkMailTo = email => `mailto:${email}`;

const linkLogin = () => '/login/signin';

const linkPaymentSettings = divisionId => `/${divisionId}/league_payments/payment_provider_settings`;

const linkDashboard = divisionId => `/${divisionId}/dashboard`;

const linkRegistrationNew = divisionId => `/${divisionId}/registration/new`;

const linkRegistrations = divisionId => `/${divisionId}/registration`;

const linkRegistrationForm = (divisionId, registrationFormId, registrationId) =>
  // eslint-disable-next-line max-len
  `/divisions/${divisionId}/registration/${registrationFormId}/fees_and_payments_overview?registration_id=${registrationId}`;

const linkDivisionTeams = divisionId => `/${divisionId}/division_teams`;

const linkDirectory = divisionId => `/${divisionId}/league_roster/list`;

const linkAlerts = divisionId => `/${divisionId}/league_messages/list_alerts`;

const linkEmails = divisionId => `/${divisionId}/league_messages/list_emails`;

// -------------------------------------
// League Payments / Invoicing links
// -------------------------------------

const linkInvoicing = divisionId => `/${divisionId}/league_payments/invoicing`;

const linkInvoicingNew = divisionId => `/${divisionId}/league_payments/invoicing/new`;

// ------------------------------------
// LeagueMessages links
// ------------------------------------
const linkLeagueMessagesListEmails = divisionId => `/${divisionId}/league_messages/list_emails`;

const linkLeagueMessagesListAlerts = divisionId => `/${divisionId}/league_messages/list_alerts`;

const linkLeagueMessagesClubEmail = (divisionId, broadcastEmailId = null) => {
  if (broadcastEmailId) {
    return `/${divisionId}/league_messages/club_email/${broadcastEmailId}`;
  }
  return `/${divisionId}/league_messages/club_email`;
};

const linkLeagueMessagesClubAlert = (divisionId, broadcastAlertId = null) => {
  if (broadcastAlertId) {
    return `/${divisionId}/league_messages/club_alert/${broadcastAlertId}`;
  }
  return `/${divisionId}/league_messages/club_alert`;
};

const leagueMessagesViewEmailLink = (divisionId, broadcastEmailId) =>
  `/${divisionId}/league_messages/view_email/${broadcastEmailId}`;

const leagueMessagesViewAlertLink = (divisionId, broadcastAlertId) =>
  `/${divisionId}/league_messages/view_alert/${broadcastAlertId}`;

// ------------------------------------
// Rostering links
// ------------------------------------
const linkLeagueRostering = divisionId => `/${divisionId}/league_rostering`;

// ------------------------------------
// Roster links
// ------------------------------------
const linkLeagueRosterList = divisionId => `/${divisionId}/league_roster/list`;

const linkLeagueRosterMember = (divisionId, memberId) => `/${divisionId}/league_roster/player/${memberId}`;

const linkLeagueRosterMemberEdit = (divisionId, memberId) => `/${divisionId}/league_roster/edit/${memberId}`;

const linkRosterNew = divisionId => `/${divisionId}/league_roster/new`;

const linkDivisionRosterEdit = (divisionId, member) =>
  `/${member.divisionId || divisionId}/league_roster/edit/${member.id}`;

const linkRosterImport = divisionId => `/${divisionId}/league_roster/import`;

const linkRosterRosterImports = divisionId => `/${divisionId}/league_roster/roster_imports`;

const linkRosterExport = divisionId => `/${divisionId}/league_roster/export`;

const linkRosterMemberRegistrationForm = (divisionId, registrationFormId) =>
  `/divisions/${divisionId}/registration/${registrationFormId}`;

const linkRosterChangeTeam = (divisionId, memberId) => `/${divisionId}/league_roster/change_team/${memberId}`;

const linkRosterInvite = (divisionId, memberId) => `/${divisionId}/league_roster/invite_all?player_id=${memberId}`;

// ------------------------------------
// Team Links
// ------------------------------------
const linkTeamHome = teamId => `/${teamId}/home`;

const linkTeamRoster = teamId => `/${teamId}/roster/list`;

// ------------------------------------
// League Members Links
// ------------------------------------
const linkMembers = divisionId => `/${divisionId}/league_members/list`;

const linkDetailedMemberView = (divisionId, programMemberId) => `/${divisionId}/league_members/${programMemberId}`;

// ------------------------------------
// League Safety Links
// ------------------------------------
const linkQuestionEdit = divisionId => `/${divisionId}/safety/questions_edit`;
const linkSafety = divisionId => `/${divisionId}/safety`;

// ------------------------------------
// Roster links with Members Param
// HACK : delete after Members Tab live
// ------------------------------------

const linkMemberImports = divisionId => `/${divisionId}/league_roster/roster_imports?return=league_members`;

const linkMemberNew = divisionId => `/${divisionId}/league_roster/new?return=league_members`;

const linkMemberExport = divisionId => `/${divisionId}/league_roster/export?return=league_members`;

export {
  linkMailTo,
  linkLogin,
  linkPaymentSettings,
  linkAlerts,
  linkEmails,
  linkInvoicing,
  linkInvoicingNew,
  linkLeagueMessagesListEmails,
  linkLeagueMessagesListAlerts,
  linkLeagueMessagesClubEmail,
  linkLeagueMessagesClubAlert,
  leagueMessagesViewEmailLink,
  leagueMessagesViewAlertLink,
  linkLeagueRostering,
  linkLeagueRosterList,
  linkLeagueRosterMember,
  linkLeagueRosterMemberEdit,
  linkRosterNew,
  linkDivisionRosterEdit,
  linkRosterImport,
  linkRosterRosterImports,
  linkRosterExport,
  linkRosterMemberRegistrationForm,
  linkRosterChangeTeam,
  linkRosterInvite,
  linkTeamHome,
  linkTeamRoster,
  linkDashboard,
  linkRegistrationNew,
  linkRegistrations,
  linkRegistrationForm,
  linkDivisionTeams,
  linkDirectory,
  linkMembers,
  linkDetailedMemberView,
  linkMemberImports,
  linkMemberExport,
  linkMemberNew,
  linkQuestionEdit,
  linkSafety,
};
