import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Panel, PopupConfirm } from '@teamsnap/teamsnap-ui';

const QuestionEditRow = ({ currentQuestion, onDelete, clickOpenModal, currentQuestionCount }) => {
  const prompt = currentQuestion.prompt.replace(/"/g, '');
  const response = currentQuestion.symptomAnswers.join(', ').replace(/"/g, '');

  return (
    <Panel key={ `question-card-${currentQuestion.id}` }>
      <div className="Grid Grid--alignMiddle">
        <div className="Grid-cell u-padMd u-textSemiBold" style={ { flexBasis: '60px' } }>
          <div className="u-textCenter u-colorPrimary" style={ { fontSize: '18px' } }>
            { /* TODO: Add safety icon to TSUI or add as PNG */ }
            <svg className="Icon" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66992 19.998C8.86523 19.998 9.14844 19.9102 9.40234 19.7832C15.0762 16.9609 17.0293 15.3301 17.0293 11.8438V4.77344C17.0293 3.36719 16.5801 2.77148 15.3301 2.25391C14.3926 1.85352 10.9551 0.701172 10.0762 0.427734C9.63672 0.291016 9.09961 0.203125 8.66992 0.203125C8.23047 0.203125 7.69336 0.300781 7.25391 0.427734C6.375 0.691406 2.9375 1.86328 2 2.25391C0.759766 2.77148 0.300781 3.36719 0.300781 4.77344V11.8438C0.300781 15.3301 2.30273 16.873 7.92773 19.7832C8.18164 19.9199 8.46484 19.998 8.66992 19.998ZM7.80078 14.5586C7.41016 14.5586 7.08789 14.3828 6.80469 14.0312L4.23633 10.9941C4.04102 10.7598 3.94336 10.5156 3.94336 10.2617C3.94336 9.69531 4.40234 9.25586 4.95898 9.25586C5.28125 9.25586 5.52539 9.39258 5.76953 9.67578L7.78125 12.127L11.6387 5.99414C11.8828 5.61328 12.1758 5.42773 12.5273 5.42773C13.0645 5.42773 13.5625 5.81836 13.5625 6.375C13.5625 6.59961 13.4648 6.84375 13.3184 7.06836L8.76758 14.0117C8.54297 14.3633 8.19141 14.5586 7.80078 14.5586Z" />
            </svg>
          </div>
        </div>
        <div className="Grid-cell u-sizeFill u-padMd u-borderLeft">
          <div className="u-spaceBottomSm" style={ {whiteSpace: "pre-line"} }>{ prompt }</div>
          <p className="u-colorGrey">{ `A response of "${response}" will fail the health check.` }</p>
        </div>
        <div className="Grid-cell u-sizeFit u-flex u-flexJustifyBetween u-padMd">
          <button className="Button Button--small u-spaceRightSm" onClick={ () => clickOpenModal(prompt, response) }>
            <Icon name="edit" />
          </button>
          { currentQuestionCount > 1 && <PopupConfirm
            aria-label="Delete assignment"
            buttonText={ <Icon name="trash" style={ { color: '#e26362' } } /> }
            confirmButtonMods="Button--no"
            confirmButtonText="Delete"
            cancelButtonMods=""
            onAccept={ () => onDelete(currentQuestion.id) }
            direction={ ['down'] }
            popUpText={
              <div className="u-textCenter">
                <h3>Delete Question?</h3>
                <p>This action cannot be undone.</p>
              </div>
            }
          />
        }
        </div>
      </div>
    </Panel>
  );
};
QuestionEditRow.propTypes = {
  currentQuestion: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  clickOpenModal: PropTypes.func.isRequired,
};

export default QuestionEditRow;
