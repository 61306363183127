/**
 * Registration Health Data
 *
 * Various selectors using reselect for selecting data from state
 *
 */
import _values from 'lodash/values';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.REGISTRATION_HEALTH];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectRegistrationHealthIsFetching = state => isFetchingSelector(state);

export const selectRegistrationHealthValues = state => _values(itemSelector(state))[0] || {};

export const selectRegistrationHealth = state => itemSelector(state);
