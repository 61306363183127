import { connect } from 'react-redux';

// Actions
import { toggleRosterListSort, toggleSelectAllMembers } from 'state/leagueRostering/actions';
// Selectors
import {
  reSelectAndSortFilteredMembersAndTeams,
  selectListCheckedAllMembers,
  selectRegistrationVersion,
} from 'state/leagueRostering/selectors';
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
// Components
import RosterList from '../components/RosterList';

const mapDispatchToProps = {
  toggleRosterListSort,
  toggleSelectAllMembers,
};

const mapStateToProps = (state, ownProps) => ({
  isAllSelected: selectListCheckedAllMembers(state),
  members: reSelectAndSortFilteredMembersAndTeams(state) || [],
  division: selectActiveDivision(state),
  registrationVersion: selectRegistrationVersion(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterList);
