const isChildDivision = (rootDivision, division) => {
  if (rootDivision && division) {
    if (
      division.rootId === rootDivision.rootId &&
      division.leftBoundary > rootDivision.leftBoundary &&
      division.rightBoundary < rootDivision.rightBoundary
    ) {
      return true;
    }
    return false;
  }
  return false;
};

const isArchived = division => division && (division.isArchived || division.isAncestorArchived) || "";
const isActive = division => !isArchived(division);

export { isChildDivision, isActive, isArchived };
