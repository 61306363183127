import React from 'react';
import { Link } from 'react-router-dom';
import {
  linkLeagueMessagesClubEmail,
  linkLeagueMessagesListEmails,
  linkLeagueRostering,
  linkDashboard,
  linkMembers,
} from 'utils/links';

import classes from './AppHeader.module.scss';

export const AppHeader = props => {
  const divisionId = props.activeDivisionId ? props.activeDivisionId : 1;
  return (
    <div style={ { textAlign: 'center', marginBottom: '20px' } }>
      <h1>Season Frontend</h1>
      <span>
        <Link to={ linkDashboard(divisionId) } activeclassname={ classes.activeRoute }>
          Dashboard
        </Link>
      </span>
      <span>
        { ' · ' }
        <Link to={ linkLeagueRostering(divisionId) } activeclassname={ classes.activeRoute }>
          League Rostering
        </Link>
      </span>
      <span>
        { ' · ' }
        <Link to={ linkLeagueMessagesClubEmail(divisionId) } activeclassname={ classes.activeRoute }>
          League Messages : Club Email
        </Link>
        { ' · ' }
        <Link to={ linkLeagueMessagesListEmails(divisionId) } activeclassname={ classes.activeRoute }>
          League Messages : List Email
        </Link>
      </span>
      <span>
        { ' · ' }
        <Link to={ linkMembers(divisionId) } activeclassname={ classes.activeRoute }>
          League Members
        </Link>
      </span>
    </div>
  );
};

export default AppHeader;
