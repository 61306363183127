import { connect } from 'react-redux';

import { addAttachment, deleteAttachment, updateAttachment } from 'state/leagueMessages/actions';

import { selectBroadcastEmailAttachmentsByBroadcastEmailId } from 'state/teamsnap/broadcastEmailAttachments/selectors';
import { deleteBroadcastEmailAttachment } from 'state/teamsnap/broadcastEmailAttachments/actions';
import { attachmentsSize, currentBroadcastEmailId } from 'state/leagueMessages/selectors';

import ClubEmailAttachments from '../components/ClubEmailAttachments';

const mapDispatchToProps = {
  addAttachment,
  updateAttachment,
  deleteAttachment,
  deleteBroadcastEmailAttachment,
};

const mapStateToProps = state => ({
  attachments: state.leagueMessages.attachments || [],
  uploadedAttachments: selectBroadcastEmailAttachmentsByBroadcastEmailId(state, currentBroadcastEmailId(state)),
  totalAttachmentsSize: attachmentsSize(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClubEmailAttachments);
