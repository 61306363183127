import React from 'react';

// Components
import { Cell, Grid } from '@teamsnap/teamsnap-ui';
// Core
import divisionMenuOptions from '../../core/divisionMenuOptions';

const DivisionOrTeam = ({ divisionTeams, divisionTree, selectedDivisionId, getDefaultValue, updateCurrentFilter }) => {
  const activeDivision = divisionTree[0];
  const divisionOptions = divisionMenuOptions(divisionTree, getDefaultValue('divisionId', ''));

  return (
    <form className="u-spaceMd">
      <Grid className="Grid Grid--alignMiddle">
        <Cell className="u-sizeFill">
          <label htmlFor="division-search-filter" className="u-block u-spaceBottomXs u-fontSizeMd u-textSemiBold">
            Division
          </label>
        </Cell>
        <Cell className="u-sizeFill">
          <label
            htmlFor="team-search-filter"
            className="u-block u-spaceBottomXs u-fontSizeMd"
            style={ { marginLeft: '12px' } }
          >
            <span className="u-textSemiBold">Team</span>
            <span className="u-colorGrey u-spaceLeftXs">(optional)</span>
          </label>
        </Cell>
      </Grid>
      <Grid className="Grid Grid--alignMiddle">
        <Cell className="u-sizeFill">
          <div className="SelectBox">
            <select
              className="SelectBox-options"
              name="divisionId"
              id="division-search-filter"
              onChange={ updateCurrentFilter }
            >
              <option value="">Select division</option>
              <option value={ activeDivision.id } selected={ selectedDivisionId }>
                { activeDivision.name }
              </option>
              { divisionOptions.length > 0 ? (
                <optgroup label="Subdivisions">
                  { divisionOptions.map(option => (
                    <option
                      key={ option.value }
                      value={ option.value }
                      selected={ option.selected }
                    >
                      { option.label }
                    </option>
                  )) }
                </optgroup>
              ) : null }
            </select>
          </div>
        </Cell>
        <Cell className="u-sizeFit u-spaceLeftSm u-spaceRightSm u-fontSizeXl u-colorGrey">/</Cell>
        <Cell className="u-sizeFill">
          <div className="SelectBox">
            <select className="SelectBox-options" name="teamId" id="team-search-filter" onChange={ updateCurrentFilter }>
              <option value="">Select team</option>
              { divisionTeams.map(team => (
                <option value={ team.id } selected={ team.id === parseInt(getDefaultValue('teamId', ''), 10) }>
                  { team.name }
                </option>
              )) }
            </select>
          </div>
        </Cell>
      </Grid>
    </form>
  );
};

export default DivisionOrTeam;
