import React, { Component } from 'react';
import PropTypes from 'prop-types';

// This component is actually in the organization-frontend project.
// This code just includes a piece of that project and mounts the component from it to the page.

export default class BackgroundCheckButton extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
  };

  componentDidMount() {
    if (window.Organization) {
      window.Organization.mountComponent(
        `program-member-background-check-${this.props.member.id}`,
        window.Organization.components.BgReportButton,
        {
          programMemberId: parseInt(this.props.member.id, 10),
          isPlayer: this.props.member.isPlayer,
          isOtherThanPlayer: this.isOtherThanPlayer(this.props.member),
          firstName: this.props.member.firstName,
          lastName: this.props.member.lastName,
        },
      );
    }
  }

  isOtherThanPlayer(member) {
    return (
      member.isNonPlayer ||
      member.isLeagueOwner ||
      member.isCommissioner ||
      member.isManager ||
      member.isCoach ||
      member.isTeamOwner
    );
  }

  render() {
    return <div id={ `program-member-background-check-${this.props.member.id}` } />;
  }
}

export function setDivisionIdForBackgroundChecks(divisionId, modalId) {
  if (window.Organization) {
    window.Organization.init().then(() => {
      window.Organization.setDivisionId(divisionId);
      window.Organization.mountComponent(modalId, window.Organization.components.BgReportOrderModal);
    });
  }
}

function getOrganizationJsUrl() {
  return window.TeamSnap.organizationJsUrl;
}

function installJS() {
  const s = window.document.createElement('script');
  s.type = 'text/javascript';
  s.src = `${getOrganizationJsUrl()}/embed.js`;
  const x = window.document.head.getElementsByTagName('script')[0];
  if (x && x.parentNode) {
    x.parentNode.insertBefore(s, x);
  }
}

installJS();
