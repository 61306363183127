import React, { useEffect } from 'react';
import AppLoading from 'shared/components/AppLoading';
import 'shared/styles/main.module.scss';

function AppSdk(props) {
  const { initActiveDivision, setActiveDivisionId, params } = props;

  useEffect(() => {
    let init = () => Promise.resolve();
    if (props.initApp) {
      init = props.initApp;
    }

    init().then(() => {
      setActiveDivisionId(params.divisionid);
      initActiveDivision(params.divisionid, window.TeamSnap.memberId);
    });
  }, [props, props.params, params.divisionid, initActiveDivision, setActiveDivisionId]);

  return <div>{ props.loading ? <AppLoading loading /> : props.children }</div>;
}

export default AppSdk;
