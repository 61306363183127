import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class LeagueMembersFilter extends Component {
  static propTypes = {
    updateRoleField: PropTypes.func.isRequired,
    roleField: PropTypes.string.isRequired,
  };

  onChange = e => {
    this.props.updateRoleField(e.target.value);
  };

  render() {
    return (
      <form className="u-inlineBlock u-padRightSm ">
        <div className="FieldGroup">
          <div className="SelectBox">
            <select
              className="SelectBox-options"
              name="roles"
              id="roles"
              value={ this.props.roleField }
              onChange={ this.onChange }
            >
              <option value="Everyone"> Everyone </option>
              <option value="Commissioners"> Commissioners </option>
              <option value="Team Owners"> Team Owners </option>
              <option value="Managers"> Managers </option>
            </select>
          </div>
        </div>
      </form>
    );
  }
}
