import { connect } from 'react-redux';
// Actions
import {
  disableRosteringPendingMode, enableRosteringPendingMode, updatePlayerTabStatus
} from 'state/leagueRostering/actions';
// Selectors
import {
  selectFilteredPlayersCount, selectRosteringPendingMode, selectRosterListDivisionId, selectSelectedMembersCount
} from 'state/leagueRostering/selectors';
// Components
import RosterAssignment from '../components/RosterAssignment';


const mapDispatchToProps = dispatch => ({
  enableRosteringPendingMode: () => dispatch(enableRosteringPendingMode()),
  disableRosteringPendingMode: () => dispatch(disableRosteringPendingMode()),
  updatePlayerTabStatus: playerTabActive => dispatch(updatePlayerTabStatus(playerTabActive)),
});

const mapStateToProps = state => ({
  countOfPlayersInFilter: selectFilteredPlayersCount(state),
  countOfSelectedMembers: selectSelectedMembersCount(state),
  currentRosterDivisionId: selectRosterListDivisionId(state),
  pendingModeEnabled: selectRosteringPendingMode(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RosterAssignment);
