import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './InputSelect.module.scss';

class InputSelect extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    elementClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    optionsDataFields: PropTypes.object,
    placeholder: PropTypes.string,
    updateHandler: PropTypes.func,
    wrapperClass: PropTypes.string,
  };

  getOptionValue = option => {
    if (!option) {
      return null;
    }
    const { optionsDataFields } = this.props;
    if (typeof option === 'object') {
      if (optionsDataFields) {
        return option[optionsDataFields.value];
      }
      return option.value;
    }
    return option;
  };

  getOptionLabel = option => {
    const { optionsDataFields } = this.props;
    if (typeof option === 'object') {
      if (optionsDataFields) {
        return option[optionsDataFields.label];
      }
      return option.label;
    }
    return option;
  };

  updateValue = () => {
    const { value } = this.refs.input;
    const fieldName = this.props.name;
    if (typeof this.props.updateHandler === 'function') {
      this.props.updateHandler(value, fieldName);
    }
  };

  componentDidUpdate() {
    const { defaultValue } = this.props;
    if (!this.getOptionValue(defaultValue) || !this.getOptionLabel(defaultValue)) {
      this.refs.input.selectedIndex = 0;
    } else {
      this.refs.input.value = defaultValue;
    }
  }

  render() {
    const { defaultValue, disabled, elementClass, options, placeholder, name, wrapperClass } = this.props;

    return (
      <label className={ ['ts__select-box', classes.reactSelect, wrapperClass].join(' ') } disabled={ disabled }>
        { placeholder && (
          <span style={ { display: 'none' } } className="ts__select-option">
            { placeholder }
          </span>
        ) }
        <select
          style={ { opacity: '1' } }
          className={ elementClass }
          defaultValue={ defaultValue }
          disabled={ disabled }
          name={ name }
          onChange={ this.updateValue }
          ref="input"
        >
          { placeholder && <option value={ !disabled ? '' : defaultValue }>{ placeholder }</option> }
          { options.map((option, index) => {
            if (option.isOptgroup) {
              return <optgroup key={ option.label + index } label={ option.label } />;
            }
            return (
              <option value={ !disabled ? this.getOptionValue(option) : placeholder }>
                { !disabled ? this.getOptionLabel(option) : placeholder }
              </option>
            );
          }) }
        </select>
      </label>
    );
  }
}

export default InputSelect;
