import { connect } from 'react-redux';
import { selectActiveDivisionId } from 'state/app/selectors';
import { selectMembers } from 'state/members/selectors';
import { selectProgramMembers, selectProgramMemberIsFetching } from 'state/programMembers/selectors';
import { initDetailedMemberView } from 'state/leagueMembers/actions';
import { selectAndSortProgramMembershipsByProgramMemberId } from 'state/programMemberships/selectors';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';
import { selectRootDivisionPreferences } from 'state/teamsnap/divisionsPreferences/selectors';
import { showModal } from 'state/modal/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import DetailedMemberView from '../components/detailedMemberView/DetailedMemberView';

const mapDispatchToProps = {
  showModal,
  initDetailedMemberView,
  analyticEvent,
};

const mapStateToProps = (state, ownProps) => ({
  activeDivisionId: selectActiveDivisionId(state),
  params: ownProps.params,
  programMember: selectProgramMembers(state)[ownProps.params.programMemberId] || {},
  isFetching: selectProgramMemberIsFetching(state),
  members: selectMembers(state),
  programMemberships: selectAndSortProgramMembershipsByProgramMemberId(state)[ownProps.params.programMemberId] || [],
  rootDivisionId: selectActiveDivisionRootId(state),
  rootDivisionPreferences: selectRootDivisionPreferences(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailedMemberView);
