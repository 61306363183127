import _parseInt from 'lodash/parseInt';
import { selectProgramMembersByPage } from 'state/leagueMembers/selectors';
import { selectActiveDivisionId } from 'state/app/selectors';
import { loadProgramMemberships } from 'state/programMemberships/actions';
import { loadProgramMembers, loadSingleProgramMember } from 'state/programMembers/actions';
import { loadMembers } from 'state/members/actions';
import { loadMemberRegistrationSignups } from 'state/memberRegistrationSignups/actions';
import { loadRegistrationForms } from 'state/registrationForms/actions';
import { loadProgramMemberPhotos } from 'state/programMemberPhotos/actions';
import * as constants from './constants';

export const saveProgramMemberIdsForPage = (currentPage, programMemberIds) => dispatch =>
  dispatch({
    type: constants.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE,
    payload: {
      currentPage,
      programMemberIds,
    },
  });

export const clearCachedPages = () => dispatch =>
  dispatch({
    type: constants.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE,
  });

export const saveListViewCurrentPage = (currentPage, programMemberIds) => dispatch =>
  dispatch({
    type: constants.LIST_VIEW_SET_CURRENT_PAGE,
    payload: {
      currentPage,
      programMemberIds,
    },
  });
export const setListViewTotalProgramMemberCount = totalProgramMemberCount => dispatch =>
  dispatch({
    type: constants.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT,
    payload: {
      totalProgramMemberCount,
    },
  });
export const setListViewTotalPages = totalPages => dispatch =>
  dispatch({
    type: constants.LIST_VIEW_SET_TOTAL_PAGES,
    payload: {
      totalPages,
    },
  });

export const saveListViewSearchFilters = searchFilters => dispatch =>
  dispatch({
    type: constants.LIST_VIEW_SEARCH_FILTERS,
    payload: {
      searchFilters,
    },
  });

function calculateTotalPages({ totalItems = 1, pageSize = 1 }) {
  return Math.ceil(totalItems / pageSize);
}

// loads members and when that resolves, saves their ids, recalculates total number of pages,
// and then loads the memberships
export const loadProgramMembersByPage = (currentPage, searchParams) => (dispatch, getState) => {
  const activeDivision = selectActiveDivisionId(getState());
  const params = {
    divisionId: activeDivision,
    pageSize: 50,
    pageNumber: currentPage,
    ...searchParams,
  };

  dispatch(saveListViewSearchFilters(searchParams));

  return Promise.all([
    dispatch(loadProgramMembers(params, { fullResponse: true })).then(({ items: programMembers, pageInfo }) => {
      const programMemberIds = programMembers.map(member => member.id);

      dispatch(saveProgramMemberIdsForPage(currentPage, programMemberIds));
      dispatch(setListViewTotalPages(calculateTotalPages(pageInfo)));
      dispatch(setListViewTotalProgramMemberCount(pageInfo.totalItems));
      return dispatch(loadProgramMemberships({ programMemberId: programMemberIds }));
    }),
  ]);
};

// a caching mechanism to not reload a page if you've already loaded it
export const loadProgramMembersForPage = (currentPage, searchParams) => (dispatch, getState) => {
  if (!selectProgramMembersByPage(getState(), currentPage)) {
    return dispatch(loadProgramMembersByPage(currentPage, searchParams));
  }
  return Promise.resolve();
};

export const initDetailedMemberView = params => dispatch =>
  Promise.all([
    dispatch(loadSingleProgramMember(_parseInt(params.programMemberId))),
    dispatch(loadProgramMemberPhotos({ programMemberId: params.programMemberId })),
    dispatch(loadProgramMemberships({ programMemberId: params.programMemberId })).then(result => {
      const ids = result.map(membership => membership.memberId);
      return Promise.all([
        dispatch(loadMembers({ id: ids })),
        dispatch(loadMemberRegistrationSignups({ member_id: ids })).then(r => {
          const registrationFormIds = r.map(regSignup => regSignup.registrationFormId);
          return dispatch(loadRegistrationForms({ id: registrationFormIds }));
        }),
      ]);
    }),
  ]);
