import React, { PureComponent } from 'react';
import _parseInt from 'lodash/parseInt';
import PropTypes from 'prop-types';
import { Modal } from 'shared/toolkit';
import { Button } from '@teamsnap/teamsnap-ui';
import { SUCCESS, ERROR } from 'shared/toolkit/Feedback/Feedback';
import styles from './TeamsAndDivisionsModal.module.scss';
import { DivisionsAndTeamsDropdown } from './DivisionsAndTeamsDropdown';

export default class TeamsAndDivisionsModal extends PureComponent {
  state = {
    disableButton: false,
    selectedDivisionId: this.props.divisionTree[0].id,
    selectedTeamId: null,
    isPlayer: true,
    isManager: false,
  };

  getDivisions = () => {
    const divisionChildren = this.props.divisionTree[0].childDivisions;
    const recurse = (divisions, options = [], level = 1) => {
      divisions.forEach(division => {
        // insert spaces and dashes before division name
        const label = `\xA0\xA0 ${Array(level).join('-')} ${division.name}`;
        options.push({ label, value: division.id });
        if (division.childDivisions.length) {
          return recurse(division.childDivisions, options, level + 1);
        }
      });
      return options;
    };
    const activeDivision = this.props.divisionTree[0];
    return [{ label: activeDivision.name, value: activeDivision.id }, ...recurse(divisionChildren)];
  };

  getTeams = () => {
    const sortedTeams = this.props
      .teamsByDivision(this.state.selectedDivisionId)
      .sort((a, b) => a.name.localeCompare(b.name));
    return [...sortedTeams];
  };

  addToTeamParams = () => {
    const { selectedDivisionId, selectedTeamId, isManager, isPlayer } = this.state;
    const { id, activeDivisionId } = this.props;
    const params = {
      programMemberId: id,
      targetDivisionId: selectedDivisionId,
      sourceDivisionId: activeDivisionId,
      roles: { is_manager: isManager, is_player: isPlayer },
    };
    if (selectedTeamId) {
      params.teamId = selectedTeamId;
    }
    return params;
  };

  successFeedback = () => ({
    title: this.props.successMessage,
    type: SUCCESS,
  });

  failureFeedback = () => ({
    title: this.props.failureMessage,
    type: ERROR,
  });

  triggerCreateMembership = () => {
    const { createNewProgramMembership, showFeedback } = this.props;
    const successFeedback = this.successFeedback();
    const failureFeedback = this.failureFeedback();
    const params = this.addToTeamParams();
    this.sendAnalytics();
    return createNewProgramMembership(params)
      .then(() => {
        showFeedback(successFeedback);
        return Promise.all(this.itemsToReload(params));
      })
      .catch(() => showFeedback(failureFeedback));
  };

  sendAnalytics = () => {
    // need to have if statements to return roles here because GA doesn't evaluate function before firing event.
    const { isPlayer, isManager } = this.state;

    let role = '';
    if (isPlayer && isManager) {
      role = 'manager_player_selected';
    }
    if (!isPlayer && isManager) {
      role = 'manager_selected';
    }
    if (isPlayer && !isManager) {
      role = 'default_player_selected';
    }
    if (!isPlayer && !isManager) {
      role = 'none_selected';
    }

    const event = {
      eventCategory: 'members_tab',
      eventAction: 'add_to_team_role',
      eventLabel: role,
    };
    this.props.analyticEvent(event);
  };

  itemsToReload = ({ programMemberId }) => {
    const { loadMembers, loadProgramMemberships, origin, loadSingleProgramMember } = this.props;
    const items = [
      loadProgramMemberships({ programMemberId }).then(result => {
        const ids = result.map(membership => membership.memberId);
        loadMembers({ id: ids });
      }),
    ];
    if (origin === 'League Members Table') {
      items.push(loadSingleProgramMember(programMemberId));
    }
    return items;
  };

  triggerTransferMembership = () => {
    const { showFeedback, loadProgramMemberships, moveMember, id, programMembership, loadMembers } = this.props;
    const { selectedDivisionId, selectedTeamId } = this.state;
    const successFeedback = this.successFeedback();
    const failureFeedback = this.failureFeedback();
    const teamId = selectedTeamId > 0 ? selectedTeamId : null;

    return moveMember(programMembership.memberId, selectedDivisionId, teamId)
      .then(() => {
        showFeedback(successFeedback);
        return Promise.all([
          loadProgramMemberships({ programMemberId: id }),
          loadMembers({ id: programMembership.memberId }),
        ]);
      })
      .catch(() => showFeedback(failureFeedback));
  };

  sendNewMembershipRequest = () => {
    switch (this.props.type) {
      case 'Add':
        return this.triggerCreateMembership();
      case 'Transfer':
        return this.triggerTransferMembership();
      default:
        break;
    }
  };

  handleConfirm = () => {
    const { hideModal } = this.props;
    hideModal();
    this.sendNewMembershipRequest();
    this.setState({
      disableButton: true,
      selectedTeamId: null,
    });
  };

  handleClose = () => {
    this.props.hideModal();
  };

  noTeamSelected = () => {
    this.setState({ selectedTeamId: null });
  };

  teamSelected = selectedTeamId => {
    this.setState({ selectedTeamId });
  };

  // Allows for toggling back and forth of team selected or unrostered in division
  selectTeam = selectedTeamId => {
    selectedTeamId !== 'Unrostered / No Team' ? this.teamSelected(_parseInt(selectedTeamId)) : this.noTeamSelected();
  };

  selectDivision = selectedDivisionId => {
    this.setState({ selectedDivisionId });
  };

  handleClick = event => {
    const { name, checked } = event.target;
    this.setState({
      [name]: checked,
    });
  };

  render() {
    const { disableButton } = this.state;
    const { hideModal, title, confirmationButtonText, warningMessage, type } = this.props;

    return (
      <Modal handleClose={ hideModal } title={ title } componentStyles={ styles } overlay>
        <div className={ styles.ModalBody }>
          { type === 'Add' && (
            <div className="u-spaceBottomMd u-block">
              Copy basic player and contact information into a new roster profile and add this new profile to another
              team/division. Learn more{ ' ' }
              <a href="https://helpme.teamsnap.com/article/1097-managing-organization-members#additional-team-division">
                here
              </a>
              .
            </div>
          ) }
          { warningMessage && <small className="u-spaceBottomMd u-block">{ warningMessage }</small> }
          <DivisionsAndTeamsDropdown
            divisions={ this.getDivisions() }
            teams={ this.getTeams() }
            selectDivision={ this.selectDivision }
            selectTeam={ this.selectTeam }
            selectedDivisionId={ this.state.selectedDivisionId }
            selectedTeamId={ this.state.selectedTeamId }
          />
          { type === 'Add' && (
            <div>
              <label className="FieldGroup-label" htmlFor="select-1">
                Select Roles
              </label>
              <div className="Checkbox Checkbox--inline">
                <input
                  className="u-padRightXs Checkbox-input Checkbox--inline Checkbox--large"
                  aria-checked="true"
                  tabIndex="0"
                  type="checkbox"
                  id="checkbox-inline-large-1"
                  checked={ this.state.isPlayer }
                  name="isPlayer"
                  onChange={ this.handleClick }
                />
                <label className="Checkbox-label" htmlFor="checkbox-inline-large-1">
                  Player
                </label>
              </div>
              <div className="Checkbox Checkbox--inline">
                <input
                  className="Checkbox-input"
                  type="checkbox"
                  id="checkbox-inline-large-2"
                  tabIndex="0"
                  name="isManager"
                  checked={ this.state.isManager }
                  onChange={ this.handleClick }
                />
                <label className="Checkbox-label" htmlFor="checkbox-inline-large-2">
                  Manager
                </label>
              </div>
            </div>
          ) }
        </div>
        <footer className={ styles.ModalFooter }>
          <Button
            label="Cancel"
            color="negative"
            icon="dismiss"
            mods="u-spaceRightSm"
            onClick={ this.handleClose }
            isDisabled={ disableButton }
          />
          <Button
            label={ confirmationButtonText }
            color="primary"
            icon="check"
            onClick={ this.handleConfirm }
            isDisabled={ disableButton }
          />
        </footer>
      </Modal>
    );
  }
}

TeamsAndDivisionsModal.propTypes = {
  divisionTree: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  showFeedback: PropTypes.func,
  teamsByDivision: PropTypes.func.isRequired,
  createNewProgramMembership: PropTypes.func.isRequired,
  activeDivisionId: PropTypes.number.isRequired,
  loadProgramMemberships: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  confirmationButtonText: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  failureMessage: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  moveMember: PropTypes.func.isRequired,
  programMembership: PropTypes.object,
  warningMessage: PropTypes.string,
  loadMembers: PropTypes.func.isRequired,
  origin: PropTypes.string,
  loadSingleProgramMember: PropTypes.func.isRequired,
  analyticEvent: PropTypes.func.isRequired,
};

TeamsAndDivisionsModal.defaultProps = {
  showFeedback: null,
  origin: null,
  programMembership: null,
  warningMessage: null,
};
