"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FileService = exports.FileType = void 0;
/**
 * @enum FileType
 * This enum provides the different file types for generating files on the browser
 */
var FileType;
(function (FileType) {
  FileType[FileType["CSV"] = 0] = "CSV";
})(FileType = exports.FileType || (exports.FileType = {}));
/**
 * @class FileService
 * used to work with Dates, leverages INTL methods (browser only) and should be used instead of moment or time.js
 */
var FileService = /** @class */function () {
  function FileService() {}
  FileService.create = function (data, fileType) {
    switch (fileType) {
      case FileType.CSV:
        return new Blob([this.makeCSV(this.buildCSVContent(data))], {
          type: 'text/csv;charset=UTF-8'
        });
      default:
        return null;
    }
  };
  FileService.buildCSVContent = function (data) {
    var colHeaderNames = Object.keys(data[0]);
    var csvData = [];
    // Build Column Header Names
    csvData.push(colHeaderNames);
    // Build column/ row data
    csvData.push.apply(csvData, data.map(function (row) {
      // row is an object so turn the values into an array
      var rowData = Object.values(row);
      // Escape double quotes so we can make sure we do not have any issue with columns
      // that have multiple commas inside one cell
      var escapedData = rowData.map(function (col) {
        return "\"".concat(col.toString().replace(/"/g, '\\"'), "\"");
      });
      return escapedData;
    }));
    return csvData;
  };
  FileService.makeCSV = function (data) {
    return data.map(function (row) {
      return row.join(',');
    }).join('\n');
  };
  return FileService;
}();
exports.FileService = FileService;