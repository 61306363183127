import { loadItems } from 'state/teamsnap/actions';

const loadBatchInvoices = params => dispatch => {
  dispatch(loadItems('batchInvoices', params));
};

const loadBatchInvoicesByDivisionId = divisionId => dispatch => {
  dispatch(loadBatchInvoices({ divisionId }));
};

export { loadBatchInvoices, loadBatchInvoicesByDivisionId };
