import React, { Component } from 'react';
import { Panel, PanelHeader, PanelRow, PanelBody, Table } from '@teamsnap/teamsnap-ui';
import {
  linkRegistrationForm,
  linkLeagueRosterMember,
  linkLeagueRosterMemberEdit,
  linkTeamRoster,
  linkRosterInvite,
} from 'utils/links';
import PropTypes from 'prop-types';
import { Popover, PopoverItem } from 'shared/toolkit';
import DeleteMembershipModal from '../../containers/DeleteMembershipModalContainer';
import TeamsAndDivisionsModal from '../../containers/TeamsAndDivisionsModalContainer';
import './TeamsAndRegistrationsTable.scss';

export default class TeamsAndRegistrationTable extends Component {
  static propTypes = {
    programMemberships: PropTypes.array.isRequired,
    rootDivisionId: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
    programMember: PropTypes.object.isRequired,
    analyticEvent: PropTypes.func.isRequired,
  };

  getRows = programMemberships =>
    programMemberships.map(membership => ({
      team: this.displayTeamsAndDivisions(membership),
      role: this.displayRole(membership),
      registration: this.displayRegistrationForm(membership),
      actions: this.membershipSnapActions(membership),
    }));

  displayRegistrationForm = programMembership => {
    if (this.memberHasRegistration(programMembership)) {
      const { registration } = programMembership;
      const { name } = registration.registrationForm;
      return <a href={ this.registrationFormLink(registration) }>{ name }</a>;
    }
    return '-';
  };

  memberHasRegistration = ({ registration }) => {
    if (registration && registration.registrationForm) {
      return true;
    }
  };

  registrationFormLink = ({ registrationFormId, registrationSignupId }) => {
    const { rootDivisionId } = this.props;
    return linkRegistrationForm(rootDivisionId, registrationFormId, registrationSignupId);
  };

  displayRole = ({ roles }) => (roles.includes('Player') && roles.includes('Non-Player') ? 'Player' : roles[0]);

  canDeleteMembership = ({ roles }) =>
    !roles.includes('Commissioner') && !roles.includes('League Owner') && !roles.includes('Team Owner');

  canTransferMembership = ({ roles }) => !roles.includes('Commissioner') && !roles.includes('League Owner');

  isInvitable = ({ member }) => {
    const { userId, isOwner } = member;
    if (!userId) {
      return true;
    }
    return false;
  };

  hasBeenInvited = ({ member }) => {
    const { userId, invitationCode } = member;
    if (invitationCode && !userId) {
      return true;
    }
    return false;
  };

  membershipSnapActions = programMembership => {
    const staticActionItems = [
      <PopoverItem
        className="GI-snapView"
        id={ `${programMembership.id} view` }
        key={ `${programMembership.id} view` }
        link={ linkLeagueRosterMember(programMembership.divisionId, programMembership.memberId) }
        icon="preview"
        text="View"
        callBack={ () => this.sendSnapActionAnalytics('view') }
      />,
      <PopoverItem
        className="GI-snapEdit"
        id={ `${programMembership.id} edit` }
        key={ `${programMembership.id} edit` }
        link={ linkLeagueRosterMemberEdit(programMembership.divisionId, programMembership.memberId) }
        icon="edit"
        text="Edit"
        callBack={ () => this.sendSnapActionAnalytics('edit') }
      />,
    ];
    const conditionalActionItems = this.conditionalActionItems(programMembership);

    return <Popover items={ [...staticActionItems, ...conditionalActionItems] } />;
  };

  conditionalActionItems = programMembership => {
    const actionItems = [];
    if (this.canTransferMembership(programMembership)) {
      actionItems.push(
        <PopoverItem
          className="GI-snapTransfer"
          id={ `${programMembership.id} transfer` }
          key={ `${programMembership.id} transfer` }
          callBack={ () => this.showTransferModal(programMembership) }
          icon="rostering"
          text="Transfer"
          isModalTrigger
        />,
      );
    }
    if (this.memberHasRegistration(programMembership)) {
      actionItems.push(
        <PopoverItem
          className="GI-snapManageRegistration"
          id={ `${programMembership.id} registration` }
          key={ `${programMembership.id} registration` }
          link={ this.registrationFormLink(programMembership.registration) }
          icon="registration"
          text="Manage Registration"
          callBack={ () => this.sendSnapActionAnalytics('manage_registration') }
        />,
      );
    }
    if (programMembership.teamId) {
      actionItems.push(
        <PopoverItem
          className="GI-snapViewOnTeam"
          id={ `${programMembership.id} team` }
          key={ `${programMembership.id} team` }
          link={ linkTeamRoster(programMembership.teamId) }
          icon="team"
          text="View on Team"
          callBack={ () => this.sendSnapActionAnalytics('view_on_team') }
        />,
      );
    }
    if (this.canDeleteMembership(programMembership)) {
      actionItems.push(
        <PopoverItem
          className="GI-snapDelete"
          id={ `${programMembership.id} delete` }
          key={ `${programMembership.id} delete` }
          callBack={ () => this.showDeleteModal(programMembership) }
          icon="trash"
          text="Delete"
          isModalTrigger
        />,
      );
    }
    return actionItems;
  };

  showDeleteModal = programMembership => {
    const { showModal, programMember } = this.props;
    this.sendSnapActionAnalytics('delete');
    return showModal(DeleteMembershipModal, {
      programMember,
      programMembership,
    });
  };

  showTransferModal = programMembership => {
    const { showModal, programMember } = this.props;
    const modalProps = {
      title: `Transfer ${programMember.firstName} to Team / Division`,
      pageOrigin: 'detailedMemberView',
      confirmationButtonText: 'Transfer to Team/Division',
      successMessage: 'Member transferred!',
      failureMessage: 'Member failed to transfer.',
      // eslint-disable-next-line max-len
      warningMessage:
        'WARNING:  All statistics, availabilities, payments and other team-specific information will be deleted when a player is transferred to another team or unassigned.  Proceed with caution.',
      type: 'Transfer',
      id: programMember.id,
      programMembership,
    };
    this.sendSnapActionAnalytics('transfer');
    return showModal(TeamsAndDivisionsModal, modalProps);
  };

  displayTeamsAndDivisions = programMembership => {
    const { teamName, divisionName, member } = programMembership;
    return (
      <div>
        <p>{ teamName }</p>
        <small>({ divisionName })</small>
        { this.isInvitable(programMembership) && (
          <div>
            <a
              className="Button Button--primary Button--small u-spaceTopSm"
              href={ linkRosterInvite(member.divisionId, member.id) }
              target="_blank"
              rel="noopener noreferrer"
              onClick={ () => this.sendInviteAnalytics(programMembership) }
            >
              { this.hasBeenInvited(programMembership) ? 'Resend Invite' : 'Invite to Join' }
            </a>
          </div>
        ) }
      </div>
    );
  };

  sendInviteAnalytics = programMembership => {
    const event = {
      eventCategory: 'detailed_member_view',
      eventAction: 'invite',
      eventLabel: this.hasBeenInvited(programMembership) ? 'resend_invite' : 'invite_to_join',
    };
    this.props.analyticEvent(event);
  };

  sendSnapActionAnalytics = snapAction => {
    const event = {
      eventCategory: 'detailed_member_view',
      eventAction: 'snap_actions',
      eventLabel: snapAction,
    };
    this.props.analyticEvent(event);
  };

  render() {
    return (
      <Panel>
        <PanelHeader title="Current Teams and Registrations" />
        <PanelBody>
          <PanelRow mods="u-padNone">
            <Table
              columns={ [
                {
                  name: 'team',
                  label: 'Team',
                  mods: 'u-size8of24 u-flexGrow0',
                },
                {
                  name: 'role',
                  label: 'Role',
                  mods: 'u-size4of24 u-flexGrow0',
                },
                {
                  name: 'registration',
                  label: 'Registration',
                  mods: 'u-sizeFill',
                },
                {
                  name: 'actions',
                  label: 'Actions',
                  mods: 'u-size3of24 u-flexGrow0',
                  align: 'center',
                },
              ] }
              rows={ this.getRows(this.props.programMemberships) }
              mods="u-borderNone u-spaceNone"
            />
          </PanelRow>
        </PanelBody>
      </Panel>
    );
  }
}
