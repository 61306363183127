/* eslint no-underscore-dangle: 0 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideModal } from 'state/modal/actions';
import { Button } from '@teamsnap/teamsnap-ui';
import defaultStyles from '../Modal/Modal.module.scss';

class ModalConfirm extends Component {
  componentDidMount() {
    window.__app_root.addEventListener('keydown', this.handleKeydown, true);
    window.__app_root.addEventListener('click', this.handleDocumentClick, false);
    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    window.__app_root.removeEventListener('keydown', this.handleKeydown, true);
    window.__app_root.removeEventListener('click', this.handleDocumentClick, false);
    document.body.classList.remove('modal-open');
  }

  closeModal() {
    this.props.closeModal();
  }

  handleKeydown = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.closeModal();
    }
  };

  handleDocumentClick = e => {
    const modalArea = this.modal;

    if (!modalArea.contains(e.target)) {
      this.closeModal();
    }
  };

  handleOverlayClick = () => {
    this.closeModal();
  };

  handleConfirm = () => {
    this.props.confirmFunction();
    this.props.closeModal();
  };

  renderInputOptions = inputOptions =>
    inputOptions.map((input, index) => (
      <div>
        <input
          type={ input.type }
          id={ input.type + '-' + index }
          tabIndex="0"
          name={ input.name }
          checked={ input.value === true ? true : null }
          onChange={ () => input.onChange() }
        />
        <label htmlFor={ input.type + '-' + index } style={ { paddingLeft: '5px' } }>
          { input.label }
        </label>
        <br/><br/>
      </div>
    ))

  renderModalText = (styles, text) => {
    if (typeof text === 'function') {
      return <div className={ styles.ModalBody }>{ text() }</div>;
    }

    return <div className={ styles.ModalBody } dangerouslySetInnerHTML={ { __html: text } } />;
  }

  render() {
    const {
      title,
      text,
      overlay,
      componentStyles,
      className,
      confirmCancelButtonText,
      confirmButtonText,
      hideCancel,
      inputOptions,
    } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <div
        ref={ modal => {
          this.modal = modal;
        } }
        className={ styles[className] }
      >
        { overlay && <div className={ styles.ModalOverlay } onClick={ this.handleOverlayClick } role="presentation" /> }
        <div className={ styles.ModalContainer }>
          <div className={ styles.ModalContent }>
            <h3 className={ styles.ModalHeader }>{ title }</h3>

            { this.renderModalText(styles, text) }

            <div className={ styles.ModalBody }>
              { this.renderInputOptions(inputOptions) }
            </div>
            <footer className={ styles.ModalFooter }>
              { !hideCancel && (
                <Button className="Button Button--negative u-spaceRightSm" onClick={ this.props.closeModal }>
                  { confirmCancelButtonText }
                </Button>
              ) }
              <Button className="Button Button--primary" onClick={ this.handleConfirm }>
                { confirmButtonText }
              </Button>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

ModalConfirm.propTypes = {
  confirmFunction: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  confirmButtonText: PropTypes.string,
  confirmCancelButtonText: PropTypes.string,
  handleClose: PropTypes.func,
  overlay: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  hideCancel: PropTypes.bool,
  inputOptions: PropTypes.arrayOf(PropTypes.object),
};

ModalConfirm.defaultProps = {
  confirmButtonText: 'Confirm',
  confirmCancelButtonText: 'Cancel',
  handleClose: null,
  overlay: false,
  componentStyles: {},
  className: 'Modal',
  hideCancel: false,
  inputOptions: [],
};

export default connect(null, {
  closeModal: hideModal,
})(ModalConfirm);
