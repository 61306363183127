import _values from 'lodash/values';
import { memberSorts } from 'utils/sorts';
import { createSelector } from 'reselect';
import initialState from 'state/initialState';
import { selectLoaded, selectLoading } from 'state/loading/selectors';
import * as DateService from 'utils/date-service';

import { selectActiveDivisionId } from 'state/app/selectors';

import { selectDivisionById, selectDivisions, selectActiveDivision } from 'state/teamsnap/divisions/selectors';

import { selectTeamNamesById } from 'state/teamsnap/teamNames/selectors';

import {
  selectMembersAndDivisionsByDivisionId,
  selectMembersAndTeamsByTeamId,
  selectMembersAndTeamsByTeamIdAndPendingTeamId,
  linkTeamsToMembers,
  selectMembersById,
  selectMembersByPendingTeamId,
} from 'state/teamsnap/members/selectors';

import { selectRegistrationForms, selectRegistrationFormById } from 'state/teamsnap/registrationForms/selectors';
import {
  selectRegistrationLineItemsByFormId,
  selectRegistrationFormLineItems,
} from 'state/teamsnap/registrationFormLineItems/selectors';
import {
  selectRegistrationFormLineItemOptions,
  selectRegistrationLineItemOptionsByLineItemId,
} from 'state/teamsnap/registrationFormLineItemOptions/selectors';

import * as constants from './constants';

const selectActiveSavedFilterId = state => state.leagueRostering.data.activeSavedFilterId;

const selectCheckoutTypes = () => constants.CHECKOUT_TYPES;

const selectCountAssignedToDivision = state => divisionId => {
  const counts = state.leagueRostering.data.countAssignedToDivision;
  return counts[divisionId] || 0;
};

const selectCountAssignedToTeam = state => teamId => {
  const counts = state.leagueRostering.data.countAssignedToTeam;
  return counts[teamId] || 0;
};

const selectDefaultRosterLoadCount = state => state.leagueRostering.data.defaultLoadCount;

const selectFiltersById = state => state.leagueRostering.data.filters || {};

const selectFilters = state => _values(selectFiltersById(state));

const selectFilterNames = state => selectFilters(state).map(filter => filter.search_title);

const selectCurrentFilter = state => state.leagueRostering.data.currentFilter;

const selectDivisionRosterCount = state => divisionId =>
  state.leagueRostering.data.divisionRosterCount[divisionId] || 0;

const selectActiveDivisionRosterCount = state => {
  const activeDivisionId = selectActiveDivisionId(state);
  return selectDivisionRosterCount(state)(activeDivisionId);
};

const selectFilteredMemberIds = state => state.leagueRostering.data.filteredMemberIds;

const selectFilteredMembersCountNoCommissioners = state => selectFilteredMemberIdsNoCommissioners(state).length;

const selectFilteredMemberIdsNoCommissioners = state => {
  const filteredMembers = _values(selectFilteredMembers(state));
  const filteredMembersNoCommissioners = filteredMembers.filter(member => !member.isCommissioner);
  return filteredMembersNoCommissioners.map(member => member.id);
};

const selectFilteredMembers = state => {
  const filteredMemberIds = selectFilteredMemberIds(state);
  return selectMembersById(state, filteredMemberIds);
};

const selectFilteredMembersAndTeams = state => {
  const filteredMembers = selectFilteredMembers(state);
  return linkTeamsToMembers(state, filteredMembers);
};

const selectAndSortFilteredMembersAndTeams = state => {
  const members = selectFilteredMembersAndTeams(state);
  return memberSorts(members, selectRosterListSortOn(state), selectRosterListSortReverse(state));
};

const selectAndSortRosterMembersByDivisionId = state => divisionId => {
  const members = selectMembersAndDivisionsByDivisionId(state, divisionId);
  return memberSorts(members, selectRosterListSortOn(state), selectRosterListSortReverse(state));
};

const selectAndSortRosterMembersByTeamId = state => teamId => {
  const members = selectMembersAndTeamsByTeamId(state, teamId);
  return memberSorts(members, selectRosterListSortOn(state), selectRosterListSortReverse(state));
};

const selectFilteredPlayersCount = state => state.leagueRostering.data.filteredMemberIds.length;

const selectHasFilters = state => {
  let hasFilters = false;
  const currentFilter = selectCurrentFilter(state);
  const defaultFilter = initialState.leagueRostering.data.currentFilter;

  const { advancedFilters } = currentFilter;
  if (advancedFilters && advancedFilters['0'] && advancedFilters['0'].value !== null) {
    hasFilters = true;
  }
  if (!hasFilters) {
    Object.keys(currentFilter).some(field => {
      if (field !== 'advancedFilters' && !defaultFilter[field]) {
        hasFilters = true;
        return true;
      }
    });
  }
  return hasFilters;
};

const selectHasLoadedMembersForTeamId = (state, teamId) => {
  const loadedMemberForTeamIds = selectLoadedMemberTeamIds(state);
  return loadedMemberForTeamIds.indexOf(teamId) > -1;
};

const selectHasUnappliedSearchFilters = state => state.leagueRostering.data.currentFilter.hasUnappliedFilters || null;

const selectIsLoadingMembersForTeamId = (state, teamId) =>
  state.leagueRostering.data.loadingMembersForTeamId[teamId] || false;

const selectIsSearching = state => state.leagueRostering.data.isSearching;

const selectIsTeamIdShowingMembers = (state, teamId) => {
  const teamIdsShown = selectShowTeamMembersForTeamId(state);
  return teamIdsShown.indexOf(teamId) > -1;
};

const selectLoadedMemberTeamIds = state => state.leagueRostering.data.loadedMembersForTeamId;

const selectLoadingLeagueRoster = state => selectLoading(state, 'leagueRostering');

const selectLeagueRosteringLoaded = state => selectLoaded(state, 'leagueRostering');

const selectOpenedMemberSearchPopupId = state => state.leagueRostering.data.openedMemberSearchPopupId;

const selectSelectedMembers = state => {
  const selectedMemberIds = selectSelectedMemberIds(state);
  return selectMembersById(state, selectedMemberIds);
};

const selectSelectedMembersCount = state => selectSelectedMemberIds(state).length;

const selectSelectedMembersCountNoCommissioners = state => selectSelectedMemberIdsNoCommissioners(state).length;

const selectSelectedMemberIds = state => state.leagueRostering.data.selectedMemberIds;

const selectSelectedMemberIdsNoCommissioners = state => {
  const selectedMembers = _values(selectSelectedMembers(state));
  const selectedMembersNoCommissioners = selectedMembers.filter(member => !member.isCommissioner);
  return selectedMembersNoCommissioners.map(member => member.id);
};

const selectIsMemberSelected = state => memberId => {
  const selectedMemberIds = selectSelectedMemberIds(state);
  return selectedMemberIds.indexOf(memberId) > -1;
};

export const selectListSearchResults = state => state.leagueRostering.data.filteredMemberIds;

export const selectListCheckedMemberIds = state => state.leagueRostering.data.selectedMemberIds;

export const selectListCheckedAllMembers = state => state.leagueRostering.data.selectedAllMembers;

export const selectListBulkAction = state => state.leagueRostering.data.bulkAction;

export const selectListBulkActionDivisionId = state => state.leagueRostering.data.bulkActionDivisionId;

export const selectListBulkActionTeamId = state => state.leagueRostering.data.bulkActionTeamId;

export const selectListCheckedMembersCount = state => {
  const checkedMemberIds = selectListCheckedMemberIds(state);
  return Object.keys(checkedMemberIds).reduce((prev, id) => prev + (checkedMemberIds[id] === true ? 1 : 0), 0);
};

export const selectBulkActionDivisionId = state => state.leagueRostering.data.bulkActionDivisionId;

export const selectBulkActionTeamId = state => state.leagueRostering.data.bulkActionTeamId;

const selectRosterAssignmentView = state => state.leagueRostering.data.rosterAssignmentView;

const selectRosteringPendingMode = state => state.leagueRostering.data.pendingModeEnabled;

const selectRosterListDivisionId = state => state.leagueRostering.data.currentDivisionId;

const selectRosterListDivisionChildren = state => {
  const currentDivisionId = selectRosterListDivisionId(state);
  const divisions = selectDivisions(state);
  if (currentDivisionId === null) {
    // We're at the root, so show the root division structure
    const rootDivision = selectActiveDivision(state);
    return [rootDivision];
  }
  const children = [];
  Object.keys(divisions).forEach(divisionId => {
    const division = divisions[divisionId];
    if (division.parentId === currentDivisionId) {
      children.push(division);
    }
  });
  return children;
};

const selectRosterListSortOn = state => state.leagueRostering.data.sortOn;

const selectRosterListSortReverse = state => state.leagueRostering.data.sortReverse;

const selectSignupStatuses = () => constants.SIGNUP_STATUSES;

const selectShowTeamMembersForTeamId = state => state.leagueRostering.data.showTeamMembersForTeamId;

const selectSearchParams = state => state.searchParams;

const selectSelectedDivisionId = state => state.leagueRostering.data.currentFilter.divisionId;

const selectDivisionOrTeamFilters = state => {
  const currentFilter = selectCurrentFilter(state);

  const getDivisionName = () => {
    const valueDisplayObj = {};
    const division = selectDivisionById(state, currentFilter.divisionId);

    if (currentFilter.divisionId && Number.isInteger(parseInt(currentFilter.divisionId, 10)) && division) {
      valueDisplayObj[currentFilter.divisionId] = division.name;
    }

    return valueDisplayObj;
  };

  const getTeamName = () => {
    const team = selectTeamNamesById(state, currentFilter.teamId);
    const valueDisplayObj = {};

    if (currentFilter.teamId && Number.isInteger(parseInt(currentFilter.teamId, 10)) && team) {
      valueDisplayObj[currentFilter.teamId] = selectTeamNamesById(state, currentFilter.teamId).name;
    }

    return valueDisplayObj;
  };

  return {
    divisionId: {
      value: currentFilter.divisionId || '',
      valueDisplay: getDivisionName(),
    },
    teamId: {
      value: currentFilter.teamId || '',
      valueDisplay: getTeamName(),
    },
  };
};

const selectMemberInfoFilters = state => {
  const currentFilter = selectCurrentFilter(state);

  const formatMemberName = currentFilter => {
    const name = [];

    if (currentFilter.first_name) {
      name.push(currentFilter.first_name);
    }
    if (currentFilter.last_name) {
      name.push(currentFilter.last_name);
    }

    return name.join(' ');
  };

  return {
    memberName: {
      value: formatMemberName(currentFilter),
    },
    gender: {
      value: currentFilter.gender || '',
      valueDisplay: {
        female: 'Female',
        male: 'Male',
      },
    },
    dates: {
      value:
        DateService.isValidDate(new Date(currentFilter.born_before_date)) ||
        DateService.isValidDate(new Date(currentFilter.born_after_date)),
      valueDisplay: {
        true: DateService.formatDateRange(currentFilter.born_after_date, currentFilter.born_before_date),
        false: '',
      },
    },
  };
};

const selectMemberStatusFilters = state => {
  const currentFilter = selectCurrentFilter(state);
  return {
    hideRostered: {
      value: currentFilter.hideRostered || '',
      valueDisplay: {
        false: '', // we don't want a value to display in the pill for "false"
        true: 'Hide Rostered',
      },
    },
    pending_assignments: {
      value: currentFilter.pending_assignments || '',
      valueDisplay: {
        exclude: 'Hide Pending',
        include: '', // we don't want a value to display in the pill for "include"
      },
    },
  };
};

const selectCustomFieldFilters = state => {
  // get current filter state
  const currentFilter = selectCurrentFilter(state);

  // get all the keys of the current filter
  const keys = Object.keys(currentFilter.advancedFilters || {});

  // get all the customField keys from the list of keys
  const customFieldKeys = keys.filter(key => key.match(/custom_field/));

  // get all the values for those keys
  const customFieldValues = customFieldKeys.map(key => currentFilter.advancedFilters[key]);

  // filter out unset values
  const setCustomFieldValues = customFieldValues.filter(filter => filter.value !== '' && filter.value !== null);

  const displayText = state.leagueRostering.data.registrationVersion == 2 ? 'Additional Questions' : 'Custom Fields';

  return {
    customFieldsText: {
      value: setCustomFieldValues.length > 0,
      valueDisplay: {
        false: '',
        true: displayText,
      },
    },
    customFieldsCount: {
      value: setCustomFieldValues.length > 0,
      valueDisplay: {
        false: '',
        true: setCustomFieldValues.length,
      },
    },
  };
};

const selectRegistrationStatusFilters = state => {
  const currentFilter = selectCurrentFilter(state);
  const regFormId = currentFilter.registration_form_id;
  const regForm = selectRegistrationFormById(state, regFormId);
  const regFormLineItemId = currentFilter.registration_form_line_item_id;
  const regFormLineItem =
    Object.values(selectRegistrationFormLineItems(state)).filter(e => e.id === +regFormLineItemId)[0] || {};
  const regFormLineItemOptionId = currentFilter.registration_form_line_item_option_id;
  const regFormLineItemOption =
    Object.values(selectRegistrationFormLineItemOptions(state)).filter(e => e.id === +regFormLineItemOptionId)[0] || {};

  return {
    registration_form_id: {
      value: currentFilter.registration_form_id !== '',
      valueDisplay: {
        false: '',
        true: (regForm || {}).name,
      },
    },
    registration_form_line_item_id: {
      value: currentFilter.registration_form_line_item_id !== '',
      valueDisplay: {
        false: '',
        true: regFormLineItem.name,
      },
    },
    registration_form_line_item_option_id: {
      value: currentFilter.registration_form_line_item_option_id !== '',
      valueDisplay: {
        false: '',
        true: regFormLineItemOption.name,
      },
    },
  };
};

const selectParticipantGroupFilters = state => {
  const currentFilter = selectCurrentFilter(state);
  return {
    participantGroupText: {
      value: currentFilter?.participantGroups?.length > 0,
      valueDisplay: {
        false: '',
        true: `Participant Groups`,
      },
    },
    participantGroupCount: {
      value: currentFilter?.participantGroups?.length > 0,
      valueDisplay: {
        false: '',
        true: currentFilter?.participantGroups?.length,
      },
    },
  };
};

const selectCurrentRegistrationFormId = state => state.leagueRostering.data.currentFilter.registration_form_id;

const selectCurrentRegistrationFormOption =
  state => state.leagueRostering.data.currentFilter.registration_form_line_item_id;

const selectRegistrationFormsForDivision = state => selectRegistrationForms(state);

const selectRegistrationFormLineItemsForDivision = state => {
  const currentForm = selectCurrentRegistrationFormId(state);
  return selectRegistrationLineItemsByFormId(state)(currentForm);
};

const selectRegistrationFormLineItemOptionsForDivision = state => {
  const currentRegOption = selectCurrentRegistrationFormOption(state);
  return selectRegistrationLineItemOptionsByLineItemId(state)(currentRegOption);
};

// ---- RE-SELECT THAT UP ---- //

const reSelectAndSortFilteredMembersAndTeams = createSelector(
  [selectFilteredMembersAndTeams, selectRosterListSortOn, selectRosterListSortReverse],
  (members, sortOn, sortReverse) => memberSorts(members, sortOn, sortReverse),
);

const reSelectAndSortRosterMembersByDivisionId = createSelector(
  [selectMembersAndDivisionsByDivisionId, selectRosterListSortOn, selectRosterListSortReverse],
  (members, sortOn, sortReverse) => memberSorts(members, sortOn, sortReverse),
);

const reSelectAndSortRosterMembersByTeamId = createSelector(
  [selectMembersAndTeamsByTeamId, selectRosterListSortOn, selectRosterListSortReverse],
  (members, sortOn, sortReverse) => memberSorts(members, sortOn, sortReverse),
);

const reSelectAndSortRosterMembersByTeamIdAndPendingTeamId = createSelector(
  [selectMembersAndTeamsByTeamIdAndPendingTeamId, selectRosterListSortOn, selectRosterListSortReverse],
  (members, sortOn, sortReverse) => memberSorts(members, sortOn, sortReverse),
);

const selectPendingTeamMembersByTeamIdCount = (state, teamId) => {
  let count = 0;
  const pendingMembers = selectMembersByPendingTeamId(state);
  if (pendingMembers[teamId] !== undefined) {
    count = pendingMembers[teamId].length;
  }
  return count;
};

const selectPlayerTabActive = state => state.leagueRostering.data.playerTabActive;
const selectRegistrationVersion = state => state.leagueRostering.data.registrationVersion;

export {
  reSelectAndSortFilteredMembersAndTeams,
  reSelectAndSortRosterMembersByDivisionId,
  reSelectAndSortRosterMembersByTeamId,
  reSelectAndSortRosterMembersByTeamIdAndPendingTeamId,
  selectActiveSavedFilterId,
  selectAndSortFilteredMembersAndTeams,
  selectAndSortRosterMembersByDivisionId,
  selectAndSortRosterMembersByTeamId,
  selectCountAssignedToDivision,
  selectCountAssignedToTeam,
  selectCheckoutTypes,
  selectCurrentFilter,
  selectDefaultRosterLoadCount,
  selectDivisionRosterCount,
  selectDivisionOrTeamFilters,
  selectFilteredMemberIds,
  selectFilteredMemberIdsNoCommissioners,
  selectFilteredMembers,
  selectFilteredMembersAndTeams,
  selectFilteredMembersCountNoCommissioners,
  selectFilters,
  selectFiltersById,
  selectFilterNames,
  selectFilteredPlayersCount,
  selectHasFilters,
  selectHasLoadedMembersForTeamId,
  selectHasUnappliedSearchFilters,
  selectIsLoadingMembersForTeamId,
  selectIsMemberSelected,
  selectIsSearching,
  selectIsTeamIdShowingMembers,
  selectLeagueRosteringLoaded,
  selectLoadedMemberTeamIds,
  selectLoadingLeagueRoster,
  selectMemberInfoFilters,
  selectMemberStatusFilters,
  selectOpenedMemberSearchPopupId,
  selectActiveDivisionRosterCount,
  selectPendingTeamMembersByTeamIdCount,
  selectRosterAssignmentView,
  selectRosteringPendingMode,
  selectRosterListDivisionId,
  selectRosterListDivisionChildren,
  selectRosterListSortOn,
  selectRosterListSortReverse,
  selectSearchParams,
  selectSelectedDivisionId,
  selectSelectedMembers,
  selectSelectedMembersCount,
  selectSelectedMembersCountNoCommissioners,
  selectSelectedMemberIds,
  selectSelectedMemberIdsNoCommissioners,
  selectShowTeamMembersForTeamId,
  selectSignupStatuses,
  selectRegistrationStatusFilters,
  selectRegistrationFormsForDivision,
  selectRegistrationFormLineItemsForDivision,
  selectRegistrationFormLineItemOptionsForDivision,
  selectCustomFieldFilters,
  selectPlayerTabActive,
  selectRegistrationVersion,
  selectParticipantGroupFilters
};
