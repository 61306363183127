import { connect } from 'react-redux';

// selectors
import { selectDivisionById } from 'state/teamsnap/divisions/selectors';
import { selectLoggedInMember } from 'state/teamsnap/members/selectors';
import { selectTeamNameForMemberId, selectTeamNamesById } from 'state/teamsnap/teamNames/selectors';
// Components
import { deletePendingMemberByMemberId } from 'state/leagueRostering/actions';
import RosterAssignmentListRow from '../components/RosterAssignmentListRow';

const mapDispatchToProps = (dispatch, ownProps) => ({
  deletePendingMemberByMemberId: () => {
    dispatch(deletePendingMemberByMemberId(ownProps.member.id));
  },
});

const mapStateToProps = (state, ownProps) => {
  let pendingDivision = null;
  if (ownProps.member.pendingDivisionId) {
    pendingDivision = selectDivisionById(state, ownProps.member.pendingDivisionId);
  }

  return {
    currentMember: selectLoggedInMember(state) || {},
    team: ownProps.team || selectTeamNameForMemberId(state, ownProps.member.id),
    pendingDivision,
    pendingTeam: ownProps.member.pendingTeamId ? selectTeamNamesById(state, ownProps.member.pendingTeamId) : null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterAssignmentListRow);
