import React from 'react';
import sortBy from 'utils/sorter';
import _isEmpty from 'lodash/isEmpty';

// Components
import { Cell, Grid, Toggle } from '@teamsnap/teamsnap-ui';

const RegistrationStatus = ({
  registrationForms,
  registrationFormLineItems,
  registrationFormLineItemOptions,
  updateCurrentFilter,
  getDefaultValue,
}) => {
  const activeRegistrationForms = (Object.values(registrationForms) || []).filter(form => form.isRetired === false);
  const sortedRegistrationForms = sortBy('name', activeRegistrationForms);

  const sortedRegistrationFormLineItems = sortBy('name', Object.values(registrationFormLineItems || {}) || []);
  const sortedRegistrationFormLineItemOptions = sortBy(
    'name',
    Object.values(registrationFormLineItemOptions || {}) || [],
  );

  return (
    <form className="u-spaceMd">
      <Grid mods="u-spaceBottomLg">
        <Cell className="u-sizeFill">
          <label htmlFor="registration_form_id" className="u-block u-spaceBottomXs u-fontSizeMd u-textSemiBold">
            Registration Form
          </label>
          <div className="SelectBox">
            <select className="SelectBox-options" name="registration_form_id" onChange={ updateCurrentFilter }>
              <option value="">Select form</option>
              { sortedRegistrationForms.map(form => (
                <option
                  key={ form.id }
                  value={ form.id }
                  selected={ +getDefaultValue('registration_form_id') === form.id }
                  title={ form.name }
                >
                  { `${form.name.substring(0, 45)}${form.name.length >= 45 ? '...' : ''}` }
                </option>
              )) }
            </select>
          </div>
        </Cell>
      </Grid>
      <Grid mods="u-spaceBottomLg">
        <Cell className="u-sizeFill">
          <label
            htmlFor="registration_form_line_item_id"
            className="u-block u-spaceBottomXs u-fontSizeMd u-textSemiBold"
          >
            Fee
          </label>
          <div className="SelectBox">
            <select
              className="SelectBox-options"
              name="registration_form_line_item_id"
              onChange={ updateCurrentFilter }
              disabled={ _isEmpty(sortedRegistrationFormLineItems) }
            >
              <option value="">Any fee</option>
              { sortedRegistrationFormLineItems.map(form => (
                <option value={ form.id } selected={ +getDefaultValue('registration_form_line_item_id') === form.id }>
                  { form.name }
                </option>
              )) }
            </select>
          </div>
        </Cell>
      </Grid>
      <Grid mods="u-spaceBottomLg">
        <Cell className="u-sizeFill">
          <label
            htmlFor="registration_form_line_item_option_id"
            className="u-block u-spaceBottomXs u-fontSizeMd u-textSemiBold"
          >
            Fee Option
          </label>
          <div className="SelectBox">
            <select
              className="SelectBox-options"
              name="registration_form_line_item_option_id"
              onChange={ updateCurrentFilter }
              disabled={ _isEmpty(sortedRegistrationFormLineItemOptions) }
            >
              <option value="">Any fee option</option>
              { sortedRegistrationFormLineItemOptions.map(form => (
                <option
                  value={ form.id }
                  selected={ +getDefaultValue('registration_form_line_item_option_id') === form.id }
                >
                  { form.name }
                </option>
              )) }
            </select>
          </div>
        </Cell>
      </Grid>
      { /* <Grid mods="u-spaceBottomLg">
        <Cell className="u-sizeFill u-spaceRightMd">
          <label
            className="u-fontSizeMd u-textSemiBold u-block"
            htmlFor="hide_rostered"
          >
            Hide Paid
          </label>
          <span className="u-colorGrey">
            Remove members that have paid or have an active installment plan on
            this registration.
          </span>
        </Cell>
        <Cell className="u-sizeFit">
          <Toggle
            className="Toggle"
            inputProps={{
              onChange: updateCurrentFilter,
              checked: getDefaultValue("hidePaid", false)
            }}
            name="hidePaid"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell className="u-sizeFill u-spaceRightMd">
          <label
            className="u-fontSizeMd u-textSemiBold u-block"
            htmlFor="hideUnpaid"
          >
            Hide Unpaid
          </label>
          <span className="u-colorGrey">
            Remove members that have not paid or have an inactive installment
            plan.
          </span>
        </Cell>
        <Cell className="u-sizeFit">
          <Toggle
            className="Toggle"
            inputProps={{
              onChange: updateCurrentFilter,
              checked: getDefaultValue("hideUnpaid", false)
            }}
            name="hideUnpaid"
          />
        </Cell>
      </Grid> */ }
    </form>
  );
};

export default RegistrationStatus;
