import { connect } from 'react-redux';
import { selectActiveDivisionId } from 'state/app/selectors';
import { selectProgramMemberIsError, selectProgramMemberIsFetching } from 'state/programMembers/selectors';
import { selectProgramMembershipsIsLoading } from 'state/programMemberships/selectors';
import {
  selectCurrentPage,
  selectTotalPages,
  selectProgramMembersByCurrentPage,
  selectSearchFilter,
  selectTotalProgramMemberCount,
  selectProgramMemberCountRange,
} from 'state/leagueMembers/selectors';
import { selectIsLoggedInMemberEmailable } from 'state/teamsnap/members/selectors';
import { showModal } from 'state/modal/actions';
import { saveListViewCurrentPage, loadProgramMembersForPage, clearCachedPages } from 'state/leagueMembers/actions';
import { showFeedback } from 'state/feedback/actions';
import { triggerMemberDedup } from 'state/programMembers/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import LeagueMembersList from '../components/leagueMembersList/LeagueMembersList';

const mapDispatchToProps = {
  showModal,
  showFeedback,
  loadProgramMembersForPage,
  clearCachedPages,
  saveListViewCurrentPage,
  triggerMemberDedup,
  analyticEvent,
};

const mapStateToProps = state => {
  const divisionId = selectActiveDivisionId(state);

  return {
    isFetching: selectProgramMemberIsFetching(state),
    isLoading: selectProgramMembershipsIsLoading(state),
    isError: selectProgramMemberIsError(state),
    activeDivisionId: divisionId,
    programMembers: selectProgramMembersByCurrentPage(state),
    paginationCurrentPage: selectCurrentPage(state),
    paginationTotalPages: selectTotalPages(state),
    lastSearchParams: selectSearchFilter(state),
    totalProgramMemberCount: selectTotalProgramMemberCount(state),
    programMemberCountRange: selectProgramMemberCountRange(state),
    isLoggedInMemberEmailable: selectIsLoggedInMemberEmailable(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeagueMembersList);
