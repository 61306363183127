import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import _get from "lodash/get";
import _isEqual from "lodash/isEqual";
import {
  Panel,
  PanelBody,
  PanelRow,
  Button,
  Icon,
  Grid,
  Cell
} from "@teamsnap/teamsnap-ui";
import {
  linkInvoicing,
  linkMembers,
  linkRegistrationNew,
  linkInvoicingNew
} from "utils/links";

import PaymentsImg from "./assets/payments-illustration--cropped@1x.png";
import MembersImg from "./assets/members-illustration--cropped@1x.png";
import SeasonImg from "./assets/season-illustration--cropped@1x.png";
import StaffImg from "./assets/staff-illustration--cropped@1x.png";
import TeamImg from "./assets/team-illustration--cropped@1x.png";

import styles from "./WidgetGettingStarted.module.scss";

class WidgetGettingStarted extends Component {
  state = {
    steps: {
      rosters: {
        label: "Bring on your staff",
        name: "rosters",
        completed: false
      },
      registrations: {
        label: "Open Registration",
        name: "registrations",
        completed: false
      },
      divisions: {
        label: "Organize your teams",
        name: "divisions",
        completed: false
      },
      schedule: {
        label: "Schedule your season",
        name: "schedule",
        completed: false
      },
      payments: {
        label: "Collect payments",
        name: "payments",
        completed: false
      }
    }
  };

  componentDidMount() {
    this.setStepsCompleted(
      this.props.behaviors,
      this.props.paymentProvider,
      this.props.hasActiveStripeAccount,
      this.props.isArchivedDivision
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_isEqual(nextProps.behaviors, this.props.behaviors) ||
      !_isEqual(nextProps.paymentProvider) ||
      !_isEqual(nextProps.hasActiveStripeAccount)
    ) {
      this.setStepsCompleted(
        nextProps.behaviors,
        nextProps.paymentProvider,
        nextProps.hasActiveStripeAccount,
        nextProps.isArchivedDivision
      );
    }
  }

  setStepsCompleted = (
    behaviors,
    paymentProvider,
    hasActiveStripeAccount,
    isArchivedDivision
  ) => {
    const newSteps = this.state.steps;
    newSteps.payments.completed =
      paymentProvider === "wepay"
        ? _get(behaviors, "hasPaymentsAccount", false)
        : hasActiveStripeAccount;
    newSteps.rosters.completed = _get(
      behaviors,
      "hasMultipleCommissioners",
      false
    );
    newSteps.registrations.completed = _get(
      behaviors,
      "hasRegistrationForm",
      false
    );
    newSteps.divisions.completed = _get(
      behaviors,
      "hasSubdivisionOrTeam",
      false
    );
    newSteps.schedule.completed = _get(behaviors, "hasDivisionEvent", false);

    let activeStep;

    if(isArchivedDivision) {
      activeStep = newSteps.registrations;
    } else {
      activeStep = Object.values(newSteps).find(step => !step.completed);
    }

    this.setState(
      {
        steps: newSteps,
        activeStep: activeStep.name
      },
      () => this.mapSteps(this.state)
    );
  };

  setActiveStep = step => {
    this.setState({
      activeStep: step.name
    });
  };

  mapSteps = steps =>
    Object.values(steps).map((step, index) => {
      const stepClass =
        `${this.state.activeStep}` === step.name
          ? `${styles.SideNavstepLinkActive}`
          : `${styles.SideNavstepLink}`;
      const iconClass = step.completed
        ? `${styles.SideNavstepLinkIconCompleted}`
        : ` ${styles.SideNavstepLinkIcon}`;

      return (
        <li
          key={ `${step + index}` }
          // eslint-disable-next-line
          className={`${styles["SideNav-step"]} ${
            this.state.activeStep === step.name ? styles.SideNavStepActive : ""
          }` }
        >
          <a
            className={ stepClass }
            id={ step.name }
            onClick={ () => this.setActiveStep(step) }
          >
            <span className={ iconClass }>
              { step.completed ? <Icon name="check" /> : null }
            </span>
            { step.label }
          </a>
          <div className={ styles.stepDecorator } />
        </li>
      );
    });

  renderPaymentsPanel = (divisionId, paymentProvider) => (
    <section
      className={ styles.Step }
      style={ { backgroundImage: `url(${PaymentsImg})`, height: "100%" } }
    >
      <Grid>
        <Cell mods="u-size1of2 u-padLg">
          <header>
            <h2 className={ styles["Step-title"] }>Need to Collect?</h2>
          </header>
          <div className={ styles["Step-details"] }>
            <p>
              Get paid upfront, on time and directly into your bank account.
            </p>
            <p>Save time and reduce fraud with safe online transactions.</p>
            <p>The perfect combo with TeamSnap invoicing and registration.</p>
          </div>
          { paymentProvider === "wepay" && (
            <a
              href={ `${window.location.origin}/${divisionId}/league_payments/invoicing` }
              className="Button Button--primary"
            >
              Setup Payments
            </a>
          ) }
          { paymentProvider === "stripe" && (
            <div>
              <Cell mods="u-size1of2 u-textCenter" style={ { marginBottom: "10px" } }>
                <a
                  href={ `${window.location.origin}/${divisionId}/league_payments/invoicing/new` }
                  className="Button Button--primary u-sizeFull"
                  >
                  Create an Invoice
                </a>
              </Cell>
              <Cell mods="u-size1of2 u-textCenter">
                <a
                  href={ `${window.location.origin}/divisions/${divisionId}/registration/new` }
                  className="Button Button--primary u-sizeFull"
                >
                  Create a Registration
                </a>
              </Cell>
            </div>
          ) }
        </Cell>
      </Grid>
    </section>
  );

  renderRostersPanel = divisionId => (
    <section
      className={ styles.Step }
      style={ { backgroundImage: `url(${StaffImg})`, height: "100%" } }
    >
      <Grid>
        <Cell mods="u-size1of2 u-padLg">
          <header>
            <h2 className={ styles["Step-title"] }>Need some Help?</h2>
          </header>
          <div className={ styles["Step-details"] }>
            <p>
              Get your staff onboarded and share the wealth – and also the
              workload.
            </p>
            <p>
              Everyone will be on the same page, connected through TeamSnap.
            </p>
          </div>
          <Button
            location={ linkMembers(divisionId) }
            routerLink={ Link }
            color="primary"
          >
            Add Members
          </Button>
        </Cell>
      </Grid>
    </section>
  );

  renderRegistrationsPanel = divisionId => (
    <section
      className={ styles.Step }
      style={ { backgroundImage: `url(${MembersImg})`, height: "100%" } }
    >
      <Grid>
        <Cell mods="u-size1of2 u-padLg">
          <header>
            <h2 className={ styles["Step-title"] }>Ready for members?</h2>
          </header>
          <div className={ styles["Step-details"] }>
            <p>
              Get started with online registration – our top time-saving
              feature.
            </p>
            <p>Automate the collection of member contact information.</p>
            <p>
              Build registration forms easily with our self-service, intuitive
              tool.
            </p>
          </div>
          <a
            href={ `${window.location.origin}/divisions/${divisionId}/registration/new?appcue=-Lp9i7YrxRwV5-8SiwjQ` }
            className="Button Button--primary"
          >
            Start a Registration
          </a>
        </Cell>
      </Grid>
    </section>
  );

  renderDivisionsPanel = divisionId => (
    <section
      className={ styles.Step }
      style={ { backgroundImage: `url(${TeamImg})`, height: "100%" } }
    >
      <Grid>
        <Cell mods="u-size1of2 u-padLg">
          <header>
            <h2 className={ styles["Step-title"] }>Ready to Organize?</h2>
          </header>
          <div className={ styles["Step-details"] }>
            <p>Make one division – or dozens!</p>
            <p>Group your teams by season, age, or program.</p>
            <p>
              Set yourself up for quick and easy communication from the get-go!
            </p>
          </div>
          <a
            href={ `${window.location.origin}/${divisionId}/division_teams` }
            className="Button Button--primary"
          >
            Add a Division
          </a>
        </Cell>
      </Grid>
    </section>
  );

  renderSchedulePanel = divisionId => (
    <section
      className={ styles.Step }
      style={ { backgroundImage: `url(${SeasonImg})`, height: "100%" } }
    >
      <Grid>
        <Cell mods="u-size1of2 u-padLg">
          <header>
            <h2 className={ styles["Step-title"] }>Ready to Schedule?</h2>
          </header>
          <div className={ styles["Step-details"] }>
            <p>Quickly automate a complete schedule for all your teams.</p>
            <p>See who’s playing where and when.</p>
            <p>Catch conflicts early by specifying blackout dates.</p>
          </div>
          <a
            href={ `${window.location.origin}/${divisionId}/league_schedule` }
            className="Button Button--primary"
          >
            Go to Schedule
          </a>
        </Cell>
      </Grid>
    </section>
  );

  render() {
    const { divisionId, paymentProvider } = this.props;

    return (
      <Panel>
        <PanelBody mods="Grid Grid--withGutter">
          <nav className={ `Grid-cell u-size3of12 ${styles.SideNav}` }>
            <header className={ `u-padMd ${styles["SideNav-header"]}` }>
              <h3 className={ styles["SideNav-title"] }>Welcome to TeamSnap</h3>
              <p className={ styles["SideNav-note"] }>Get ready to...</p>
            </header>
            <ul>{ this.mapSteps(this.state.steps) }</ul>
            <footer className={ styles["SideNav-footer"] }>
              <a
                role="Tooltip"
                onClick={ () => window.Appcues.show("-L9zuYPmW655GhHt0O6n") }
                className={ styles["SideNav-tour"] }
              >
                <Icon name="info" />
                <span className="u-spaceLeftXs">Take the Tour</span>
              </a>
            </footer>
          </nav>

          <PanelRow className="Grid-cell u-size9of12">
            { this.state.activeStep === "payments"
              ? this.renderPaymentsPanel(divisionId, paymentProvider)
              : null }
            { this.state.activeStep === "rosters"
              ? this.renderRostersPanel(divisionId)
              : null }
            { this.state.activeStep === "registrations"
              ? this.renderRegistrationsPanel(divisionId)
              : null }
            { this.state.activeStep === "divisions"
              ? this.renderDivisionsPanel(divisionId)
              : null }
            { this.state.activeStep === "schedule"
              ? this.renderSchedulePanel(divisionId)
              : null }
          </PanelRow>
        </PanelBody>
      </Panel>
    );
  }
}

WidgetGettingStarted.propTypes = {
  divisionId: PropTypes.string.isRequired,
  behaviors: PropTypes.shape({}).isRequired,
  paymentProvider: PropTypes.string,
  hasActiveStripeAccount: PropTypes.bool,
  isArchivedDivision: PropTypes.bool
};

WidgetGettingStarted.defaultProps = {
  paymentProvider: null,
  hasActiveStripeAccount: false,
  isArchivedDivision: null,
};

export default WidgetGettingStarted;
