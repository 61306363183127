import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'shared/toolkit/Modal';
import { ImgProgressBar } from 'shared/components/Img';

class SavingEmailModal extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    attachments: PropTypes.array,
  };

  render() {
    const { title, attachments } = this.props;
    return (
      <Modal title={ title }>
        <div style={ { textAlign: 'center' } }>
          <ImgProgressBar />
          <br />
          <br />
          <table>
            <tbody className="fullwidth">
              { attachments.map(attachment => {
                if (attachment.fileList) {
                  return (
                    <tr key={ attachment.id } style={ { textAlign: 'left', width: '100%' } }>
                      <td>Uploading { attachment.fileList[0].name }</td>
                      <td style={ { textAlign: 'right' } }>{ attachment.progress } %</td>
                    </tr>
                  );
                }
                return null;
              }) }
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

export default SavingEmailModal;
