export const ADD_STORED_FILTERS = 'leagueRostering.ADD_STORED_FILTERS';
export const ADD_FILTERED_MEMBER_IDS = 'leagueRostering.ADD_FILTERED_MEMBER_IDS';
export const ADD_MEMBER_SELECTION = 'leagueRostering.ADD_MEMBER_SELECTION';
export const APPLY_ROSTER_FILTER = 'leagueRostering.APPLY_ROSTER_FILTER';
export const ASSIGN_MEMBERS_TO_TEAM = 'leagueRostering.ASSIGN_MEMBERS_TO_TEAM';
export const CHANGE_TEAM_ERROR = 'leagueRostering.CHANGE_TEAM_ERROR';
export const CHANGE_TEAM_START = 'leagueRostering.CHANGE_TEAM_START';
export const CHANGE_TEAM_SUCCESS = 'leagueRostering.CHANGE_TEAM_SUCCESS';
export const CLEAR_FILTER = 'leagueRostering.CLEAR_FILTER';
export const CLEAR_MEMBER_SEARCH_FILTERS = 'leagueRostering.CLEAR_MEMBER_SEARCH_FILTERS';
export const CLEAR_ASSIGNED_MEMBER_COUNT = 'leagueRostering.CLEAR_ASSIGNED_MEMBER_COUNT';
export const LIST_CLICK_ALL_MEMBERS = 'leagueRostering.LIST_CLICK_ALL_MEMBERS';
export const LIST_CLICK_MEMBER = 'leagueRoster.LIST_CLICK_MEMBER';
export const LOAD_LEAGUE_ROSTER_START = 'leagueRostering.LOAD_LEAGUE_ROSTER_START';
export const LOAD_LEAGUE_ROSTER_SUCCESS = 'leagueRostering.LOAD_LEAGUE_ROSTER_SUCCESS';
export const LOAD_LEAGUE_ROSTER_ERROR = 'leagueRostering.LOAD_LEAGUE_ROSTER_ERROR';
export const LOAD_MEMBERS_FOR_TEAM_START = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_START';
export const LOAD_MEMBERS_FOR_TEAM_SUCCESS = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_SUCCESS';
export const LOAD_MEMBERS_FOR_TEAM_END = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_END';
export const REMOVE_SAVED_FILTER = 'leagueRostering.REMOVE_SAVED_FILTER';
export const REPLACE_CURRENT_FILTER = 'leagueRostering.REPLACE_CURRENT_FILTER';
export const REMOVE_ALL_MEMBER_SELECTIONS = 'leagueRostering.REMOVE_ALL_MEMBER_SELECTIONS';
export const REMOVE_MEMBER_SELECTION = 'leagueRostering.REMOVE_MEMBER_SELECTION';
export const RESET_ADVANCED_FILTER = 'leagueRostering.RESET_ADVANCED_FILTER';
export const ROSTER_SEARCH_START = 'leagueRostering.ROSTER_SEARCH_START';
export const ROSTER_SEARCH_END = 'leagueRostering.ROSTER_SEARCH_END';
export const SAVE_FILTER = 'leagueRostering.SAVE_FILTER';
export const SEARCH_DIVISION_MEMBERS_ERROR = 'leagueRoster.SEARCH_DIVISION_MEMBERS_ERROR';
export const SEARCH_DIVISION_MEMBERS_START = 'leagueRoster.SEARCH_DIVISION_MEMBERS_START';
export const SEARCH_DIVISION_MEMBERS_SUCCESS = 'leagueRoster.SEARCH_DIVISION_MEMBERS_SUCCESS';
export const SEARCH_FILTER_ADD = 'leagueRoster.SEARCH_FILTER_ADD';
export const SEARCH_FILTER_CLEAR = 'leagueRoster.SEARCH_FILTER_CLEAR';
export const SEARCH_FILTER_DELETE = 'leagueRoster.SEARCH_FILTER_DELETE';
export const SEARCH_FILTER_UPDATE = 'leagueRoster.SEARCH_FILTER_UPDATE';
export const SELECT_ALL_FILTERED_MEMBERS = 'leagueRostering.SELECT_ALL_FILTERED_MEMBERS';
export const SET_ASSIGNED_MEMBER_COUNT = 'leagueRostering.SET_ASSIGNED_MEMBER_COUNT';
export const SET_CHANGE_TEAM_DIVISION_ID = 'leagueRostering.SET_CHANGE_TEAM_DIVISION_ID';
export const SET_CHANGE_TEAM_MEMBER_ID = 'leagueRostering.SET_CHANGE_TEAM_MEMBER_ID';
export const SET_CHANGE_TEAM_TEAM_ID = 'leagueRostering.SET_CHANGE_TEAM_TEAM_ID';
export const SET_CURRENT_ROSTERING_DIVISION = 'leagueRostering.SET_CURRENT_ROSTERING_DIVISION';
export const SET_DIVISION_ROSTER_COUNT = 'leagueRostering.SET_DIVISION_ROSTER_COUNT';
export const TOGGLE_MEMBER_SEARCH_POPUP = 'leagueRostering.TOGGLE_MEMBER_SEARCH_POPUP';
export const TOGGLE_ROSTER_ASSIGNMENT_VIEW = 'leagueRostering.TOGGLE_ROSTER_ASSIGNMENT_VIEW';
export const TOGGLE_ROSTER_LIST_SORT = 'leagueRostering.TOGGLE_ROSTER_LIST_SORT';
export const TOGGLE_ROSTERING_PENDING_MODE = 'leagueRostering.TOGGLE_ROSTERING_PENDING_MODE';
export const TOGGLE_SHOW_TEAM_MEMBERS = 'leagueRostering.TOGGLE_SHOW_TEAM_MEMBERS';
export const UPDATE_CURRENT_FILTER = 'leagueRostering.UPDATE_CURRENT_FILTER';
export const ADD_CURRENT_ADVANCED_FILTER = 'leagueRostering.ADD_CURRENT_ADVANCED_FILTER';
export const DELETE_CURRENT_ADVANCED_FILTER = 'leagueRostering.DELETE_CURRENT_ADVANCED_FILTER';
export const UPDATE_CURRENT_ADVANCED_FILTER = 'leagueRostering.UPDATE_CURRENT_ADVANCED_FILTER';
export const UPDATE_HAS_UNAPPLIED_FILTERS = 'leagueRostering.UPDATE_HAS_UNAPPLIED_FILTERS';
export const UPDATE_MEMBER_SELECTION = 'leagueRostering.UPDATE_MEMBER_SELECTION';
export const UPDATE_IS_PLAYER_TAB = 'leagueRostering.UPDATE_IS_PLAYER_TAB';
export const SET_REGISTRATION_VERSION = 'leagueRostering.SET_REGISTRATION_VERSION';

export const CHECKOUT_TYPES = [
  { label: 'Installment Plan', value: 3 },
  { label: 'No Fees', value: 5 },
  { label: 'Offline', value: 4 },
  { label: 'Pay Now', value: 2 },
];

export const SIGNUP_STATUSES = [
  { label: 'Authorized', value: 'authorized' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Charged Back', value: 'charged_back' },
  { label: 'Failed', value: 'failed' },
  { label: 'Paid', value: 'paid' },
  { label: 'Refunded', value: 'refunded' },
];
