import { createCollection, getCollection, COLLECTIONS } from 'state/snapi/slice';
import { loadingErrorAction, loadingStartAction, loadingSuccessAction } from 'state/loading/actions';
import { setAppError } from 'state/app/actions';
import * as constants from './constants';
import api from 'utils/api';

export const saveHealthCheckQuestionnaire = questionnaire => dispatch =>
  dispatch({
    type: constants.HEALTH_CHECK_QUESTIONNAIRE,
    payload: questionnaire,
  });

export const createHealthCheckQuestionnaireTemplateQuestionSuccess = question => dispatch =>
  dispatch({
    type: constants.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION,
    payload: question,
  });

export const createHealthCheckQuestionnaireTemplateError = error => dispatch =>
  dispatch({
    type: constants.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR,
    error,
  });

export const createHealthCheckQuestionnaireTemplateQuestionError = error => dispatch =>
  dispatch({
    type: constants.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR,
    error,
  });

const loadDefaultHealthCheckQuestionnaireStart = () => loadingStartAction('healthCheckQuestionnaire');
const loadDefaultHealthCheckQuestionnaireSuccess = () => loadingSuccessAction('healthCheckQuestionnaire');
const loadDefaultHealthCheckQuestionnaireError = error => loadingErrorAction('healthCheckQuestionnaire', error);

const loadHealthCheckQuestionsStart = () => loadingStartAction('healthCheckQuestions');
const loadHealthCheckQuestionsSuccess = () => loadingSuccessAction('healthCheckQuestions');
const loadHealthCheckQuestionsError = error => loadingErrorAction('healthCheckQuestions', error);

const loadDefaultQuestions = data => dispatch => {
  dispatch(loadHealthCheckQuestionsStart());

  const questions = data.map(el => {
    const link = el.links.healthCheckQuestionnaireTemplateQuestions;
    return api.get(link);
  });

  return Promise.all(questions).then(
    data => {
      dispatch(loadHealthCheckQuestionsSuccess());
      const questions = data[0]?.items || [];
      dispatch(saveHealthCheckQuestionnaire(questions));
    },
    error => {
      dispatch(setAppError(error));
      return dispatch(loadHealthCheckQuestionsError(error));
    },
  );
  //
};

export const initDefaultHealthCheckQuestionnaire = rootDivisionId => dispatch => {
  dispatch(loadDefaultHealthCheckQuestionnaireStart());

  return Promise.all([
    dispatch(
      getCollection(COLLECTIONS.HEALTH_CHECK_QUESTIONNAIRE, `health_check_questionnaire_templates/search`, {
        division_id: rootDivisionId,
      }),
    ),
  ]).then(
    ([data]) => {
      dispatch(loadDefaultHealthCheckQuestionnaireSuccess());
      dispatch(loadDefaultQuestions(data));
    },
    error => {
      dispatch(setAppError(error));
      return dispatch(loadDefaultHealthCheckQuestionnaireError(error));
    },
  );
};

// --------------------------------------
// createNewQuestionnaireTemplate
//
// create a new template first, and then add questions
// ------------------------------------------

export const createNewHealthQuestionnaire = params => dispatch => dispatch(
    createCollection(COLLECTIONS.HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE, `health_check_questionnaire_templates`, params),
  ).catch(error => console.log(error));

// --------------------------------------
// createNewQuestionnaireTemplateQuestion
//
// ------------------------------------------

export const createNewHealthQuestionnaireTemplateQuestion = params => dispatch => dispatch(
    createCollection(
      COLLECTIONS.HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION,
      `health_check_questionnaire_template_questions`,
      params,
    ),
  )
    .then(data => dispatch(createHealthCheckQuestionnaireTemplateQuestionSuccess(data[0])))
    .catch(error => console.log(error));
