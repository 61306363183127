import history from 'utils/history';
import _forOwn from 'lodash/forOwn';

import { clearAppMessages, setAppNotice, setAppError } from 'state/app/actions';
import {
  bulkDeleteBroadcastEmails,
  loadBroadcastEmailsByMemberId,
  loadBroadcastEmailById,
} from 'state/teamsnap/broadcastEmails/actions';
import { loadingErrorAction, loadingStartAction, loadingSuccessAction } from 'state/loading/actions';
import { addItems } from 'state/teamsnap/actions';
import {
  findBroadcastEmailAttachmentsbyBroadcastEmailId,
  uploadBroadcastEmailAttachment,
} from 'state/teamsnap/broadcastEmailAttachments/actions';
import { loadTeamNamesByDivisionId } from 'state/teamsnap/teamNames/actions';
import { showFeedback } from 'state/feedback/actions';
import { SUCCESS, ERROR } from 'shared/toolkit/Feedback/Feedback';

import { selectActiveDivisionId } from 'state/app/selectors';
import {
  attachmentsSize,
  selectAttachment,
  selectEmailsByPage,
  selectListEmailsDeleteEmailIds,
  selectLeagueMessagesLoaded,
} from 'state/leagueMessages/selectors';
import {
  selectEmailAddressesByMemberId,
  selectLoggedInMemberId,
  selectLoggedInMember,
} from 'state/teamsnap/members/selectors';
import { selectBroadcastEmailById } from 'state/teamsnap/broadcastEmails/selectors';
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';

import getErrorMessage from 'utils/errorMessage';
import teamsnapSdk from 'utils/teamsnapSdk';
import { linkLeagueMessagesListEmails } from 'utils/links';
import { displayFileSize } from 'utils/fileSize';
import { templateToObject } from 'utils/utils';

import { ChurnZeroService } from 'frontend-toolkit';

import * as constants from './constants';

// ------------------------------------------
// Load actions
// ------------------------------------------
const loadLeagueMessagesStart = () => loadingStartAction('leagueMessages');
const loadLeagueMessagesSuccess = () => loadingSuccessAction('leagueMessages');
const loadLeagueMessagesError = error => loadingErrorAction('leagueMessages', error);

const initLeagueMessages = () => (dispatch, getState) => {
  if (selectLeagueMessagesLoaded(getState())) {
    return Promise.resolve();
  }
  dispatch(loadLeagueMessagesStart());

  const memberId = selectLoggedInMemberId(getState());
  const activeDivision = selectActiveDivision(getState());

  return Promise.all([
    dispatch(loadBroadcastEmailsByMemberId(memberId)),
    dispatch(loadTeamNamesByDivisionId(activeDivision.id)),
  ]).then(
    () => dispatch(loadLeagueMessagesSuccess()),
    error => {
      const errorMessage = getErrorMessage(error);
      dispatch(setAppError(errorMessage));
      return dispatch(loadLeagueMessagesError(errorMessage));
    },
  );
};

// ------------------------------------------
// Load email actions
// ------------------------------------------
const loadClubEmailStart = () => loadingStartAction('clubEmail');
const loadClubEmailSuccess = () => loadingSuccessAction('clubEmail');
const loadClubEmailError = error => loadingErrorAction('clubEmail', error);

const setBroadcastEmail = broadcastEmail => ({
  type: constants.SET_BROADCAST_EMAIL,
  broadcastEmail,
  attachments: [],
});

const initClubEmail = broadcastEmailId => (dispatch, getState) => {
  let broadcastEmail;
  const divisionId = selectActiveDivisionId(getState());

  dispatch(loadClubEmailStart());

  if (broadcastEmailId) {
    broadcastEmail = selectBroadcastEmailById(getState(), broadcastEmailId);
    if (!broadcastEmail) {
      dispatch(loadClubEmailError(`Unable to find broadcastEmailId ${broadcastEmailId}`));
      return history.push(linkLeagueMessagesListEmails(divisionId));
    }
    dispatch(findBroadcastEmailAttachmentsbyBroadcastEmailId(broadcastEmailId));
    // If we want to load up an already sent email, we want to be able to send it again
    if (broadcastEmail.isDraft === false) {
      broadcastEmail = { ...broadcastEmail };
      broadcastEmail.id = null;
      broadcastEmail.href = null;
    }
  } else {
    const member = selectLoggedInMember(getState());
    const template = templateToObject(teamsnapSdk().collections.broadcastEmails.template);
    const emailAddresses = selectEmailAddressesByMemberId(getState(), member.id);
    delete template.type;
    // DM TODO Need a way to create an empty broadcastEmail
    // This extends to being able to create any empty item from
    // teamsnapSdk
    broadcastEmail = teamsnapSdk().createBroadcastEmail(template);
    broadcastEmail.subject = '';
    broadcastEmail.body = '';
    broadcastEmail.recipientIds = [];
    broadcastEmail.recipientTeamIds = [];
    broadcastEmail.recipientManagerTeamIds = [];
    broadcastEmail.recipientUnnassigned = false;
    broadcastEmail.recipientAllCommissioners = false;
    broadcastEmail.isLeague = true;
    broadcastEmail.memberId = member.id;
    broadcastEmail.fromEmailAddress = emailAddresses.length > 0 ? emailAddresses[0].email : '';
    broadcastEmail.teamIds = [];
    broadcastEmail.managersOnly = false;
  }
  dispatch(clearAttachments());
  dispatch(setBroadcastEmail(broadcastEmail));
  return dispatch(loadClubEmailSuccess());
};

// ------------------------------------------
// Email actions
// ------------------------------------------
const setSubject = subject => ({
  type: constants.SET_SUBJECT,
  subject,
});

const setBody = body => ({
  type: constants.SET_BODY,
  body,
});

const setFromEmailAddress = fromEmailAddress => ({
  type: constants.SET_FROM_EMAIL_ADDRESS,
  fromEmailAddress,
});

const clearCurrentBroadcastEmail = () => ({
  type: constants.CLEAR_BROADCAST_EMAIL,
});

const setRecipient = ({ recipientType, recipientValue }) => ({
  type: constants.SET_RECIPIENT,
  recipientType,
  recipientValue,
});

const setSavingEmailStatus = status => ({
  type: constants.SAVING_EMAIL_STATUS,
  status,
});

const savingEmailStart = dispatch => {
  dispatch(clearAppMessages());
  dispatch(setSavingEmailStatus('Saving Email'));
};

const savingEmailError = (dispatch, error, reject) => {
  dispatch(setSavingEmailStatus());
  dispatch(
    showFeedback({
      message: `Unable to save email: ${error.message}`,
      type: ERROR,
    }),
  );
  console.error(error.message);
  reject();
};

const savingEmailEnd = (dispatch, state, broadcastEmail, draft) => {
  dispatch(addItems({ items: [broadcastEmail] })).then(() => {
    dispatch(setAppNotice(draft ? 'Email saved as a draft' : 'Your email is being sent...'));
    dispatch(setSavingEmailStatus());
    history.push(linkLeagueMessagesListEmails(selectActiveDivisionId(state)));
    dispatch(clearCurrentBroadcastEmail());
  });
};

// --------------------------------------
// saveDraft
//
// If the email has any attachments or is a draft
// then we need to save it as a draft
// --------------------------------------
function saveDraft(dispatch, broadcastEmail, attachments, draft) {
  return new Promise((resolve, reject) => {
    if ((attachments && attachments.length > 0) || draft === true) {
      broadcastEmail.isDraft = true;
      return teamsnapSdk()
        .saveBroadcastEmail(broadcastEmail)
        .then(
          broadcastEmail => resolve(broadcastEmail),
          error => {
            savingEmailError(dispatch, error, reject);
          },
        );
    }
    return resolve(broadcastEmail);
  });
}

// --------------------------------------
// saveAttachments
//
// If the email has any attachments then save them
// otherwise skip it
// --------------------------------------

function saveAttachments(dispatch, broadcastEmail, member, attachments, draft) {
  return new Promise((resolve, reject) => {
    if (attachments && attachments.length > 0) {
      dispatch(setSavingEmailStatus('Saving Attachments'));

      const promises = [];
      attachments.map(attachment => {
        for (let i = 0; i < attachment.fileList.length; i += 1) {
          const file = attachment.fileList[i];
          const progressCallback = progress => {
            const percent = (progress.loaded / progress.total) * 100;
            const finalPercent = Math.round(percent) || 0;
            dispatch(updateAttachmentProgress(attachment.id, finalPercent));
          };

          promises.push(
            new Promise((resolve, reject) =>
              dispatch(
                uploadBroadcastEmailAttachment(broadcastEmail.id, member.id, file, progressCallback, resolve, reject),
              ),
            ),
          );
        }
      });

      Promise.all(promises)
        .then(() => {
          // We need to reload because we have updated broacastEmailAttachments
          if (draft) {
            dispatch(loadBroadcastEmailById(broadcastEmail.id));
          }
          resolve(broadcastEmail);
        })
        .catch(error => {
          savingEmailError(dispatch, error, reject);
        });
    } else {
      return resolve(broadcastEmail);
    }
  });
}

// --------------------------------------
// sendEmail
//
// If this email isn't a draft then go ahead
// and send it
// --------------------------------------

function sendEmail(dispatch, broadcastEmail, draft) {
  return new Promise((resolve, reject) => {
    if (!draft) {
      dispatch(setSavingEmailStatus('Sending Email'));

      broadcastEmail.isDraft = false;

      ChurnZeroService.trackChurnZeroEvent('Org Level Message Sent');
      return teamsnapSdk()
        .saveBroadcastEmail(broadcastEmail)
        .then(
          broadcastEmail => resolve(broadcastEmail),
          error => {
            savingEmailError(dispatch, error, reject);
          },
        );
    }
    return resolve(broadcastEmail);
  });
}

// --------------------------------------
// saveEmail
//
// This will save an email to the server
// If the email is a draft or it has attachments
// it needs to be saved first as a draft, then
// the attachments need to be saved. Then if it wasn't
// a draft then it needs to be saved to actually be sent
// --------------------------------------

const saveEmail = ({ draft }) => (dispatch, getState) => {
  const state = getState();
  const { broadcastEmail } = state.leagueMessages;
  const attachments = state.leagueMessages.attachments.filter(attachment => attachment.fileList);
  const division = selectActiveDivision(getState());
  const member = selectLoggedInMember(getState());

  if (broadcastEmail.teamIds && broadcastEmail.teamIds.length > 0) {
    if (broadcastEmail.managersOnly) {
      broadcastEmail.recipientManagerTeamIds = broadcastEmail.teamIds;
    } else {
      broadcastEmail.recipientTeamIds = broadcastEmail.teamIds;
    }
  }

  savingEmailStart(dispatch);

  broadcastEmail.divisionId = division.id;
  broadcastEmail.isDraft = draft;

  return saveDraft(dispatch, broadcastEmail, attachments, draft)
    .then(savedBroadcastEmail => saveAttachments(dispatch, savedBroadcastEmail, member, attachments, draft))
    .then(savedBroadcastEmail => sendEmail(dispatch, savedBroadcastEmail, draft))
    .then(savedBroadcastEmail => savingEmailEnd(dispatch, state, savedBroadcastEmail, draft));
};

// ------------------------------------------
// Attachments
// ------------------------------------------
const clearAttachments = () => ({
  type: constants.CLEAR_ATTACHMENTS,
});

const addAttachment = () => ({
  type: constants.ADD_ATTACHMENT,
  attachment: { id: Date.now() },
});

const updateAttachment = (id, fileList) => (dispatch, getState) => {
  const state = getState();
  const twentyMB = 20971520;
  const size = attachmentsSize(state);
  const totalSize = fileList[0].size + size;

  if (totalSize > twentyMB) {
    dispatch(
      setAppError(
        `Whoops! It looks like your total file size is greater than 20 MB!
        That last file put you over the limit by ${displayFileSize(totalSize - twentyMB)}`,
        true,
      ),
    );

    const attachment = selectAttachment(state, id);
    return dispatch(deleteAttachment(attachment));
  }
  return dispatch({
    type: constants.UPDATE_ATTACHMENT,
    attachment: { id, fileList, progress: 0 },
  });
};

const updateAttachmentProgress = (id, progress) => ({
  type: constants.UPDATE_ATTACHMENT_PROGRESS,
  id,
  progress,
});

const deleteAttachment = attachment => ({
  type: constants.DELETE_ATTACHMENT,
  attachment,
});

// ------------------------------------------
// Delete Emails
// ------------------------------------------
const clickDeleteAllEmails = (checked, page) => (dispatch, getState) => {
  let broadcastEmailIds = [];
  if (checked === true) {
    const broadcastEmails = selectEmailsByPage(getState(), page || 1);
    broadcastEmailIds = broadcastEmails.map(broadcastEmail => broadcastEmail.id);
  }
  dispatch({
    type: constants.CLICK_DELETE_ALL_EMAILS,
    checked,
    broadcastEmailIds,
  });
};

const clickDeleteEmail = (emailId, checked) => ({
  type: constants.CLICK_DELETE_EMAIL,
  emailId,
  checked,
});

const deleteEmailsSuccess = dispatch => () => {
  dispatch({
    type: constants.CLICK_DELETE_ALL_EMAILS,
    checked: false,
  });
  dispatch(
    showFeedback({
      message: 'The requested emails have been deleted',
      type: SUCCESS,
    }),
  );
};

const deleteEmailsError = dispatch => error => {
  dispatch(showFeedback({ message: getErrorMessage(error), type: ERROR }));
};

const deleteEmails = () => (dispatch, getState) => {
  const deleteEmailIds = selectListEmailsDeleteEmailIds(getState());
  const broadcastEmails = [];
  _forOwn(deleteEmailIds, (value, key) => {
    if (value === true) {
      const broadcastEmail = selectBroadcastEmailById(getState(), key);
      if (broadcastEmail) {
        broadcastEmails.push(broadcastEmail);
      }
    }
  });
  if (broadcastEmails.length > 0) {
    return dispatch(
      bulkDeleteBroadcastEmails(broadcastEmails, deleteEmailsSuccess(dispatch), deleteEmailsError(dispatch)),
    );
  }
  dispatch(showFeedback({ message: 'Nothing selected to delete', type: ERROR }));
};

// ------------------------------------------
// View Email
// ------------------------------------------
const loadViewEmailStart = () => loadingStartAction('viewEmail');
const loadViewEmailSuccess = () => loadingSuccessAction('viewEmail');
const loadViewEmailError = error => loadingErrorAction('viewEmail', error);

const initViewEmail = broadcastEmailId => (dispatch, getState) => {
  dispatch(loadViewEmailStart());

  const broadcastEmail = selectBroadcastEmailById(getState(), broadcastEmailId);
  if (broadcastEmail) {
    return dispatch(loadViewEmailSuccess());
  }
  const activeDivisionId = selectActiveDivisionId(getState());
  dispatch(loadViewEmailError(`Unable to find broadcast email ${broadcastEmailId}`));
  history.push(linkLeagueMessagesListEmails(activeDivisionId));
};

export {
  initLeagueMessages,
  setBroadcastEmail,
  initClubEmail,
  setSubject,
  setBody,
  setFromEmailAddress,
  clearCurrentBroadcastEmail,
  setRecipient,
  setSavingEmailStatus,
  saveEmail,
  clearAttachments,
  addAttachment,
  updateAttachment,
  updateAttachmentProgress,
  deleteAttachment,
  clickDeleteAllEmails,
  clickDeleteEmail,
  deleteEmails,
  initViewEmail,
  // private
  loadLeagueMessagesStart as _loadLeagueMessagesStart,
  loadLeagueMessagesSuccess as _loadLeagueMessagesSuccess,
  loadLeagueMessagesError as _loadLeagueMessagesError,
  loadClubEmailStart as _loadClubEmailStart,
  loadClubEmailSuccess as _loadClubEmailSuccess,
  loadClubEmailError as _loadClubEmailError,
  savingEmailStart as _savingEmailStart,
  savingEmailError as _savingEmailError,
  savingEmailEnd as _savingEmailEnd,
  saveDraft as _saveDraft,
  saveAttachments as _saveAttachments,
  sendEmail as _sendEmail,
  deleteEmailsSuccess as _deleteEmailsSuccess,
  deleteEmailsError as _deleteEmailsError,
  loadViewEmailStart as _loadViewEmailStart,
  loadViewEmailSuccess as _loadViewEmailSuccess,
  loadViewEmailError as _loadViewEmailError,
};
