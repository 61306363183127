import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Grid, Cell, Icon, RadialProgress, ProgressBar } from '@teamsnap/teamsnap-ui';
import WidgetBase from 'shared/components/Widget';
import WidgetCounts from 'shared/components/Widget/WidgetCounts';

class AlertLayout extends PureComponent {
  renderChart = () => {
    const { chartType, chartProgress } = this.props;

    return chartType === 'radial' ? (
      <RadialProgress size="large" progress={ chartProgress } />
    ) : (
      <ProgressBar isVertical isPrecise progress={ chartProgress } />
    );
  };

  renderTopSection = () => {
    const { countersTop, countersBottom, iconName, chartType } = this.props;

    return (
      <Grid>
        <Cell mods="u-size1of3 u-textCenter">
          { chartType ? this.renderChart() : <Icon style={ { fontSize: '100px', color: '#eee' } } name={ iconName } /> }
        </Cell>

        <Cell mods="u-size2of3">
          <WidgetCounts items={ countersTop } mods="u-size1of2" />
          <WidgetCounts items={ countersBottom } mods="u-size1of1 u-borderTop u-padTopSm u-spaceTopMd" />
        </Cell>
      </Grid>
    );
  };

  render() {
    const { infoComponent, headerText, isLoading, buttonText, buttonLocation, buttonColor, children } = this.props;

    return (
      <WidgetBase
        headerText={ headerText }
        infoComponent={ infoComponent }
        isLoading={ isLoading }
        buttonText={ buttonText }
        buttonLocation={ buttonLocation }
        buttonColor={ buttonColor }
      >
        { this.renderTopSection() }
        { children }
      </WidgetBase>
    );
  }
}

AlertLayout.propTypes = {
  iconName: PropTypes.string,
  chartType: PropTypes.string,
  chartProgress: PropTypes.number,
  countersTop: PropTypes.arrayOf(PropTypes.shape({})),
  countersBottom: PropTypes.arrayOf(PropTypes.shape({})),
  infoComponent: PropTypes.node,
  headerText: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLocation: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.node,
};

AlertLayout.defaultProps = {
  iconName: null,
  chartType: null,
  chartProgress: null,
  countersTop: [],
  countersBottom: [],
  infoComponent: null,
  headerText: null,
  isLoading: false,
  buttonText: null,
  buttonLocation: null,
  buttonColor: null,
  children: null,
};

export default AlertLayout;
