/**
 * TextArea Component
 *
 * A textarea component that requires an input object and calls <Field />
 *
 * TODO:
 *  -> The css needs to be updated in teamsnapUI for better use with our components
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'shared/toolkit/Form';
import defaultStyles from './TextArea.module.scss';

class TextArea extends PureComponent {
  render() {
    const { input, meta, label, note, placeholder, disabled, required, size, componentStyles, className } = this.props;

    const styles = { ...defaultStyles, ...componentStyles };
    const inputClass = meta.touched && meta.error ? `${className}Error` : className;

    return (
      <Field
        name={ input.name }
        label={ label }
        required={ required }
        meta={ meta }
        size={ size }
        className={ className === 'TextAreaAligned' ? 'FieldAligned' : 'Field' }
      >
        { note && <small>{ note }</small> }
        <textarea
          { ...input }
          id={ input.name }
          disabled={ disabled }
          required={ required }
          className={ styles[inputClass] }
          placeholder={ placeholder }
        />
      </Field>
    );
  }
}

TextArea.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({}),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  note: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.number,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

TextArea.defaultProps = {
  meta: {},
  label: undefined,
  placeholder: undefined,
  note: null,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'TextArea',
};

export default TextArea;
