import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import gtmMiddleware from '../middleware/googleTagManager';
import appcuesMiddleware from '../middleware/appcues';
import exceptionMiddleware, { setupNotifier } from '../middleware/exceptionNotifier';
import { createLogger } from 'redux-logger';
import makeRootReducer from './reducers';

export default (initialState = {}, history = {}) => {
  // ======================================================
  // Redux Logger Configuration
  // ======================================================

  const reduxLogger = createLogger({
    collapsed: true,
    timestamp: false,
  });

  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [thunk, gtmMiddleware, appcuesMiddleware];

  if (process.env.NODE_ENV === 'production') {
    setupNotifier();
    middleware.push(exceptionMiddleware);
  }

  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];
  if (window.__DEBUG__) {
    middleware.push(reduxLogger);
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================

  const store = configureStore({
    reducer: makeRootReducer(),
    preloadedState: initialState,
    middleware,
  });

  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require('./reducers').default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};
