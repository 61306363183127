/**
 * InputControl Component
 *
 * An input control component is used to render different types of selection controls.
 * This include items such as RadioButtons, Checkboxes and Toggles/Switches.
 *
 * TODO:
 *  -> These Input components need to be updated to better work with & without
 *     redux-forms.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'shared/toolkit/Form';
import defaultStyles from './InputControl.module.scss';

class InputControl extends PureComponent {
  renderInput = (option, index, styles) => {
    const { input, type, defaultChecked, disabled, note, required, className } = this.props;
    const labelFor = `${input.name}-${index}`;
    const isToggle = type === 'toggle';

    return (
      <div key={ option.value } className={ styles[className] }>
        <input
          { ...input }
          id={ labelFor }
          type={ isToggle ? 'checkbox' : type }
          className={ `${styles.InputControlInput} ${className}` }
          disabled={ disabled }
          required={ required }
          value={ option.value }
          checked={ (option.value && option.value === input.value) || defaultChecked }
        />

        <label htmlFor={ labelFor } className={ styles.InputControlLabel + styles.label }>
          { !isToggle && option.title }
        </label>
        { note && <small>{ note }</small> }
      </div>
    );
  };

  renderInputOptions = (options, styles) => options.map((option, index) => this.renderInput(option, index, styles));

  render() {
    const { input, options, label, meta, size, title, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const option = { value: input.value, title };
    return (
      <Field
        name={ input.name }
        label={ label }
        meta={ meta }
        size={ size }
        componentStyles={ styles }
        className={ className === 'InputControlAligned' ? 'FieldAligned' : 'Field' }
      >
        { options.length ? this.renderInputOptions(options, styles) : this.renderInput(option, 0, styles) }
      </Field>
    );
  }
}

InputControl.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ),
  title: PropTypes.string,
  note: PropTypes.node,
  meta: PropTypes.shape({}),
  type: PropTypes.string,
  label: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

InputControl.defaultProps = {
  meta: {},
  options: [],
  title: null,
  note: null,
  type: 'checkbox',
  label: undefined,
  defaultChecked: false,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'InputControl',
};

export default InputControl;
