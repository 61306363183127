import { connect } from 'react-redux';
// Actions
import {
  assignPendingMembersByTeamId,
  assignSelectedMembersToTeam,
  deletePendingMembersByTeamId,
  toggleRosterListSort
} from 'state/leagueRostering/actions';
// Selectors
import {
  reSelectAndSortRosterMembersByDivisionId,
  reSelectAndSortRosterMembersByTeamIdAndPendingTeamId,
  selectCountAssignedToTeam,
  selectRosteringPendingMode,
  selectSelectedMembersCount
} from 'state/leagueRostering/selectors';
import { showModal } from 'state/modal/actions';
// Components
import RosterAssignmentList from '../components/RosterAssignmentList';


const mapDispatchToProps = (dispatch, ownProps) => {
  let teamId = null;
  if (ownProps.teamName) {
    teamId = ownProps.teamName.id;
  }
  return {
    assignMembers: (isManager) => {
      dispatch(assignSelectedMembersToTeam(teamId, isManager));
    },
    assignPendingMembersByTeamId: () => {
      dispatch(assignPendingMembersByTeamId(teamId));
    },
    deletePendingMembersByTeamId: () => {
      dispatch(deletePendingMembersByTeamId(teamId));
    },
    toggleRosterListSort: sortOn => {
      dispatch(toggleRosterListSort(sortOn));
    },
    showModal: (modal, modalProps) => {
      dispatch(showModal(modal, modalProps));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  let members = [];
  let hasPendingTeamMembers = false;
  let pendingTeamMembersCount = 0;
  let teamId = null;
  if (ownProps.division) {
    members = reSelectAndSortRosterMembersByDivisionId(state, ownProps.division.id);
  } else if (ownProps.teamName) {
    teamId = ownProps.teamName.id;
    members = reSelectAndSortRosterMembersByTeamIdAndPendingTeamId(state, teamId);
    hasPendingTeamMembers = ownProps.teamName.pendingMemberCount > 0 || ownProps.teamName.pendingMemberRemovalCount > 0;
    pendingTeamMembersCount = ownProps.teamName.pendingMemberCount + ownProps.teamName.pendingMemberRemovalCount;
  }
  return {
    countOfTeamAssignments: selectCountAssignedToTeam(state)(teamId),
    countOfSelectedMembers: selectSelectedMembersCount(state),
    members: members || [],
    hasPendingTeamMembers,
    pendingTeamMembersCount,
    isPendingMode: selectRosteringPendingMode(state),
    isPlayerTab: ownProps.isPlayerTab || false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RosterAssignmentList);
