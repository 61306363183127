import React, { Component } from 'react';
import AppLoading from 'shared/components/AppLoading';

class RouteNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classic: false,
    };
  }

  UNSAFE_componentWillMount() {
    // If we have a leagueFrontendBaseUrl then we are in classic shell
    // and any non found routes should be loaded by classic
    if (window.TeamSnap.leagueFrontendBaseUrl) {
      this.setState({ classic: true });
      window.location.replace(window.location.href);
    }
  }

  render() {
    if (this.state.classic === true) {
      return <AppLoading loading />;
    }
    return <div>Not Found</div>;
  }
}

export default RouteNotFound;
