import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Grid, Cell, Icon } from '@teamsnap/teamsnap-ui';
import WidgetBase from 'shared/components/Widget';
import WidgetCounts from 'shared/components/Widget/WidgetCounts';

class InitialLayout extends PureComponent {
  renderTopSection = () => {
    const { iconName, counters } = this.props;

    return (
      <Grid>
        <Cell mods="u-size1of3 u-textCenter">
          <Icon style={ { fontSize: '60px', color: '#eee' } } name={ iconName } />
        </Cell>

        <Cell mods="u-size2of3">
          <WidgetCounts items={ counters } />
        </Cell>
      </Grid>
    );
  };

  render() {
    const { infoComponent, headerText, isLoading, buttonText, buttonLocation, buttonColor, children } = this.props;

    return (
      <WidgetBase
        headerText={ headerText }
        infoComponent={ infoComponent }
        isLoading={ isLoading }
        buttonText={ buttonText }
        buttonLocation={ buttonLocation }
        buttonColor={ buttonColor }
      >
        { this.renderTopSection() }
        { children }
      </WidgetBase>
    );
  }
}

InitialLayout.propTypes = {
  iconName: PropTypes.string,
  counters: PropTypes.arrayOf(PropTypes.shape({})),
  infoComponent: PropTypes.node,
  headerText: PropTypes.string,
  isLoading: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonLocation: PropTypes.string,
  buttonColor: PropTypes.string,
  children: PropTypes.node,
};

InitialLayout.defaultProps = {
  iconName: null,
  counters: [],
  infoComponent: null,
  headerText: null,
  isLoading: false,
  buttonText: null,
  buttonLocation: null,
  buttonColor: null,
  children: null,
};

export default InitialLayout;
