/**
 * Registration Health Data
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { COLLECTIONS, queryCollection } from 'state/snapi/slice';

export const loadRegistrationHealth = divisionId => dispatch =>
  dispatch(queryRegistrationHealthAndDateAggregates(divisionId));

export const queryRegistrationHealthAndDateAggregates = divisionId => dispatch =>
  dispatch(
    queryCollection(
      {
        [COLLECTIONS.REGISTRATION_HEALTH]: 'registrationHealthDatum',
        [COLLECTIONS.REGISTRATION_DATE_AGGREGATE]: 'registrationDateAggregates',
      },
      {
        registrationHealthDatum__divisionId: divisionId,
        registrationDateAggregates__divisionId: divisionId,
      },
    ),
  );
