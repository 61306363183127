import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import ErrorMessage from 'shared/components/AppMessage/ErrorMessage';
import NoticeMessage from 'shared/components/AppMessage/NoticeMessage';
import './AppMessage.module.scss';

class AppMessage extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    noticeMessage: PropTypes.string,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    const { errorMessage, noticeMessage } = this.props;
    return (
      <div>
        <CSSTransition classNames="app-message" timeout={ { exit: 100, enter: 300 } } appearTimeout={ 100 } appear>
          <div>
            { errorMessage && <ErrorMessage message={ errorMessage } key="errorMessage" /> }
            { noticeMessage && <NoticeMessage message={ noticeMessage } key="noticeMessage" /> }
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default AppMessage;
