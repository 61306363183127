import { Button, ButtonGroup, Cell, Grid } from '@teamsnap/teamsnap-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  FontIconCoach, FontIconCommissioner, FontIconManager, FontIconMembers, FontIconPendingChanges,
  FontIconPendingMemberAddition,
  FontIconPendingMemberRemoval
} from 'shared/components/ReusedFontIcons';
import RosterAssignmentDivisionBreadcrumbContainer from '../containers/RosterAssignmentDivisionBreadcrumbContainer';
import RosterListContainer from '../containers/RosterListContainer';
import RosterUnassignmentDropTargetContainer from '../containers/RosterUnassignmentDropTargetContainer';
import TeamListContainer from '../containers/TeamListContainer';
import classes from './RosterAssignment.module.scss';

class RosterAssignment extends Component {
  static propTypes = {
    countOfPlayersInFilter: PropTypes.number,
    countOfSelectedMembers: PropTypes.number,
    currentRosterDivisionId: PropTypes.number,
    pendingModeEnabled: PropTypes.bool.isRequired,
    enableRosteringPendingMode: PropTypes.func.isRequired,
    disableRosteringPendingMode: PropTypes.func.isRequired,
    updatePlayerTabStatus: PropTypes.func.isRequired,
  };

  state = {
    isPlayerTab: true,
  };

  setPlayerTab = value => {
    this.setState({ isPlayerTab: value });
    // here dispatch global state
    this.props.updatePlayerTabStatus(value);
  };

  render() {
    const {
      countOfPlayersInFilter,
      countOfSelectedMembers,
      currentRosterDivisionId,
      enableRosteringPendingMode,
      disableRosteringPendingMode,
      pendingModeEnabled,
    } = this.props;

    const rosteringModeTooltipText = `Builder mode lets you make roster changes and publish when you're
    ready. Instant mode moves rosters instantly, and are immediately visible to your roster members.`;

    return (
      <DndProvider backend={ HTML5Backend }>
        <div className="Panel">
          <div className="Panel-header">
            <h3 className="Panel-title u-size2of3">Assign Members to Division / Team</h3>

            <div className="u-size1of3">
              <Grid className="Grid Grid--fit Grid--alignMiddle">
                <Cell className="u-sizeFill u-textRight u-padRightSm">
                  <span className="Tooltip Tooltip--text" data-tooltip={ rosteringModeTooltipText }>
                    <strong>Rostering Mode</strong>
                  </span>
                  { /* eslint-disable max-len */ }
                  <a
                    className="helplink--ss-info"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://helpme.teamsnap.com/article/1818-drag-and-drop-rostering-on-the-rostering-tab"
                  />
                  { /* eslint-enable max-len */ }
                </Cell>
                <Cell className="u-sizeFit">
                  <ButtonGroup>
                    <Button
                      className={ `Button ${!pendingModeEnabled ? 'is-active' : ''}` }
                      onClick={ disableRosteringPendingMode }
                    >
                      Instant
                    </Button>
                    <Button
                      className={ `Button ${pendingModeEnabled ? 'is-active' : ''}` }
                      onClick={ enableRosteringPendingMode }
                    >
                      Builder
                    </Button>
                  </ButtonGroup>
                </Cell>
              </Grid>
            </div>
          </div>
          <div className={ classes.PanelBody }>
            <div className={ classes['grid--1-5'] }>
              <div className={ classes['assignment-helper'] }>
                <span className={ classes['results--pull-left'] }>({ countOfPlayersInFilter }) Results</span>
                <span className={ classes['selected--pull-right'] }>({ countOfSelectedMembers }) Selected</span>
              </div>
              <RosterUnassignmentDropTargetContainer>
                <RosterListContainer />
              </RosterUnassignmentDropTargetContainer>
            </div>
            <div className={ classes['grid--1-5'] }>
              { currentRosterDivisionId && (
                <div>
                  <RosterAssignmentDivisionBreadcrumbContainer />
                  <TeamListContainer
                    currentRosterDivisionId={ currentRosterDivisionId }
                    isPlayerTab={ this.state.isPlayerTab }
                    setIsPlayerTab={ value => this.setPlayerTab(value) }
                  />
                </div>
              ) }
            </div>
          </div>
          <div className="RosterAssignment__panel-footer">
            <div className={ classes.legend }>
              <h5 className={ classes['legend-title'] }>Legend:</h5>
              <ul className={ classes['legend-items'] }>
                <li className={ classes['legend-item'] }>
                  <FontIconMembers />
                  Teams
                </li>
                <li className={ classes['legend-item'] }>
                  <i className="ss-user" />
                  Members
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconManager />
                  Manager
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconCommissioner />
                  Commissioner
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconCoach />
                  Coach
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconPendingChanges className="u-colorHighlight" />
                  Pending Changes
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconPendingMemberAddition className="u-colorSecondary" />
                  Pending Addition
                </li>
                <li className={ classes['legend-item'] }>
                  <FontIconPendingMemberRemoval />
                  Pending Removal
                </li>
              </ul>
            </div>
          </div>
        </div>
      </DndProvider>
    );
  }
}

export default RosterAssignment;
