export const emptyDivisionAggregate = {
  teamCount: 0,
  descendantsTeamCount: 0,
  selfAndDescendantsTeamCount: 0,
  childrenCount: 0,
  descendantsCount: 0,
  assignedMemberCount: 0,
  descendantsAssignedMemberCount: 0,
  selfAndDescendantsAssignedMemberCount: 0,
  unassignedMemberCount: 0,
  descendantsUnassignedMemberCount: 0,
  selfAndDescendantsUnassignedMemberCount: 0,
  selfAndDescendantsPendingMemberCount: 0,
  selfAndDescendantsPendingMemberRemovalCount: 0,
  selfAndDescendantsCommissionerCount: 0,
};
