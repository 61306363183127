"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function get() {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormPill = void 0;
var React = __importStar(require("react"));
var teamsnap_ui_1 = require("@teamsnap/teamsnap-ui");
var PropTypes = __importStar(require("prop-types"));
var propTypes = {
  ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.instanceOf(Element)
  })]),
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['checkbox', 'currency', 'date', 'input', 'radio', 'select', 'text']).isRequired,
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.bool,
  isLocked: PropTypes.bool,
  isRequired: PropTypes.bool,
  onRequiredToggleClick: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  currency: PropTypes.shape({
    name: PropTypes.string,
    symbol: PropTypes.string
  })
};
var FormPill = function FormPill(_a) {
  var id = _a.id,
    name = _a.name,
    type = _a.type,
    text = _a.text,
    placeholder = _a.placeholder,
    selected = _a.selected,
    isLocked = _a.isLocked,
    isRequired = _a.isRequired,
    onRequiredToggleClick = _a.onRequiredToggleClick,
    errors = _a.errors,
    style = _a.style,
    onChange = _a.onChange,
    options = _a.options,
    currency = _a.currency;
  var handleEvent = function handleEvent(event, value) {
    var _a;
    return onChange(event, (_a = {}, _a[name] = value, _a));
  };
  var renderElement = function renderElement() {
    var fontSize = '18px';
    switch (type) {
      case 'checkbox':
        return React.createElement("div", {
          className: "u-flex u-flexJustifyBetween u-flexAlignItemsBaseline"
        }, React.createElement(teamsnap_ui_1.Field, {
          type: "checkbox",
          isInline: true,
          id: "".concat(name, "-").concat(id),
          formFieldProps: {
            size: 'large',
            text: text,
            onClick: function onClick(e) {},
            onChange: function onChange(e) {},
            checked: selected
          },
          style: {
            fontSize: fontSize
          },
          name: name
        }), isLocked && React.createElement("span", {
          className: "checkbox-lock-icon u-fontSizeSm u-colorNeutral6 u-padTopXs"
        }, React.createElement(teamsnap_ui_1.Icon, {
          name: "lock"
        })), selected && !isLocked && React.createElement("button", {
          className: "required-selector",
          onClick: function onClick(e) {
            var _a;
            return onRequiredToggleClick(e, {
              target: {
                name: name,
                value: text
              }
            }, (_a = {}, _a[name] = text, _a));
          }
        }, isRequired ? 'Required' : 'Optional'));
      case 'radio':
        return React.createElement(teamsnap_ui_1.Field, {
          type: "radio",
          isInline: true,
          id: "".concat(name, "-").concat(id),
          formFieldProps: {
            size: 'large',
            text: text,
            onClick: function onClick(e) {},
            onChange: function onChange(e) {},
            checked: selected
          },
          style: {
            fontSize: fontSize
          },
          name: name
        });
      case 'select':
        return React.createElement(teamsnap_ui_1.Select, {
          name: name,
          inputProps: {
            style: {
              fontSize: fontSize,
              paddingLeft: 0,
              borderWidth: 0,
              height: '18px',
              lineHeight: '18px'
            },
            onChange: function onChange(e) {
              return handleEvent(e, options);
            }
          },
          options: options
        });
      case 'date':
        return React.createElement(teamsnap_ui_1.Field, {
          type: "date",
          formFieldProps: {
            size: 'small',
            rightIcon: React.createElement(teamsnap_ui_1.Icon, {
              name: "schedule",
              style: {
                height: 24,
                width: 24
              }
            }),
            inputProps: {
              onChange: function onChange(e) {
                return handleEvent(e, null);
              },
              maxLength: 255,
              style: {
                fontSize: fontSize,
                borderWidth: 0,
                boxShadow: 'none'
              }
            }
          },
          name: name
        });
      case 'currency':
        return React.createElement(teamsnap_ui_1.Field, {
          type: "number",
          mods: "u-size1of1",
          formFieldProps: {
            leftIcon: React.createElement("span", null, currency.symbol),
            placeholder: placeholder,
            size: 'small',
            rightIcon: React.createElement("span", null, currency.name),
            inputProps: {
              value: text,
              step: 10,
              min: '0.00',
              onChange: function onChange(e) {
                return handleEvent(e, text);
              },
              onWheel: function onWheel(e) {
                return e.target.blur();
              },
              style: {
                fontSize: fontSize,
                borderWidth: 0,
                boxShadow: 'none',
                touchAction: 'none'
              }
            }
          },
          name: name
        });
      default:
        return React.createElement(teamsnap_ui_1.Field, {
          type: "input",
          mods: "u-size1of1",
          formFieldProps: {
            placeholder: text,
            size: 'small',
            inputProps: {
              value: text,
              onChange: function onChange(e) {
                return handleEvent(e, text);
              },
              maxLength: 255,
              style: {
                fontSize: fontSize,
                padding: 0,
                borderWidth: 0,
                boxShadow: 'none'
              }
            }
          },
          name: name
        });
    }
  };
  return React.createElement("div", {
    style: style
  }, React.createElement(teamsnap_ui_1.Pill, {
    onClick: function onClick(e) {
      var _a;
      if (['radio', 'checkbox'].includes(type) && e.target.localName !== 'label') {
        onChange({
          target: {
            name: name,
            value: text,
            id: id
          }
        }, (_a = {}, _a[name] = text, _a));
      }
    },
    status: !errors ? selected && teamsnap_ui_1.PillStatus.ACTIVE : teamsnap_ui_1.PillStatus.ERROR,
    mods: type === 'checkbox' ? 'u-flex u-flexCol u-padSidesMd u-padEndsLg u-spaceBottomLg cursor-pointer' : 'u-flex u-flexCol u-padSidesMd u-padEndsLg u-spaceBottomLg',
    style: {
      backgroundColor: 'white'
    }
  }, renderElement()), errors && React.createElement("div", {
    className: "FormPill-validation-error u-fontSizeLg"
  }, errors.map(function (error) {
    return React.createElement("p", null, error);
  })));
};
exports.FormPill = FormPill;
exports.FormPill.defautProps = {
  options: [],
  ref: null,
  selected: false,
  currency: {
    name: 'USD',
    symbol: '$'
  }
};