import { loadItems, loadAdditionalQuestionsFilters } from 'state/teamsnap/actions';

const loadLeagueCustomFields = (params) => (dispatch, getState) => {
  dispatch(loadItems('leagueCustomFields', params));
};

const loadLeagueCustomFieldsByDivisionId = (divisionId) => (dispatch) => {
  dispatch(loadLeagueCustomFields({ divisionId }));
};

const loadAdditionalQuestionsFiltersByDivisionId = (divisionId) => (dispatch) => {
  dispatch(loadAdditionalQuestionsFilters({ divisionId }));
};

export { loadLeagueCustomFields, loadLeagueCustomFieldsByDivisionId, loadAdditionalQuestionsFiltersByDivisionId};
