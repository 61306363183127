import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ModalRootContainer = ({ component, props }) => {
  if (!component) {
    return null;
  }
  const ModalComponent = component;

  return <ModalComponent { ...props } />;
};

ModalRootContainer.propTypes = {
  component: PropTypes.func,
  props: PropTypes.object,
};

export default connect(state => state.modal)(ModalRootContainer);
