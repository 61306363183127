"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFormattedAmount = void 0;
var getFormattedAmount = function getFormattedAmount(amount, options) {
  var _a = options || {},
    locale = _a.locale,
    currency = _a.currency;
  var dollarUS = Intl.NumberFormat(locale || 'en-US', {
    style: 'currency',
    currency: currency || 'USD'
  });
  return dollarUS.format(+amount);
};
exports.getFormattedAmount = getFormattedAmount;