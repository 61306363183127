import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Icon } from '@teamsnap/teamsnap-ui';
import { getClassNameStyles } from 'utils/utils';

const baseClassNames = {
  PanelCell: 'Panel-cell',
  PanelExpandableControl: 'Panel-expandableControl',
  PanelExpandableControlIsExpanded: 'Panel-expandableControl is-expanded',
  PanelExpandableControlIcon: 'Panel-expandableControlIcon',
};

class TableCell extends PureComponent {
  getClassName = key => {
    const { componentClassNames, componentStyles } = this.props;
    return getClassNameStyles(key, baseClassNames, componentClassNames, componentStyles);
  };

  renderNestedLink = columnData => {
    const { handleRowClick, showNestedRow } = this.props;

    return (
      <a
        className={ this.getClassName(showNestedRow ? 'PanelExpandableControlIsExpanded' : 'PanelExpandableControl') }
        onClick={ handleRowClick }
        role="presentation"
      >
        <span className="Panel-expandableControlIcon">
          <Icon name="right" style={ { fontSize: '10px' } } />
        </span>

        { columnData }
      </a>
    );
  };

  render() {
    const { row, column, handleRowClick } = this.props;
    const isTriggerColumn = column.nestedLink && handleRowClick;

    // Get Cell data from row by column.key
    const columnData = _get(row, column.key);

    // Get classNames from key root if nested otherwise return column default or component default
    const cellClassNames = _get(row, `${column.key.split('.')[0]}.className`, column.className);

    return (
      <div className={ cellClassNames || this.getClassName('PanelCell') }>
        { isTriggerColumn && this.renderNestedLink(columnData) }
        { !isTriggerColumn && columnData }
      </div>
    );
  }
}

TableCell.propTypes = {
  row: PropTypes.shape({}).isRequired,
  column: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  handleRowClick: PropTypes.func,
  showNestedRow: PropTypes.bool,
  componentClassNames: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
};

TableCell.defaultProps = {
  handleRowClick: null,
  showNestedRow: false,
  componentStyles: {},
  componentClassNames: {},
};

export default TableCell;
