import { createSlice } from '@reduxjs/toolkit';
import _omit from 'lodash/omit';
import _mapKeys from 'lodash/mapKeys';
import _groupBy from 'lodash/groupBy';
import { plissken } from 'utils/utils';
import api from 'utils/api';

export const COLLECTIONS = {
  DIVISION: 'divisions',
  DIVISION_BEHAVIORS: 'division_behaviors',
  DIVISION_STRUCTURE_HEALTH: 'division_structure_health',
  HEALTH_CHECK_QUESTIONNAIRE: 'health_check_questionnaire',
  HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE: 'health_check_questionnaire_template',
  HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION: 'health_check_questionnaire_templates_question',
  INVOICING_HEALTH: 'invoicing_health',
  MEMBER: 'member',
  MEMBER_REGISTRATION_SIGNUPS: 'member_registration_signups',
  PROGRAM_MEMBERS: 'program_members',
  PROGRAM_MEMBERSHIPS: 'program_memberships',
  PROGRAM_MEMBER_PHOTOS: 'program_member_photos',
  REGISTRATION_DATE_AGGREGATE: 'registration_date_aggregate',
  REGISTRATION_FORM: 'registration_form',
  REGISTRATION_HEALTH: 'registration_health',
  TEAM_NAMES: 'team_names',
};

const snapiSlice = createSlice({
  name: 'snapi',
  initialState: Object.values(COLLECTIONS).reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: {
        isFetching: false,
        isError: false,
        status: 'none',
      },
    }),
    {},
  ),
  reducers: {
    fetch(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = true;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'loading';
    },
    save(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = {
        ...state[action.payload.key].items,
        ...action.payload.items,
      };
    },
    update(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = action.payload.items;
    },
    remove(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = _omit(state[action.payload.key].items, action.payload.item);
    },
    reset(state, action) {
      state[action.payload.key] = {};
    },
    error(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = true;
      state[action.payload.key].status = 'error';
    },
  },
});

export const { update, save, fetch, remove, reset, error: SnapiError } = snapiSlice.actions;

export const getCollection = (actionName, url, params, options = {}) => (dispatch, getState) => {
  const { replaceState, selectorCallback, fullResponse } = options;

  // Check if selector is passed in and check state before fetching action (should this check be elsewhere?).
  if (selectorCallback) {
    const collectionState = selectorCallback(getState());
    const isArray = Array.isArray(collectionState);

    // Check if array is not empty or value is 'truthy' and return selector items
    if ((isArray && collectionState.length) || (!isArray && collectionState)) {
      return Promise.resolve(collectionState);
    }
  }

  // Collection items did not exist in state, fetch from API
  dispatch(fetch({ key: actionName }));

  return api
    .get(url, params)
    .then(response => {
      const mappedKeys = _mapKeys(response.items, 'id');

      dispatch(
        replaceState ? update({ key: actionName, items: mappedKeys }) : save({ key: actionName, items: mappedKeys }),
      );
      return Promise.resolve(fullResponse ? response : response.items);
    })
    .catch(error => {
      dispatch(SnapiError({ key: actionName }));
      return Promise.reject(error);
    });
};

/**
 * queryCollection expects both actionNames and params to be objects
 * The actionName keys will be used as the query 'type' in the request, it will also format
 * these items as camelCase for parsed collectionJSON response.
 *
 * For example:
 *  queryCollection({ BATCH_INVOICE, INVOICE }, { batchInvoice__id: 1, invoice__batchInvoiceId: 1})
 */
export const queryCollection = (actionNames, params) => dispatch => {
  const actions = Object.keys(actionNames).map(action => {
    const name = actionNames[action];

    return {
      name: action,
      requestType: plissken(name, true),
      responseType: plissken(name),
    };
  });

  // Set fetching state for each query item.
  actions.map(action => dispatch(fetch({ key: action.name })));

  // Stringify 'types' for query endpoint.
  const types = actions.map(action => action.requestType).join(',');

  // Make API 'query' request and dispatch collections
  return api
    .get('q', { types, ...params })
    .then(response => {
      const groupItems = _groupBy(response.items, item => plissken(item.type));

      actions.map(action =>
        dispatch(
          save({
            key: action.name,
            items: _mapKeys(groupItems[action.responseType], 'id'),
          }),
        ),
      );

      return Promise.resolve(groupItems);
    })
    .catch(error => {
      actions.map(action => dispatch(SnapiError({ key: action.name })));

      return Promise.reject(error);
    });
};

// Create Collection by name, url and params
export const createCollection = (actionName, url, params, command = false) => dispatch => {
  dispatch(fetch({ key: actionName }));
  const apiType = command ? 'cmd' : 'post';
  return api[apiType](url, params, command)
    .then(response => {
      dispatch(save({ key: actionName, items: _mapKeys(response.items, 'id') }));
      return Promise.resolve(response.items);
    })
    .catch(error => {
      dispatch(SnapiError({ key: actionName }));
      return Promise.reject(error);
    });
};

export const updateCollectionItem = (actionName, item) => ({
  type: `UPDATE_ITEM_${actionName}`,
  payload: item,
});

export const deleteCollection = (actionName, url, item) => dispatch =>
  api
    .delete(`${url}/${item.id}`)
    .then(_response => {
      dispatch(remove({ key: actionName, item }));
      return Promise.resolve();
    })
    .catch(error => {
      dispatch(SnapiError({ key: actionName }));
      return Promise.reject(error);
    });

export default snapiSlice;
