import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import classes from './DivisionAssignmentOverview.module.scss';



function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

const rosterList = {
  drop(props) {
    props.assignMembers();
  },
};

const MemberNumberDetail = ({ text, number, numberCssClassName }) => (
  <span className={ classes['OverviewCount-item'] }>
    <h5 className={ classes['OverviewCount-title'] }>{ text }</h5>
    <b
      className={
        numberCssClassName
          ? [numberCssClassName, classes['OverviewCount-count']].join(' ')
          : classes['OverviewCount-count']
      }
    >
      { number }
    </b>
  </span>
);

MemberNumberDetail.propTypes = {
  text: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  numberCssClassName: PropTypes.string,
};

class DivisionAssignmentOverview extends Component {
  static propTypes = {
    allMemberCount: PropTypes.number,
    allRosteredCount: PropTypes.number,
    allUnRosteredCount: PropTypes.number,
    assignedToDivisionCount: PropTypes.number,
    assignMembers: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    countOfSelectedMembers: PropTypes.number,
    countOfTeamAssignments: PropTypes.number,
    division: PropTypes.object,
    isPlayerTab: PropTypes.bool,
    isPendingMode: PropTypes.bool.isRequired,
    setIsPlayerTab: PropTypes.func,
    isOver: PropTypes.bool,
    pendingChangesCount: PropTypes.number,
  };

  render() {
    const {
      allMemberCount,
      allRosteredCount,
      allUnRosteredCount,
      assignedToDivisionCount,
      assignMembers,
      connectDropTarget,
      countOfSelectedMembers,
      countOfTeamAssignments,
      division,
      isPlayerTab,
      isPendingMode,
      setIsPlayerTab,
      isOver,
      pendingChangesCount,
      selfAndDescendantsCommissionerCount,
    } = this.props;

    return connectDropTarget(
      <div className={ classes.DivisionAssignmentOverviewRow }>
        <div className={ classes.MembersOverview }>
          <div className={ classes.OverviewCount }>
            <MemberNumberDetail text="Total Members" number={ allMemberCount - selfAndDescendantsCommissionerCount } />
            <MemberNumberDetail text="Not Rostered" number={ allUnRosteredCount - selfAndDescendantsCommissionerCount } />
            <MemberNumberDetail text="Rostered" number={ allRosteredCount } />
            <MemberNumberDetail
              text="Pending Changes"
              number={ pendingChangesCount }
              numberCssClassName="u-colorHighlight"
            />
          </div>
        </div>
       { !isPendingMode && (
          <div className="Reg-Overview__sub-nav align-left">
            <h3
              onClick={ () => setIsPlayerTab(!isPlayerTab) }
              className={ isPlayerTab === true ? 'Reg-Overview__sub-nav-item ' + classes['active-tab'] : 'Reg-Overview__sub-nav-item' }
            >
              Player
            </h3>
            <h3
              onClick={ () => setIsPlayerTab(!isPlayerTab) }
              className={ isPlayerTab === false ? 'Reg-Overview__sub-nav-item ' + classes['active-tab'] : 'Reg-Overview__sub-nav-item' }
            >
              Non-Player
            </h3>
          </div>
        ) }
        <div
          onClick={ assignMembers }
          className={
            countOfSelectedMembers || countOfTeamAssignments
              ? [classes['drop-zone'], classes.active].join(' ')
              : classes['drop-zone']
          }
        >
          <div className={ classes['drop-info'] }>- Drop Here or Click to Assign to { division.name } -</div>
        </div>
      </div>,
    );
  }
}

export default DropTarget('row', rosterList, collect)(DivisionAssignmentOverview);
