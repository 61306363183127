import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { selectDivisionAndChildDivisionIds } from 'state/teamsnap/divisions/selectors';
import { selectMemberById } from 'state/teamsnap/members/selectors';
import { select, selectAndSortBy, selectAsArray } from 'state/teamsnap/selectors';

const selectTeamNames = state => select(state, 'teamNames');
const selectTeamNamesById = (state, id) => selectTeamNames(state)[_parseInt(id)];
const selectTeamNamesAsArray = state => selectAsArray(state, 'teamNames');

const selectAndSortTeamNamesByDivisionId = createSelector([selectTeamNames], teams =>
  selectAndSortBy(teams, 'divisionId'),
);

const selectTeamNamesByDivisionId = state => divisionId =>
  selectAndSortTeamNamesByDivisionId(state)[_parseInt(divisionId)] || [];

const selectAllTeamNamesUnderDivisionId = (state, divisionId) => {
  if (!divisionId) {
    return [];
  }

  const allTeamNames = [];
  const divisionIds = selectDivisionAndChildDivisionIds(state, divisionId);
  divisionIds.forEach(id => {
    const teamNames = selectTeamNamesByDivisionId(state)(id);
    if (teamNames && teamNames.length > 0) {
      allTeamNames.push(...teamNames);
    }
  });

  return allTeamNames.sort((a, b) => a.name.localeCompare(b.name));
};

const selectMemberCountByTeamId = (state, teamId, isPlayerTab, isPendingMode) => {
  const team = selectTeamNamesById(state, teamId);
  if (team) {
    if(isPendingMode) {
      return team.playerMemberCount + team.nonPlayerMemberCount;
    } else if (isPlayerTab) {
      return team.playerMemberCount;
    } else {
      return team.nonPlayerMemberCount;
    }
  }
  return 0;
};

const selectPendingChangesCountByTeamId = (state, teamId) => {
  const team = selectTeamNamesById(state, teamId);
  if (team) {
    return team.pendingMemberCount + team.pendingMemberRemovalCount;
  }
  return 0;
};

const selectTeamNameForMemberId = (state, memberId) => {
  const member = selectMemberById(state, memberId);
  let team = null;
  if (member && member.teamId) {
    team = selectTeamNamesById(state, member.teamId);
  }
  return team;
};

const selectPendingOrAssignedTeamNameForMemberId = (state, memberId) => {
  const member = selectMemberById(state, memberId);
  let team = null;
  if (member && member.pendingTeamId) {
    team = selectTeamNamesById(state, member.pendingTeamId);
  } else if (member && member.teamId) {
    team = selectTeamNamesById(state, member.teamId);
  }
  return team;
};

export {
  selectTeamNames,
  selectTeamNamesById,
  selectTeamNamesAsArray,
  selectTeamNamesByDivisionId,
  selectAllTeamNamesUnderDivisionId,
  selectMemberCountByTeamId,
  selectPendingChangesCountByTeamId,
  selectPendingOrAssignedTeamNameForMemberId,
  selectTeamNameForMemberId,
};
