import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { featureRollout } from 'utils/window';

import scrollToElement from 'scroll-to-element';

import AppLoading from 'shared/components/AppLoading';
import DotLoading from 'shared/components/DotLoading';

import Panel from 'shared/toolkit/Panel';
import Button from 'shared/toolkit/Button';

import LeagueRosterSubnav from './LeagueRosterSubnav';
import RosterAssignmentContainer from '../containers/RosterAssignmentContainer';
import EditFilterContainer from '../containers/EditFilterContainer';
import SavedFiltersContainer from '../containers/SavedFiltersContainer';
import SearchMembersContainer from '../containers/SearchMembersContainer';

import classes from './LeagueRoster.module.scss';

class LeagueRoster extends Component {
  static propTypes = {
    activeDivisionTreeIds: PropTypes.array,
    applyFilter: PropTypes.func,
    clearFilter: PropTypes.func,
    countOfPlayersInFilter: PropTypes.number,
    currentFilter: PropTypes.object,
    division: PropTypes.object,
    hasFilter: PropTypes.bool,
    isSearching: PropTypes.bool,
    loading: PropTypes.bool,
    loadInitialPlayers: PropTypes.func,
    initLeagueRoster: PropTypes.func.isRequired,
    savePreviousFilteredSession: PropTypes.func,
  };

  clearSearch = () => {
    if (!this.props.isSearching && this.props.countOfPlayersInFilter === 0) {
      this.props.clearFilter();
      this.props.loadInitialPlayers();
    } else {
      this.props.clearFilter();
    }
  };

  scrollToSearch = () => {
    scrollToElement('#searchBox');
  };

  submitSearch = () => {
    this.props.applyFilter();
  };

  UNSAFE_componentWillMount() {
    this.props.initLeagueRoster();
    window.addEventListener('beforeunload', e => {
      this.props.savePreviousFilteredSession();
    });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', e => {
      this.props.savePreviousFilteredSession();
    });
    if (!this.props.isSearching) {
      this.props.savePreviousFilteredSession();
    }
  }

  componentDidUpdate() {
    /**
     * When we update this component with our props, we want to validate that
     * the filter we have is a valid filter for a given user
     *
     * Since rostering is contextual to a division, we can do this by ensuring
     * that the currentFilter's division exists in the division tree.
     *
     * Failure to do this will result in our filters trying to populate themselves
     * based off of a divisions data that the current user does not have access to.
     * The above can happen if a user changes divisions with saved filters or
     * if setting up a filter for a division at that specific division,
     * persisting the filter and then moving down the division tree to roster
     * at a subdivision.
     *
     * TLDR: This might look weird but we don't want to remove this code.
     */
    const treeIds = this.props.activeDivisionTreeIds;
    if (treeIds.length > 0 && this.props.currentFilter.divisionId) {
      if (!treeIds.includes(+this.props.currentFilter.divisionId)) {
        this.props.clearFilter();
      }
    }
  }

  componentDidMount() {
    if (!this.props.hasFilter && !this.props.isSearching) {
      this.props.loadInitialPlayers();
    }
  }

  render() {
    const { division, isSearching, loading } = this.props;

    if (loading || !division) {
      return <AppLoading loading />;
    }

    return (
      <div>
        <LeagueRosterSubnav />

        <Panel
          title={ `Search Members in ${division.name}` }
          componentStyles={ classes }
        >
          <SavedFiltersContainer />

          <SearchMembersContainer submitSearch={ this.submitSearch } isSearching={ isSearching } />

        </Panel>

        <br />

        <RosterAssignmentContainer isSearching={ isSearching } />
      </div>
    );
  }
}

export default LeagueRoster;
