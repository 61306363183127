import * as constants from './constants';
import { selectFormByName } from './selectors';

const updateField = ({ formName, fieldName, fieldValue }) => ({
  type: constants.FORM_UPDATE_FIELD,
  formName,
  fieldName,
  fieldValue,
});

const addForm = formName => ({
  type: constants.ADD_FORM,
  formName,
});

const addFormError = ({ errorMessage, formName }) => ({
  type: constants.ADD_FORM_ERROR,
  errorMessage,
  formName,
});

const createFormWithName = formName => (dispatch, getState) => {
  const existingForm = selectFormByName(getState(), formName);
  if (existingForm) {
    dispatch(
      addFormError({
        errorMessage: 'A form with this name already exists!',
        formName,
      }),
    );
  } else {
    dispatch(addForm(formName));
    return selectFormByName(getState(), formName);
  }
};

const updateFieldByFormName = (formName, fieldName, fieldValue) => (dispatch, getState) => {
  // Call create in case this form doesn't exist in the store
  dispatch(createFormWithName(formName));

  dispatch(updateField({ formName, fieldName, fieldValue }));
};

export {
  createFormWithName,
  updateFieldByFormName,
  // private
  addForm as _addForm,
  addFormError as _addFormError,
  updateField as _updateField,
};
