/**
 * Loader Component
 *  - https://teamsnap-ui-patterns.netlify.com/patterns/components/loader.html
 *
 * A loader component can recieve different 'types' as well as messages.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { randomStartupMessage } from 'utils/startupMessages';
import defaultStyles from './Loader.module.scss';

class Loader extends PureComponent {
  renderSpinAnimation = () => <span className={ defaultStyles.SpinAnimation } />;

  renderPulseAnimation = () => (
    <span className={ defaultStyles.PulseAnimation }>
      <span className={ defaultStyles.PulseAnimationDot } />
      <span className={ defaultStyles.PulseAnimationDot } />
      <span className={ defaultStyles.PulseAnimationDot } />
    </span>
  );

  renderJelloAnimation = () => (
    <span className={ defaultStyles.JelloAnimation }>
      <span className={ defaultStyles.JelloAnimationShadow } />
      <span className={ defaultStyles.JelloAnimationBox } />
    </span>
  );

  renderAnimation = type => {
    if (type === 'jello') {
      return this.renderJelloAnimation();
    }
    if (type === 'pulse') {
      return this.renderPulseAnimation();
    }
    return this.renderSpinAnimation();
  };

  render() {
    const { type, text, message, componentStyles, className, style } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const loaderClass = type === 'jello' ? `${className}Jello` : `${className}`;

    const loaderMessage = message === 'random' ? randomStartupMessage() : message;

    return (
      <div className={ styles[loaderClass] } style={ style }>
        <div className={ styles.LoaderIndicator }>
          { this.renderAnimation(type) }
          { text && <div className={ styles.LoaderText }>{ text }</div> }
        </div>
        { message && <div className={ styles.LoaderMessage }>{ loaderMessage }</div> }
      </div>
    );
  }
}

Loader.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  message: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

Loader.defaultProps = {
  type: 'spin',
  text: 'Loading',
  message: null,
  componentStyles: {},
  className: 'Loader',
  style: {},
};

export default Loader;
