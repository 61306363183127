import _forOwn from 'lodash/forOwn';
import _parseInt from 'lodash/parseInt';
import _values from 'lodash/values';
import { createSelector } from 'reselect';

import { select } from 'state/teamsnap/selectors';
import { selectMemberEmailAddressesByMemberId } from 'state/teamsnap/memberEmailAddresses/selectors';
import { selectContactEmailAddressesByMemberId } from 'state/teamsnap/contactEmailAddresses/selectors';
import { selectDivisions, selectDivisionAndChildDivisionIds } from 'state/teamsnap/divisions/selectors';
import { selectTeamsByDivisionId } from 'state/teamsnap/teams/selectors';
import { selectTeamNames } from 'state/teamsnap/teamNames/selectors';

const selectMembers = state => select(state, 'members');
const selectMemberById = (state, id) => selectMembers(state)[_parseInt(id)];
const selectAndSortMembersByTeamId = createSelector([selectMembers], members => {
  const sortedByTeamId = {};
  _forOwn(members, member => {
    if (member.teamId) {
      if (!sortedByTeamId[member.teamId]) {
        sortedByTeamId[member.teamId] = [];
      }
      sortedByTeamId[member.teamId].push(member);
    }
  });
  return sortedByTeamId;
});

const selectAndSortMembersByTeamIdAndPendingTeamId = createSelector([selectMembers], members => {
  const sortedByTeamId = {};
  _forOwn(members, member => {
    if (member.teamId) {
      if (!sortedByTeamId[member.teamId]) {
        sortedByTeamId[member.teamId] = [];
      }
      sortedByTeamId[member.teamId].push(member);
    }

    if (member.pendingTeamId) {
      if (!sortedByTeamId[member.pendingTeamId]) {
        sortedByTeamId[member.pendingTeamId] = [];
      }
      sortedByTeamId[member.pendingTeamId].push(member);
    }
  });
  return sortedByTeamId;
});

const selectMembersByPendingTeamId = createSelector([selectMembers], members => {
  const sortedByPendingTeamId = {};
  _forOwn(members, member => {
    if (member.pendingTeamId) {
      if (!sortedByPendingTeamId[member.pendingTeamId]) {
        sortedByPendingTeamId[member.pendingTeamId] = [];
      }
      sortedByPendingTeamId[member.pendingTeamId].push(member);
    }
  });
  return sortedByPendingTeamId;
});

const selectAssignedMembers = state => {
  const assignedMembers = [];
  const members = selectMembers(state);
  Object.keys(members).map(memberId => {
    const member = members[memberId];
    if (member.teamId) {
      return assignedMembers.push(member);
    }
    return null;
  });
  return assignedMembers;
};

const selectCountAssignedMembers = state => {
  const assignedMembers = selectAssignedMembers(state);
  return assignedMembers.length;
};

const selectMembersAndDivisionsByDivisionId = (state, divisionId) =>
  linkDivisionsToMembers(state, selectMembersByDivisionId(state)(divisionId));

const selectMembersAndTeamsByTeamId = (state, teamId) =>
  linkTeamsToMembers(state, selectMembersByTeamId(state)(teamId));

const selectMembersAndTeamsByTeamIdAndPendingTeamId = (state, teamId) =>
  linkTeamsToMembers(state, selectMembersByTeamIdAndPendingTeamId(state)(teamId));

const selectMembersForDivisionAndChildren = state => divisionId => {
  const selectedMembers = [];
  if (!divisionId) {
    return selectedMembers;
  }
  const members = selectMembers(state);
  const divisionIds = selectDivisionAndChildDivisionIds(state, divisionId);
  Object.keys(members).forEach(id => {
    const member = members[id];
    if (member.divisionId && divisionIds.indexOf(member.divisionId) > -1) {
      selectedMembers.push(member);
    }
  });
  return selectedMembers;
};

const selectCountMembersForDivisionAndChildren = state => divisionId => {
  const members = selectMembersForDivisionAndChildren(state)(divisionId);
  return members.length;
};

const selectLoggedInMemberId = state => state.app.loggedInMemberId;

const selectLoggedInMember = state => selectMemberById(state, selectLoggedInMemberId(state));

const selectEmailAddressesByMemberId = (state, memberId) =>
  selectContactEmailAddressesByMemberId(state)(memberId).concat(selectMemberEmailAddressesByMemberId(state)(memberId));

// Returns division commissioners indexed by divisionId
// `noIndex` will return single a single commissioner object when a single divisionId is used
const selectCommissionersByDivisionIds = (state, divisionIds, noIndex) => {
  if (!Array.isArray(divisionIds)) {
    // Let's ensure we're working with an array of divisionIds
    divisionIds = [divisionIds];
  }
  let commissioners = {};
  const membersById = selectMembers(state);
  Object.keys(membersById).some(key => {
    const member = membersById[key];
    const divisionIdIndex = divisionIds.indexOf(member.divisionId);
    // Is this the droid we're looking for?
    if (divisionIdIndex > -1 && member.isCommissioner) {
      const divisionId = divisionIds[divisionIdIndex];
      commissioners[divisionId] = member;
    }
    // Prevent the loop from continuing by determining if
    // we've matched up a member for every `divisionId`
    if (Object.keys(commissioners).length === divisionIds.length) {
      // We can break out of `Array.some()` by returning `true`
      return true;
    }
  });
  // Only return a single member object without the division index
  if (divisionIds.length === 1 && noIndex) {
    if (commissioners[divisionIds[0]]) {
      commissioners = commissioners[divisionIds[0]];
    }
  }
  return commissioners;
};

const selectOwnerByTeamId = (state, teamId, includePending) => {
  let teamOwner = null;
  const membersById = selectMembers(state);
  Object.keys(membersById).some(key => {
    const member = membersById[key];
    if (member.teamId === teamId && (member.isOwner || (includePending && member.isOwnershipPending))) {
      teamOwner = member;
      return true;
    }
  });
  return teamOwner;
};

const selectTeamOwnersByDivisionId = (state, divisionId, includePending) => {
  const teamOwners = {};
  const divisionTeams = selectTeamsByDivisionId(state)(divisionId);
  if (!divisionTeams.length) {
    return;
  }
  const divisionTeamIds = divisionTeams.map(team => team.id);

  if (divisionTeams.length) {
    const membersById = selectMembers(state);
    Object.keys(membersById).some(key => {
      const member = membersById[key];
      const teamIdIndex = divisionTeamIds.indexOf(member.teamId);
      if (teamIdIndex > -1 && (member.isOwner || (includePending && member.isOwnershipPending))) {
        const teamId = divisionTeamIds[teamIdIndex];
        teamOwners[teamId] = member;
      }
      if (Object.keys(teamOwners).length === divisionTeamIds.length) {
        return true;
      }
    });
  }
  return teamOwners;
};

const selectUnassignedMembers = state => {
  const unassignedMembers = [];
  const members = selectMembers(state);
  Object.keys(members).forEach(memberId => {
    const member = members[memberId];
    if (member.teamId === null) {
      unassignedMembers.push(member);
    }
  });
  return unassignedMembers;
};

const selectUnassignedMembersCount = state => {
  const unassignedMembers = selectUnassignedMembers(state);
  return unassignedMembers.length;
};

const selectMembersById = (state, memberIds) => {
  const members = {};
  if (!Array.isArray(memberIds)) {
    memberIds = [memberIds];
  }
  memberIds.forEach(memberId => {
    const member = selectMemberById(state, memberId);
    if (member && member.id) {
      members[member.id] = member;
    }
  });
  return members;
};

const selectAndSortOwnersByTeamId = createSelector([selectMembers], members => {
  const sortedByTeamId = {};
  _forOwn(members, member => {
    if (member.teamId && (member.isOwner || member.isOwnershipPending)) {
      if (!sortedByTeamId[member.teamId]) {
        sortedByTeamId[member.teamId] = [];
      }
      sortedByTeamId[member.teamId].push(member);
    }
  });
  return sortedByTeamId;
});

const selectOwnersByTeamId = state => teamId => selectAndSortOwnersByTeamId(state)[_parseInt(teamId)] || [];

const selectAndSortCommissionersByDivisionId = createSelector([selectMembers], members => {
  const sortedByDivisionId = {};
  _forOwn(members, member => {
    if (member.isCommissioner) {
      if (!sortedByDivisionId[member.divisionId]) {
        sortedByDivisionId[member.divisionId] = [];
      }
      sortedByDivisionId[member.divisionId].push(member);
    }
  });
  return sortedByDivisionId;
});

const selectCommissionersByDivisionId = (state, divisionId) =>
  selectAndSortCommissionersByDivisionId(state)[_parseInt(divisionId)] || [];

const selectMembersByTeamId = state => teamId => selectAndSortMembersByTeamId(state)[_parseInt(teamId)] || [];

const selectMembersByTeamIdAndPendingTeamId = state => teamId =>
  selectAndSortMembersByTeamIdAndPendingTeamId(state)[_parseInt(teamId)] || [];

const selectMembersByDivisionId = state => divisionId => {
  const selectedMembers = [];
  const members = selectMembers(state);
  _forOwn(members, member => {
    if (member.divisionId === divisionId) {
      selectedMembers.push(member);
    }
  });
  return selectedMembers;
};

const linkTeamsToMembers = (state, members) => {
  let linkedMembers = [];
  if (members) {
    members = _values(members);
    if (members.length) {
      const teams = selectTeamNames(state);
      linkedMembers = members.map(member => {
        member.team = teams[member.teamId] || {};
        return member;
      });
    }
  }
  return linkedMembers;
};

const linkDivisionsToMembers = (state, members) => {
  let linkedMembers = [];
  if (members) {
    if (!Array.isArray(members)) {
      members = _values(members);
    }
    if (members.length) {
      const divisions = selectDivisions(state);
      linkedMembers = members.map(member => {
        member.division = divisions[member.divisionId] || {};
        return member;
      });
    }
  }
  return linkedMembers;
};

const selectIsLoggedInMemberEmailable = state => {
  const member = selectLoggedInMember(state);
  return member.isEmailable;
};

export {
  linkDivisionsToMembers,
  linkTeamsToMembers,
  selectAssignedMembers,
  selectCountAssignedMembers,
  selectCountMembersForDivisionAndChildren,
  selectMembersAndDivisionsByDivisionId,
  selectMembersAndTeamsByTeamId,
  selectMembersAndTeamsByTeamIdAndPendingTeamId,
  selectMembersByDivisionId,
  selectMembersForDivisionAndChildren,
  selectMembers,
  selectMemberById,
  selectMembersByPendingTeamId,
  selectMembersByTeamId,
  selectMembersByTeamIdAndPendingTeamId,
  selectLoggedInMemberId,
  selectLoggedInMember,
  selectEmailAddressesByMemberId,
  selectCommissionersByDivisionIds,
  selectMembersById,
  selectOwnerByTeamId,
  selectTeamOwnersByDivisionId,
  selectUnassignedMembers,
  selectUnassignedMembersCount,
  selectAndSortOwnersByTeamId,
  selectOwnersByTeamId,
  selectAndSortCommissionersByDivisionId,
  selectCommissionersByDivisionId,
  selectIsLoggedInMemberEmailable,
};
