import { connect } from 'react-redux';

// Actions
import { removeSavedFilterWithLocalStorage, useSavedFilter } from 'state/leagueRostering/actions';
// Selectors
import { selectActiveSavedFilterId, selectFilters } from 'state/leagueRostering/selectors';
// Components
import SavedFilters from '../components/SavedFilters';

const mapDispatchToProps = dispatch => ({
  useSavedFilter: filter => {
    dispatch(useSavedFilter(filter));
  },
  removeSavedFilter: filterIndex => dispatch(removeSavedFilterWithLocalStorage(filterIndex)),
});

const mapStateToProps = state => ({
  activeSavedFilterId: selectActiveSavedFilterId(state),
  savedFilters: selectFilters(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedFilters);
