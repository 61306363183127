import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'shared/toolkit';
import { Button, Panel, PanelCell, PanelFooter, FieldWrapper } from '@teamsnap/teamsnap-ui';

const QuestionEditModal = props => {
  const { prompt, response, closeModal, submitModal, type } = props;

  const [promptValue, setPromptValue] = React.useState(prompt);
  const [defaultPrompt, setDefaultPrompt] = React.useState(prompt);
  const [responseValue, setResponseValue] = React.useState(response);
  const [defaultResponse, setDefaultResponse] = React.useState(prompt);

  useEffect(() => {
    setDefaultPrompt(prompt);
  }, [prompt]);

  useEffect(() => {
    setDefaultResponse(response);
  }, [response]);

  const isInvalid = (promptValue === defaultPrompt && defaultResponse === responseValue) || promptValue.trim() === '';

  const clickSubmitForm = e => {
    submitModal(e);
  };

  return (
    <Modal handleClose={ closeModal } title={ `${type} Question` } overlay>
      <form onSubmit={ clickSubmitForm }>
        <Panel mods="u-borderNone u-spaceBottomNone" style={ { width: '550px' } }>
          <PanelCell mods="u-padBottomNone">
            <FieldWrapper
              field="textarea"
              label="Question:"
              name="prompt"
              status={ defaultPrompt !== '' && promptValue.trim() === '' ? 'error' : null }
              message={ defaultPrompt !== '' && promptValue.trim() === '' ? 'This field is required.' : null }
              fieldProps={ {
                inputProps: {
                  required: true,
                  value: promptValue,
                  onChange: e => setPromptValue(e.target.value),
                  placeholder: 'Add a yes/no question...',
                  rows: '3',
                  className: 'Input',
                  style: { height: 'auto', lineHeight: '1.5', padding: '8px' },
                },
              } }
            />
            <FieldWrapper
              field="radio"
              label="How should a person respond to clear this question?"
              name="response"
              fieldProps={ {
                mods: 'Checkbox--pill u-inlineBlock u-spaceRightSm u-spaceTopSm u-padBottomNone',
                style: { width: '80px' },
                options: [
                  {
                    label: 'Yes',
                    inputProps: {
                      defaultChecked: response === 'No' ? true : false,
                    },
                  },
                  {
                    label: 'No',
                    inputProps: {
                      defaultChecked: response === 'Yes' || type === 'New' ? true : false,
                    },
                  },
                ],
                inputProps: {
                  required: true,
                  onChange: e => setResponseValue(e),
                },
              } }
            />
          </PanelCell>
          <PanelFooter>
            <Button
              type="button"
              onClick={ closeModal }
              label="Cancel"
              color="negative"
              mods="u-size1of6 u-spaceRightSm"
            />
            <Button
              type="submit"
              label={ type === 'Edit' ? 'Update' : 'Save' }
              color="primary"
              mods="u-size1of6"
              isDisabled={ isInvalid }
            />
          </PanelFooter>
        </Panel>
      </form>
    </Modal>
  );
};

QuestionEditModal.propTypes = {
  prompt: PropTypes.string,
  response: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default QuestionEditModal;
