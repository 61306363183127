import { getCollection, createCollection, COLLECTIONS } from 'state/snapi/slice';

// Load Members
export const loadProgramMembers = (params, options = {}) => dispatch =>
  dispatch(getCollection(COLLECTIONS.PROGRAM_MEMBERS, 'program_members/search', params, options));

export const loadSingleProgramMember = id => dispatch =>
  dispatch(getCollection(COLLECTIONS.PROGRAM_MEMBERS, `program_members/${id}`));

// beta functionality to allow commissioners to begin the deduplication process to see
// their new members on the league members table
export const triggerMemberDedup = params => dispatch =>
  dispatch(createCollection(COLLECTIONS.PROGRAM_MEMBERS, 'program_members/run_pipeline', params));
