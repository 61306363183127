/*
 * LeagueMessagesPlaceholder
 *
 * TODO:
 *  - We should evaluate if this can be extracted into the toolkit directory as Placeholder?
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Panel } from 'shared/toolkit';
import styles from './LeagueMessagesPlaceholder.module.scss';

class LeagueMessagesPlaceholder extends PureComponent {
  render() {
    const { title, bodyText, buttonText, link, icon } = this.props;

    return (
      <Panel>
        <div className={ styles.PlaceholderInnerContent }>
          <Header size="2" title={ title } iconLeftName={ icon } iconSize="25px" />

          <p className={ styles.PlaceholderBody }>{ bodyText }</p>

          <Button
            type={ typeof link === 'string' ? 'link' : 'button' }
            className="ButtonPrimaryLarge"
            text={ buttonText }
            iconLeft={ icon }
            handleClick={ link }
          />
        </div>
      </Panel>
    );
  }
}

LeagueMessagesPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default LeagueMessagesPlaceholder;
