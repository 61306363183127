import _values from 'lodash/values';
import _forOwn from 'lodash/forOwn';
import { selectMembers } from 'state/members/selectors';
import { selectMemberRegistrationSignupsByMemberId } from 'state/memberRegistrationSignups/selectors';

import { COLLECTIONS } from 'state/snapi/slice';
import { createSelector } from 'reselect';

const stateSelector = state => state.snapi[COLLECTIONS.PROGRAM_MEMBERSHIPS];
const isFetchingSelector = state => stateSelector(state).isFetching;
const isErrorSelector = state => stateSelector(state).isError;
const itemSelector = state => stateSelector(state).items;

// Select members Fetching or Error states
export const selectProgramMembershipsIsFetching = state => isFetchingSelector(state);
export const selectProgramMembershipsIsError = state => isErrorSelector(state);

export const selectProgramMembershipsIsLoading = state => stateSelector(state).status === 'loading';

export const selectProgramMemberships = state => itemSelector(state) || {};
export const selectProgramMembershipsValues = state => _values(itemSelector(state));

const memberRoles = (programMember = {}) => {
  const roles = [];
  if (programMember.isLeagueOwner) {
    roles.push('League Owner');
  }
  if (programMember.isCommissioner) {
    roles.push('Commissioner');
    // SNAPI adds superfluous roles when user is a commissionner. The return below allows the frontend to ignore them
    return roles;
  }
  if (programMember.isOwner) {
    roles.push('Team Owner');
  }
  if (programMember.isManager) {
    roles.push('Manager');
  }
  if (programMember.isCoach) {
    roles.push('Coach');
  }
  if (programMember.isNonPlayer) {
    roles.push('Non-Player');
  }
  return roles;
};

// defaults role to Player since members endpoint does not give us this value
const getRoles = membership => {
  const roles = memberRoles(membership);
  if (roles.length) {
    return roles;
  }
  roles.push('Player');
  return roles;
};

// whenever memberships change, recalulates and sorts by member id
// this is also where we connect program memberships to members aka rosters
export const selectAndSortProgramMembershipsByProgramMemberId = createSelector(
  selectProgramMemberships,
  selectMembers,
  selectMemberRegistrationSignupsByMemberId,
  (programMemberships, members, memberRegSignups) => {
    const sortedProgramMemberships = {};
    _forOwn(programMemberships, programMembership => {
      if (programMembership.programMemberId) {
        if (!sortedProgramMemberships[programMembership.programMemberId]) {
          sortedProgramMemberships[programMembership.programMemberId] = [];
        }
        sortedProgramMemberships[programMembership.programMemberId].push({
          ...programMembership,
          teamName: programMembership.teamName || 'No Team',
          roles: getRoles(members[programMembership.memberId]),
          registration: memberRegSignups[programMembership.memberId] || {},
          member: members[programMembership.memberId] || {},
        });
      }
    });
    return sortedProgramMemberships;
  },
);

export const selectMemberIdsFromProgramMemberships = createSelector(selectProgramMemberships, memberships => {
  const sortedMemberIds = [];
  _forOwn(memberships, membership => {
    if (!sortedMemberIds.includes(membership.memberId)) {
      sortedMemberIds.push(membership.memberId);
    }
  });
  return sortedMemberIds;
});
