/**
 * Table Header Component
 *
 * Table Header displays the columns for the table component.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';
import { getClassNameStyles } from 'utils/utils';
import styles from './TableHeader.module.scss';

const baseClassNames = {
  PanelCell: 'Panel-cell',
  PanelTitle: 'Panel-title',
  PanelRow: 'Panel-row Panel-row--withCells',
};

class TableHeader extends PureComponent {
  getClassName = key => {
    const { componentClassNames, componentStyles } = this.props;
    return getClassNameStyles(key, baseClassNames, componentClassNames, componentStyles);
  };

  // TODO: Update these sortable columns from teamsnap-ui.  THey are not yet completed in teamsnap-ui
  renderSortColumn = column => {
    const { sort, sortReverse } = this.props;

    const columnKey = column.sortKey || column.key;

    const iconTopClass = sort === columnKey && sortReverse === false ? 'IconTopActive' : 'IconTop';
    const iconBottomClass = sort === columnKey && sortReverse === true ? 'IconBottomActive' : 'IconBottom';

    return (
      <a href={ columnKey } onClick={ this.props.onSortClick } style={ { color: 'inherit' } }>
        { column.label }

        <div className={ styles.Icons }>
          <Icon name="up" style={ { fontSize: '10px' } } mods={ styles[iconTopClass] } />
          <Icon name="down" style={ { fontSize: '10px' } } mods={ styles[iconBottomClass] } />
        </div>
      </a>
    );
  };

  renderColumn = column => (
    <div key={ column.key } className={ column.className || this.getClassName('PanelCell') }>
      <h4 className={ this.getClassName('PanelTitle') }>
        { column.sortable && this.renderSortColumn(column) }
        { !column.sortable && column.label }
      </h4>
    </div>
  );

  render() {
    const { columns } = this.props;

    return <div className={ this.getClassName('PanelRow') }>{ columns.map(column => this.renderColumn(column)) }</div>;
  }
}

TableHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.any,
    }),
  ).isRequired,
  sort: PropTypes.string,
  sortReverse: PropTypes.bool,
  onSortClick: PropTypes.func,
  componentClassNames: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
};

TableHeader.defaultProps = {
  sort: null,
  sortReverse: false,
  onSortClick: null,
  componentStyles: {},
  componentClassNames: {},
};

export default TableHeader;
