import _forOwn from 'lodash/forOwn';
import _values from 'lodash/values';
import _parseInt from 'lodash/parseInt';

// -----------------------------------
// select
//
// Given an item type (plural, i.e. `divisions`) return an object stored byId
// or an empty object if that itemType hasn't been loaded
// -----------------------------------
const select = (state, itemType) => {
  if (state.teamsnap[itemType] && state.teamsnap[itemType].byId) {
    return state.teamsnap[itemType].byId;
  }
  return {};
};

// -----------------------------------
// selectById
//
// returns the item based on the id or undefined if it doesn't exist
// -----------------------------------
const selectById = (state, itemType, id) => select(state, itemType)[_parseInt(id)];

// -----------------------------------
// selectAndSortBy
//
// Takes a collection and a by (object key, i.e. memberId)
// and sorts them
// -----------------------------------
const selectAndSortBy = (collection, by) => {
  const sortedBy = {};
  _forOwn(collection, item => {
    if (item[by] !== undefined) {
      if (!sortedBy[item[by]]) {
        sortedBy[item[by]] = [];
      }
      sortedBy[item[by]].push(item);
    }
  });
  return sortedBy;
};

// -----------------------------------
// selectAsArray
//
// Converts a objects values into an array
// -----------------------------------
const selectAsArray = (state, itemType) => _values(select(state, itemType));

export { select, selectById, selectAndSortBy, selectAsArray };
