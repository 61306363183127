import React from 'react';
import PropTypes from 'prop-types';
import { linkTeamHome } from 'utils/links';
import { displayMemberAge } from 'utils/member';

import InputCheckbox from 'shared/components/Form/InputCheckbox';
import {
  FontIconCommissioner,
  FontIconManager,
  FontIconCoach,
  FontIconPendingMemberAddition,
  FontIconPendingMemberRemoval,
} from 'shared/components/ReusedFontIcons';

import classes from './RosterListRow.module.scss';

const roleIcons = member => (
  <span>
    { member.isCommissioner && <FontIconCommissioner /> }

    { member.isManager && <FontIconManager /> }

    { member.isCoach && <FontIconCoach /> }
  </span>
);

const renderPendingMemberIcon = pendingMoveClassName => {
  if (pendingMoveClassName === 'pendingMove--toTeam') {
    return <FontIconPendingMemberAddition className="u-colorSecondary u-spaceRightXs" />;
  }
  if (pendingMoveClassName === 'pendingMove--fromTeam') {
    return <FontIconPendingMemberRemoval className="u-spaceRightXs" />;
  }
};

const renderTeamNameLink = (member, team) => {
  if (member.teamId && team) {
    return <a href={ linkTeamHome(team.id) }>{ team.name }</a>;
  }
  return 'Not Rostered';
};

const renderPendingLabel = (member, pendingTeam, pendingDivision, deletePendingMemberByMemberId) => {
  if (!member.isPending) {
    return null;
  }

  let pendingLocationName = null;
  if (pendingDivision && !pendingTeam) {
    pendingLocationName = pendingDivision.name;
  } else if (pendingTeam) {
    pendingLocationName = pendingTeam.name;
  }

  let cancelLink = null;
  if (deletePendingMemberByMemberId) {
    cancelLink = (
      <div className="u-spaceTopXs">
        <a className="u-colorNegative" onClick={ deletePendingMemberByMemberId }>
          Cancel
        </a>
      </div>
    );
  }

  return (
    <div>
      <span className={ classes.pendingLabel }>(pending to { pendingLocationName })</span>
      { cancelLink }
    </div>
  );
};

const RosterMemberRow = ({
  checkboxHandler,
  checkboxIsChecked,
  className,
  currentMember,
  member,
  showCheckbox,
  style,
  team,
  pendingDivision,
  pendingTeam,
  toggleSelectMember,
  pendingMoveClassName,
  deletePendingMemberByMemberId,
}) => (
  <tr
    className={ `${classes.rosterListItem} ${className} ${pendingMoveClassName ? classes[pendingMoveClassName] : ''}` }
    style={ style }
  >
    <td className={ classes['col-player-name'] } onClick={ toggleSelectMember }>
      { renderPendingMemberIcon(pendingMoveClassName) }
      <span className={ classes.memberName }>{ member.firstName }</span>
      { !member.lastName && roleIcons(member) }
    </td>
    <td className={ classes['col-player-name'] } onClick={ toggleSelectMember }>
      <span className={ classes.memberName }>{ member.lastName }</span>
      { member.lastName && roleIcons(member) }
    </td>
    <td className={ classes['col-player-gender'] } onClick={ toggleSelectMember }>
      { member.gender }
    </td>
    <td className={ classes['col-player-age'] } onClick={ toggleSelectMember }>
      { displayMemberAge(member, currentMember, '-') }
    </td>
    <td className={ classes['col-player-team'] } onClick={ toggleSelectMember }>
      { renderTeamNameLink(member, team, pendingTeam) }
      { renderPendingLabel(member, pendingTeam, pendingDivision, deletePendingMemberByMemberId) }
    </td>
    { showCheckbox && (
      <td className={ classes['col-player-select'] }>
        <InputCheckbox
          checked={ checkboxIsChecked }
          updateHandler={ checkboxHandler }
          name="player_select"
          disabled={ member.isCommissioner }
          title={ member.isCommissioner ? 'Cannot Assign Commissioner' : '' }
        />
      </td>
    ) }
  </tr>
);

RosterMemberRow.propTypes = {
  checkboxHandler: PropTypes.func,
  checkboxIsChecked: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  currentMember: PropTypes.object,
  member: PropTypes.object.isRequired,
  showCheckbox: PropTypes.bool,
  style: PropTypes.object,
  team: PropTypes.shape({}),
  teamName: PropTypes.shape({}),
  pendingDivision: PropTypes.shape({}),
  pendingTeam: PropTypes.shape({}),
  division: PropTypes.object,
  toggleSelectMember: PropTypes.func,
  pendingMoveClassName: PropTypes.string,
  deletePendingMemberByMemberId: PropTypes.func,
};

export default RosterMemberRow;
