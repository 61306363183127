import { SHOW_FEEDBACK, HIDE_FEEDBACK } from '../app/constants';

const initialState = {
  visible: false,
  feedbackProps: {},
};

function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_FEEDBACK:
      return {
        ...state,
        feedbackProps: action.payload.feedbackProps,
        visible: true,
      };
    case HIDE_FEEDBACK:
      return initialState;
    default:
      return state;
  }
}

export default feedbackReducer;
