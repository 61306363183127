import initialState from 'state/initialState';
import * as constants from './constants';

const leagueMembersReducer = (state = initialState.leagueMembers, action) => {
  switch (action.type) {
    case constants.LIST_VIEW_SET_CURRENT_PAGE:
      return {
        ...state,
        listView: {
          ...state.listView,
          currentPage: action.payload.currentPage,
        },
      };
    case constants.LIST_VIEW_SET_TOTAL_PAGES:
      return {
        ...state,
        listView: {
          ...state.listView,
          totalPages: action.payload.totalPages,
        },
      };
    case constants.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT:
      return {
        ...state,
        listView: {
          ...state.listView,
          totalProgramMemberCount: action.payload.totalProgramMemberCount,
        },
      };
    case constants.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE:
      return {
        ...state,
        listView: {
          ...state.listView,
          programMemberIdsPerPage: {
            ...state.listView.programMemberIdsPerPage,
            [action.payload.currentPage]: action.payload.programMemberIds,
          },
        },
      };
    case constants.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE:
      return {
        ...state,
        listView: {
          ...state.listView,
          programMemberIdsPerPage: {
            1: null,
          },
        },
      };
    case constants.LIST_VIEW_SEARCH_FILTERS:
      return {
        ...state,
        listView: {
          ...state.listView,
          searchFilterParams: action.payload.searchFilters,
        },
      };
    default:
      return state;
  }
};

export default leagueMembersReducer;
