/* eslint-disable react/no-unused-prop-types */

/**
 * Grid Component
 *
 * A grid component that wraps Grid Cell children (required)
 *
 * Grid--fit, Grid--withGutter, Grid--equalHeight, Grid--alignCenter, Grid--alignMiddle, Grid--debugger
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Grid extends PureComponent {
  render() {
    const { children, className, classModifiers } = this.props;
    const gridClasses = `${className} ${classModifiers || ''}`;

    return <div className={ gridClasses }>{ children }</div>;
  }
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classModifiers: PropTypes.string,
};

Grid.defaultProps = {
  className: 'Grid',
  classModifiers: null,
};

export default Grid;
