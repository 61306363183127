import _values from 'lodash/values';
import _forOwn from 'lodash/forOwn';
import { createSelector } from 'reselect';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.PROGRAM_MEMBER_PHOTOS];
const isFetchingSelector = state => stateSelector(state).isFetching;
const isErrorSelector = state => stateSelector(state).isError;
const itemSelector = state => stateSelector(state).items;

// Select members Fetching or Error states
export const selectProgramMemberPhotosIsFetching = state => isFetchingSelector(state);
export const selectProgramMemberPhotosIsError = state => isErrorSelector(state);

export const selectProgramMemberPhotosIsLoading = state => stateSelector(state).status === 'loading';

export const selectRawProgramMemberPhotos = state => itemSelector(state) || {};
export const selectProgramMemberPhotosValues = state => _values(itemSelector(state));

export const selectAndSortPhotoByProgramMemberId = createSelector(selectRawProgramMemberPhotos, photos => {
  const sortedPhotos = {};
  _forOwn(photos, photo => {
    if (photo.programMemberId) {
      if (!sortedPhotos[photo.programMemberId]) {
        sortedPhotos[photo.programMemberId] = photo;
      }
    }
  });
  return sortedPhotos;
});
