import _parseInt from 'lodash/parseInt';
import initialState from 'state/initialState';
import teamsnapSdk from 'utils/teamsnapSdk';

function getById(state, pluralItemType) {
  if (state[pluralItemType] && state[pluralItemType].byId) {
    return { ...state[pluralItemType].byId };
  }
  return {};
}

const teamsnapSdkReducer = (state = initialState.teamsnap, action) => {
  if (action.itemType) {
    const pluralItemType = action.itemType === 'participantGroup' ? 'participantGroups' : teamsnapSdk().getPluralType(action.itemType);
    const byId = getById(state, pluralItemType);

    if (/teamsnap.ADD_ITEMS_/g.exec(action.type)) {
      action.items.forEach(item => {
        byId[item.id] = item;
      });
      return {
        ...state,
        [pluralItemType]: { byId },
      };
    }
    if (/teamsnap.UPDATE_ITEM_/g.exec(action.type)) {
      return {
        ...state,
        [pluralItemType]: {
          byId: {
            ...state[pluralItemType].byId,
            [action.item.id]: action.item,
          },
        },
      };
    }
    if (/teamsnap.REMOVE_ITEM_/g.exec(action.type)) {
      const newById = { ...byId };
      delete newById[_parseInt(action.item.id)];
      return {
        ...state,
        [pluralItemType]: { byId: newById },
      };
    }
    return state;
  }
  return state;
};

export default teamsnapSdkReducer;
