import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';
import { sortedHealthCheckQuestionnaire } from 'state/healthCheckQuestionnaire/selectors';
import {
  createNewHealthQuestionnaire,
  createNewHealthQuestionnaireTemplateQuestion,
} from 'state/healthCheckQuestionnaire/actions';

import QuestionnaireEditList from '../components/QuestionnaireEditList';

const mapDispatchToProps = {
  createNewHealthQuestionnaire,
  createNewHealthQuestionnaireTemplateQuestion,
};

const mapStateToProps = state => ({
  questionnaire: sortedHealthCheckQuestionnaire(state),
  rootDivisionId: selectActiveDivisionRootId(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuestionnaireEditList));
