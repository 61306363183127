"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validator = exports.validate = void 0;
var validator = {
  required: function required(value, rule) {
    value = ('' + value).trim();
    if (value == null || value === '') {
      return rule.error;
    }
    return null;
  },
  range: function range(value, rule) {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (rule.min && value < rule.min.value) {
      return rule.min.error;
    }
    if (rule.max && value > rule.max.value) {
      return rule.max.error;
    }
    return null;
  },
  length: function length(value, rule) {
    if (rule.min && value.length < rule.min.value) {
      return rule.min.error;
    }
    if (rule.max && value.length > rule.max.value) {
      return rule.max.error;
    }
    return null;
  }
};
exports.validator = validator;
var validate = function validate(step) {
  var errors = [];
  if (step.validation) {
    for (var _i = 0, _a = Object.entries(step.validation); _i < _a.length; _i++) {
      var _b = _a[_i],
        type = _b[0],
        rule = _b[1];
      var error = validator[type](step.value, rule);
      if (error) {
        errors.push(error);
      }
    }
  }
  return errors.length ? errors : null;
};
exports.validate = validate;