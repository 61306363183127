import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckBoxWithLabel from 'shared/components/CheckBoxWithLabel';
import DotLoading from 'shared/components/DotLoading';
import Table from 'shared/toolkit/Table';
import { RadioButton } from 'shared/toolkit/InputControl';
import _forEach from 'lodash/forEach';

import './ClubEmailRecipientsList.module.scss';

const teamColumns = [
  {
    label: 'Name',
    key: 'name',
    className: 'Panel-cell u-size11of12',
    nestedLink: true,
  },
  { label: 'Action', key: 'action', className: 'Panel-cell u-size1of12' },
];

const radioButtonOptions = [
  { title: 'Everyone (on selected teams)', value: 'team_everyone' },
  { title: 'Managers (on selected teams)', value: 'team_managers' },
];

class ClubEmailRecipientList extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    selectedRecipients: PropTypes.shape({}).isRequired,
    toggleRecipients: PropTypes.func.isRequired,
    divisionLeaves: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    teamNamesByDivisionId: PropTypes.func.isRequired,
  };

  state = {
    divisionTeamData: [],
  };

  UNSAFE_componentWillMount() {
    this.setState({
      divisionTeamData: this.calculateDivisionTeamData(this.props),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedRecipients !== this.props.selectedRecipients) {
      this.setState({
        divisionTeamData: this.calculateDivisionTeamData(nextProps),
      });
    }
  }

  calculateDivisionTeamData = props => {
    const { loading, divisionLeaves, selectedRecipients, teamNamesByDivisionId } = props;
    if (loading) {
      return [];
    }
    return divisionLeaves
      .filter(div => teamNamesByDivisionId(div.id).length > 0)
      .map(divisionData => ({
        id: `division_${divisionData.id}`,
        name: this.renderDivisionName(divisionData, selectedRecipients, teamNamesByDivisionId),
        action: this.renderDivisionCheckbox(divisionData, selectedRecipients, teamNamesByDivisionId),
        nestedRows: teamNamesByDivisionId(divisionData.id).map(teamName => ({
          id: `team_${teamName.id}`,
          name: teamName.name,
          action: this.renderTeamCheckbox(teamName, selectedRecipients),
        })),
      }));
  };

  managersOnlyCheckboxValue = (selectedTeamCount, selectedRecipients) => {
    if (selectedTeamCount > 0) {
      return selectedRecipients.managersOnly ? 'team_managers' : 'team_everyone';
    }
    return '';
  };

  selectedTeamCountForDivision = (division, selectedRecipients, teamNamesByDivisionId) =>
    teamNamesByDivisionId(division.id).reduce((count, teamName) => {
      if (selectedRecipients[`team_${teamName.id}`] === true) {
        return count + 1;
      }
      return count;
    }, 0);

  toggleTeamAll = _event => {
    this.props.toggleRecipients('team_all');
  };

  toggleTeamNone = _event => {
    this.props.toggleRecipients('team_none');
  };

  isDivisionChecked = (divisionId, selectedRecipients, teamNamesByDivisionId) =>
    teamNamesByDivisionId(divisionId).every(teamName => selectedRecipients[`team_${teamName.id}`] === true);

  toggleDivision = divisionId => {
    const checked = this.isDivisionChecked(divisionId, this.props.selectedRecipients, this.props.teamNamesByDivisionId);

    this.props.toggleRecipients(
      checked ? 'teams_off' : 'teams_on',
      this.props.teamNamesByDivisionId(divisionId).map(teamName => teamName.id),
    );
  };

  toggleManagersOrEveryone = event => {
    const { value } = event.target;
    this.props.toggleRecipients(value);
  };

  selectedTeamCount = selectedRecipients => {
    let selectedTeamCount = 0;
    _forEach(selectedRecipients, (value, key) => {
      if (/team_/.exec(key) && value === true) {
        selectedTeamCount += 1;
      }
    });
    return selectedTeamCount;
  };

  renderDivisionCheckbox = (division, selectedRecipients, teamNamesByDivisionId) => {
    const id = `division_${division.id}`;
    const checked = this.isDivisionChecked(division.id, selectedRecipients, teamNamesByDivisionId);
    return (
      <CheckBoxWithLabel id={ id } isChecked={ checked } clickId={ division.id } onClick={ this.toggleDivision } label="" />
    );
  };

  renderTeamCheckbox = (teamName, selectedRecipients) => {
    const id = `team_${teamName.id}`;
    return (
      <CheckBoxWithLabel
        id={ id }
        isChecked={ selectedRecipients[id] || false }
        clickId={ id }
        onClick={ this.props.toggleRecipients }
        label=""
      />
    );
  };

  renderDivisionName = (division, selectedRecipients, teamNamesByDivisionId) => {
    const selectedTeamCount = this.selectedTeamCountForDivision(division, selectedRecipients, teamNamesByDivisionId);
    return (
      <span>
        { division.name }
        { selectedTeamCount > 0 && <span> | { `${selectedTeamCount} team${selectedTeamCount > 1 ? 's' : ''}` } </span> }
      </span>
    );
  };

  render() {
    const { loading, selectedRecipients, toggleRecipients } = this.props;

    const { divisionTeamData } = this.state;

    const selectedTeamCount = this.selectedTeamCount(selectedRecipients);

    return (
      <DotLoading loading={ loading }>
        <div className="u-spaceTopLg u-spaceBottomLg">
          <div className="u-flex u-spaceBottomSm">
            <div className="u-sizeFill">
              <label className="FormGroup-label u-inlineBlock u-padNone">Recipients</label>
              <small className="u-inlineBlock u-spaceLeftSm">
                { `${selectedTeamCount} team${selectedTeamCount === 1 ? '' : 's'} selected:` }
              </small>
            </div>
            <div className="u-sizeFill u-flexAlignSelfEnd u-textRight">
              <a onClick={ this.toggleTeamAll }>Select All</a>
              { ' | ' }
              <a onClick={ this.toggleTeamNone }>Select None</a>
            </div>
          </div>

          <Table
            columns={ teamColumns }
            rows={ divisionTeamData }
            paginate={ false }
            className="TableCollapsable"
            showHeader={ false }
          />

          <div className="FormGroup">
            <RadioButton
              input={ {
                name: 'manager-or-everyone',
                onChange: this.toggleManagersOrEveryone,
                value: this.managersOnlyCheckboxValue(selectedTeamCount, selectedRecipients),
              } }
              options={ radioButtonOptions }
              disabled={ selectedTeamCount === 0 }
              componentStyles={ { label: ' u-padNone' } }
            />
          </div>

          <div className="FormGroup">
            <label className="FormGroup-label">Other Recipients:</label>
            <CheckBoxWithLabel
              id="aemail_commissioners"
              isChecked={ selectedRecipients.commissioners || false }
              clickId="commissioners"
              onClick={ toggleRecipients }
              label="Email all commissioners"
            />
            <CheckBoxWithLabel
              id="aemail_unassigned_players"
              isChecked={ selectedRecipients.unassigned || false }
              clickId="unassigned"
              onClick={ toggleRecipients }
              label="Email members not assigned to a team"
            />
            <CheckBoxWithLabel
              id="acopy_self"
              isChecked={ selectedRecipients.self || false }
              clickId="self"
              onClick={ toggleRecipients }
              label="Send a copy of this email to myself"
            />
          </div>
        </div>
      </DotLoading>
    );
  }
}

export default ClubEmailRecipientList;
