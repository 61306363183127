import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppLoading from 'shared/components/AppLoading';
import styles from './LeagueMessages.module.scss';

function LeagueMessages(props) {
  useEffect(() => {
    props.initLeagueMessages();
  }, [props]);

  if (props.loading) {
    return <AppLoading loading />;
  }
  return <div className={ styles['League-Messages--container'] }>{ props.children }</div>;
}

LeagueMessages.propTypes = {
  children: PropTypes.object,
  initLeagueMessages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default LeagueMessages;
