"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCamelCase = void 0;
var toCamelCase = function toCamelCase(str) {
  return str.toLowerCase()
  // Replace any forward slashes with spaces to prevent word sticking
  .replace(/[^a-zA-Z0-9]+/g, ' ')
  // Replace any multiple spaces with a single space
  .replace(/\s\s+/g, ' ')
  // Split the string into words
  .split(' ')
  // Convert the array of words into a camelCased string
  .map(function (word, index) {
    if (index === 0) {
      // Return the first word in lowercase
      return word;
    } else {
      // Capitalize the first letter of each subsequent word
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  })
  // Join all the words to form the camelCase string
  .join('');
};
exports.toCamelCase = toCamelCase;