import React, { useState, useEffect } from 'react';
import {
  getDate,
  getDateParts,
  formatDate,
  getMonthYear,
  getCalendarMonths,
  daysInMonth as getDaysInMonth,
  getYear,
} from 'utils/date-service';
import _range from 'lodash/range';
import moment from 'moment';

import InputSelect from 'shared/components/Form/InputSelect';

import classes from './SimpleDateSelect.module.scss';

const MIN_YEAR = 1916;

const SimpleDateSelect = props => {
  const { className, disabled, mods, updateDateFilter, dateFormat, name, defaultDate } = props;

  const defaultDateParts = () => {
    let parts = { year: null, month: null, date: null };

    if (defaultDate) {
      parts = getDateParts(defaultDate);
      parts.month += 1;
    }
    return parts;
  };

  const [dateParts, setDateParts] = useState(defaultDateParts);

  const dateDisplay = dateParts => {
    if (dateParts.date) return dateParts.date.toString();
    return 'Day';
  };

  const monthDisplay = dateParts => {
    if (dateParts.month) return dateParts.month.toString();
    return 'Month';
  };

  const yearDisplay = dateParts => {
    if (dateParts.year) return dateParts.year.toString();
    return 'Year';
  };

  const getCalendarDays = () => {
    const { year, month } = dateParts;
    const daysInMonth = year && month ? getDaysInMonth(getMonthYear(year, month)) : 31;

    const range = _range(1, daysInMonth + 1);
    const daysForSelect = range.map((day, index) => ({
      label: day.toString(),
      value: index + 1,
      key: day.toString(),
    }));
    return [{ label: 'Day', value: 'Day', key: 'Day' }, ...daysForSelect];
  };

  const getCalendarMonthsForSelect = () => {
    const monthsForSelect = getCalendarMonths().map((month, index) => ({
      label: month,
      value: index + 1,
      key: month,
    }));

    return [{ label: 'Month', value: 'Month', key: 'Month' }, ...monthsForSelect];
  };

  const getCalendarYears = () => {
    const maxYear = getYear() + 1;
    const years = _range(MIN_YEAR, maxYear).reverse();
    const yearsForSelect = years.map((year, index) => ({
      label: year,
      value: moment.utc().year() - index,
      key: year,
    }));
    return [{ label: 'Year', value: 'Year', key: 'Year' }, ...yearsForSelect];
  };

  const updateDateParts = (value, fieldName, newDateParts) => {
    const castedValue = Number(value);
    const workingDateParts = newDateParts;

    if (Number.isNaN(castedValue)) {
      workingDateParts[fieldName] = null;
    } else {
      workingDateParts[fieldName] = castedValue;
    }

    setDateParts(newDateParts);
  };

  const updateHandler = (value, fieldName) => {
    const newDateParts = { ...dateParts };
    if (value && fieldName) {
      updateDateParts(value, fieldName, newDateParts);
    }
  };

  useEffect(() => {
    setDateParts(defaultDateParts);
  }, [defaultDate]);

  useEffect(() => {
    let returnedDate = getDate({
      ...dateParts,
      month: dateParts.month - 1,
    });
    if (dateFormat) {
      returnedDate = formatDate(returnedDate, dateFormat);
    }
    updateDateFilter(name, returnedDate);
  }, [dateParts]);

  return (
    <span className={ `${classes.wrapper} ${mods} ${className}` }>
      <InputSelect
        defaultValue={ monthDisplay(dateParts) }
        disabled={ disabled }
        name="month"
        options={ getCalendarMonthsForSelect() }
        updateHandler={ updateHandler }
        wrapperClass={ classes.month }
      />
      <InputSelect
        id="date-select"
        defaultValue={ dateDisplay(dateParts) }
        disabled={ disabled }
        name="date"
        options={ getCalendarDays() }
        updateHandler={ updateHandler }
        wrapperClass={ classes.day }
      />
      <InputSelect
        defaultValue={ yearDisplay(dateParts) }
        disabled={ disabled }
        name="year"
        options={ getCalendarYears() }
        updateHandler={ updateHandler }
        wrapperClass={ classes.year }
      />
    </span>
  );
};

export default SimpleDateSelect;
