/**
 * Select Component
 *
 * A select box component that requires an input object and calls <Field />
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'shared/toolkit/Form';
import defaultStyles from './Select.module.scss';

class Select extends PureComponent {
  renderOptions = options =>
    options.map(option => (
      <option key={ option.value } value={ option.value } disabled={ option.disabled }>
        { option.title }
      </option>
    ));

  render() {
    const {
      input,
      meta,
      options,
      label,
      placeholder,
      disabled,
      required,
      size,
      componentStyles,
      className,
    } = this.props;

    const styles = { ...defaultStyles, ...componentStyles };

    const inputClass = meta.touched && meta.error ? `${className}Error` : className;

    // Set placeholder as first value in options (if provided)
    const selectOptions = placeholder ? [{ title: placeholder, value: '', disabled: true }, ...options] : options;

    // Find currently selected value and update selected text/value
    const selectedOption = selectOptions.find(option => option.value.toString() === input.value.toString());

    return (
      <Field
        name={ input.name }
        label={ label }
        required={ required }
        meta={ meta }
        size={ size }
        className={ className === 'SelectAligned' ? 'FieldAligned' : 'Field' }
        componentStyles={ styles }
      >
        <label className={ styles[disabled ? 'SelectIsDisabled' : inputClass] } htmlFor={ input.name }>
          <select className={ styles.SelectOptions } id={ input.name } disabled={ disabled } { ...input }>
            { this.renderOptions(selectOptions) }
          </select>

          <span className={ styles.SelectText }>{ selectedOption.title }</span>
        </label>
      </Field>
    );
  }
}

Select.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  meta: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      value: PropTypes.any.isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Select.defaultProps = {
  meta: {},
  options: [],
  label: '\u00a0',
  placeholder: undefined,
  disabled: false,
  required: false,
  size: undefined,
  componentStyles: {},
  className: 'Select',
};

export default Select;
