/* eslint-disable react/no-unused-prop-types */

/**
 * Grid Cell Component
 *
 * A grid cell component that accepts various sizes and defaults to a full flex width.
 *
 * Grid-cell--center, Grid-cell--left, Grid-cell--right
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import { Grid } from 'shared/toolkit/Grid';
import defaultStyles from './Cell.module.scss';

const setCellSize = (type, size) => (size ? `u-${type}size${_capitalize(size)}` : null);

const getSizeClasses = props => {
  const { size, xs, sm, md, lg } = props;

  return [
    setCellSize('', size),
    setCellSize('xs-', xs),
    setCellSize('sm-', sm),
    setCellSize('md-', md),
    setCellSize('lg-', lg),
  ].join(' ');
};

class Cell extends PureComponent {
  render() {
    const { children, className, classModifiers, componentStyles, styleName, isGrid } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const cellClasses = `${className} ${classModifiers || ''} ${getSizeClasses(this.props)}`;

    return (
      <div className={ cellClasses }>
        <div className={ styles[styleName] }>{ isGrid ? <Grid>{ children }</Grid> : children }</div>
      </div>
    );
  }
}

Cell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  classModifiers: PropTypes.string,
  size: PropTypes.string,
  xs: PropTypes.string,
  sm: PropTypes.string,
  md: PropTypes.string,
  lg: PropTypes.string,
  isGrid: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  styleName: PropTypes.string,
};

Cell.defaultProps = {
  children: null,
  className: 'Grid-cell',
  classModifiers: null,
  size: 'Fill',
  xs: null,
  sm: null,
  md: null,
  lg: null,
  isGrid: false,
  componentStyles: {},
  styleName: 'GridCell',
};

export default Cell;
