import forOwn from 'lodash/forOwn';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { select, selectAsArray, selectAndSortBy } from 'state/teamsnap/selectors';

const selectBroadcastEmails = state => select(state, 'broadcastEmails');
const selectBroadcastEmailsAsArray = state => selectAsArray(state, 'broadcastEmails');
const selectBroadcastEmailById = (state, id) => selectBroadcastEmails(state)[_parseInt(id)];

const selectAndSortBroadcastEmailsByMemberId = createSelector([selectBroadcastEmails], broadcastEmails => {
  const broadcastEmailsByMemberId = selectAndSortBy(broadcastEmails, 'memberId');
  forOwn(broadcastEmailsByMemberId, (broadcastEmails, memberId) => {
    if (broadcastEmails && broadcastEmails.length > 0) {
      broadcastEmailsByMemberId[memberId] = reverse(sortBy(broadcastEmails, ['updatedAt']));
    }
  });
  return broadcastEmailsByMemberId;
});

const selectBroadcastEmailsByMemberId = (state, broadcastEmailId) =>
  selectAndSortBroadcastEmailsByMemberId(state)[_parseInt(broadcastEmailId)] || [];

export {
  selectBroadcastEmails,
  selectBroadcastEmailById,
  selectBroadcastEmailsAsArray,
  selectAndSortBroadcastEmailsByMemberId,
  selectBroadcastEmailsByMemberId,
};
