"use strict";

/**
 * This environment utility class is the primary tool that will be used by frontend
 * applications to deterministically identify the correct, relevant, staging environment
 * hostname for its team based staging environment.
 *
 * The goal of this class is to be able to be given a hostname (such as
 * accounts.soar.staging.teamsnap.com) and to then be able to generate all other relevant
 * hostnames (e.g. the related authentication hostname would be auth.soar.staging.teamsnap.com).
 * */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnvironmentUtilities = void 0;
// TEAM_ENV_REGEX is the regex that we use to determine if a host is a team staging
// environment host or not.
var TEAM_ENV_REGEX = /^(\w+)\.(\w+)\.staging\.teamsnap\.(io|com|dev)$/;
// This class contains utility functionality for handling frontend environments.
var EnvironmentUtilities = /** @class */function () {
  // Build a new instance of the environment utilities for the given host.
  function EnvironmentUtilities(host) {
    this.host = host;
  }
  // isTeamStagingEnvironment tests if the host that these utilities were constructed
  // for is a team staging environment host.
  EnvironmentUtilities.prototype.isTeamStagingEnvironment = function () {
    return TEAM_ENV_REGEX.test(this.host);
  };
  // getStagingHost checks if this host is for a team staging environment. If it is,
  // we construct the correct hostname for the given host. Otherwise, we return
  // the fallback URL.
  EnvironmentUtilities.prototype.getStagingHost = function (subdomain, fallback) {
    if (this.isTeamStagingEnvironment()) {
      var teamSpace = TEAM_ENV_REGEX.exec(this.host)[2];
      return "".concat(subdomain, ".").concat(teamSpace, ".staging.teamsnap.dev");
    }
    return fallback;
  };
  return EnvironmentUtilities;
}();
exports.EnvironmentUtilities = EnvironmentUtilities;