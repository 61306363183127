/**
 * Division Actions
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, COLLECTIONS } from 'state/snapi/slice';

export const loadDivisionTree = params => dispatch =>
  dispatch(getCollection(COLLECTIONS.DIVISION, 'divisions/tree', params));

export const loadDivision = params => dispatch =>
  dispatch(getCollection(COLLECTIONS.DIVISION, 'divisions/search', params));
