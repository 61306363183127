import { connect } from 'react-redux';
import { hideModal } from 'state/modal/actions';
import { showFeedback } from 'state/feedback/actions';
import { selectDivisionTreeForDivisionId } from 'state/teamsnap/divisions/selectors';
import { selectActiveDivisionId } from 'state/app/selectors';
import { createNewProgramMembership, loadProgramMemberships } from 'state/programMemberships/actions';
import { moveMember, loadMembers } from 'state/members/actions';
import { selectTeamNamesByDivisionId } from 'state/teamsnap/teamNames/selectors';
import { loadSingleProgramMember } from 'state/programMembers/actions';
import { analyticEvent } from 'state/analyticEvent/actions';
import TeamsAndDivisionsModal from '../components/teamsAndDivisionsModals/TeamsAndDivisionsModal';

const mapDispatchToProps = {
  hideModal,
  showFeedback,
  createNewProgramMembership,
  loadProgramMemberships,
  loadMembers,
  moveMember,
  loadSingleProgramMember,
  analyticEvent,
};

const mapStateToProps = state => {
  const divisionId = selectActiveDivisionId(state);
  return {
    divisionTree: selectDivisionTreeForDivisionId(state)(divisionId),
    teamsByDivision: selectTeamNamesByDivisionId(state),
    activeDivisionId: divisionId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamsAndDivisionsModal);
