import React, { useState, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Icon } from '@teamsnap/teamsnap-ui';



import QuestionEditRow from './QuestionEditRow';
import QuestionEditModal from './QuestionEditModal';
import { linkSafety } from 'utils/links';

const QuestionnaireEditList = props => {
  const {
    questionnaire,
    rootDivisionId,
    createNewHealthQuestionnaire,
    createNewHealthQuestionnaireTemplateQuestion,
    history,
  } = props;

  const healthCheckTooltipText = `Learn more about Health Check and see our recommended screening questions.`;

  const [currentQuestions, setCurrentQuestions] = useState(questionnaire);
  const [currentQuestionsCount, setCurrentQuestionsCount] = useState(questionnaire);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isInFlight, setIsInFlight] = useState(false);
  const [modalPrompt, setModalPrompt] = useState('');
  const [modalResponse, setModalResponse] = useState('');
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    setCurrentQuestions(questionnaire);
  }, [questionnaire]);

  useEffect(() => {
    setCurrentQuestionsCount(currentQuestions.length);
  }, [currentQuestions]);

  const prepareModal = props => {
    const { prompt, response, type, e } = props;
    setModalPrompt(prompt);
    setModalResponse(response);
    setModalType(type);
    setCurrentQuestion(e);
    setIsOpen(true);
  };


  const closeModal = () => {
    setIsOpen(false);
  };

  const submitQuestion = e => {
    if (modalType === 'New') {
      submitNewQuestion(e);
    } else if (modalType === 'Edit') {
      submitEditedQuestion(e);
    }
  };

  const submitNewQuestion = e => {
    const { prompt, response } = e.target;
    const listCount = currentQuestions.length;
    const symptomFreeAnswer = response.value === 'Yes' ? 'Yes' : 'No';
    const symptomAnswers = response.value === 'Yes' ? ['No'] : ['Yes'];

    const newQuestion = {
      prompt: prompt.value,
      id: listCount + 1,
      symptomAnswers: symptomAnswers,
      symptomFreeAnswer: symptomFreeAnswer,
    };

    const updatedQuestions = [...currentQuestions, newQuestion];

    setCurrentQuestions(updatedQuestions);

    closeModal(true);
  };

  const submitEditedQuestion = e => {
    const { prompt, response } = e.target;
    const symptomFreeAnswer = response.value === 'Yes' ? 'Yes' : 'No';
    const symptomAnswers = response.value === 'Yes' ? ['No'] : ['Yes'];

    let updatedQuestion = {
      ...currentQuestion,
      prompt: prompt.value,
      symptomAnswers: symptomAnswers,
      symptomFreeAnswer: symptomFreeAnswer,
    };


    const newQuestions = [...currentQuestions];
    const updatedQuestions = newQuestions.map(item => {
      if (item.id === updatedQuestion.id) return updatedQuestion;
      return item;
    });

    setCurrentQuestions(updatedQuestions);
    closeModal(true);
  };

  const submitQuestions = () => {
    setIsInFlight(true);

    createNewHealthQuestionnaire({ divisionId: rootDivisionId })
      .then(response => {
        const healthCheckQuestionnaireTemplateId = response[0].id;


        return Promise.all(
          currentQuestions.map((question, index) => {
            let params = {
              prompt: question.prompt,
              symptomFreeAnswer: question.symptomFreeAnswer,
              symptomAnswers: question.symptomAnswers,
              displayOrder: index + 1,
            };

            return createNewHealthQuestionnaireTemplateQuestion({ ...params, healthCheckQuestionnaireTemplateId });
          }),
        );
      })
      .finally(() => {
        setIsInFlight(false);
        history.push(linkSafety(rootDivisionId));
      });
  };

  return (
    <div className="u-size3of4 u-spaceSidesAuto">
      <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter u-spaceBottomMd">
        <h2 className="header-banner-title">
          Edit Health Check Questions
          <span className="Tooltip" tabIndex="0" data-tooltip={ healthCheckTooltipText } style={ {fontFamily: "'Open Sans', sans-serif"} }>
            <a
              className="ss-info u-fontSizeLg u-spaceLeftSm"
              target="_blank"
              rel="noopener noreferrer"
              href="https://helpme.teamsnap.com/article/1518-creating-custom-health-check-questions"
            />
          </span>
        </h2>
        <Button
          type="button"
          iconPosition="left"
          icon="plus"
          onClick={ () => {
            prepareModal({ prompt: '', response: '', type: 'New' });
          } }
        >
          New Question
        </Button>{ ' ' }
      </div>
      { currentQuestionsCount ===  1 &&
        <div className="Feedback Feedback--highlight" role="alert">
          <div className="Feedback-icon">
            <Icon name="notifications" />
          </div>
          You must have at least one Health Check question.
      </div>
      }

      { currentQuestions.map(e => (
        <QuestionEditRow
          key={ e.id }
          currentQuestion={ e }
          clickOpenModal={ (prompt, response) => {
            prepareModal({ prompt, response, type: 'Edit', e });
          } }
          currentQuestionCount= { currentQuestionsCount }
          onDelete={ id => {
            let filteredArray = currentQuestions.filter(item => item.id !== id);
            setCurrentQuestions(filteredArray);
          } }
        />
      )) }
      <div className="u-flex u-flexJustifyCenter u-spaceTopXl">
        <Button
          type="link"
          mods="u-textCenter u-size1of6 u-spaceRightSm"
          color="negative"
          icon="dismiss"
          isDisabled={ isInFlight }
          location={ linkSafety(rootDivisionId) }
        >
          Cancel
        </Button>
        <Button
          color="primary"
          mods="u-textCenter u-size1of6"
          icon="check"
          isDisabled={ isInFlight }
          onClick={ submitQuestions }
        >
          Save
        </Button>
      </div>
      { modalIsOpen && (
        <QuestionEditModal
          prompt={ modalPrompt }
          response={ modalResponse }
          closeModal={ () => {
            closeModal();
          } }
          submitModal={ e => {
            submitQuestion(e);
          } }
          type={ modalType }
        />
      ) }
    </div>
  );
};

QuestionnaireEditList.propTypes = {
  questionnaire: PropTypes.array.isRequired,
  rootDivisionId: PropTypes.number,
  createNewHealthQuestionnaire: PropTypes.func.isRequired,
  createNewHealthQuestionnaireTemplateQuestion: PropTypes.func.isRequired,
};

export default withRouter(QuestionnaireEditList);
