import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import AppLoading from 'shared/components/AppLoading';
import { Panel, PanelHeader, PanelRow, PanelBody, PanelFooter, Icon } from '@teamsnap/teamsnap-ui';
import { linkMembers } from 'utils/links';
import imagePlaceholder from './user_placeholder_image.png';
import TeamsAndDivisionsModal from '../../containers/TeamsAndDivisionsModalContainer';
import TeamsAndRegistrationTable from './TeamsAndRegistrationsTable';
import './DetailedMemberView.module.scss';

class DetailedMemberView extends Component {
  static propTypes = {
    programMember: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    saveRouteParams: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    activeDivisionId: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
    programMemberships: PropTypes.array.isRequired,
    rootDivisionId: PropTypes.number.isRequired,
    initDetailedMemberView: PropTypes.func.isRequired,
    analyticEvent: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    const { params, initDetailedMemberView } = this.props;
    initDetailedMemberView(params);
  }

  showTeamsAndDivisionsModal = ({ id, firstName }) => {
    const { showModal } = this.props;
    const modalProps = {
      title: `Copy ${firstName} to Team / Division`,
      pageOrigin: 'detailedMemberView',
      confirmationButtonText: 'Copy to Team/Division',
      successMessage: 'Member Added/Invited!',
      failureMessage: 'Member was not successfully added.',
      type: 'Add',
      id,
    };
    this.sendAnalytics();
    return showModal(TeamsAndDivisionsModal, modalProps);
  };

  sendAnalytics = () => {
    const event = {
      eventCategory: 'detailed_member_view',
      eventAction: 'add_to_team',
    };
    this.props.analyticEvent(event);
  };

  render() {
    const {
      isFetching,
      programMember,
      activeDivisionId,
      rootDivisionId,
      programMemberships,
      showModal,
      analyticEvent,
    } = this.props;

    if (isFetching) {
      return <AppLoading loading />;
    }
    return (
      <div>
        <h2 className="u-colorGrey">
          <a href={ linkMembers(activeDivisionId) } className="u-colorPrimary">
            Members
          </a>
          <span className="u-inlineBlock u-padLeftSm u-padRightSm">/</span>
          <span>{ programMember.fullName }</span>
        </h2>
        <div className="Grid Grid--withGutter u-padTopXl">
          <div className="Grid-cell u-size5of24">
            <Panel>
              <PanelHeader
                headerImage={ {
                  Source: programMember.photo ? programMember.photo.links.programMemberOriginalPhoto : null,
                  Placeholder: `${imagePlaceholder}`,
                } }
                title={ programMember.fullName }
              />
              <PanelBody>
                <PanelRow>
                  { programMember.formattedBirthday && (
                    <p className="u-spaceEndsXs">Birthday: { programMember.formattedBirthday }</p>
                  ) }
                  { programMember.gender && <p className="u-spaceEndsXs">{ programMember.gender }</p> }
                </PanelRow>
              </PanelBody>
              <PanelFooter>
                <button
                  className="Button Button--primary u-size1of1 u-textCenter"
                  disabled={ _isEmpty(programMemberships) }
                  onClick={ () => this.showTeamsAndDivisionsModal(programMember) }
                >
                  + Copy Profile
                </button>
              </PanelFooter>
            </Panel>
          </div>
          <div className="Grid-cell u-size19of24">
            <TeamsAndRegistrationTable
              programMember={ programMember }
              programMemberships={ programMemberships }
              activeDivisionId={ activeDivisionId }
              rootDivisionId={ rootDivisionId }
              showModal={ showModal }
              analyticEvent={ analyticEvent }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DetailedMemberView;
