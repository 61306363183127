import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckBoxWithLabel extends Component {
  handleClick = event => {
    this.props.onClick(this.props.clickId);
  };

  render() {
    const { id, isChecked, label } = this.props;

    return (
      <label htmlFor={ id } className="hrs__checkbox">
        <input id={ id } type="checkbox" checked={ isChecked } onClick={ this.handleClick } />
        <span className="hrs__checkbox-label"> { label }</span>
      </label>
    );
  }
}

CheckBoxWithLabel.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  clickId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckBoxWithLabel;
