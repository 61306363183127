import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isActive } from 'utils/divisions';

// Actions
import { showModal } from 'state/modal/actions';
import { loadDivisionAggregates } from 'state/teamsnap/divisionAggregates/actions';
import {
  assignPendingMembersByDivisionId,
  deletePendingMembersByDivisionId,
  toggleRosterAssignmentView,
} from 'state/leagueRostering/actions';
// Selectors
import { selectDivisionById } from 'state/teamsnap/divisions/selectors';
import { selectDivisionAggregateByDivisionIdOrEmpty } from 'state/teamsnap/divisionAggregates/selectors';
import {
  selectRosterListDivisionId,
  selectRosterAssignmentView,
  selectRosterListDivisionChildren,
} from 'state/leagueRostering/selectors';
import { selectTeamNamesByDivisionId } from 'state/teamsnap/teamNames/selectors';

import TeamList from '../components/TeamList';

export class DivisionTeamsListTeamRowContainer extends Component {
  static propTypes = {
    currentRosterDivision: PropTypes.object,
    loadData: PropTypes.func,
    isPlayerTab: PropTypes.bool,
  };

  componentDidMount() {
    this.props.loadData();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    // We want these to refresh when the selected division changes
    const currentDivision = this.props.currentRosterDivision;
    const nextDivision = nextProps.currentRosterDivision;
    if (currentDivision && currentDivision.id !== nextDivision.id) {
      this.props.loadData();
    }
  }

  render() {
    const props = { ...this.props };
    delete props.loadData;
    return <TeamList { ...props } />;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  assignPendingMembersByDivisionId: () => {
    dispatch(assignPendingMembersByDivisionId(ownProps.currentRosterDivisionId));
  },
  deletePendingMembersByDivisionId: () => {
    dispatch(deletePendingMembersByDivisionId(ownProps.currentRosterDivisionId));
  },
  toggleRosterAssignmentView: () => {
    dispatch(toggleRosterAssignmentView());
  },
  loadData: () => {
    dispatch(loadDivisionAggregates(ownProps.currentRosterDivisionId));
  },
  showModal: (modal, modalProps) => {
    dispatch(showModal(modal, modalProps));
  },
});

const mapStateToProps = (state, ownProps) => {
  const currentRosterDivisionId = selectRosterListDivisionId(state);
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, currentRosterDivisionId);
  return {
    currentRosterDivision: selectDivisionById(state, currentRosterDivisionId),
    isPlayerTab: ownProps.isPlayerTab || false,
    setIsPlayerTab: ownProps.setIsPlayerTab,
    divisions: selectRosterListDivisionChildren(state).filter(isActive),
    teamNames: selectTeamNamesByDivisionId(state)(currentRosterDivisionId),
    rosterAssignmentView: selectRosterAssignmentView(state),
    teamCount: divisionAggregate.teamCount,
    allPendingAssignmentsCount: divisionAggregate.selfAndDescendantsPendingMemberCount || 0,
    allPendingRemovalsCount: divisionAggregate.selfAndDescendantsPendingMemberRemovalCount,
    allTeamsCount: divisionAggregate.selfAndDescendantsTeamCount,
    allAssignedMemberCount: divisionAggregate.selfAndDescendantsAssignedMemberCount,
    allUnassignedMemberCount: divisionAggregate.selfAndDescendantsUnassignedMemberCount,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DivisionTeamsListTeamRowContainer);
