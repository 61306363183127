import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputCheckbox extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    elementClass: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    updateHandler: PropTypes.func,
    style: PropTypes.object,
    wrapperClass: PropTypes.string,
  };

  updateValue = () => {
    const { value } = this.refs.input;
    const fieldName = this.props.name;
    if (typeof this.props.updateHandler === 'function') {
      this.props.updateHandler(value, fieldName);
    }
  };

  render() {
    const { defaultValue, elementClass, placeholder, maxLength, name, style, wrapperClass } = this.props;
    return (
      <span className={ wrapperClass }>
        <input
          className={ elementClass }
          defaultValue={ defaultValue }
          key={ defaultValue }
          maxLength={ maxLength }
          name={ name }
          onBlur={ this.updateValue }
          placeholder={ placeholder }
          style={ style }
          type="text"
          ref="input"
        />
      </span>
    );
  }
}

export default InputCheckbox;
