import React, { useEffect } from 'react';
import sortBy from 'utils/sorter';
import _isEmpty from 'lodash/isEmpty';

// Components
import { Cell, Grid, FieldWrapper, Icon } from '@teamsnap/teamsnap-ui';
import SimpleDateSelect from 'shared/components/SimpleDateSelect/SimpleDateSelect';

const CustomFields = ({ leagueCustomFields, getDefaultValue, updateField, currentFilter, removeCustomField }) => {
  const keys = Object.keys(currentFilter.advancedFilters || {});
  // get all the customField keys from the list of keys
  const customFieldKeys = keys.filter(key => key.match(/custom_field/));
  const visibleCustomFields = customFieldKeys.map(e => /\d+/.exec(e)[0]);

  const sortedSearchCustomFields = sortBy(
    'name',
    Object.values(leagueCustomFields || {}).filter(e => visibleCustomFields.includes(`${e.id}`)),
  );
  const sortedNonSearchCustomFields = sortBy(
    'name',
    Object.values(leagueCustomFields || {}).filter(e => !visibleCustomFields.includes(`${e.id}`)),
  );

  return (
    <form className={ visibleCustomFields.length > 0 ? 'u-spaceTopMd' : '' }>
      <div className="u-spaceSidesMd">
        { sortedSearchCustomFields.map(e => (
          <Grid mods="u-spaceBottomMd u-flexAlignItemsBaseline" key={ e.name }>
            <Cell className="u-sizeFill u-flexAlignSelfCenter">
              <label htmlFor={ `custom_field_${e.id}` } className="u-block u-padRightMd u-fontSizeMd u-textSemiBold">
                { e.name }
              </label>
            </Cell>
            <Cell className="u-size13of24">
              { e.kind === 'Menu' ? (
                <div className="SelectBox">
                  <select
                    className="SelectBox-options"
                    name={ `custom_field_${e.id}` }
                    onChange={ e =>
                      updateField(
                        {
                          field: e.target.name,
                          value: e.target.value,
                          operator: 'equals',
                        },
                        e.target.name,
                      )
                    }
                    value={ getDefaultValue('advancedFilters', {})[`custom_field_${e.id}`]?.value || '' }
                  >
                    <option value="">Any</option>
                    { (e.options || []).map(option => (
                      <option key={ option } value={ option }>
                        { option }
                      </option>
                    )) }
                  </select>
                </div>
              ) : null }
              { e.kind === 'Check Box' ? (
                <div className="SelectBox">
                  <select
                    className="SelectBox-options"
                    name={ `custom_field_${e.id}` }
                    onChange={ e =>
                      updateField(
                        {
                          field: e.target.name,
                          value: e.target.value,
                          operator: 'equals',
                        },
                        e.target.name,
                      )
                    }
                    value={ getDefaultValue('advancedFilters', {})[`custom_field_${e.id}`]?.value || '' }
                  >
                    <option value="">Any</option>
                    { [1, 0].map(option => (
                      <option key={ option } value={ option }>
                        { option ? 'Checked' : 'Unchecked' }
                      </option>
                    )) }
                  </select>
                </div>
              ) : null }

              { e.kind === 'Text' ? (
                <FieldWrapper
                  field="input"
                  fieldProps={ {
                    inputProps: {
                      onInput: e =>
                        updateField(
                          {
                            field: e.target.name,
                            value: e.target.value,
                            operator: 'begins_with',
                          },
                          e.target.name,
                        ),
                      value: getDefaultValue('advancedFilters', {})[`custom_field_${e.id}`]?.value || '',
                    },
                  } }
                  name={ `custom_field_${e.id}` }
                />
              ) : null }

              { e.kind === 'Date' ? (
                <SimpleDateSelect
                  mods="u-spaceRightNone"
                  name={ `custom_field_${e.id}` }
                  updateDateFilter={ (key, date) => {
                    updateField(
                      {
                        field: key,
                        value: date !== 'Invalid date' ? date : null,
                        operator: 'equals',
                      },
                      key,
                    );
                  } }
                  dateFormat="YYYY-MM-DD"
                />
              ) : null }
            </Cell>
            <Cell className="u-sizeFit">
              <span
                style={ { cursor: 'pointer' } }
                className="u-spaceLeftSm u-spaceBottomMd u-colorNegative"
                onClick={ () => removeCustomField(`custom_field_${e.id}`) }
              >
                <Icon className="Icon" name="dismiss" mods="u-colorNegative" />
              </span>
            </Cell>
          </Grid>
        )) }
      </div>
      <Grid mods="u-padMd" style={ { borderTop: '1px solid #d6d6d6', background: '#fafafa' } }>
        <Cell className="u-sizeFill">
          <div className="SelectBox">
            <select
              className="SelectBox-options"
              value={ -1 }
              onChange={ e => {
                if (e.target.value !== '-1') {
                  updateField(
                    {
                      field: `custom_field_${e.target.value}`,
                      value: null,
                      operator: 'equals',
                    },
                    `custom_field_${e.target.value}`,
                  );
                }
              } }
            >
              <option value={ -1 }>Add a custom field</option>
              { sortedNonSearchCustomFields.map(e => (
                <option value={ e.id }>{ e.name }</option>
              )) }
            </select>
          </div>
        </Cell>
      </Grid>
    </form>
  );
};

export default CustomFields;
