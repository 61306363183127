/* eslint-disable react/no-unused-prop-types */

/**
 * Header Component
 *
 * Header component that accepts a heading size and calls the Heading component
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';
import { Heading } from 'shared/toolkit';
import defaultStyles from './Header.module.scss';

class Header extends PureComponent {
  render() {
    const { title, children, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };
    const headingProps = _omit(this.props, ['className', 'children']);

    return (
      <header className={ styles[className] }>
        { title && <Heading { ...headingProps } /> }
        { children }
      </header>
    );
  }
}

Header.propTypes = {
  title: PropTypes.string,
  iconLeftName: PropTypes.string,
  iconRightName: PropTypes.string,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Header.defaultProps = {
  title: null,
  iconLeftName: null,
  iconRightName: null,
  iconSize: undefined,
  iconColor: null,
  children: null,
  size: 3,
  componentStyles: {},
  className: 'Header',
};

export default Header;
