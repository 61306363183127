import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';


import SafetyTab from '../components/SafetyTab';

const mapStateToProps = state => ({
  rootDivisionId: selectActiveDivisionRootId(state),

});

export default withRouter(connect(mapStateToProps)(SafetyTab));
