import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Cell } from '@teamsnap/teamsnap-ui';
import WidgetGettingStarted from 'shared/components/WidgetGettingStarted';
import WidgetInvoicing from 'shared/components/WidgetInvoicing';
import WidgetRegistrations from 'shared/components/WidgetRegistrations';
import WidgetOrganization from 'shared/components/WidgetOrganization';
import WidgetAdvertisements from 'shared/components/WidgetAdvertisements';
import WidgetLinks from 'shared/components/WidgetLinks';
import AppLoading from 'shared/components/AppLoading';

class PageDashboard extends Component {
  componentDidMount() {
    const { loadDivisionBehaviors, loadDivision, setTotalProgramMemberCount, divisionId } = this.props;
    loadDivisionBehaviors({ division_id: divisionId });
    loadDivision({ id: divisionId });
    setTotalProgramMemberCount(divisionId);
  }

  randomAd(ads) {
    return ads[Math.floor(Math.random() * ads.length)];
  }

  render() {
    const {
      divisionId,
      behaviors,
      paymentProvider,
      hasActiveStripeAccount,
      isArchivedDivision,
      isPageFetching,
      analyticEvent,
    } = this.props;

    const ads = [<WidgetAdvertisements divisionId={ divisionId } />];

    if (isPageFetching) {
      // return <Loader message='random' />
      return <AppLoading />;
    }

    const paymentsSetupComplete = paymentProvider === 'wepay' ? behaviors.hasPaymentsAccount : hasActiveStripeAccount;

    const isWidgetComplete =
      paymentsSetupComplete &&
      behaviors.hasMultipleCommissioners &&
      behaviors.hasRegistrationForm &&
      behaviors.hasSubdivisionOrTeam &&
      behaviors.hasDivisionEvent;

    return (
      <div>
        { !isWidgetComplete && (
          <WidgetGettingStarted
            paymentProvider={ paymentProvider }
            hasActiveStripeAccount={ hasActiveStripeAccount }
            divisionId={ divisionId }
            behaviors={ behaviors }
            analyticEvent={ analyticEvent }
            isArchivedDivision={ isArchivedDivision }
          />
        ) }

        <Grid isWithGutter>
          <WidgetInvoicing
            paymentProvider={ paymentProvider }
            divisionId={ divisionId }
            isReady={ paymentsSetupComplete }
          />

          <WidgetRegistrations divisionId={ divisionId } isReady={ behaviors.hasRegistrationForm } />

          <WidgetOrganization divisionId={ divisionId } isReady={ behaviors.hasSubdivisionOrTeam } />
        </Grid>

        <Grid isWithGutter mods="u-padTopMd">
          <Cell mods="u-size2of3">{ this.randomAd(ads) }</Cell>

          <Cell mods="u-size1of3">
            <WidgetLinks divisionId={ divisionId } isArchivedDivision={ isArchivedDivision } />
          </Cell>
        </Grid>
      </div>
    );
  }
}

PageDashboard.propTypes = {
  divisionId: PropTypes.string.isRequired,
  loadDivisionBehaviors: PropTypes.func.isRequired,
  behaviors: PropTypes.shape({}).isRequired,
  isPageFetching: PropTypes.bool.isRequired,
  loadDivision: PropTypes.func.isRequired,
  paymentProvider: PropTypes.string,
  hasActiveStripeAccount: PropTypes.bool,
  setTotalProgramMemberCount: PropTypes.func.isRequired,
  isArchivedDivision: PropTypes.bool,
};

PageDashboard.defaultProps = {
  paymentProvider: null,
  hasActiveStripeAccount: false,
  isArchivedDivision: null,
};

export default PageDashboard;
