import React from 'react';
import PropTypes from 'prop-types';
import QuestionnaireContainer from '../containers/QuestionnaireContainer';
// import { Tabs } from '@teamsnap/teamsnap-ui';
import { Button } from 'shared/toolkit';
import { linkQuestionEdit } from 'utils/links';


const SafetyTab = props => {
  const { rootDivisionId } = props;

  // const tabs = [
  //   {
  //     heading: 'Questions',
  //     content: <QuestionnaireContainer />,
  //   },
  // ];
  return (
    <>
      <div className="u-flex u-flexJustifyBetween u-flexAlignItemsCenter">
        <h1>Safety</h1>
        <Button type="link" text="Edit Questions" iconLeft="edit" handleClick={ linkQuestionEdit(rootDivisionId) } />
      </div>
      <QuestionnaireContainer />
      { /* <Tabs tabs={tabs} /> */ }
    </>
  );
};

SafetyTab.propTypes = {
  rootDivisionId: PropTypes.number,
};

export default SafetyTab;
