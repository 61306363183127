import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadRegistrationHealth } from 'state/registrationHealth/actions';
import { selectRegistrationHealthValues, selectRegistrationHealthIsFetching } from 'state/registrationHealth/selectors';
import {
  selectRegistrationDateAggregateValuesByDate,
  selectRegistrationDateAggregateIsFetching,
} from 'state/registrationDateAggregates/selectors';
import { linkRegistrationNew, linkRegistrations } from 'utils/links';
import InitialLayout from 'shared/components/Widget/InitialLayout';
import AlertLayout from 'shared/components/Widget/AlertLayout';
import WidgetBase from 'shared/components/Widget';
import WidgetContent from 'shared/components/Widget/WidgetContent';
import WidgetCounts from 'shared/components/Widget/WidgetCounts';
import LabelIndicator from 'shared/components/Widget/LabelIndicator';
import { Grid, Cell, ProgressBar } from '@teamsnap/teamsnap-ui';
import { calculatePercent } from 'utils/utils';
import { getDatePartsWithFormat } from 'utils/date-service';
import styles from './WidgetRegistrations.module.scss';

class WidgetRegistrations extends PureComponent {
  UNSAFE_componentWillMount() {
    const { loadRegistrationHealth, divisionId, isReady } = this.props;

    if (isReady) {
      loadRegistrationHealth(divisionId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadRegistrationHealth, divisionId, isReady } = this.props;
    if (nextProps.isReady !== isReady) {
      loadRegistrationHealth(divisionId);
    }
  }

  setInitialProps = sharedProps => ({
    ...sharedProps,
    buttonText: 'Create New Registration Form',
    buttonLocation: linkRegistrationNew(this.props.divisionId),
    buttonColor: 'primary',
    counters: sharedProps.counters.filter(counter => counter.label !== 'Total Invoices'),
    children: (
      <WidgetContent
        heading="Bring people into your organization"
        subheading="Sign up members directly through registration."
      />
    ),
  });

  // TODO: Currently no 'Alert' status for members
  //  - Might need to be customized like CompleteStatus
  setAlertProps = sharedProps => ({
    ...sharedProps,
    buttonText: 'Alert Text',
    buttonLocation: null,
    buttonColor: 'primary',
    countersTop: sharedProps.counters,
    children: <div>Something went wrong, please try again.</div>,
  });

  renderProgressBar = (activity, calculatedTotal) => {
    const date = getDatePartsWithFormat(activity.registeredOn, { month: 'MMM', day: 'DD' }, 'YYYY-MM-DD');

    return (
      <Cell key={ activity.registeredOn } mods={ [styles.chartColumn, 'u-sizeFill'].join(' ') }>
        <ProgressBar
          isVertical
          progress={
            activity.confirmedAndCompletedRegistrantsCount === 0
              ? 3
              : calculatePercent(calculatedTotal, activity.confirmedAndCompletedRegistrantsCount)
          }
        />

        <div className={ styles.chartXaxisLabel }>
          <span className="u-fontSizeSm u-colorInfo">{ date.day }</span>
          <span className="u-fontSizeXs">{ date.month }</span>
        </div>
      </Cell>
    );
  };

  renderWidgetComplete = sharedProps => {
    const {
      divisionId,
      registrationHealth: { registrationFormsCount },
      registrationDateAggregates,
    } = this.props;

    const widgetProps = {
      ...sharedProps,
      buttonText: registrationFormsCount === 0 ? 'Create New Registration Form' : 'Manage My Registrations',
      buttonLocation: registrationFormsCount === 0 ? linkRegistrationNew(divisionId) : linkRegistrations(divisionId),
      buttonColor: registrationFormsCount === 0 ? 'primary' : 'neutral',
    };

    const maxSignups = Math.max(
      ...registrationDateAggregates.map(activity => activity.confirmedAndCompletedRegistrantsCount),
    );
    const dataPointRounded = maxSignups === 0 ? 40 : Math.ceil(maxSignups / 10) * 10;
    const dataPointDivider = dataPointRounded / 4;

    return (
      <WidgetBase { ...widgetProps }>
        <div>
          <Grid isFit mods="u-padBottomSm">
            <Cell>
              <div className="u-spaceBottomSm">Past 7 Days</div>
            </Cell>
            <Cell mods="u-textRight">
              <LabelIndicator indicatorColor="primary" label="New signups" />
            </Cell>
          </Grid>

          <Grid mods={ styles.chartGraph }>
            <Cell mods={ [styles.chartColumn, 'u-sizeFill'].join(' ') }>
              <div className={ styles.chartYaxis }>
                { [4, 3, 2, 1].map(dp => (
                  <div key={ dp } className={ styles.chartYaxisLabel }>
                    { Math.round(dp * dataPointDivider) }
                  </div>
                )) }
                <div className={ styles.chartYaxisLabel }>0</div>
              </div>
            </Cell>

            { registrationDateAggregates.map(activity => this.renderProgressBar(activity, dataPointRounded)) }
          </Grid>
        </div>

        <WidgetCounts items={ sharedProps.counters } mods="u-size1of2" />
      </WidgetBase>
    );
  };

  render() {
    const {
      isReady,
      isFetching,
      registrationHealth: {
        registrationFormsCount,
        confirmedAndCompletedRegistrantsCount,
        hasRegistrationAlert, // temporary placeholder field until added to api
      },
    } = this.props;

    let isLoading = isFetching;

    if (!isReady) {
      isLoading = false;
    }

    const props = {
      headerText: 'Registration Overview',
      isLoading,
      iconName: 'roster',
      counters: [
        { label: 'Active Forms', value: registrationFormsCount || '0' },
        {
          label: 'Signups',
          value: confirmedAndCompletedRegistrantsCount || '0',
        },
      ],
    };

    if (!isReady) {
      return <InitialLayout { ...this.setInitialProps(props) } />;
    }
    if (hasRegistrationAlert) {
      return <AlertLayout { ...this.setAlertProps(props) } />;
    }

    return this.renderWidgetComplete(props);
  }
}

WidgetRegistrations.propTypes = {
  divisionId: PropTypes.string.isRequired,
  queryRegistrationHealthAndDateAggregates: PropTypes.func.isRequired,
  registrationHealth: PropTypes.shape({}).isRequired,
  registrationDateAggregates: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
};

WidgetRegistrations.defaultProps = {};

const mapStateToProps = state => ({
  registrationHealth: selectRegistrationHealthValues(state),
  registrationDateAggregates: selectRegistrationDateAggregateValuesByDate(state),
  isFetching: selectRegistrationHealthIsFetching(state) || selectRegistrationDateAggregateIsFetching(state),
});

export default connect(mapStateToProps, {
  loadRegistrationHealth: loadRegistrationHealth,
})(WidgetRegistrations);
