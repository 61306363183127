/**
 * Division Structure Health Data
 *
 * Various selectors using reselect for selecting data from state
 *
 */
import _values from 'lodash/values';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.DIVISION_STRUCTURE_HEALTH];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectDivisionStructureHealthIsFetching = state => isFetchingSelector(state);

export const selectDivisionStructureHealthValues = state => _values(itemSelector(state))[0] || {};

export const selectDivisionStructureHealth = state => itemSelector(state);
