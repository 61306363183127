const convertLoading = loadingKey => loadingKey.replace(/[A-Z]/g, char => `_${char}`).toUpperCase();

const loadingStartAction = (loadingKey = '', startedAt = Date.now()) => ({
  type: `loading.LOAD_START_${convertLoading(loadingKey)}`,
  loadingKey,
  startedAt,
});

const loadingSuccessAction = (loadingKey = '', endedAt = Date.now()) => ({
  type: `loading.LOAD_SUCCESS_${convertLoading(loadingKey)}`,
  loadingKey,
  endedAt,
});

const loadingErrorAction = (loadingKey = '', error = '', endedAt = Date.now()) => ({
  type: `loading.LOAD_ERROR_${convertLoading(loadingKey)}`,
  loadingKey,
  error,
  endedAt,
});

export { loadingStartAction, loadingSuccessAction, loadingErrorAction };
