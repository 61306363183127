import React, { useEffect } from 'react';
import ModalRootContainer from 'shared/components/ModalRoot/ModalRootContainer';
import FeedbackRootContainer from 'shared/components/FeedbackRoot/FeedbackRootContainer';
import AppHeader from 'shared/components/AppHeader/AppHeader';
import { useAmplitude } from 'shared/hooks/useAmplitude';

import 'shared/styles/main.module.scss';
import styles from './Root.module.scss';

function Root(props) {
  const { params, locationChange, location, division } = props;
  const { amplitudeTrack } = useAmplitude();

  useEffect(() => {
    locationChange(location.pathname);
    if (division && window.TeamSnap?.organizationId) {
      amplitudeTrack.screenViewed(location.pathname, division);
    }
  }, [location.pathname, locationChange, division]);

  return (
    <div className={ styles.rootContainer }>
      <FeedbackRootContainer />
      { window.TeamSnap.showHeader && <AppHeader activeDivisionId={ params.divisionId } /> }
      { props.children }
      <ModalRootContainer />
    </div>
  );
}

export default Root;
