import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';
import classes from './RosterListRow.module.scss';

import RosterMemberRow from './RosterMemberRow';

const memberRow = {
  canDrag(props) {
    return !props.member.isCommissioner;
  },
  beginDrag(props) {
    if (!props.isSelected) {
      props.toggleSelectMember();
    }
    return {};
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class RosterListRow extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    currentMember: PropTypes.object,
    isDragging: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool,
    member: PropTypes.object,
    division: PropTypes.object,
    team: PropTypes.object,
    teamName: PropTypes.object,
    toggleSelectMember: PropTypes.func,
    pendingDivision: PropTypes.shape({}),
    pendingTeam: PropTypes.shape({}),
    hasLoadedMembersForTeam: PropTypes.bool,
  };

  formatCssClassNameForMemberPendingMove = member => {
    if (member.isPending) {
      return 'pendingMove--toTeam';
    }
    return null;
  };

  render() {
    const {
      connectDragSource,
      currentMember,
      isDragging,
      isSelected,
      member,
      division,
      team,
      teamName,
      toggleSelectMember,
      pendingDivision,
      pendingTeam,
    } = this.props;

    return connectDragSource(
      <tbody>
        <RosterMemberRow
          checkboxHandler={ toggleSelectMember }
          checkboxIsChecked={ isSelected }
          className={ `${classes.rosterListItem} ${isSelected && classes.selected}` }
          currentMember={ currentMember }
          member={ member }
          showCheckbox
          style={ { opacity: isDragging ? 0.5 : 1 } }
          team={ team }
          teamName={ teamName }
          division={ division }
          toggleSelectMember={ toggleSelectMember }
          pendingDivision={ pendingDivision }
          pendingTeam={ pendingTeam }
          pendingMoveClassName={ this.formatCssClassNameForMemberPendingMove(member) }
        />
      </tbody>,
    );
  }
}

export default DragSource('row', memberRow, collect)(RosterListRow);
