import React from 'react';
import ReactDOM from 'react-dom';
import history from 'utils/history';
import { setupAppcues } from 'utils/appcues';
import App from './App';
import * as serviceWorker from './serviceWorker';
import createStore from './store/createStore';
import { datadogRum } from '@datadog/browser-rum';
import { ampli } from './ampli';
import ENVIRONMENT from './environment';

datadogRum.init({
  applicationId: 'e8c47b64-f706-408c-8683-32ee8305ca0c',
  clientToken: 'pub04848e4fb8ba7997cfbd1ba99afbe185',
  site: 'datadoghq.com',
  service: 'season-frontend',
  env: 'prod',
  version: '0.0.1',
  sampleRate: 100,
  trackInteractions: true,
});

ampli.load({
  // @ts-ignore
  environment: ENVIRONMENT === 'prod' ? 'prodteamsnapforbusiness' : 'devteamsnapforbusiness',
  client: { configuration: { defaultTracking: false } },
});

const initialState = window.___INITIAL_STATE__;
const store = createStore(initialState, history);
window.__app_root = document.getElementById('root');
setupAppcues();
ReactDOM.render(<App store={ store } history={ history } />, window.__app_root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
