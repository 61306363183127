/**
 * Button Component
 *
 * A common button component, if you pass type `link` it will render a
 * react-router Link otherwise it will render a button with an onClick function.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from '@teamsnap/teamsnap-ui';
import defaultStyles from './Button.module.scss';

class Button extends PureComponent {
  renderIcon = (name, fontSize, classModifier) => <Icon name={ name } style={ { fontSize } } mods={ classModifier } />;

  renderText = () => {
    const { text, children, iconLeft, iconRight, iconSize } = this.props;

    return (
      <span>
        { iconLeft && this.renderIcon(iconLeft, iconSize, 'u-spaceRightXs') }
        { text || children }
        { iconRight && this.renderIcon(iconRight, iconSize, 'u-spaceLeftXs') }
      </span>
    );
  };

  renderLink = styles => {
    const { handleClick, className, disabled } = this.props;

    return (
      <Link to={ handleClick } className={ styles[className] } disabled={ disabled }>
        { this.renderText() }
      </Link>
    );
  };

  renderButton = styles => {
    const { handleClick, className, disabled, type } = this.props;

    return (
      <button type={ type } onClick={ handleClick } className={ styles[className] } disabled={ disabled }>
        { this.renderText() }
      </button>
    );
  };

  render() {
    const styles = { ...defaultStyles, ...this.props.componentStyles };
    return this.props.type === 'link' ? this.renderLink(styles) : this.renderButton(styles);
  }
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconSize: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  handleClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Button.defaultProps = {
  type: 'button',
  text: null,
  iconLeft: null,
  iconRight: null,
  iconSize: '10px',
  children: null,
  disabled: false,
  componentStyles: {},
  className: 'Button',
  handleClick: null,
};

export default Button;
