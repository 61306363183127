import { connect } from 'react-redux';
import { initApp, initActiveDivision, setActiveDivisionId } from 'state/app/actions';
import { selectLoadingDivision, selectActiveDivisionId } from 'state/app/selectors';
import AppSdk from './AppSdk';

const mapDispatchToProps = {
  initApp,
  initActiveDivision,
  setActiveDivisionId,
};

const mapStateToProps = state => ({
  loading: selectLoadingDivision(state, selectActiveDivisionId(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSdk);
