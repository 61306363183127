import { LOCATION_CHANGE } from 'state/locationChange/actions';
import { startAppcues } from 'utils/appcues';

const appcuesMiddleware = () => next => action => {
  if (action?.type === LOCATION_CHANGE) {
    startAppcues();
  }
  return next(action);
};

export default appcuesMiddleware;
