/**
 * Division Structure Health Data
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, COLLECTIONS } from 'state/snapi/slice';

export const loadDivisionStructureHealth = params => dispatch =>
  dispatch(getCollection(COLLECTIONS.DIVISION_STRUCTURE_HEALTH, 'division_structure_health_data/search', params));
