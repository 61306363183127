import initialState from 'state/initialState';
import * as constants from './constants';

function formsReducer(state = initialState.forms, action) {
  const { formName, fieldName, fieldValue } = action;

  switch (action.type) {
    case constants.ADD_FORM:
      return {
        ...state,
        [formName]: {},
      };
    case constants.ADD_FORM_ERROR:
      return state;
    case constants.FORM_UPDATE_FIELD:
      return {
        ...state,
        [formName]: { ...(state[formName] || {}), [fieldName]: fieldValue },
      };
    default:
      return state;
  }
}

export default formsReducer;
