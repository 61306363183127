"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function get() {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }
  __setModuleDefault(result, mod);
  return result;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = __importStar(require("react"));
var teamsnap_ui_1 = require("@teamsnap/teamsnap-ui");
require("./MultiSelectPicker.scss");
var saveButtonId = 'multiselect-save-button';
var MultiSelectPicker = function MultiSelectPicker(_a) {
  var containerMods = _a.containerMods,
    _b = _a.index,
    index = _b === void 0 ? 0 : _b,
    _c = _a.placeholder,
    placeholder = _c === void 0 ? 'Select' : _c,
    options = _a.options,
    updateSelectedOptions = _a.updateSelectedOptions,
    selected = _a.selected,
    disabled = _a.disabled,
    _d = _a.testId,
    testId = _d === void 0 ? 'multiselect-picker' : _d;
  var _e = React.useState(false),
    showOptions = _e[0],
    toggleShowOptions = _e[1];
  var _f = React.useState([]),
    selectedOptions = _f[0],
    setSelectedOptions = _f[1];
  React.useEffect(function () {
    if (selected) {
      setSelectedOptions(selected);
    }
  }, [selected]);
  var closeAndSave = function closeAndSave() {
    toggleShowOptions(false);
    updateSelectedOptions(selectedOptions);
  };
  var handleBodyClick = React.useCallback(function (e) {
    var _a;
    e.stopPropagation();
    var isTargetingPopup = e.target.closest('.multiselect-picker-container') != null;
    if (!isTargetingPopup) {
      (_a = document.getElementById(saveButtonId)) === null || _a === void 0 ? void 0 : _a.click();
    }
  }, []);
  React.useEffect(function () {
    if (!showOptions) {
      window.removeEventListener('click', handleBodyClick, false);
    } else {
      window.addEventListener('click', handleBodyClick, false);
    }
  }, [showOptions, handleBodyClick]);
  var onKeyDown = function onKeyDown() {
    if (disabled) return;
    toggleShowOptions(!showOptions);
    if (showOptions) {
      closeAndSave();
    }
  };
  return React.createElement("div", {
    "data-testid": testId,
    className: "multiselect-picker-container ".concat(containerMods)
  }, React.createElement("div", {
    "aria-disabled": disabled,
    className: "multiselect-picker-selected",
    onKeyDown: onKeyDown,
    onClick: function onClick() {
      return !disabled && toggleShowOptions(true);
    },
    role: "button",
    tabIndex: 0
  }, selectedOptions.map(function (option) {
    var _a;
    return React.createElement("div", {
      className: "selected selected-".concat(option.replace(/ /g, ''), "-").concat(index),
      key: "selected-".concat(option.replace(/ /g, ''), "-").concat(index)
    }, React.createElement("span", {
      title: option
    }, (_a = options.find(function (x) {
      return x.value === option;
    })) === null || _a === void 0 ? void 0 : _a.label), React.createElement("button", {
      type: "button",
      onClick: function onClick(e) {
        e.stopPropagation();
        updateSelectedOptions(selectedOptions.filter(function (e) {
          return e !== option;
        }));
      }
    }, React.createElement("svg", {
      width: "20",
      height: "20",
      viewBox: "0 0 20 20",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, React.createElement("path", {
      d: "M10.0002 11.229L5.97915 15.25C5.81248 15.4167 5.61115 15.4967 5.37515 15.49C5.13915 15.4827 4.93782 15.3957 4.77115 15.229C4.60448 15.0623 4.52115 14.8577 4.52115 14.615C4.52115 14.3717 4.60448 14.1667 4.77115 14L8.77115 10L4.75015 5.979C4.58348 5.81233 4.50348 5.60767 4.51015 5.365C4.51748 5.12167 4.60448 4.91667 4.77115 4.75C4.93782 4.58333 5.14248 4.5 5.38515 4.5C5.62848 4.5 5.83348 4.58333 6.00015 4.75L10.0002 8.771L14.0211 4.75C14.1878 4.58333 14.3925 4.5 14.6351 4.5C14.8785 4.5 15.0835 4.58333 15.2501 4.75C15.4168 4.91667 15.5001 5.12167 15.5001 5.365C15.5001 5.60767 15.4168 5.81233 15.2501 5.979L11.2292 10L15.2501 14.021C15.4168 14.1877 15.5001 14.389 15.5001 14.625C15.5001 14.861 15.4168 15.0623 15.2501 15.229C15.0835 15.3957 14.8785 15.479 14.6351 15.479C14.3925 15.479 14.1878 15.3957 14.0211 15.229L10.0002 11.229Z",
      fill: "#191919"
    }))));
  }), selectedOptions.length === 0 && React.createElement("span", {
    className: "placeholder"
  }, placeholder), React.createElement("svg", {
    className: "arrow",
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M11.3 14.3L8.70001 11.7C8.38335 11.3833 8.31268 11.021 8.48801 10.613C8.66268 10.2043 8.97501 10 9.42501 10H14.575C15.025 10 15.3373 10.2043 15.512 10.613C15.6873 11.021 15.6167 11.3833 15.3 11.7L12.7 14.3C12.6 14.4 12.4917 14.475 12.375 14.525C12.2583 14.575 12.1333 14.6 12 14.6C11.8667 14.6 11.7417 14.575 11.625 14.525C11.5083 14.475 11.4 14.4 11.3 14.3Z",
    fill: "#666666"
  }))), showOptions && React.createElement("div", {
    className: "multiselect-picker-options",
    key: "options-".concat(index)
  }, React.createElement("div", {
    className: "options"
  }, options.map(function (option) {
    return React.createElement("div", {
      className: "Checkbox",
      key: "option-".concat(option.value.replace(/ /g, ''), "-").concat(index)
    }, React.createElement("input", {
      className: "multiselect-picker-".concat(option.value.replace(/ /g, ''), " u-padRightXs Checkbox-input Checkbox--inline Checkbox--large"),
      "aria-checked": "true",
      tabIndex: 0,
      type: "checkbox",
      id: "multiselect-picker-".concat(option.value.replace(/ /g, '')),
      checked: selectedOptions.includes(option.value),
      name: "multiselect-picker-".concat(option.value.replace(/ /g, '')),
      value: option.value,
      onChange: function onChange(e) {
        if (selectedOptions.includes(e.target.value)) {
          setSelectedOptions(selectedOptions.filter(function (e) {
            return e !== option.value;
          }));
        } else {
          setSelectedOptions(__spreadArray(__spreadArray([], selectedOptions, true), [e.target.value], false));
        }
      }
    }), React.createElement("label", {
      className: "Checkbox-label",
      htmlFor: "multiselect-picker-".concat(option.value.replace(/ /g, ''))
    }, option.label));
  })), React.createElement("div", {
    className: "actions"
  }, React.createElement(teamsnap_ui_1.Button, {
    mods: "btn-clear",
    onClick: function onClick() {
      setSelectedOptions([]);
    }
  }, "Clear"), React.createElement(teamsnap_ui_1.Button, {
    otherProps: {
      id: saveButtonId
    },
    mods: "btn-save",
    onClick: closeAndSave
  }, "Save"))));
};
exports.default = MultiSelectPicker;