import { ampli } from '../../ampli';
import { matchPath } from 'react-router-dom';

export const ROUTES = {
  ['Messages List']: '/:divisionId/league_messages/list_emails',
  ['New Message']: '/:divisionId/league_messages/club_email',
  ['Health Check Questions']: '/:divisionId/safety',
  ['Rostering']: '/:divisionId/league_rostering',
  ['Members List']: '/:divisionId/league_members/list',
};

export const useAmplitude = () => {
  const getRoleDisplayName = (role) => {
    if (role === 'owner') {
      return 'Organization Owner';
    } else if (role === 'organization_admin') {
      return 'Organization Admin';
    } else if (role === 'program_admin') {
      return 'Program Admin';
    } else if (role === 'program_coordinator') {
      return 'Program Coordinator';
    }
    return '';
  };

  const amplitudeIdentify = (uuid, email, role) => {
    ampli.identify(uuid, {
      email: email,
      selected_org_staff_role: getRoleDisplayName(role),
    });
  };

  const amplitudeTrack = {
    screenViewed: (path, division) => {
      for (const [screenName, pathToMatch] of Object.entries(ROUTES)) {
        const match = matchPath(path, {
          path: pathToMatch,
          exact: true,
          strict: true,
        });

        if (match) {
          if (['Messages List', 'New Message', 'Rostering', 'Members List'].includes(screenName)) {
            /*
              Because Season Rostering and Division Rostering use the same path we need to determine if division
              is root or child division and send the correct screen name for amplitude screen view tracking
            */
            let screenType = 'Season';
            if (division.id !== division.rootId) {
              screenType = 'Division';
            }
            ampli.screenViewed({
              screen_name: `${screenType} ${screenName}`,
              url: path,
            });
          } else {
            ampli.screenViewed({
              screen_name: screenName,
              url: path,
            });
          }
        }
      }
    },
  };

  return { amplitudeIdentify, amplitudeTrack };
};
