import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
  // Pagination should display when there 2 or more pages
  // or if the user has a currentPage that is higher than the
  // totalPages. This is so if a user has a url to page=2 but there
  // is only one page worth of data we display the pagination control
  // so they can get back to the first page, and then the Pagination
  // control will dissapear

  static propTypes = {
    clickCallback: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number.isRequired,
    visiblePages: PropTypes.number,
  };

  static defaultProps = {
    currentPage: 1,
    visiblePages: 2,
  };

  handlePage = (page, event) => {
    if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
    if (this.props.clickCallback) {
      this.props.clickCallback(page);
    }
  };

  renderPrev(currentPage, totalPages) {
    if (currentPage === 1) {
      return (
        <span className="disabled prev_page" key="prev">
          « Prev
        </span>
      );
    }
    return (
      // eslint-disable-next-line
      <a onClick={this.handlePage.bind(null, currentPage - 1)} className="prev_page" rel="prev" key="prev">
        « Prev
      </a>
    );
  }

  renderNext(currentPage, totalPages) {
    if (currentPage === totalPages) {
      return (
        <span className="disabled next_page" key="next">
          Next »
        </span>
      );
    }
    return (
      // eslint-disable-next-line
      <a onClick={this.handlePage.bind(null, currentPage + 1)} className="next_page" rel="next" key="next">
        Next »
      </a>
    );
  }

  renderGap = () => <span className="gap">…</span>;

  renderPage(page, currentPage) {
    if (currentPage === page) {
      return (
        <span className="current" key={ `pagination_${page}` }>
          { page }
        </span>
      );
    }
    // eslint-disable-next-line
    return (
      <a onClick={ this.handlePage.bind(null, page) } key={ `pagination_${page}` }>
        { page }
      </a>
    );
  }

  render() {
    const { currentPage, totalPages, visiblePages } = this.props;
    if (totalPages < 2 && currentPage < 2) {
      return null;
    }

    let pages;
    if (totalPages < visiblePages && currentPage < totalPages) {
      pages = Array(totalPages).map(i => this.renderPage(i, currentPage));
    } else {
      const half = visiblePages / 2;
      let leftSide = currentPage - half;
      let rightSide = currentPage + half;

      if (leftSide < 2) {
        leftSide = 2;
      }
      if (rightSide > totalPages - 1) {
        rightSide = totalPages - 1;
      }

      pages = [];
      pages.push(this.renderPrev(currentPage, totalPages));
      pages.push(this.renderPage(1, currentPage));
      if (leftSide > 2) {
        pages.push(this.renderGap());
      }
      for (let i = leftSide; i <= rightSide; i += 1) {
        pages.push(this.renderPage(i, currentPage));
      }
      if (rightSide < totalPages - 1) {
        pages.push(this.renderGap());
      }
      if (totalPages > 1) {
        pages.push(this.renderPage(totalPages, currentPage));
      }
      pages.push(this.renderNext(currentPage, totalPages));
    }

    return <div className="pagination">{ pages }</div>;
  }
}

export default Pagination;
