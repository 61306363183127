import { Tooltip } from '@teamsnap/teamsnap-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { ModalConfirm } from 'shared/toolkit';
import classes from './RosterUnassignmentDropTarget.module.scss';

function collect(connect, monitor) {
  const item = monitor.getItem();
  const draggedMemberId = item ? item.memberId : null;
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    getItem: item,
    draggedMemberId,
  };
}

const unassign = {
  drop(props, monitor) {
    const { memberId } = monitor.getItem();
    if (memberId) {
      const member = props.members[memberId];

      if(member && member.isManager && member.isNonPlayer && !props.isPendingMode) {
        showConfirmModal(props, member);
      } else {
        props.assignMembers(memberId, member.isManager);
      }
    }
  },
};

function showConfirmModal(props, member) {
  const confirmFunction = () => {
    props.assignMembers(member.id, false);
  };

  const toolTipText =
    'Managers are able to add, edit, and remove participants from the roster, as well as make changes to schedules, games, and events';

  const modalProps = {
    confirmFunction,
    title: 'Unassign Manager',
    text: () => (
      <p>
        If you unassign a manager{ member.team?.name ? ' from ' + member.team.name : '' }
        <Tooltip
          text={ toolTipText }
          style={ { wordWrap: 'break-word' } }
          type="icon" mods="u-padXs"
        >
          manager permissions
        </Tooltip>
        will be removed.<br/><br/>
        Do you wish to proceed?
      </p>
    ),
    confirmButtonText: 'Yes, unassign',
    confirmCancelButtonText: 'No, keep assignment',
    overlay: true,
    className: 'ModalMedium'
  };
  
  return props.showModal(ModalConfirm, modalProps);
}

class RosterUnassignmentDropTarget extends Component {
  static propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    children: PropTypes.node,
    assignMembers: PropTypes.func.isRequired,
    members: PropTypes.array.isRequired,
    isPendingMode: PropTypes.bool.isRequired,
    isOver: PropTypes.bool,
    divisionName: PropTypes.string,
    draggedMemberId: PropTypes.number,
    showModal: PropTypes.func.isRequired,
  };

  render() {
    const { children, connectDropTarget, divisionName, draggedMemberId, isOver, members } = this.props;

    return connectDropTarget(
      <div className={ classes['drop-target-container'] }>
        { draggedMemberId && (
          <div className={ classes['drop-target'] }>
            <div className={ classes['drop-target-box'] }>
              <span className={ isOver ? classes.hovered : null }>- Drop Here to Unassign -</span>
            </div>
          </div>
        ) }
        { children }
      </div>,
    );
  }
}

export default DropTarget('row', unassign, collect)(RosterUnassignmentDropTarget);
