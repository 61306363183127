import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ImgEnvelopeLarge, ImgPencilLarge, ImgPaperclipSmall } from 'shared/components/Img';
import { PanelRow, PanelCell } from '@teamsnap/teamsnap-ui';

import { linkLeagueMessagesClubEmail, leagueMessagesViewEmailLink } from 'utils/links';
import { formatLocalDate } from 'utils/date-service';

function renderSentAt(broadcastEmail) {
  if (broadcastEmail.isDraft) {
    return <em>Not yet sent</em>;
  }
  return formatLocalDate(broadcastEmail.createdAt, 'MM/DD/YYYY h:mm a');
}

class ListEmailRow extends Component {
  static propTypes = {
    broadcastEmail: PropTypes.object.isRequired,
    division: PropTypes.object.isRequired,
    deleteIsChecked: PropTypes.bool.isRequired,
    clickDeleteEmail: PropTypes.func.isRequired,
  };

  renderEmailSubject(division, broadcastEmail) {
    if (broadcastEmail.isDraft) {
      return (
        <span className="ss-draw hrs__link-color">
          <ImgPencilLarge className="CLASSIC_ONLY hrs__margin-right--5px" />
          <em>
            <Link to={ linkLeagueMessagesClubEmail(division.id, broadcastEmail.id) }>{ broadcastEmail.subject }</Link>
          </em>
        </span>
      );
    }
    return (
      <span className="ss-mail hrs__link-color">
        <ImgEnvelopeLarge className="CLASSIC_ONLY hrs__margin-right--5px" />
        <Link to={ leagueMessagesViewEmailLink(division.id, broadcastEmail.id) }>{ broadcastEmail.subject }</Link>
      </span>
    );
  }

  onClickDeleteEmail = event => {
    const { clickDeleteEmail, broadcastEmail } = this.props;
    clickDeleteEmail(broadcastEmail.id, event.target.checked);
  };

  limitedRecipients(broadcastEmail) {
    if (broadcastEmail.recipientCount > 5) {
      let names = broadcastEmail.recipientNames.split(',');
      names = names.splice(0, 5);
      return names.join(',');
    }
    return broadcastEmail.recipientNames;
  }

  renderRecipients(broadcastEmail) {
    if (broadcastEmail.isDraft && broadcastEmail.recipientCount === 0) {
      return <em>Draft email. No recipients defined.</em>;
    }
    return `LEAGUE: ${this.limitedRecipients(broadcastEmail)}`;
  }

  renderAttachments(broadcastEmail) {
    if (broadcastEmail.attachmentNames && broadcastEmail.attachmentNames.length > 0) {
      return (
        <span>
          <ImgPaperclipSmall />
          { ` ${broadcastEmail.attachmentCount} ${broadcastEmail.attachmentCount > 1 ? 'Files' : 'File'}` }
        </span>
      );
    }
    return null;
  }

  render() {
    const { division, broadcastEmail, deleteIsChecked } = this.props;

    return (
      <PanelRow isWithCells>
        <PanelCell>{ this.renderEmailSubject(division, broadcastEmail) }</PanelCell>
        <PanelCell>{ this.renderRecipients(broadcastEmail) }</PanelCell>
        <PanelCell>{ renderSentAt(broadcastEmail) }</PanelCell>
        <PanelCell>{ this.renderAttachments(broadcastEmail) }</PanelCell>
        <PanelCell className="Panel-cell u-flex u-flexJustifyEnd">
          <label className="hrs__checkbox--inline" style={ { paddingRight: 40 } }>
            <input
              className="delete_checkbox"
              type="checkbox"
              checked={ deleteIsChecked || false }
              onClick={ this.onClickDeleteEmail }
            />
            <span className="hrs__checkbox-label" />
          </label>
        </PanelCell>
      </PanelRow>
    );
  }
}

export default ListEmailRow;
