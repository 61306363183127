import { loadItems } from 'state/teamsnap/actions';
import { isArchived } from 'utils/divisions';

import { loadingStartAction, loadingSuccessAction } from 'state/loading/actions';

import { setLoadedDivisionTeamNames } from 'state/app/actions';

import { selectLoadedDivisionTeamNames } from 'state/app/selectors';
import { selectDivisionById } from 'state/teamsnap/divisions/selectors';

const loadTeamNamesByDivisionId = divisionId => (dispatch, getState) => {
  if (!selectLoadedDivisionTeamNames(getState(), divisionId)) {
    const division = selectDivisionById(getState(), divisionId);
    dispatch(loadingStartAction('allDivisionTeamNames'));

    return dispatch(
      loadItems('teamNames', {
        ancestorDivisionId: divisionId,
        isActive: !isArchived(division),
      }),
    ).then(_items => {
      dispatch(loadingSuccessAction('allDivisionTeamNames'));
      return dispatch(setLoadedDivisionTeamNames(divisionId));
    });
  }
  return Promise.resolve();
};

export { loadTeamNamesByDivisionId };
