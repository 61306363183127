import React from 'react';

// Components
import { Cell, Grid, Toggle } from '@teamsnap/teamsnap-ui';

const MemberStatus = ({ updateCurrentFilter, getDefaultValue }) => (
    <form className="u-spaceMd">
      <Grid mods="u-spaceBottomLg">
        <Cell className="u-sizeFill u-spaceRightMd">
          <h4>
            <label htmlFor="hide_rostered">Hide Rostered</label>
          </h4>
          <span className="u-colorGrey">Exclude members that are already assigned to teams.</span>
        </Cell>
        <Cell className="u-sizeFit">
          <Toggle
            className="Toggle"
            inputProps={ {
              onChange: updateCurrentFilter,
              checked: getDefaultValue('hideRostered', false),
            } }
            name="hideRostered"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell className="u-sizeFill u-spaceRightMd">
          <h4>
            <label htmlFor="pending_assignments">Hide Pending</label>
          </h4>
          <span className="u-colorGrey">
            Exclude members that have been assigned to teams or divisions in builder mode but not published.
          </span>
        </Cell>
        <Cell className="u-sizeFit">
          <Toggle
            className="Toggle"
            inputProps={ {
              onChange: updateCurrentFilter,
              checked: !(getDefaultValue('pending_assignments', 'include') === 'include'),
            } }
            name="pending_assignments"
          />
        </Cell>
      </Grid>
    </form>
  );

export default MemberStatus;
