import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@teamsnap/teamsnap-ui';
import './Questionnaire.scss';


const Questionnaire = props => {
  const { questionnaire } = props;

  const questionFormatted = prompt => prompt.replace(/"/g, '');

  const questionRows = questionnaire.map(question => ({
    question: questionFormatted(question.prompt),
    response: question.symptomFreeAnswer,
    id: question.id,
  }));

  const columns = [
    { name: 'question', label: 'Health Check Questions', isSortable: false, mods: 'u-size9of12' },
    {
      name: 'response',
      label: 'Cleared Response',
      isSortable: false,
      align: 'center',
    },
  ];

  return (
    <Table className="Panel" columns={ columns } isStriped placeHolder="Loading..." rows={ questionRows } />
  );
};

Questionnaire.propTypes = {
  questionnaire: PropTypes.array.isRequired,
};

export default Questionnaire;
