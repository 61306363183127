import { connect } from 'react-redux';
import history from 'utils/history';
import { withRouter } from 'react-router-dom';
import { clickDeleteAllEmails, clickDeleteEmail, deleteEmails } from 'state/leagueMessages/actions';
import { locationChange } from 'state/locationChange/actions';

import { selectActiveDivisionId } from 'state/app/selectors';
import { selectEmailsByPage, selectLoadingLeagueMessages, selectTotalEmailPages } from 'state/leagueMessages/selectors';
import { selectAncestorDivisions, selectActiveDivision } from 'state/teamsnap/divisions/selectors';

import { linkLeagueMessagesListEmails, linkAlerts } from 'utils/links';

/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component - in this case, the counter:   */

import ListEmails from '../components/ListEmails';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't care   */

const clickPage = (page, division) => {
  history.push({
    pathname: `${linkLeagueMessagesListEmails(division.id)}`,
    search: `?page=${page}`,
    state: {
      page,
    },
  });
};

const redirectToListAlerts = divisionId => history.push(linkAlerts(divisionId));

const mapDispatchToProps = {
  clickDeleteEmail,
  clickDeleteAllEmails,
  deleteSelectedEmails: deleteEmails,
  clickPage,
  redirectToListAlerts,
  locationChange,
};

const mapStateToProps = (state, ownProps) => ({
  broadcastEmails: selectEmailsByPage(state, ownProps.location.state?.page || 1),
  totalEmailPages: selectTotalEmailPages(state),
  division: selectActiveDivision(state),
  ancestorDivisions: selectAncestorDivisions(state, selectActiveDivisionId(state)),
  deleteAllEmails: state.leagueMessages.listEmails.deleteAllEmails,
  deleteEmailIds: state.leagueMessages.listEmails.deleteEmailIds,
  loading: selectLoadingLeagueMessages(state),
});

/*  Note: mapStateToProps is where you should use `reselect` to create selectors, ie:

    import { createSelector } from 'reselect'
    const counter = (state) => state.counter
    const tripleCount = createSelector(counter, (count) => count * 3)
    const mapStateToProps = (state) => ({
      counter: tripleCount(state)
    })

    Selectors can compute derived data, allowing Redux to store the minimal possible state.
    Selectors are efficient. A selector is not recomputed unless one of its arguments change.
    Selectors are composable. They can be used as input to other selectors.
    https://github.com/reactjs/reselect    */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListEmails));
