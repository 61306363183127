import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './AssignmentConfirmation.module.scss';

class AssignmentConfirmation extends Component {
  static propTypes = {
    countOfAssignedMembers: PropTypes.number,
  };

  render() {
    const { countOfAssignedMembers } = this.props;

    if (countOfAssignedMembers > 0) {
      return (
        <div className={ classes.PostDrop }>
          <i className={ [classes['PostDrop-icon'], 'ss-check'].join(' ') } />
          <h4 className={ classes['PostDrop-title'] }>Assignment Complete</h4>
          <p className={ classes['PostDrop-text'] }>{ countOfAssignedMembers } Members Assigned</p>
        </div>
      );
    }
    return null;
  }
}

export default AssignmentConfirmation;
