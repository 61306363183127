import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LeagueRosterSubnav extends Component {
  static propTypes = {
    division: PropTypes.object,
  };

  render() {
    return (
      <div id="subnav">
        <h2 className="title">
          Rostering
          <a
            className="helplink--ss-info"
            target="_blank"
            rel="noopener noreferrer"
            href="https://helpme.teamsnap.com/hc/en-us/articles/115009005267-Assigning-Members-to-a-Division-or-Team"
          />
        </h2>
      </div>
    );
  }
}

export default LeagueRosterSubnav;
