import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import appReducer from 'state/app/reducer';
import loadingReducer from 'state/loading/reducer';
import formsReducer from 'state/forms/reducer';
import healthCheckQuestionnaireReducer from 'state/healthCheckQuestionnaire/reducer';
import modalReducer from 'state/modal/reducer';
import feedbackReducer from 'state/feedback/reducer';
import teamsnapReducer from 'state/teamsnap/reducer';
import snapi from 'state/snapi/slice';
import leagueMembersReducer from 'state/leagueMembers/reducer';
import leagueRosteringReducer from 'state/leagueRostering/reducer';
import leagueMessagesReducer from 'state/leagueMessages/reducer';

export const makeRootReducer = asyncReducers =>
  combineReducers({
    // Add sync reducers here
    app: appReducer,
    form: formReducer,
    forms: formsReducer,
    feedback: feedbackReducer,
    healthCheckQuestionnaire: healthCheckQuestionnaireReducer,
    loading: loadingReducer,
    leagueMembers: leagueMembersReducer,
    leagueMessages: leagueMessagesReducer,
    leagueRostering: leagueRosteringReducer,
    modal: modalReducer,
    snapi: snapi.reducer,
    teamsnap: teamsnapReducer,
    ...asyncReducers,
  });

export default makeRootReducer;
