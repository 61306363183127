import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './SavedFilterButton.module.scss';

class SavedFilterButton extends Component {
  static propTypes = {
    clickHandler: PropTypes.func,
    filter: PropTypes.object,
    isActive: PropTypes.bool,
    removeHandler: PropTypes.func,
  };

  click = () => {
    this.props.clickHandler(this.props.filter);
  };

  removeFilter = e => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(`Are you sure you want to delete this filter: ${this.props.filter.search_title}`)
    ) {
      e.stopPropagation();
      this.props.removeHandler(this.props.filter.filterIndex);
    }
  };

  render() {
    const { filter, isActive } = this.props;

    return (
      <a className={ `${classes['pill-button']} ${isActive ? classes['active-filter'] : ''}` } onClick={ this.click }>
        { filter.search_title }
        <span
          className={ classes['filter-dismissal--ss-delete'] }
          style={ { color: '#e26362' } }
          onClick={ this.removeFilter }
        />
      </a>
    );
  }
}

export default SavedFilterButton;
