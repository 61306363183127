import { Button, Cell, Grid, Tooltip } from '@teamsnap/teamsnap-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { ModalConfirm } from 'shared/toolkit';
import FontIcon from 'shared/toolkit/FontIcon';
import RosterAssignmentListRowContainer from '../containers/RosterAssignmentListRowContainer';
import AssignmentConfirmation from './AssignmentConfirmation';
import AssignmentDropZone from './AssignmentDropZone';
import classes from './RosterAssignmentList.module.scss';

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

// this is a bit hacky, but I have to have a way for the function inside the compoenent to be
// called by this drop which is outside the component.
let doAssignment = () => null;

const rosterList = {
  drop(props) {
    doAssignment();
  },
};

class RosterAssignmentList extends Component {
  static propTypes = {
    assignMembers: PropTypes.func.isRequired,
    assignPendingMembersByTeamId: PropTypes.func.isRequired,
    deletePendingMembersByTeamId: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    countOfTeamAssignments: PropTypes.number,
    countOfSelectedMembers: PropTypes.number,
    division: PropTypes.object,
    hasPendingTeamMembers: PropTypes.bool.isRequired,
    isPendingMode: PropTypes.bool.isRequired,
    isOver: PropTypes.bool.isRequired,
    members: PropTypes.array,
    pendingTeamMembersCount: PropTypes.number.isRequired,
    showModal: PropTypes.func.isRequired,
    teamName: PropTypes.shape({}),
    toggleRosterListSort: PropTypes.func,
    isPlayerTab: PropTypes.bool.isRequired,
  };

  state = {
    isManager: true
  }

  makeClickSort = sortOn => () => {
    this.props.toggleRosterListSort(sortOn);
  };

  showConfirmApproveAssignmentsModal = () => {
    const { teamName } = this.props;
    const confirmFunction = this.props.assignPendingMembersByTeamId;
    const modalProps = {
      confirmFunction,
      title: `Publish Roster for ${teamName.name}`,
      text: `<p><strong>Please confirm roster changes for ${teamName.name}</strong></p>
        <ul class='u-padMd'>
          <li>All pending additions will be finalized.</li>
          <li>Members will be able to see which team they are on.</li>
          <li>
            All statistics, availabilities, payments and other team-specific information
            will be deleted if the member’s team is changed or if they are unassigned.
          </li>
        </ul>`,
      confirmButtonText: 'Publish Roster',
      overlay: true,
      className: 'ModalMedium',
    };
    return this.props.showModal(ModalConfirm, modalProps);
  };

  showCancelPendingAssignmentsModal = () => {
    const { teamName } = this.props;
    const confirmFunction = this.props.deletePendingMembersByTeamId;
    const modalProps = {
      confirmFunction,
      title: `Cancel Pending Assignments for ${teamName.name}`,
      text: `<p>Are you sure you want to cancel the pending assignments for ${teamName.name}?</p>`,
      confirmButtonText: 'Yes',
      confirmCancelButtonText: 'No',
      overlay: true,
      className: 'ModalMedium',
    };
    return this.props.showModal(ModalConfirm, modalProps);
  };

  showAssignManagerPermissionToNonPlayerModal = () => {
    if (this.props.isPlayerTab === false && !this.props.isPendingMode) {
      this.setState({ isManager: true });

      const confirmFunction = () => {
        this.props.assignMembers(this.state.isManager);
      };

      const toolTipText =
        'Assigning a member as a manager of a team grants them access to all areas of team site, with full administrative permissions to manage information and settings within the team.';

      const modalProps = {
        confirmFunction,
        title: 'Manager Permission',
        text: () => (
          <p>
            Would you like to make this person a { ' ' }
            <Tooltip
              text={ toolTipText }
              style={ { wordWrap: 'break-word' } }
              type="icon"
            >
              manager
            </Tooltip>?
          </p>
        ),
        confirmButtonText: 'Confirm and Assign',
        confirmCancelButtonText: 'Cancel Assignment',
        overlay: true,
        className: 'ModalMedium',
        inputOptions: [
          {
            type: 'radio',
            name: 'isManager',
            value: this.state.isManager,
            label: 'Yes, grant manager permissions.',
            onChange: () => this.setState({ isManager: true }),
          },
          {
            type: 'radio',
            name: 'isManager',
            value: !this.state.isManager,
            label: 'No, do not grant manager permissions.',
            onChange: () => this.setState({ isManager: false }),
          },
        ],
      };
      return this.props.showModal(ModalConfirm, modalProps);
    } else {
      return this.props.assignMembers();
    }
  };

  render() {
    doAssignment = this.showAssignManagerPermissionToNonPlayerModal;

    const {
      connectDropTarget,
      countOfTeamAssignments,
      countOfSelectedMembers,
      division,
      isOver,
      members,
      teamName,
      hasPendingTeamMembers,
      pendingTeamMembersCount,
      isPlayerTab,
      isPendingMode
    } = this.props;

    const sortIcon = <FontIcon name="icon-sort" />;

    return connectDropTarget(
      <div
        className={
          countOfSelectedMembers || countOfTeamAssignments
            ? [classes['drop-zone'], classes.active].join(' ')
            : classes['drop-zone']
        }
      >
        { hasPendingTeamMembers && (
          <Grid
            className={ classes.pendingAssignmentsRow }
            mods="u-padSidesSm u-padEndsMd Grid Grid--fit Grid--alignMiddle"
          >
            <Cell>
              <span className="u-colorSecondary">{ pendingTeamMembersCount }</span> pending change
              { pendingTeamMembersCount > 1 ? 's' : '' } on { teamName.name }
            </Cell>
            <Cell mods="u-textRight">
              <Button
                type="button"
                className="Button Button--primary Button--small u-spaceSidesSm"
                onClick={ this.showConfirmApproveAssignmentsModal }
              >
                Publish
              </Button>
              <Button
                type="button"
                className="Button Button--negative Button--small"
                onClick={ this.showCancelPendingAssignmentsModal }
              >
                Cancel Pending
              </Button>
            </Cell>
          </Grid>
        ) }
        <table
          className={
            !hasPendingTeamMembers
              ? [classes.dropZone__table, classes.dropZone__table__hasPendingAssignments].join(' ')
              : classes.dropZone__table
          }
        >
          <thead>
            <tr>
              <th onClick={ this.makeClickSort('firstName') }>First Name { sortIcon }</th>
              <th onClick={ this.makeClickSort('lastName') }>Last Name { sortIcon }</th>
              <th onClick={ this.makeClickSort('gender') }>Gender { sortIcon }</th>
              <th onClick={ this.makeClickSort('age') }>Age { sortIcon }</th>
              <th onClick={ this.makeClickSort('team') }>Team { sortIcon }</th>
            </tr>
          </thead>
          { members.length > 0 &&
            members.filter((m) => isPendingMode || m.isNonPlayer === (isPlayerTab === false)).map(member => (
              <RosterAssignmentListRowContainer key={ member.id } member={ member } teamName={ teamName } />
            )) }
          { members.length === 0 && (
            <tr>
              <td colSpan="5">
                <span className={ classes['drop-placeholder'] }>- No Players Assigned to this team -</span>
              </td>
            </tr>
          ) }
        </table>
        <div className={ classes['drop-info'] }>
          { countOfSelectedMembers > 0 && (
            <AssignmentDropZone
              assignMembers={ this.showAssignManagerPermissionToNonPlayerModal }
              division={ division }
              isOver={ isOver }
              teamName={ teamName }
            />
          ) }
          { countOfTeamAssignments > 0 && <AssignmentConfirmation countOfAssignedMembers={ countOfTeamAssignments } /> }
        </div>
      </div>,
    );
  }
}

export default DropTarget('row', rosterList, collect)(RosterAssignmentList);
