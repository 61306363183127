import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';

import { select, selectAndSortBy } from 'state/teamsnap/selectors';

const selectLeagueCustomFields = state => select(state, 'leagueCustomFields');
const selectLeagueCustomFieldById = (state, id) => selectLeagueCustomFields(state)[_parseInt(id)];

const selectAndSortLeagueCustomFieldsForDivisionId = createSelector([selectLeagueCustomFields], leagueCustomFields =>
  selectAndSortBy(leagueCustomFields, 'divisionId'),
);

const selectLeagueCustomFieldsForDivisionId = state => divisionId =>
  selectAndSortLeagueCustomFieldsForDivisionId(state)[_parseInt(divisionId)] || [];

export { selectLeagueCustomFields, selectLeagueCustomFieldById, selectLeagueCustomFieldsForDivisionId };
