import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';

class PopoverItem extends PureComponent {
  render() {
    const { isModalTrigger, id, link, text, icon, className, callBack } = this.props;

    if (isModalTrigger === true) {
      return (
        <a className={ `u-colorInfo u-block ${className}` } key={ id } onClick={ callBack }>
          <Icon name={ icon } mods="u-spaceRightXs u-fontSizeSm" />
          { text }
        </a>
      );
    }

    return (
      <a className={ `u-colorInfo u-block ${className}` } key={ id } href={ link } onClick={ callBack || null }>
        <Icon name={ icon } mods="u-spaceRightXs u-fontSizeSm" />
        { text }
      </a>
    );
  }
}

PopoverItem.propTypes = {
  link: PropTypes.string,
  callBack: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  isModalTrigger: PropTypes.bool,
};

PopoverItem.defaultProps = {
  link: null,
  callBack: null,
  className: '',
  isModalTrigger: false,
};

export default PopoverItem;
