import { connect } from 'react-redux';
import { hideModal } from 'state/modal/actions';
import { showFeedback } from 'state/feedback/actions';
import { deleteMember } from 'state/members/actions';
import { initDetailedMemberView } from 'state/leagueMembers/actions';
import { loadProgramMemberships } from 'state/programMemberships/actions';
import DeleteMembershipModal from '../components/detailedMemberView/DeleteMembershipModal';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  hideModal,
  showFeedback,
  deleteMember,
  initDetailedMemberView,
  loadProgramMemberships,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteMembershipModal);
