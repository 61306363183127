/**
 * Division Behaviors
 *
 * Various actions using redux-thunk and axios for managing communication with API and redux store
 *
 */

import { getCollection, COLLECTIONS } from 'state/snapi/slice';

export const loadDivisionBehaviors = params => dispatch =>
  dispatch(getCollection(COLLECTIONS.DIVISION_BEHAVIORS, 'division_behaviors/search', params));
