import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _parseInt from 'lodash/parseInt';
import { featureRollout } from 'utils/window';

import AppMessageContainer from 'shared/components/AppMessage/AppMessageContainer';
import AppLoading from 'shared/components/AppLoading';
import Pagination from 'shared/components/Pagination';
import { linkLeagueMessagesClubEmail, linkLeagueMessagesListEmails } from 'utils/links';
import { ButtonGroup, Button, Header } from 'shared/toolkit';

import history from 'utils/history';
import ListEmailsNoData from './ListEmailsNoData';
import ListEmailsContent from './ListEmailsContent';

import styles from './ListEmails.module.scss';

class ListEmails extends Component {
  static propTypes = {
    division: PropTypes.object,
    ancestorDivisions: PropTypes.array,
    broadcastEmails: PropTypes.array,
    loading: PropTypes.bool,
    deleteAllEmails: PropTypes.bool,
    deleteEmailIds: PropTypes.object,
    totalEmailPages: PropTypes.number,
    clickDeleteAllEmails: PropTypes.func.isRequired,
    deleteSelectedEmails: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    locationChange: PropTypes.func.isRequired,
  };

  onPaginationClick = page => {
    const { division, locationChange } = this.props;
    locationChange(`${linkLeagueMessagesListEmails(division.id)}`);
    return history.push({
      path: `${linkLeagueMessagesListEmails(division.id)}`,
      search: `?page=${page}`,
      state: {
        page,
      },
    });
  };

  getPathName = divisionId => linkLeagueMessagesListEmails(divisionId);

  UNSAFE_componentWillMount() {
    this.buttonGroups = [
      {
        text: 'Email',
        iconLeft: 'mail',
        isActive: true,
        handleClick: () => null,
      },
    ];
  }

  render() {
    const { division, totalEmailPages, loading } = this.props;
    const currentPage = _parseInt(this.props.location.state?.page) || 1;

    if (loading) {
      return <AppLoading loading={ loading || !division } />;
    }

    return (
      <div>
        <Header size={ 1 } componentStyles={ styles } title="Messages">
          <Pagination
            clickCallback={ this.onPaginationClick }
            currentPage={ currentPage }
            totalPages={ totalEmailPages } />
          <ButtonGroup buttons={ this.buttonGroups } componentStyles={ styles } />
          <Button
            type="link"
            className="ButtonPrimary"
            text={ `Email ${division.name}` }
            iconLeft="mail"
            handleClick={ linkLeagueMessagesClubEmail(division.id) }
          />
        </Header>
        <div id="content">
          <AppMessageContainer />
          { totalEmailPages === 0 && <ListEmailsNoData division={ division } /> }
          { totalEmailPages !== 0 && <ListEmailsContent { ...this.props } /> }
        </div>
      </div>
    );
  }
}

export default ListEmails;
