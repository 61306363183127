import _parseInt from 'lodash/parseInt';

import { loadingErrorAction, loadingStartAction, loadingSuccessAction } from 'state/loading/actions';
import { loadMemberInfo } from 'state/teamsnap/members/actions';
import { addItems } from 'state/teamsnap/actions';
import { loadPlans } from 'state/teamsnap/plans/actions';
import { loadSports } from 'state/teamsnap/sports/actions';

import { selectAppLoaded, selectLoadedDivisions, selectAuthToken, selectSnapiUrl } from 'state/app/selectors';
import { selectDescendantDivisions } from 'state/teamsnap/divisions/selectors';

import { randomStartupMessage } from 'utils/startupMessages';
import teamsnapSdk from 'utils/teamsnapSdk';
import getErrorMessage from 'utils/errorMessage';
import * as constants from './constants';
import { selectMemberById } from 'state/teamsnap/members/selectors';
import { useAmplitude } from 'shared/hooks/useAmplitude';

const loadCollectionsStart = () => ({
  type: constants.LOAD_COLLECTIONS_START,
});

const loadCollectionsSuccess = () => ({
  type: constants.LOAD_COLLECTIONS_SUCCESS,
});

const loadCollectionsError = error => ({
  type: constants.LOAD_COLLECTIONS_ERROR,
  error,
});

const setSnapiUrl = snapiUrl => ({
  type: constants.SET_SNAPI_URL,
  snapiUrl,
});

const setAuthToken = authToken => ({
  type: constants.SET_AUTH_TOKEN,
  authToken,
});

const loadCollections = () => dispatch => {
  dispatch(loadCollectionsStart());
  return teamsnapSdk()
    .loadCollections()
    .then(
      () => {
        dispatch(loadCollectionsSuccess());
      },
      error => {
        const errorMessage = getErrorMessage(error);
        dispatch(setAppError(errorMessage));
        dispatch(loadCollectionsError(errorMessage));
      },
    );
};

const initTeamSnapSdk = () => (dispatch, getState) => {
  dispatch(setSnapiUrl(window.TeamSnap.apiv3Url));
  dispatch(setAuthToken(window.TeamSnap.authenticationToken));

  teamsnapSdk().apiUrl = selectSnapiUrl(getState());
  teamsnapSdk().auth(selectAuthToken(getState()));
  teamsnapSdk().disablePersistence();

  return dispatch(loadCollections());
};

// ------------------------------------
// Actions
// ------------------------------------
const loadAppStart = () => loadingStartAction('app');
const loadAppSuccess = () => loadingSuccessAction('app');
const loadAppError = error => loadingErrorAction('app', error);

const initApp = () => (dispatch, getState) => {
  if (selectAppLoaded(getState())) {
    return Promise.resolve();
  }
  dispatch(loadAppStart());
  dispatch(loadStartupMessage());
  return dispatch(initTeamSnapSdk()).then(() => {
    Promise.all([dispatch(loadSports()), dispatch(loadPlans()), dispatch(loadRollouts())]).then(
      () => {
        dispatch(loadAppSuccess());
      },
      error => {
        const errorMessage = getErrorMessage(error);
        dispatch(loadAppError(errorMessage));
        dispatch(setAppError(errorMessage));
      },
    );
  });
};

const loadDivisionTreeStart = () => loadingStartAction('divisionTree');
const loadDivisionTreeSuccess = () => loadingSuccessAction('divisionTree');
const loadDivisionTreeError = error => loadingErrorAction('divisionTree', error);

const loadDivisionTree = divisionId => dispatch => {
  dispatch(loadDivisionTreeStart());
  return teamsnapSdk()
    .collections.divisions.queryItems('tree', { id: divisionId })
    .then(
      divisions => {
        dispatch(addItems({ items: divisions }));
        dispatch(loadDivisionTreeSuccess());
        dispatch(setLoadedDivisions(divisionId));
      },
      error => {
        const errorMessage = getErrorMessage(error);
        dispatch(loadDivisionTreeError(errorMessage));
      },
    );
};

const loadRootDivisionStart = () => loadingStartAction('rootDivision');
const loadRootDivisionSuccess = () => loadingSuccessAction('rootDivision');
const loadRootDivisionError = error => loadingErrorAction('rootDivision', error);

const initActiveDivision = (divisionId, memberId) => (dispatch, getState) => {
  if (selectLoadedDivisions(getState(), _parseInt(divisionId))) {
    return Promise.resolve();
  }
  dispatch(loadRootDivisionStart(divisionId));
  return Promise.all([
    dispatch(setLoggedInMemberId(memberId)),
    dispatch(loadMemberInfo(memberId)),
    dispatch(loadDivisionTree(divisionId)),
  ]).then(
    () => {
      const member = selectMemberById(getState(), memberId);
      // Enable Amplitude tracking only if TSB
      if (member && window.TeamSnap?.organizationId) {
        useAmplitude().amplitudeIdentify(
          window.TeamSnap.uciUserId,
          member.emailAddresses[0],
          window.TeamSnap.orgUserRole,
        );
      }
      dispatch(loadRootDivisionSuccess(divisionId));
    },
    error => {
      const errorMessage = getErrorMessage(error);
      dispatch(loadRootDivisionError(divisionId, errorMessage));
      return dispatch(setAppError(errorMessage));
    },
  );
};

const setWePayStatus = status => ({
  type: constants.SET_WEPAY_STATUS,
  payload: status,
});

const setAppError = (error, temporary) => ({
  type: constants.SET_APP_ERROR,
  payload: error,
  temporary: temporary || false,
});

const setAppNotice = (notice, temporary) => ({
  type: constants.SET_APP_NOTICE,
  payload: notice,
  temporary: temporary || false,
});

const clearAppMessages = () => dispatch => {
  dispatch(setAppError(null));
  dispatch(setAppNotice(null));
};

const setLoggedInMemberId = memberId => ({
  type: constants.SET_LOGGED_IN_MEMBER_ID,
  memberId,
});

const addRollouts = rollouts => ({
  type: constants.ADD_ROLLOUTS,
  rollouts,
});

const loadRollouts = () => dispatch => {
  const rollouts = { ...(window.TeamSnap.featureRollouts || {}) };
  return dispatch(addRollouts(rollouts));
};

const setStartupMessage = startupMessage => ({
  type: constants.SET_STARTUP_MESSAGE,
  startupMessage,
});

const loadStartupMessage = () => dispatch => {
  const startupMessage = randomStartupMessage();
  console.log('%cWelcome to TeamSnap!', 'font-size:2em;color:#236bad;');
  console.log(`%c${startupMessage}`, 'font-size:.9em;font-weight:bold;color:#000');
  return dispatch(setStartupMessage(startupMessage));
};

const setActiveDivisionId = divisionId => ({
  type: constants.SET_ACTIVE_DIVISON_ID,
  divisionId,
});

function setLoadedDivisions(activeDivisionId) {
  return (dispatch, getState) => {
    const descendantDivisions = selectDescendantDivisions(getState(), activeDivisionId);

    dispatch({
      type: constants.SET_LOADED_DIVISIONS,
      loadedDivisions: [_parseInt(activeDivisionId), ...descendantDivisions.map(division => division.id)],
    });
  };
}

function setLoadedDivisionTeams(activeDivisionId) {
  return (dispatch, getState) => {
    const descendantDivisions = selectDescendantDivisions(getState(), activeDivisionId);

    dispatch({
      type: constants.SET_LOADED_DIVISION_TEAMS,
      loadedDivisionTeams: [_parseInt(activeDivisionId), ...descendantDivisions.map(division => division.id)],
    });
  };
}

function setLoadedDivisionTeamNames(activeDivisionId) {
  return (dispatch, getState) => {
    const descendantDivisions = selectDescendantDivisions(getState(), activeDivisionId);
    dispatch({
      type: constants.SET_LOADED_DIVISION_TEAM_NAMES,
      loadedDivisionTeamNames: [_parseInt(activeDivisionId), ...descendantDivisions.map(division => division.id)],
    });
  };
}

export {
  initApp,
  initActiveDivision,
  setWePayStatus,
  setAppError,
  setAppNotice,
  clearAppMessages,
  setLoggedInMemberId,
  setStartupMessage,
  loadStartupMessage,
  setActiveDivisionId,
  setLoadedDivisions,
  setLoadedDivisionTeams,
  setLoadedDivisionTeamNames,
  // private
  loadAppStart as _loadAppStart,
  loadAppSuccess as _loadAppSuccess,
  loadAppError as _loadAppError,
};
