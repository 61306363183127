/**
 * API
 *
 * A wrapper around the request object to simplify apvi3 calls
 *
 */

import AXIOS from 'axios';
import { ORG_API_URL } from 'state/app/constants';
import { plissken } from 'utils/utils';
import { convertToCollection } from './collectionJson';
import { request, requestAll } from './request';

export const org_axios = AXIOS.create({
  baseURL: ORG_API_URL,
  withCredentials: true,
  validateStatus: (status) => (status >= 200 && status < 300) || status == 400 || status == 403,
});

const paramsToSnake = (params = {}) =>
  Object.assign(
    ...Object.keys(params).map((key) => {
      const paramKey = plissken(key.replace(/(__|\.)/i, 'zz'), true).replace('zz', '__');
      const paramValue = Array.isArray(params[key]) ? params[key].join() : params[key];
      return { [paramKey]: paramValue };
    }),
  );

const requestParams = (method, url, params, config) => ({
  method,
  url,
  params: params ? paramsToSnake(params) : null,
  ...config,
});

const requestData = (method, url, data, config, command = false) => ({
  method,
  url,
  data: convertToCollection(data, command),
  ...config,
});

const api = {
  get(url, params, config = {}) {
    return request(requestParams('get', url, params, config));
  },

  post(url, params, config = {}) {
    return request(requestData('post', url, params, config));
  },

  cmd(url, params, config = {}) {
    return request(requestData('post', url, params, config, true));
  },

  put(url, params, config = {}) {
    return request(requestData('put', url, params, config));
  },

  patch(url, params, config = {}) {
    return request(requestData('patch', url, params, config));
  },

  delete(url, params, config = {}) {
    return request(requestParams('delete', url, params, config));
  },

  getAll(links) {
    const getLinks = links.map((link) => requestParams('get', link.url, link.params));
    return requestAll(getLinks);
  },

  // postAll (items) {
  //   const postItems = items.map(item => requestData('post', item.url, item.params))
  //   return requestPostAll(postItems)
  // }
};

export default api;
