import React from 'react';
import PropTypes from 'prop-types';

import ErrorMessage from 'shared/components/AppMessage/ErrorMessage';
import classes from './AppLoading.module.scss';

const LoadingMessage = ({ startupMessage, errorMessage = null }) => {
  if (startupMessage) {
    return (
      <div id="content-header" className={ classes['loading-screen'] } style={ { textAlign: 'center' } }>
        { !errorMessage && (
          <div className={ classes.loading }>
            <span className={ classes['loading-indicator'] } />
            <span className={ classes['loading-text'] }>Loading</span>
            <div className={ classes['startup-message'] }>{ startupMessage }</div>
          </div>
        ) }
        { errorMessage && <ErrorMessage message={ errorMessage } /> }
      </div>
    );
  }
  return null;
};

LoadingMessage.propTypes = {
  startupMessage: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
};

export default LoadingMessage;
