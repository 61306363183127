import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { sortedHealthCheckQuestionnaire } from 'state/healthCheckQuestionnaire/selectors';

import Questionnaire from '../components/Questionnaire';

const mapStateToProps = state => ({
  questionnaire: sortedHealthCheckQuestionnaire(state),
});

export default withRouter(connect(mapStateToProps)(Questionnaire));
