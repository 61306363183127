import { LOCATION_CHANGE } from 'state/locationChange/actions';
import initialState from 'state/initialState';
import _uniq from 'lodash/uniq';
import _parseInt from 'lodash/parseInt';
import * as constants from './constants';

function appReducer(state = initialState.app, action) {
  switch (action.type) {
    case constants.SET_SNAPI_URL:
      return {
        ...state,
        snapiUrl: action.snapiUrl,
      };

    case constants.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    case constants.LOAD_COLLECTIONS_START:
      return {
        ...state,
        loadingCollections: true,
      };
    case constants.LOAD_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loadingCollections: false,
      };
    case constants.LOAD_COLLECTIONS_ERROR:
      return {
        ...state,
        loadingCollections: false,
      };

    case constants.SET_WEPAY_STATUS:
      return { ...state, isWePayReady: action.payload };

    case constants.SET_APP_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
        removeMessage: action.temporary || false,
      };
    case constants.SET_APP_NOTICE:
      return {
        ...state,
        noticeMessage: action.payload,
        removeMessage: action.temporary || false,
      };
    case constants.SET_STARTUP_MESSAGE:
      return {
        ...state,
        startupMessage: action.startupMessage,
      };
    case constants.ADD_ROLLOUTS:
      return {
        ...state,
        rollouts: action.rollouts,
      };
    case LOCATION_CHANGE:
      // deletes the error and notice message on the next route change
      if (state.noticeMessage || state.errorMessage) {
        if (state.removeMessage) {
          return {
            ...state,
            noticeMessage: null,
            errorMessage: null,
            removeMessage: false,
          };
        }
        return {
          ...state,
          removeMessage: true,
        };
      }
      return state;
    case constants.SET_LOGGED_IN_MEMBER_ID:
      return {
        ...state,
        loggedInMemberId: _parseInt(action.memberId),
      };
    case constants.SET_ACTIVE_DIVISON_ID:
      return {
        ...state,
        activeDivisionId: _parseInt(action.divisionId),
      };
    case constants.SET_LOADED_DIVISIONS:
      return {
        ...state,
        loadedDivisions: _uniq([...state.loadedDivisions, ...action.loadedDivisions]),
      };
    case constants.SET_LOADED_DIVISION_TEAMS:
      return {
        ...state,
        loadedDivisionTeams: _uniq([...state.loadedDivisionTeams, ...action.loadedDivisionTeams]),
      };
    case constants.SET_LOADED_DIVISION_TEAM_NAMES:
      return {
        ...state,
        loadedDivisionTeamNames: _uniq([...state.loadedDivisionTeamNames, ...action.loadedDivisionTeamNames]),
      };
    default:
      return state;
  }
}

export default appReducer;
