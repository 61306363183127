import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { select, selectAndSortBy, selectAsArray } from 'state/teamsnap/selectors';
import { selectDivisionAndChildDivisionIds } from 'state/teamsnap/divisions/selectors';

const selectTeams = state => select(state, 'teams');
const selectTeamById = (state, id) => selectTeams(state)[_parseInt(id)];
const selectTeamsAsArray = state => selectAsArray(state, 'teams');

const selectCountTeams = state => selectTeamsAsArray(state).length;

const selectAndSortTeamsByDivisionId = createSelector([selectTeams], teams => selectAndSortBy(teams, 'divisionId'));

const selectTeamsByDivisionId = state => divisionId =>
  selectAndSortTeamsByDivisionId(state)[_parseInt(divisionId)] || [];

// get all the teams in the division and all the child divisions
const selectAllTeamsUnderDivisionId = (state, divisionId) => {
  const allTeams = [];
  const divisionIds = selectDivisionAndChildDivisionIds(state, divisionId);
  divisionIds.push(divisionId);
  divisionIds.forEach(id => {
    const teams = selectTeamsByDivisionId(state)(id);
    if (teams && teams.length > 0) {
      allTeams.push(...teams);
    }
  });
  return allTeams;
};

const selectTeamCountUnderDivision = state => divisionId => {
  const teamIds = selectAllTeamsUnderDivisionId(state, divisionId);
  return teamIds.length;
};

export {
  selectAllTeamsUnderDivisionId,
  selectAndSortTeamsByDivisionId,
  selectCountTeams,
  selectTeamCountUnderDivision,
  selectTeams,
  selectTeamsByDivisionId,
  selectTeamById,
  selectTeamsAsArray,
};
