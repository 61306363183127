import history from 'utils/history';
import { setAppNotice, setAppError } from 'state/app/actions';
import {
  loadSavedFilters,
  loadRosteringPendingMode,
  getRosterCountForDivision,
  setCurrentRosteringDivision,
} from 'state/leagueRostering/actions';
import {
  selectLeagueRosteringLoaded,
  selectListSearchResults,
  selectSearchParams,
} from 'state/leagueRostering/selectors';
import { loadingErrorAction, loadingStartAction, loadingSuccessAction } from 'state/loading/actions';
import { loadDivisionsPreferences } from 'state/teamsnap/divisionsPreferences/actions';
import { loadRegistrationFormsByDivisionId } from 'state/teamsnap/registrationForms/actions';
import { loadRegistrationFormItemsByDivisionId } from 'state/teamsnap/registrationFormLineItems/actions';
import { loadRegistrationFormItemOptionsByDivisionId } from 'state/teamsnap/registrationFormLineItemOptions/actions';
import { loadLeagueCustomFieldsByDivisionId } from 'state/teamsnap/leagueCustomFields/actions';
import { loadTeamNamesByDivisionId } from 'state/teamsnap/teamNames/actions';
import { loadAllDivisionAggregates } from 'state/teamsnap/divisionAggregates/actions';
import { loadBatchInvoicesByDivisionId } from 'state/teamsnap/batchInvoices/actions';

import { selectActiveDivisionId } from 'state/app/selectors';
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { selectTeamById } from 'state/teamsnap/teams/selectors';
import { loadItems, addItems } from 'state/teamsnap/actions';

import getErrorMessage from 'utils/errorMessage';
import { linkLeagueRostering } from 'utils/links';
import teamsnapSdk from 'utils/teamsnapSdk';
import { memberName } from 'utils/member';

import * as constants from '../constants';

// ------------------------------------------
// Load actions
// ------------------------------------------

const loadLeagueRosterStart = () => loadingStartAction('leagueRostering');

const loadLeagueRosterSuccess = () => loadingSuccessAction('leagueRostering');

const loadLeagueRosterError = error => loadingErrorAction('leagueRostering', error);

const initLeagueRoster = () => (dispatch, getState) => {
  if (selectLeagueRosteringLoaded(getState())) {
    return Promise.resolve();
  }
  dispatch(loadLeagueRosterStart());
  const activeDivision = selectActiveDivision(getState()) || '';

  return Promise.all([
    dispatch(loadDivisionsPreferences(activeDivision.rootId)),
    dispatch(loadSavedFilters()),
    dispatch(loadRosteringPendingMode()),
    dispatch(loadRegistrationFormsByDivisionId(activeDivision.rootId)),
    dispatch(loadRegistrationFormItemsByDivisionId(activeDivision.rootId)),
    dispatch(loadRegistrationFormItemOptionsByDivisionId(activeDivision.rootId)),
    dispatch(loadBatchInvoicesByDivisionId(activeDivision.rootId)),
  ]).then(
    () => {
      dispatch(loadLeagueRosterSuccess());
      dispatch(loadAllDivisionAggregates());
      dispatch(loadTeamNamesByDivisionId(activeDivision.id));
      dispatch(setCurrentRosteringDivision(activeDivision.id));
      return dispatch(getRosterCountForDivision(activeDivision.id));
    },
    error => dispatch(loadLeagueRosterError(getErrorMessage(error))),
  );
};

// ------------------------------------------
// Search Filter
// ------------------------------------------

const searchDivisionMembersStart = searchParams => ({
  type: constants.SEARCH_DIVISION_MEMBERS_START,
  searchParams,
});

const searchDivisionMembersSuccess = (searchParams, members) => ({
  type: constants.SEARCH_DIVISION_MEMBERS_SUCCESS,
  searchParams,
  members,
});

const searchDivisionMembersError = (searchParams, error) => ({
  type: constants.SEARCH_DIVISION_MEMBERS_ERROR,
  searchParams,
  error,
});

// birthday='operator:less_than,value:2010-02-02'
// :birthday => "operator:greater_than,value:2010-02-02"
// :team_id => team.id
// :is_manager => true
// :gender => "Male"
// :first_name=> "operator:begins_with,value:bo"
// :first_name=> "operator:contains,value:an"
// :email => "bob@gmail.com"
// "custom_field[#{custom_field_1.id}]" => "value:value",
// "custom_field[#{custom_field_2.id}]" => "value:plus"

const searchAllDivisionMembers = () => (dispatch, getState) => {
  const divisionId = selectActiveDivisionId(getState());
  const searchParams = selectSearchParams(getState());
  const finalSearch = {
    divisionId,
    pageSize: 100,
    pageNumber: 1,
    ...searchParams,
  };
  dispatch(searchDivisionMembersStart(finalSearch));

  return teamsnapSdk()
    .divisionAdvancedLoadMembers(finalSearch)
    .then(
      members => {
        dispatch(addItems({ items: members, loadParams: finalSearch }));
        dispatch(searchDivisionMembersSuccess(finalSearch, members));
      },
      error => {
        dispatch(searchDivisionMembersError(getErrorMessage(error), finalSearch));
      },
    );
};

// ------------------------------------------
// Click bulk action member
// ------------------------------------------

const clickAllMembers = checked => (dispatch, getState) => {
  const searchResults = selectListSearchResults(getState()) || [];

  dispatch({
    type: constants.LIST_CLICK_ALL_MEMBERS,
    selectedAllMembers: !!checked,
    checkedMemberIds: searchResults,
  });
};

const clickMember = (memberId, checked) => ({
  type: constants.LIST_CLICK_MEMBER,
  memberId,
  checked,
});

// ------------------------------------------
// Change Team
// ------------------------------------------

const setChangeTeamMemberId = (memberId = null) => ({
  type: constants.SET_CHANGE_TEAM_MEMBER_ID,
  memberId,
});

const setChangeTeamDivisionId = (divisionId = null) => ({
  type: constants.SET_CHANGE_TEAM_DIVISION_ID,
  divisionId,
});

const setChangeTeamTeamId = (teamId = null) => ({
  type: constants.SET_CHANGE_TEAM_TEAM_ID,
  teamId,
});

const changeTeamStart = (member = {}, divisionId = null, teamId = null) => ({
  type: constants.CHANGE_TEAM_START,
  member,
  divisionId,
  teamId,
});

const changeTeamSuccess = (member = {}, divisionId = null, teamId = null) => ({
  type: constants.CHANGE_TEAM_SUCCESS,
  member,
  divisionId,
  teamId,
});

const changeTeamError = (member = {}, divisionId = null, teamId = null, error = '') => ({
  type: constants.CHANGE_TEAM_ERROR,
  member,
  divisionId,
  teamId,
  error,
});

const changeTeam = (member, divisionId, teamId) => (dispatch, getState) => {
  if (member.divisionId === divisionId && member.teamId === teamId) {
    return setAppError('No changes made. This person was already on the selected team.');
  }

  dispatch(changeTeamStart(member, divisionId, teamId));
  return teamsnapSdk()
    .moveMemberToTeam({ member, teamId, divisionId })
    .then(
      () => {
        dispatch(changeTeamSuccess(member, divisionId, teamId));

        const activeDivisionId = selectActiveDivisionId(getState());
        const team = selectTeamById(getState(), teamId);

        const newMember = { ...member };
        newMember.divisionId = divisionId;
        newMember.teamId = teamId;
        dispatch(addItems({ items: [newMember] }));

        const teamName = team ? team.name : 'unassigned';

        dispatch(setAppNotice(`${memberName(member)} has been moved to ${teamName}.`));
        history.push(linkLeagueRostering(activeDivisionId));
      },
      error => {
        const errorMessage = getErrorMessage(error);
        dispatch(changeTeamError(member, divisionId, teamId, errorMessage));
        dispatch(setAppError(errorMessage, true));
      },
    );
};

export {
  initLeagueRoster,
  searchDivisionMembersStart,
  searchDivisionMembersSuccess,
  searchDivisionMembersError,
  searchAllDivisionMembers,
  clickAllMembers,
  clickMember,
  setChangeTeamMemberId,
  setChangeTeamDivisionId,
  setChangeTeamTeamId,
  changeTeamStart,
  changeTeamSuccess,
  changeTeamError,
  changeTeam,
};
