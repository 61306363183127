import React from 'react';
import FontIcon from 'shared/toolkit/FontIcon';

const FontIconCoach = props => <FontIcon name="ss-stopwatch" title="Coach" { ...props } />;
const FontIconCommissioner = props => <FontIcon name="ss-star" title="Commissioner" { ...props } />;
const FontIconDivisions = props => <FontIcon name="ss-medal" title="Divisions" { ...props } />;
const FontIconManager = props => <FontIcon name="ss-megaphone" title="Manager" { ...props } />;
const FontIconMember = props => <FontIcon name="ss-user" title="Member" { ...props } />;
const FontIconMembers = props => <FontIcon name="ss-users" title="Members" { ...props } />;
const FontIconPendingChanges = props => <FontIcon name="ss-write" title="Pending Changes" { ...props } />;
const FontIconPendingMemberAddition = props => <FontIcon name="ss-adduser" title="Pending Addition" { ...props } />;
const FontIconPendingMemberRemoval = props => <FontIcon name="ss-deleteuser" title="Pending Removal" { ...props } />;
const FontIconTeams = props => <FontIcon name="ss-usergroup" title="Teams" { ...props } />;

export {
  FontIconCoach,
  FontIconCommissioner,
  FontIconDivisions,
  FontIconManager,
  FontIconMember,
  FontIconMembers,
  FontIconPendingChanges,
  FontIconPendingMemberAddition,
  FontIconPendingMemberRemoval,
  FontIconTeams,
};
