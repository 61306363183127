/**
 * FontIcon Component
 *
 * FontIcon is a component that render the appropriate Font Icon (not inline svg)
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Icon Sizes
const ICON_SIZES = {
  default: '90%',
  xxsmall: '10px',
  xsmall: '15px',
  small: '20px',
  medium: '30px',
  large: '40px',
  xlarge: '50px',
  xxlarge: '60px',
};

class FontIcon extends PureComponent {
  render() {
    const { className, name, size } = this.props;
    const fontClassName = `${className} ${name}`;

    return <i className={ fontClassName } style={ { fontSize: ICON_SIZES[size] || size } } />;
  }
}

FontIcon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

FontIcon.defaultProps = {
  size: 'default',
  className: '',
};

export default FontIcon;
