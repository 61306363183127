import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImgRedXSmall } from 'shared/components/Img';
import { displayFileSize } from 'utils/fileSize';
import ClubEmailAttachment from './ClubEmailAttachment';

import styles from './ClubEmailAttachments.module.scss';

class ClubEmailAttachments extends Component {
  static propTypes = {
    attachments: PropTypes.array,
    uploadedAttachments: PropTypes.array,
    addAttachment: PropTypes.func.isRequired,
    updateAttachment: PropTypes.func.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    deleteBroadcastEmailAttachment: PropTypes.func.isRequired,
    totalAttachmentsSize: PropTypes.number.isRequired,
  };

  deleteUploadedAttachment = attachmentId => {
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm("Are you sure you want to delete this attachment?. This can't be undone.")
    ) {
      const broadcastEmailAttachment = this.props.uploadedAttachments.find(
        attachment => attachment.id === attachmentId,
      );
      this.props.deleteBroadcastEmailAttachment(broadcastEmailAttachment);
    }
  };

  render() {
    const {
      addAttachment,
      updateAttachment,
      deleteAttachment,
      attachments,
      totalAttachmentsSize,
      uploadedAttachments,
    } = this.props;

    return (
      <div className="FormGroup u-spaceTopLg">
        <label className="FormGroup-label">File Attachments:</label>
        <small>Maximum size: 20 MB (combined)</small>

        <div id="file_list">
          { attachments &&
            attachments.map(attachment => (
              <ClubEmailAttachment
                key={ attachment.id }
                attachment={ attachment }
                updateAttachment={ updateAttachment }
                deleteAttachment={ deleteAttachment }
              />
            )) }
        </div>

        <div className="u-spaceTopSm">
          <a onClick={ addAttachment }>Add File...</a>
        </div>

        { uploadedAttachments && uploadedAttachments.length > 0 && (
          <div className="FormGroup u-spaceTopMd">
            <label className="FormGroup-label">Uploaded Attachments</label>
            <table className={ styles.clubEmailAddedAttachments }>
              <tbody>
                { uploadedAttachments.map(attachment => (
                  <tr key={ attachment.id }>
                    <td>{ attachment.fileFileName }</td>
                    <td style={ { textAlign: 'right' } }>{ displayFileSize(attachment.fileFileSize) }</td>
                    <td>
                      <a
                        className="button small danger"
                        style={ { float: 'right' } }
                        // eslint-disable-next-line
                        onClick={this.deleteUploadedAttachment.bind(this, attachment.id)}
                      >
                        <i>
                          <ImgRedXSmall />
                        </i>
                        <span>Delete</span>
                      </a>
                    </td>
                  </tr>
                )) }
              </tbody>
            </table>
          </div>
        ) }
        <div className="u-spaceTopMd">Total Size: { displayFileSize(totalAttachmentsSize) }</div>
      </div>
    );
  }
}

export default ClubEmailAttachments;
