import React from 'react';
import PropTypes from 'prop-types';
import { ImgLoadingDots } from 'shared/components/Img';
import classes from './DotLoading.module.scss';

const DotLoading = ({ loading, children }) => {
  if (loading) {
    return (
      <div className={ classes['loader-indicator'] }>
        <ImgLoadingDots />
      </div>
    );
  }
  return children || <div />;
};

DotLoading.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default DotLoading;
