import _parseInt from 'lodash/parseInt';
import { createSelector } from 'reselect';
import { select, selectAndSortBy } from 'state/teamsnap/selectors';
import { selectOwnerByTeamId } from 'state/teamsnap/members/selectors';

const selectMemberEmailAddresses = state => select(state, 'memberEmailAddresses');
const selectMemberEmailAddressById = (state, id) => selectMemberEmailAddresses(state)[_parseInt(id)];

const selectAndSortMemberEmailAddressesByMemberId = createSelector(
  [selectMemberEmailAddresses],
  contactEmailAddresses => selectAndSortBy(contactEmailAddresses, 'memberId'),
);

const selectMemberEmailAddressesByMemberId = state => memberId =>
  selectAndSortMemberEmailAddressesByMemberId(state)[_parseInt(memberId)] || [];

const selectOwnerMemberEmailAddressesByTeamId = (state, teamId) => {
  const selectedMemberEmailAddresses = [];
  const teamOwner = selectOwnerByTeamId(state, teamId, true);
  const memberEmailAddresses = selectMemberEmailAddresses(state);

  Object.keys(memberEmailAddresses).forEach(key => {
    const memberEmailAddress = memberEmailAddresses[key];
    if (memberEmailAddress.teamId === teamId && memberEmailAddress.memberId === teamOwner.id) {
      selectedMemberEmailAddresses.push(memberEmailAddress);
    }
  });
  return selectedMemberEmailAddresses;
};

export {
  selectMemberEmailAddresses,
  selectMemberEmailAddressById,
  selectAndSortMemberEmailAddressesByMemberId,
  selectMemberEmailAddressesByMemberId,
  selectOwnerMemberEmailAddressesByTeamId,
};
