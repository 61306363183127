/* eslint no-underscore-dangle: 0 */

/**
 * Modal Component
 *
 * Basic modal component that requires children to be passed down.  You can also
 * pass options such as `overlay`, `handleClose` and an `axis` to offset the modal.
 *
 * TODO:
 *  -> The modal axis offset does not appear to be calculating correctly.
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@teamsnap/teamsnap-ui';
import defaultStyles from './Modal.module.scss';

class Modal extends Component {
  componentDidMount() {
    if (this.props.handleClose) {
      window.__app_root.addEventListener('keydown', this.handleKeydown, true);
      window.__app_root.addEventListener('click', this.handleDocumentClick, false);
    }
    document.body.classList.add('modal-open');
  }

  componentWillUnmount() {
    if (this.props.handleClose) {
      window.__app_root.removeEventListener('keydown', this.handleKeydown, true);
      window.__app_root.removeEventListener('click', this.handleDocumentClick, false);
    }
    document.body.classList.remove('modal-open');
  }

  handleKeydown = event => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      this.props.handleClose();
    }
  };

  handleDocumentClick = e => {
    const modalArea = this.modal;

    if (!modalArea.contains(e.target)) {
      this.props.handleClose();
    }
  };

  handleOverlayClick = () => {
    const { handleClose } = this.props;
    return handleClose ? handleClose() : null;
  };

  renderHeaderIcon = icon => {
    if (!icon) {
      return null;
    }
    return <Icon name={ icon } style={ { fontSize: '90%', marginRight: '4px' } } />;
  };

  render() {
    const {
      title,
      overlay,
      children,
      axis: { x, y },
      componentStyles,
      className,
      headerIcon,
    } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <div
        ref={ modal => {
          this.modal = modal;
        } }
        className={ styles[className] }
      >
        { overlay && <div className={ styles.ModalOverlay } onClick={ this.handleOverlayClick } role="presentation" /> }
        <div className={ styles.ModalContainer }>
          <div className={ styles.ModalContent }>
            <h3 className={ styles.ModalHeader }>
              { this.renderHeaderIcon(headerIcon) }
              { title }
            </h3>
            { children }
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  overlay: PropTypes.bool,
  axis: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
  headerIcon: PropTypes.string,
};

Modal.defaultProps = {
  title: null,
  handleClose: null,
  overlay: false,
  axis: {},
  componentStyles: {},
  className: 'Modal',
  headerIcon: null,
};

export default Modal;
