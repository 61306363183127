import { connect } from 'react-redux';

// Actions
import {
  initLeagueRoster,
  applyFilteredResults,
  clearCurrentFilter,
  loadInitialPlayers,
  savePreviousFilteredSession,
} from 'state/leagueRostering/actions';
// Selectors
import {
  selectFilteredPlayersCount,
  selectHasFilters,
  selectIsSearching,
  selectLoadingLeagueRoster,
  selectCurrentFilter,
} from 'state/leagueRostering/selectors';
import { selectActiveDivision, selectDivisionAndChildDivisionIds } from 'state/teamsnap/divisions/selectors';
// Components
import LeagueRoster from '../components/LeagueRoster';

const mapDispatchToProps = (dispatch, ownProps) => ({
  applyFilter: () => {
    dispatch(applyFilteredResults());
  },
  clearFilter: () => {
    dispatch(clearCurrentFilter());
  },
  initLeagueRoster: () => {
    dispatch(initLeagueRoster());
  },
  loadInitialPlayers: () => {
    dispatch(loadInitialPlayers(ownProps.defaultLoadCount));
  },
  savePreviousFilteredSession: () => {
    dispatch(savePreviousFilteredSession());
  },
});

const mapStateToProps = state => ({
  division: selectActiveDivision(state),
  activeDivisionTreeIds: selectDivisionAndChildDivisionIds(state, selectActiveDivision(state)?.id),
  countOfPlayersInFilter: selectFilteredPlayersCount(state),
  hasFilter: selectHasFilters(state),
  isSearching: selectIsSearching(state),
  loading: selectLoadingLeagueRoster(state),
  currentFilter: selectCurrentFilter(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeagueRoster);
