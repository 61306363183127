import _parseInt from 'lodash/parseInt';
import { select } from 'state/teamsnap/selectors';
import { emptyDivisionAggregate } from 'state/teamsnap/divisionAggregates/constants';

const selectDivisionAggregates = state => select(state, 'divisionAggregates');
const selectDivisionAggregateByDivisionId = (state, divisionId) =>
  selectDivisionAggregates(state)[_parseInt(divisionId)];

const selectDivisionAggregateByDivisionIdOrEmpty = (state, divisionId) =>
  selectDivisionAggregateByDivisionId(state, divisionId) || emptyDivisionAggregate;

const selectDivisionAggregateDescendantAllMemberCount = (state, divisionId) => {
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId);
  return divisionAggregate.descendantsAssignedMemberCount + divisionAggregate.descendantsUnassignedMemberCount;
};

const selectDivisionAggregateSelfAndDescendantAllMemberCount = (state, divisionId) => {
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId);
  return (
    divisionAggregate.selfAndDescendantsAssignedMemberCount + divisionAggregate.selfAndDescendantsUnassignedMemberCount
  );
};

const selectDivisionAggregateSelfAndDescendantPendingChangesCount = (state, divisionId) => {
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId);
  return (
    divisionAggregate.selfAndDescendantsPendingMemberCount +
    divisionAggregate.selfAndDescendantsPendingMemberRemovalCount
  );
};

const selectSelfAndDescendantsCommissionerCount = (state, divisionId) => {
  const divisionAggregate = selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId);
  return divisionAggregate.selfAndDescendantsCommissionerCount;
};

const selectDivisionAggregateSelfAndDescendantsTeamCount = (state, divisionId) =>
  selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId).selfAndDescendantsTeamCount;

const selectDivisionAggregateDescendantAssignedMemberCount = (state, divisionId) =>
  selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId).descendantsAssignedMemberCount;

const selectDivisionAggregateDescendantUnassignedMemberCount = (state, divisionId) =>
  selectDivisionAggregateByDivisionIdOrEmpty(state, divisionId).descendantsUnassignedMemberCount;

export {
  selectDivisionAggregates,
  selectDivisionAggregateByDivisionId,
  selectDivisionAggregateDescendantAllMemberCount,
  selectDivisionAggregateSelfAndDescendantsTeamCount,
  selectDivisionAggregateSelfAndDescendantAllMemberCount,
  selectDivisionAggregateSelfAndDescendantPendingChangesCount,
  selectDivisionAggregateDescendantAssignedMemberCount,
  selectDivisionAggregateDescendantUnassignedMemberCount,
  selectDivisionAggregateByDivisionIdOrEmpty,
  selectSelfAndDescendantsCommissionerCount,
};
