import { connect } from 'react-redux';

import { initViewEmail } from 'state/leagueMessages/actions';

import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { selectBroadcastEmailById } from 'state/teamsnap/broadcastEmails/selectors';
import { selectLoadingViewEmail } from 'state/leagueMessages/selectors';

import ViewEmail from '../components/ViewEmail';

const mapDispatchToProps = {
  initViewEmail,
};

const mapStateToProps = (state, ownProps) => ({
  broadcastEmail: selectBroadcastEmailById(state, ownProps.params.id),
  division: selectActiveDivision(state),
  loading: selectLoadingViewEmail(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmail);
