/**
 * Field Component
 *
 * Styles all inputs with the same wrapper for labels and errors, warning, ect.
 * Requires children to be provided, usually in the form of a single input.
 *
 * TODO:
 *  -> The css needs to be updated in teamsnapUI for better use with our components
 *  -> Add Icons for left or right of inputs
 *  -> Add Required & Disabled styles
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'shared/toolkit/Grid';
import defaultStyles from './Field.module.scss';

class Field extends PureComponent {
  render() {
    const {
      children,
      name,
      label,
      note,
      size,
      meta: { touched, error, warning },
      componentStyles,
      className,
    } = this.props;

    const styles = { ...defaultStyles, ...componentStyles };

    return (
      <Cell size={ size }>
        <div className={ styles[className] }>
          { label && (
            <h5 className={ styles.FieldLabel }>
              <label htmlFor={ name }>{ label }</label>
              { note && <span className={ styles['FieldLabel-note'] }>{ note }</span> }
            </h5>
          ) }

          { children }

          { touched && error && <span className={ styles.FieldError }>{ error }</span> }
          { touched && warning && <span className={ styles.FieldWarning }>{ warning }</span> }
        </div>
      </Cell>
    );
  }
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  note: PropTypes.string,
  size: PropTypes.string,
  meta: PropTypes.shape({}),
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Field.defaultProps = {
  label: null,
  note: null,
  size: '1of1',
  meta: {},
  componentStyles: {},
  className: 'Field',
};

export default Field;
