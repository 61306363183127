import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectActiveDivisionRootId } from 'state/teamsnap/divisions/selectors';
import { initDefaultHealthCheckQuestionnaire } from 'state/healthCheckQuestionnaire/actions';

import Safety from '../components/Safety';

const mapDispatchToProps = {
  initDefaultHealthCheckQuestionnaire,
};

const mapStateToProps = state => ({
  rootDivisionId: selectActiveDivisionRootId(state),

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Safety));
