import React from 'react';
import PropTypes from 'prop-types';
import { Panel, PanelHeader, PanelBody, Icon } from '@teamsnap/teamsnap-ui';
import { linkInvoicingNew, linkRegistrationNew, linkLeagueRostering, linkDirectory, linkMembers } from 'utils/links';

const REL = 'noopener noreferrer';

const linkDirectoryOrMembers = (divisionId, isArchivedDivision) =>
  isArchivedDivision ? linkDirectory(divisionId) : linkMembers(divisionId);

const divisionLinks = (divisionId, isArchivedDivision) => [
  {
    icon: 'credit-card',
    label: 'Send an invoice',
    href: linkInvoicingNew(divisionId),
  },
  {
    icon: 'registration',
    label: 'Start a registration',
    href: linkRegistrationNew(divisionId),
  },
  {
    icon: 'credit-card',
    label: 'Learn more about payments',
    href: 'https://helpme.teamsnap.com/article/597-invoicing-overview',
    rel: REL,
    target: '_blank',
  },
  {
    icon: 'plus',
    label: 'Add or find a member',
    href: linkDirectoryOrMembers(divisionId, isArchivedDivision),
  },
  {
    icon: 'team',
    label: 'Assign a member to a team',
    href: linkLeagueRostering(divisionId),
  },
  {
    icon: 'divisions',
    label: 'Start a tournament for your teams',
    href: 'https://www.teamsnap.com/tournaments',
    target: '_blank',
    rel: REL,
  },
  {
    icon: 'mail',
    label: 'Get help',
    href: 'https://helpme.teamsnap.com/?solvvy=true',
    rel: REL,
    target: '_blank',
  },
];

const renderLink = link => (
  <li key={ link.label } style={ { listStyle: 'none' } } className="u-padBottomMd">
    <a href={ link.href } rel={ link.rel } target={ link.target }>
      <Icon name={ link.icon } mods="u-spaceRightSm" />
      { link.label }
    </a>
  </li>
);

const WidgetLinks = ({ divisionId, isArchivedDivision }) => (
  <Panel mods="u-spaceBottomNone" style={ { height: '100%' } }>
    <PanelHeader title="Snap Links" />
    <PanelBody className="u-padMd">
      <ul className="u-padNone">{ divisionLinks(divisionId, isArchivedDivision).map(renderLink) }</ul>
    </PanelBody>
  </Panel>
);

WidgetLinks.propTypes = {
  divisionId: PropTypes.string.isRequired,
  isArchivedDivision: PropTypes.bool,
};

WidgetLinks.defaultProps = {
  isArchivedDivision: null,
};

export default WidgetLinks;
