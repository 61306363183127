import { loadItems } from 'state/teamsnap/actions';

import { selectDivisions, selectAncestorSelfAndDescendantDivisionIds } from 'state/teamsnap/divisions/selectors';

const loadDivisionAggregates = divisionId => dispatch => dispatch(loadItems('divisionAggregates', { divisionId }));

const loadAllDivisionAggregates = function loadAllDivisionAggregates() {
  return (dispatch, getState) => {
    const divisionIds = Object.keys(selectDivisions(getState()));
    return dispatch(loadDivisionAggregates(divisionIds));
  };
};

const loadAncestorsAndSelfAndDescendantDivisionAggregates = divisionId => (dispatch, getState) => {
  const divisionIds = selectAncestorSelfAndDescendantDivisionIds(getState(), divisionId);
  return dispatch(loadDivisionAggregates(divisionIds));
};

export { loadDivisionAggregates, loadAllDivisionAggregates, loadAncestorsAndSelfAndDescendantDivisionAggregates };
