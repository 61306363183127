/**
 * Registration Date Aggregates
 *
 * Various selectors using reselect for selecting data from state
 *
 */

import _values from 'lodash/values';
import { createSelector } from 'reselect';
import { COLLECTIONS } from 'state/snapi/slice';

const stateSelector = state => state.snapi[COLLECTIONS.REGISTRATION_DATE_AGGREGATE];
const isFetchingSelector = state => stateSelector(state).isFetching;
const itemSelector = state => stateSelector(state).items;

export const selectRegistrationDateAggregateIsFetching = state => isFetchingSelector(state);

export const selectRegistrationDateAggregateValues = state => _values(itemSelector(state)) || [];

export const selectRegistrationDateAggregateValuesByDate = createSelector(
  selectRegistrationDateAggregateValues,
  aggregates => aggregates.sort((a, b) => new Date(a.registeredOn) - new Date(b.registeredOn)),
);

export const selectRegistrationDateAggregates = state => itemSelector(state);
