import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { featureRollout } from 'utils/window';
import { ImgLoadingDots } from 'shared/components/Img';
import { FontIconMember, FontIconPendingChanges } from 'shared/components/ReusedFontIcons';
import classes from './TeamListRowTeam.module.scss';

import RosterAssignmentListContainer from '../containers/RosterAssignmentListContainer';

class TeamListRowTeam extends Component {
  static propTypes = {
    hasLoadedMembersForTeam: PropTypes.bool,
    isLoadingMembersForTeam: PropTypes.bool,
    isShowingMembers: PropTypes.bool,
    loadMembersForTeam: PropTypes.func.isRequired,
    showTeamMembers: PropTypes.func.isRequired,
    teamName: PropTypes.object.isRequired,
    memberCount: PropTypes.number.isRequired,
    pendingChangesCount: PropTypes.number.isRequired,
    isPlayerTab: PropTypes.bool.isRequired,
  };

  loadAndShowTeamMembers = () => {
    if (!this.props.hasLoadedMembersForTeam) {
      this.props.loadMembersForTeam();
    }
    this.props.showTeamMembers();
  };

  renderRosterList = () => {
    const { isLoadingMembersForTeam, isShowingMembers, teamName, isPlayerTab } = this.props;

    if (isShowingMembers) {
      if (isLoadingMembersForTeam) {
        return (
          <div className={ classes['roster-list-loading'] }>
            Loading Members
            <br />
            <ImgLoadingDots />
          </div>
        );
      }
      return <RosterAssignmentListContainer teamName={ teamName } isPlayerTab={ isPlayerTab }/>;
    }
  };

  render() {
    const { isShowingMembers, teamName, memberCount, pendingChangesCount } = this.props;

    return (
      <tr className={ classes.teamListRow }>
        <td className={ classes.teamListItem }>
          <div className={ classes.teamListItemHeader }>
            <a onClick={ this.loadAndShowTeamMembers }>
              <i
                className={
                  isShowingMembers ? classes['indicator--ss-navigatedown'] : classes['indicator--ss-navigateright']
                }
              />
              { teamName.name }
            </a>
            <div className={ classes.countContainer }>
              <span className={ classes.count }>
                { memberCount }
                <FontIconMember />
              </span>
              <span className={ classes.count }>
                { pendingChangesCount }
                <FontIconPendingChanges className="u-colorHighlight" />
              </span>
            </div>
          </div>
          { this.renderRosterList() }
        </td>
      </tr>
    );
  }
}

export default TeamListRowTeam;
