import { notify } from '../middleware/exceptionNotifier';

export default function getErrorMessage(error) {
  let localError = error;
  if (Array.isArray(error) && error.length === 3) {
    localError = error[0];
  }
  const errorMessage = localError.statusText || localError.status || localError.message;

  notify('SDK Action', errorMessage, error);
  console.error(errorMessage); // eslint-disable-line

  return errorMessage;
}
