// ------------------------------------
// Constants
// ------------------------------------
export const LOAD_LEAGUE_MESSAGES_START = 'leagueMessages.LOAD_LEAGUE_MESSAGES_START';
export const LOAD_LEAGUE_MESSAGES_SUCCESS = 'leagueMessages.LOAD_LEAGUE_MESSAGES_SUCCESS';
export const LOAD_LEAGUE_MESSAGES_ERROR = 'leagueMessages.LOAD_LEAGUE_MESSAGES_ERROR';

// ------------------------------------
// Email Constants
// ------------------------------------
export const LOAD_CLUB_EMAIL_START = 'leagueMessages.LOAD_CLUB_EMAIL_START';
export const LOAD_CLUB_EMAIL_SUCCESS = 'leagueMessages.LOAD_CLUB_EMAIL_SUCCESS';
export const LOAD_CLUB_EMAIL_ERROR = 'leagueMessages.LOAD_CLUB_EMAIL_ERROR';

export const LOAD_VIEW_EMAIL_START = 'leagueMessages.LOAD_VIEW_EMAIL_START';
export const LOAD_VIEW_EMAIL_SUCCESS = 'leagueMessages.LOAD_VIEW_EMAIL_SUCCESS';
export const LOAD_VIEW_EMAIL_ERROR = 'leagueMessages.LOAD_VIEW_EMAIL_ERROR';

export const SET_BROADCAST_EMAIL = 'leagueMessages.SET_BROADCAST_EMAIL';
export const CLEAR_BROADCAST_EMAIL = 'leagueMessages.CLEAR_BROADCAST_EMAIL';

export const SET_SUBJECT = 'leagueMessages.SET_SUBJECT';
export const SET_BODY = 'leagueMessages.SET_BODY';
export const SET_RECIPIENT = 'leagueMessages.SET_RECIPIENT';
export const SET_ALERT_RECIPIENT = 'leagueMessages.SET_ALERT_RECIPIENT';
export const SET_FROM_EMAIL_ADDRESS = 'leagueMessages.SET_FROM_EMAIL_ADDRESS';

export const SAVING_EMAIL_STATUS = 'leagueMessages.SAVING_EMAIL_STATUS';

export const CLEAR_ATTACHMENTS = 'leagueMessages.CLEAR_ATTACHMENTS';
export const ADD_ATTACHMENT = 'leagueMessages.ADD_ATTACHMENT';
export const UPDATE_ATTACHMENT = 'leagueMessages.UPDATE_ATTACHMENT';
export const UPDATE_ATTACHMENT_PROGRESS = 'leagueMessages.UPDATE_ATTACHMENT_PROGRESS';
export const DELETE_ATTACHMENT = 'leagueMessages.DELETE_ATTACHMENT';

export const CLICK_DELETE_ALL_EMAILS = 'leagueMessages.CLICK_DELETE_ALL_EMAILS';
export const CLICK_DELETE_EMAIL = 'leagueMessages.CLICK_DELETE_EMAIL';
export const DELETE_EMAIL = 'leagueMessages.DELETE_EMAIL';
