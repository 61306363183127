/**
 * Table Component
 *
 * The table component, accepts an array of rows and columns that will automatically
 * generate the appropriate column and row structure for you.  The keys will
 * default to an ID, if no id is found it will generate one for you.  You can also
 * pass in children and generate your own table rows if needed.
 *
 * Table uses the Panel component to generate it's basic design styles with some
 * overides.
 *
 * TODO:
 *  -> Add DefaultFilter && DefaultSorting
 *  -> Might be easier to Sort & Filter by a data-attribute as opposed to the field,
 *     sometimes a component is passed that makes this difficult to accurately sort.
 *  -> Need to do some testing on the PureComponent -vs- Component
 *  -> Look into Virtualization.  Pagination works fine, but we might be able to get
 *     even more performance and flexibility with virtualization.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Heading, Grid, Cell, Filter, Paginate, Panel, TableHeader, TableRow, Loader } from 'shared/toolkit';
import sortBy from 'utils/sorter';
import { updateItems, setFilters, filterItems } from './tableHelpers';
import defaultStyles from './Table.module.scss';

class Table extends PureComponent {
  state = {
    items: [],
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    sort: null,
    sortReverse: false,
    isFilterable: false,
    filter: null,
    filterCategories: [],
    filterValue: '',
  };

  UNSAFE_componentWillMount() {
    const { rows, columns, paginate, perPage, defaultSort, reverseSort } = this.props;
    this.setState({ sortReverse: reverseSort });
    const filterable = setFilters(columns);
    const items = updateItems(rows);
    const sortedItems = defaultSort ? sortBy(defaultSort, items, reverseSort) : items;

    // If paginate is false, set perPage to all items.
    const itemsPerPage = !paginate ? sortedItems.length : perPage;

    this.setTableState(sortedItems, {
      ...filterable,
      itemsPerPage,
      sort: defaultSort,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { rows } = nextProps;
    const { filter, filterValue, sort, sortReverse } = this.state;
    const items = filterItems(updateItems(rows), filter, filterValue, sort, sortReverse);

    this.setTableState(items);
  }

  setTableState = (items, props = {}) => {
    const totalItems = items.length;
    const perPage = props.itemsPerPage || this.state.itemsPerPage;
    const totalPages = Math.ceil(totalItems / perPage);

    // If totalPages >= previous state, reset to totalPages
    const currentPage = totalPages >= this.state.totalPages ? this.state.currentPage : 1;

    this.setState({
      items,
      totalItems,
      totalPages,
      currentPage,
      ...props,
    });
  };

  handlePageLinkClick = e => {
    e.preventDefault();
    const nextPage = e.target.hash.substr(1);
    const currentPage = Number(nextPage);
    this.setState({ currentPage });
  };

  handleSortClick = e => {
    e.preventDefault();
    const sort = e.target.getAttribute('href');
    let sortReverse = false;

    if (sort === this.state.sort) {
      sortReverse = !this.state.sortReverse;
    }

    // Should sorting always reset currentPage to 1?
    const items = sortBy(sort, this.state.items, sortReverse);

    this.setTableState(items, {
      sort,
      sortReverse,
    });
  };

  handleSelectChange = e => {
    const filter = e.target.value;
    const { filterValue, sort, sortReverse } = this.state;
    const items = filterItems(this.props.rows, filter, filterValue, sort, sortReverse);

    this.setTableState(items, {
      filter,
    });
  };

  handleOnKeyUp = e => {
    const { items, filter, currentPage, totalPages, sort, sortReverse } = this.state;
    const { rows } = this.props;
    const filterValue = e.target.value.toLowerCase();

    const activeItems = e.keyCode === 8 || e.keyCode === 46 ? rows : items;
    const sortedItems = filterItems(activeItems, filter, filterValue, sort, sortReverse);

    this.setTableState(sortedItems, {
      currentPage: currentPage > totalPages ? 1 : currentPage,
      filterValue,
    });
  };

  renderCustomHeader = () => {
    const { headerComponent } = this.props;
    return <Cell size="fit"> { headerComponent } </Cell>;
  };

  renderPagination = (itemCounter, className) => {
    const { currentPage, totalPages } = this.state;
    const { loadingMessage } = this.props;

    // Reset pagination counts if loading
    const pageCounter = !loadingMessage ? itemCounter : '0 - 0 of 0';
    const pageTotal = !loadingMessage ? totalPages : 0;

    return (
      <Paginate
        currentPage={ currentPage }
        totalPages={ pageTotal }
        countDisplay={ pageCounter }
        onClick={ this.handlePageLinkClick }
        className={ className }
      />
    );
  };

  renderFilterHeader = styles => (
    <Cell size="1of1">
      <Heading size={ 5 } componentStyles={ styles }>
        Advanced Search
      </Heading>
    </Cell>
  );

  renderFilter = () => {
    const { filter, filterCategories } = this.state;
    return (
      <Filter
        name="filter"
        onKeyUp={ this.handleOnKeyUp }
        onSelectChange={ this.handleSelectChange }
        categories={ filterCategories }
        selectValue={ filter }
        size="1of2"
      />
    );
  };

  renderTableHeader = columns => (
    <TableHeader
      onSortClick={ this.handleSortClick }
      columns={ columns }
      sort={ this.state.sort }
      sortReverse={ this.state.sortReverse }
    />
  );

  renderTableRow = (row, columns) => <TableRow key={ row.id } row={ row } columns={ columns } />;

  renderLoading = (loadingMessage, styles) => (
    <div className={ styles.TableLoading }>
      <Loader text="" message={ loadingMessage } />
    </div>
  );

  // Calculate currently 'active' items from row items by currentPage
  updateItemCounts = () => {
    const { currentPage, itemsPerPage, totalItems } = this.state;

    const startIndex = totalItems <= itemsPerPage ? 0 * itemsPerPage : (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage);

    const itemCounter = `${totalItems <= 0 ? startIndex : startIndex + 1} - ${
      endIndex > totalItems ? totalItems : endIndex
    } of ${totalItems}`;

    return {
      startIndex,
      endIndex,
      itemCounter,
    };
  };

  render() {
    const {
      loadingMessage,
      columns,
      children,
      headerComponent,
      footerComponent,
      paginate,
      componentStyles,
      showHeader,
    } = this.props;

    const { items, isFilterable } = this.state;
    const styles = { ...defaultStyles, ...componentStyles };

    const { startIndex, endIndex, itemCounter } = this.updateItemCounts();
    const activeItems = items.slice(startIndex, endIndex);

    // Only show table helper grid if needed
    const showTableHelpers = isFilterable || paginate || headerComponent;

    return (
      <div>
        { showTableHelpers && (
          <div className={ styles.TableGrid }>
            <Grid classModifiers="Grid--alignMiddle">
              { isFilterable && this.renderFilterHeader(styles) }
              { isFilterable && this.renderFilter() }
              { paginate && this.renderPagination(itemCounter, 'PaginateRight') }
              { headerComponent && this.renderCustomHeader() }
            </Grid>
          </div>
        ) }

        <Panel
          headerComponent={ columns.length && showHeader ? this.renderTableHeader(columns) : null }
          footerComponent={ footerComponent }
        >
          { loadingMessage && this.renderLoading(loadingMessage, styles) }
          { !loadingMessage && activeItems.map(row => this.renderTableRow(row, columns, styles)) }
          { children }
        </Panel>

        { paginate && this.renderPagination(itemCounter) }
      </div>
    );
  }
}

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.any,
    }),
  ),
  rows: PropTypes.arrayOf(PropTypes.object),
  defaultSort: PropTypes.string,
  paginate: PropTypes.bool,
  perPage: PropTypes.number,
  children: PropTypes.node,
  loadingMessage: PropTypes.string,
  headerComponent: PropTypes.node,
  footerComponent: PropTypes.node,
  componentStyles: PropTypes.shape({}),
  showHeader: PropTypes.bool,
  reverseSort: PropTypes.bool,
};

Table.defaultProps = {
  columns: [],
  rows: [],
  defaultSort: null,
  paginate: true,
  perPage: 50,
  children: null,
  loadingMessage: null,
  headerComponent: null,
  footerComponent: null,
  componentStyles: {},
  showHeader: true,
  reverseSort: false,
};

export default Table;
