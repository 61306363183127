import { connect } from 'react-redux';
import _parseInt from 'lodash/parseInt';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import _without from 'lodash/without';

import { clearAppMessages, setAppError } from 'state/app/actions';
import {
  initClubEmail,
  saveEmail,
  setSubject,
  setBody,
  setRecipient,
  setFromEmailAddress,
} from 'state/leagueMessages/actions';

import { selectActiveDivisionId } from 'state/app/selectors';
import { selectLoadingClubEmail } from 'state/leagueMessages/selectors';
import { selectLoading } from 'state/loading/selectors';
import { selectEmailAddressesByMemberId, selectLoggedInMember } from 'state/teamsnap/members/selectors';
import {
  selectDivisionById,
  selectAndSortDivisionsByParentId,
  selectDivisionLeaves,
  selectDivisionLeavesOrActiveDivision,
} from 'state/teamsnap/divisions/selectors';
import { selectAllTeamNamesUnderDivisionId, selectTeamNamesByDivisionId } from 'state/teamsnap/teamNames/selectors';

/*  This is a container component. Notice it does not contain any JSX,
    nor does it import React. This component is **only** responsible for
    wiring in the actions and state necessary to render a presentational
    component   */

import ClubEmail from '../components/ClubEmail';

/*  Object of action creators (can also be function that returns object).
    Keys will be passed as props to presentational components. Here we are
    implementing our wrapper around increment; the component doesn't
    care   */

function toggleId(ids, id) {
  const index = ids.findIndex(_id => _id === id);
  if (index > -1) {
    ids.splice(index, 1);
  } else {
    ids.push(id);
  }
  return ids;
}

const toggleRecipients = (type, ids) => (dispatch, getState) => {
  const state = getState();
  const { broadcastEmail } = state.leagueMessages;
  const teamNames = selectAllTeamNamesUnderDivisionId(state, selectActiveDivisionId(state));
  const member = selectLoggedInMember(state);

  switch (type) {
    case 'self': {
      let recipientIds;
      if (broadcastEmail.recipientIds && broadcastEmail.recipientIds.length > 0) {
        recipientIds = [];
      } else {
        recipientIds = [member.id];
      }
      return dispatch(
        setRecipient({
          recipientType: 'recipientIds',
          recipientValue: recipientIds,
        }),
      );
    }
    case 'unassigned':
      return dispatch(
        setRecipient({
          recipientType: 'recipientUnassigned',
          recipientValue: !broadcastEmail.recipientUnassigned,
        }),
      );
    case 'commissioners':
      return dispatch(
        setRecipient({
          recipientType: 'recipientAllCommissioners',
          recipientValue: !broadcastEmail.recipientAllCommissioners,
        }),
      );
    case 'team_all': {
      return dispatch(
        setRecipient({
          recipientType: 'teamIds',
          recipientValue: teamNames.map(teamName => teamName.id),
        }),
      );
    }
    case 'team_none':
      return dispatch(
        setRecipient({
          recipientType: 'teamIds',
          recipientValue: [],
        }),
      );
    case 'team_everyone':
      return dispatch(
        setRecipient({
          recipientType: 'managersOnly',
          recipientValue: false,
        }),
      );
    case 'team_managers':
      return dispatch(
        setRecipient({
          recipientType: 'managersOnly',
          recipientValue: true,
        }),
      );
    case 'teams': {
      let teamIds = broadcastEmail.teamIds || [];
      ids.forEach(id => {
        teamIds = toggleId(teamIds, id);
      });
      return dispatch(
        setRecipient({
          recipientType: 'teamIds',
          recipientValue: teamIds,
        }),
      );
    }
    case 'teams_on': {
      return dispatch(
        setRecipient({
          recipientType: 'teamIds',
          recipientValue: _uniq((broadcastEmail.teamIds || []).concat(ids)),
        }),
      );
    }
    case 'teams_off': {
      return dispatch(
        setRecipient({
          recipientType: 'teamIds',
          recipientValue: _without(broadcastEmail.teamIds || [], ...ids),
        }),
      );
    }
    default:
      if (/team_/.exec(type)) {
        let teamIds = broadcastEmail.teamIds || [];
        const parts = type.split('_');
        const id = _parseInt(parts[1]);
        teamIds = toggleId(teamIds, id);
        return dispatch(
          setRecipient({
            recipientType: 'teamIds',
            recipientValue: teamIds,
          }),
        );
      }
  }
};

const convertSelectedRecipients = broadcastEmail => {
  const selectedRecipients = {};

  if (broadcastEmail) {
    if (broadcastEmail.recipientIds && broadcastEmail.recipientIds.length > 0) {
      selectedRecipients.self = true;
    }
    if (broadcastEmail.recipientAllCommissioners) {
      selectedRecipients.commissioners = true;
    }
    if (broadcastEmail.recipientUnassigned) {
      selectedRecipients.unassigned = true;
    }
    if (broadcastEmail.teamIds && broadcastEmail.teamIds.length > 0) {
      broadcastEmail.teamIds.forEach(id => {
        selectedRecipients[`team_${id}`] = true;
      });
    }
    selectedRecipients.managersOnly = !!broadcastEmail.managersOnly;
  }
  return selectedRecipients;
};

const sortTeamNames = teamNames => _sortBy(teamNames, ['name', 'divisionName']);

const mapDispatchToProps = {
  saveEmail,
  setSubject,
  setBody,
  setFromEmailAddress,
  toggleRecipients,
  setAppError,
  clearAppMessages,
  initClubEmail,
};

const mapStateToProps = state => {
  const loggedInMember = selectLoggedInMember(state);
  return {
    teamNames: sortTeamNames(selectAllTeamNamesUnderDivisionId(state, selectActiveDivisionId(state))),
    division: selectDivisionById(state, selectActiveDivisionId(state)),
    divisionsByParentId: selectAndSortDivisionsByParentId(state),
    loading: selectLoadingClubEmail(state),
    loadingDivisionTeamNames: selectLoading(state, 'allDivisionTeamNames'),
    broadcastEmail: state.leagueMessages.broadcastEmail,
    selectedRecipients: convertSelectedRecipients(state.leagueMessages.broadcastEmail),
    modalMessage: state.leagueMessages.savingEmailMessage || '',
    showSavingModal: state.leagueMessages.savingEmail || false,
    attachments: state.leagueMessages.attachments,
    loggedInMember,
    memberEmailAddresses: loggedInMember ? selectEmailAddressesByMemberId(state, loggedInMember.id) : [],
    divisionLeaves: selectDivisionLeavesOrActiveDivision(state),
    teamNamesByDivisionId: selectTeamNamesByDivisionId(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubEmail);
