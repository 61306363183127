import { connect } from 'react-redux';
import { locationChange } from 'state/locationChange/actions';
import { selectActiveDivision } from 'state/teamsnap/divisions/selectors';
import { withRouter } from 'react-router';
import Root from './Root';

const mapDispatchToProps = {
  locationChange,
};

const mapStateToProps = (state, ownProps) => ({
  params: ownProps.params,
  division: selectActiveDivision(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
