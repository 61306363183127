import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadInvoicingHealth } from 'state/invoicingHealth/actions';
import { selectInvoicingHealthValues, selectInvoicingHealthIsFetching } from 'state/invoicingHealth/selectors';
import { linkPaymentSettings, linkInvoicingNew, linkInvoicing } from 'utils/links';
import { calculatePercent } from 'utils/utils';
import InitialLayout from 'shared/components/Widget/InitialLayout';
import AlertLayout from 'shared/components/Widget/AlertLayout';
import CompleteLayout from 'shared/components/Widget/CompleteLayout';
import WidgetContent from 'shared/components/Widget/WidgetContent';
import WidgetActionItems from 'shared/components/Widget/WidgetActionItems';
import { Grid, Cell, ProgressBar } from '@teamsnap/teamsnap-ui';
import styles from 'shared/components/Widget/Widget.module.scss';

class WidgetInvoicing extends PureComponent {
  UNSAFE_componentWillMount() {
    const { loadInvoicingHealth: loadInvoicingHealthData, divisionId, isReady } = this.props;

    if (isReady) {
      loadInvoicingHealthData({ divisionId });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { loadInvoicingHealth: loadInvoicingHealthData, divisionId, isReady } = this.props;
    if (nextProps.isReady !== isReady) {
      loadInvoicingHealthData({ divisionId });
    }
  }

  setInitialProps = sharedProps => {
    const { divisionId, paymentProvider } = this.props;

    return {
      ...sharedProps,
      buttonText: `Get Started with TeamSnap ${paymentProvider === 'wepay' ? 'Payments' : 'Invoicing'}`,
      buttonLocation: paymentProvider === 'wepay' ? linkPaymentSettings(divisionId) : linkInvoicingNew(divisionId),
      buttonColor: 'primary',
      counters: sharedProps.counters.filter(counter => counter.label !== 'Total Invoices'),
      children: (
        <WidgetContent
          heading="Start sending invoices in minutes"
          subheading="Get paid upfront, on time, online. No more trips to the bank. Need we say more? Get started now."
        />
      ),
    };
  };

  setCompleteProps = sharedProps => {
    const {
      invoicingHealth: { invoicesCount, amountCollected, amountDue },
      divisionId,
    } = this.props;

    // TEMP: Add 'total', until added to API endpoint.
    const chartProgress = calculatePercent(amountDue + amountCollected, amountCollected);

    return {
      ...sharedProps,
      chartProgress,
      chartType: amountCollected === 0 ? null : 'radial',
      buttonText: parseInt(invoicesCount, 10) === 0 ? 'Send My First Invoice' : 'Manage My Invoices',
      buttonLocation: parseInt(invoicesCount, 10) === 0 ? linkInvoicingNew(divisionId) : linkInvoicing(divisionId),
      buttonColor: parseInt(invoicesCount, 10) === 0 ? 'primary' : 'neutral',
      children: this.renderProgressBars(),
    };
  };

  setAlertProps = sharedProps => {
    const {
      invoicingHealth: { amountCollected, amountDue, wepayActionReasons },
      divisionId,
      paymentProvider,
    } = this.props;
    const chartProgress = calculatePercent(amountDue, amountCollected);

    const hasAccountAlert = wepayActionReasons ? wepayActionReasons.includes('kyc') : false;
    const hasBankAlert = wepayActionReasons ? wepayActionReasons.includes('bank_account') : false;

    return {
      ...sharedProps,
      chartProgress,
      buttonText: paymentProvider === 'wepay' ? 'Manage My WePay Account' : 'Manage My Invoices',
      buttonLocation: paymentProvider === 'wepay' ? linkPaymentSettings(divisionId) : linkInvoicing(divisionId),
      buttonColor: 'primary',
      countersTop: sharedProps.counters.filter(counter => counter.label !== 'Total Invoices'),
      countersBottom: sharedProps.counters.filter(counter => counter.label === 'Total Invoices'),
      children:
        paymentProvider === 'wepay' ? (
          <WidgetActionItems
            title="WePay Account Status"
            status="action required"
            actionItems={ [
              {
                description: 'Account information complete',
                statusColor: hasAccountAlert ? 'negative' : 'primary',
              },
              {
                description: 'Bank account information required',
                statusColor: hasBankAlert ? 'negative' : 'primary',
              },
            ] }
          />
        ) : (
          <div />
        ),
    };
  };

  renderProgressBars = () => {
    const {
      invoicingHealth: { openInvoicesCount, paidInvoicesCount, overdueInvoicesCount, invoicesCount },
    } = this.props;

    return (
      <div>
        <Grid mods="u-flexAlignItemsCenter">
          <Cell mods="u-size9of24 u-textRight">
            <h3 className={ styles.ProgressLabel }>Open Invoices</h3>
          </Cell>
          <Cell mods="u-sizeFit">
            <span className={ styles.ProgressCount }>{ openInvoicesCount }</span>
          </Cell>
          <Cell mods="u-sizeFill">
            <ProgressBar progress={ calculatePercent(invoicesCount, openInvoicesCount) } />
          </Cell>
        </Grid>
        <Grid mods="u-padTopSm u-flexAlignItemsCenter">
          <Cell mods="u-size9of24 u-textRight">
            <h3 className={ styles.ProgressLabel }>Paid Invoices</h3>
          </Cell>
          <Cell mods="u-sizeFit">
            <span className={ styles.ProgressCount }>{ paidInvoicesCount }</span>
          </Cell>
          <Cell mods="u-sizeFill">
            <ProgressBar progress={ calculatePercent(invoicesCount, paidInvoicesCount) } />
          </Cell>
        </Grid>
        <Grid mods="u-padTopSm u-flexAlignItemsCenter">
          <Cell mods="u-size9of24 u-textRight">
            <h3 className={ styles.ProgressLabel }>Overdue Invoices</h3>
          </Cell>
          <Cell mods="u-sizeFit">
            <span className={ styles.ProgressCount }>{ overdueInvoicesCount }</span>
          </Cell>
          <Cell mods="u-sizeFill">
            <ProgressBar progress={ calculatePercent(invoicesCount, overdueInvoicesCount) } color="negative" />
          </Cell>
        </Grid>
      </div>
    );
  };

  render() {
    const {
      isReady,
      isFetching,
      invoicingHealth: {
        amountInvoicedWithCurrency,
        amountCollectedWithCurrency,
        amountDueWithCurrency,
        hasWepayAccountAlert,
      },
    } = this.props;

    let isLoading = isFetching;

    if (!isReady) {
      isLoading = false;
    }

    const props = {
      headerText: 'Invoicing Overview',
      isLoading,
      iconName: 'piggybank',
      counters: [
        {
          label: 'Collected',
          value: amountCollectedWithCurrency || '$0.00',
          indicatorColor: 'primary',
        },
        {
          label: 'Due',
          value: amountDueWithCurrency || '$0.00',
          indicatorColor: 'neutral',
        },
        {
          label: 'Total Invoiced',
          value: amountInvoicedWithCurrency || '$0.00',
        },
      ],
    };

    // Return the appropriate WidgetState for Invoicing
    if (!isReady) {
      return <InitialLayout { ...this.setInitialProps(props) } />;
    }
    if (hasWepayAccountAlert === 'true') {
      return <AlertLayout { ...this.setAlertProps(props) } />;
    }

    return <CompleteLayout { ...this.setCompleteProps(props) } />;
  }
}

WidgetInvoicing.propTypes = {
  divisionId: PropTypes.string.isRequired,
  loadInvoicingHealth: PropTypes.func.isRequired,
  invoicingHealth: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isReady: PropTypes.bool.isRequired,
  paymentProvider: PropTypes.string,
};

WidgetInvoicing.defaultProps = {
  paymentProvider: null,
};

const mapStateToProps = (state, ownProps) => ({
  invoicingHealth: selectInvoicingHealthValues(state, ownProps.forceView),
  isFetching: selectInvoicingHealthIsFetching(state),
});

export default connect(mapStateToProps, { loadInvoicingHealth })(WidgetInvoicing);
