import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from './RosterAssignmentDivisionBreadcrumb.module.scss';

class RosterAssignmentDivisionBreadcrumb extends Component {
  static propTypes = {
    hierarchy: PropTypes.array,
    activeDivisionId: PropTypes.number,
    selectDivision: PropTypes.func,
    activeDivision: PropTypes.object,
  };

  render() {
    const { hierarchy, activeDivisionId, selectDivision, activeDivision } = this.props;
    return (
      <ul className={ classes.breadcrumb }>
        <li>
          <a onClick={ () => selectDivision(activeDivisionId) } className="ss-home" title={ activeDivision.name } />
        </li>
        { hierarchy &&
          hierarchy.map(division => {
            if (division.id > activeDivisionId) {
              return (
                <li key={ division.id }>
                  <a onClick={ () => selectDivision(division.id) }>{ division.name }</a>
                </li>
              );
            }
          }) }
        { hierarchy.length <= 1 && <li className={ classes.rootName }>{ activeDivision.name }</li> }
      </ul>
    );
  }
}

export default RosterAssignmentDivisionBreadcrumb;
