/**
 * Footer Component
 *
 * Generic footer component that requires children.
 *
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './Footer.module.scss';

class Footer extends PureComponent {
  render() {
    const { children, componentStyles, className } = this.props;
    const styles = { ...defaultStyles, ...componentStyles };

    return <footer className={ styles[className] }>{ children }</footer>;
  }
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  componentStyles: PropTypes.shape({}),
  className: PropTypes.string,
};

Footer.defaultProps = {
  componentStyles: {},
  className: 'Footer',
};

export default Footer;
