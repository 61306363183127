import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource } from 'react-dnd';

import classes from './RosterAssignmentListRow.module.scss';

import RosterMemberRow from './RosterMemberRow';

const memberRow = {
  beginDrag(props) {
    return { memberId: props.member.id };
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class RosterAssignmentListRow extends Component {
  static propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    currentMember: PropTypes.object,
    isDragging: PropTypes.bool.isRequired,
    member: PropTypes.object.isRequired,
    team: PropTypes.shape({}),
    teamName: PropTypes.shape({}),
    pendingDivision: PropTypes.shape({}),
    pendingTeam: PropTypes.shape({}),
    deletePendingMemberByMemberId: PropTypes.func.isRequired,
  };

  formatCssClassNameForMemberPendingMove = (member, teamName) => {
    if (teamName && (member.pendingDivisionId || member.pendingTeamId)) {
      if (member.pendingTeamId === teamName.id) {
        return 'pendingMove--toTeam';
      }
      if (member.pendingTeamId !== teamName.id) {
        return 'pendingMove--fromTeam';
      }
    }

    return null;
  };

  render() {
    const {
      connectDragSource,
      currentMember,
      isDragging,
      member,
      team,
      teamName,
      pendingDivision,
      pendingTeam,
      deletePendingMemberByMemberId,
    } = this.props;

    return connectDragSource(
      <tbody>
        <RosterMemberRow
        /* eslint-disable */
          className={ `drag-row ${isDragging}` ? 'is-dragging ' : `${!member.teamId}` ? classes.disabled : '' }
          currentMember={ currentMember }
          member={ member }
          team={ team }
          teamName={ teamName }
          pendingDivision={ pendingDivision }
          pendingTeam={ pendingTeam }
          pendingMoveClassName={ this.formatCssClassNameForMemberPendingMove(member, teamName) }
          deletePendingMemberByMemberId={ deletePendingMemberByMemberId }
        /* eslint-enable */
        />
      </tbody>,
    );
  }
}

export default DragSource('row', memberRow, collect)(RosterAssignmentListRow);
