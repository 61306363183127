import { SHOW_FEEDBACK, HIDE_FEEDBACK } from '../app/constants';

const showFeedback = feedbackProps => ({
  type: SHOW_FEEDBACK,
  payload: {
    feedbackProps,
  },
});

const hideFeedback = () => ({
  type: HIDE_FEEDBACK,
});

export { showFeedback, hideFeedback };
