import { getDateDifference } from 'utils/date-service';

export function memberName(member) {
  let name = '';
  const { firstName, lastName } = member;
  if (firstName && firstName !== null) {
    name = `${firstName}`;
  }
  if (lastName && lastName !== null) {
    name = `${name} ${lastName}`;
  }
  return name;
}

// Helper method to determine invitation status
export function hasPendingInvitation(member) {
  return !!(member.id == null && member.invitationCode !== null && member.invitationDeclined !== true);
}

export function hasDeclinedInvitation(member) {
  return !!(member.id == null && member.invitationCode !== null && member.invitationDeclined === true);
}

export function displayMemberAge(member, viewingAs, defaultDisplay = '') {
  if (member && member.birthday && (!member.hideAge || viewingAs.isManager || viewingAs.isOwner)) {
    return getDateDifference(member.birthday, 'years', false);
  }
  return defaultDisplay;
}
