"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);
  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function get() {
        return m[k];
      }
    };
  }
  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  }
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Step = void 0;
var React = __importStar(require("react"));
var PropTypes = __importStar(require("prop-types"));
var react_spring_1 = require("react-spring");
var services_1 = require("../../services");
var propTypes = {
  style: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  onStepFocus: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
var Step = function Step(_a) {
  var visible = _a.visible,
    children = _a.children,
    style = _a.style,
    onStepFocus = _a.onStepFocus;
  var opacityRef = (0, react_spring_1.useSpringRef)();
  var opacityStyles = (0, react_spring_1.useSpring)({
    ref: opacityRef,
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    },
    config: {
      duration: 700,
      easing: services_1.Easing.linear
    }
  });
  var translateRef = (0, react_spring_1.useSpringRef)();
  var translateStyles = (0, react_spring_1.useSpring)({
    ref: translateRef,
    from: {
      y: 50
    },
    to: {
      y: 0
    },
    config: {
      duration: 700,
      easing: services_1.Easing.easeOut
    }
  });
  React.useEffect(function () {
    if (visible) {
      if (onStepFocus) {
        onStepFocus();
      }
      translateRef.start();
      opacityRef.start();
    }
    return function () {
      translateRef.set({
        y: 50
      });
      opacityRef.set({
        opacity: 0
      });
    };
  }, [visible, translateRef, opacityRef]);
  return React.createElement(react_spring_1.animated.div, {
    style: opacityStyles
  }, React.createElement(react_spring_1.animated.div, {
    style: translateStyles
  }, React.createElement("div", {
    className: "u-spaceSidesAuto",
    style: style
  }, children)));
};
exports.Step = Step;